import MainCard from 'ui-component/cards/MainCard';
import AuthorList from './authorList/AuthorList';
import WorkList from './workList/WorkList';
import { useEffect, useState } from 'react';

const WORK = 'TAC_PHAM';
const AUTHOR = 'TAC_GIA';

const Creator = () => {

    const [typeView, setTypeView] = useState(AUTHOR);
    const changeTab = (value) => {
        setTypeView(value);
    };

    return (
        <>
            <MainCard
                sx={{ height: { md: 'calc(100dvh - 122px)', xs: 'calc(100dvh - 95px)' } }}
                contentSX={{ padding: { xs: '2px !important', sm: '24px !important' } }}
            >
              {
                typeView===AUTHOR ? 
                    <AuthorList typeView={typeView} changeTab={changeTab}/> 
                : 
                    <WorkList typeView={typeView} changeTab={changeTab} />
              }  
            </MainCard>
        </>
    );
};

export default Creator;
