// assets
import { IconDashboard } from '@tabler/icons';
import LanIcon from '@mui/icons-material/Lan';
import ConfigRouter from 'routes/config_router';

// constant
const icons = { IconDashboard, LanIcon };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: ConfigRouter.genealogy,
    type: 'group',
    children: [
        // {
        //     id: 'default',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard/default',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // },
        {
            id: ConfigRouter.genealogy,
            title: 'Xây dựng phả hệ',
            type: 'item',
            url: ConfigRouter.genealogy,
            icon: icons.LanIcon,
            breadcrumbs: false,
            spMobile: true
        },
        {
            id: ConfigRouter.treeviewer,
            title: 'Phả đồ',
            type: 'item',
            url: ConfigRouter.treeviewer,
            icon: icons.LanIcon,
            breadcrumbs: false,
            spMobile: false
        }
    ]
};

export default dashboard;
