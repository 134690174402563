import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const dataUser = localStorage.getItem('DATA_USER');
    if (!dataUser) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default PrivateRoute;
