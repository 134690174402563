import { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Stack,
    Grid,
    IconButton,
    ImageList,
    Modal,
    ImageListItem,
    ImageListItemBar,
    useMediaQuery,
    Typography,
    MenuItem,
    Menu
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import NotFound from 'ui-component/404/404';
import { Close } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import ModalImageAlbum from 'ui-component/family/ModalImageAlbum';
import DialogUtil from 'ui-component/show_confirm_action';
import ModalListImage from './ModalListImage';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import ModalImage from 'react-modal-image';
import config from 'config';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { xs: '80%', sm: '50%' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 3,
    minWidth: { xs: '80%', sm: '45%' },
    height: { md: '95vh', xs: '85vh' }
};
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const ModalAlbumImage = ({ open, onClose, data, type }) => {
    const imageRef = useRef(null);
    const [dataDelete, setDataDelete] = useState([]);
    const [dataListImage, setDataListImage] = useState([]);
    const customization = useSelector((state) => state.customization);
    const mediumScreen = useMediaQuery('(max-width: 900px)');
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [showAlbum, setShowAlbum] = useState(false);
    const [dataImages, setDataImages] = useState([]);
    const [dataLinks, setDataLinks] = useState([]);
    const [hasMaxImages, setHasMaxImages] = useState(false);
    const [showListImage, setShowlistImage] = useState(false);
    const [anchorElButton, setAnchorElButton] = useState(null);
    const [anchorElImage, setAnchorElImage] = useState(null);
    const openMenuButton = Boolean(anchorElButton);
    const openMenuImage = Boolean(anchorElImage);
    const [openChange, setOpenChange] = useState(false);
    const [selectedImg, setSelectedImg] = useState('');
    const [images, setImages] = useState([]);
    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: 500,
            minWidth: 100,
            // margin: '1rem',
            borderRadius: '5px',
            backgroundColor: 'transparent',
            border: '1px solid #ccc'
        },
        honizontalRoot: {
            width: 500,
            // margin: '1rem',
            borderRadius: '5px',
            backgroundColor: 'transparent',
            border: '1px solid #ccc'
        },
        honizontalLi: {
            width: 490,
            // margin: '1rem',
            height: 160,
            borderRadius: '5px',
            backgroundColor: 'transparent',
            border: '1px solid #ccc',
            [theme.breakpoints.down('sm')]: {
                width: 360,
                // margin: '1rem',
                height: 125
            }
        },
        honizontalMedia: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'start',
            width: '200px',
            height: '160px',
            [theme.breakpoints.down('sm')]: {
                width: 150,
                // margin: '1rem',
                height: 125
            }
        },
        honizontalCardActionArea: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'start'
        },
        // honizontalCardActionArea: {
        //     height: 160,
        //     width: 200,
        //     objectFit: 'cover'
        // },
        media: {
            height: 300,
            width: '100%',
            objectFit: 'contain'
        },
        adddICon: {
            '& svg': {
                fontSize: 100
            }
        },
        styleImg: {
            minHeight: '100px',
            width: '100%'
            // objectFit: 'contain'
        },
        img: {
            filter: 'none',
            WebkitFilter: `grayscale(${100})`,
            '&:hover': {
                filter: `grayscale(${0})`,
                WebkitFilter: `grayscale(${0})`
            },
            minHeight: '100px',
            width: '100%'
            // objectFit: 'contain'
        }
    }));
    const classes = useStyles();
    const onOpenModal = () => setOpenModal(true);

    const onImageChange = (event) => {
        if (hasMaxImages) {
            DialogUtil.showQuestions(false, 'Đã đạt tối đa 20 ảnh. Bạn không thể chọn thêm ảnh nữa.');
            return;
        }
        if (event.target.files && event.target.files[0]) {
            // console.log(event.target.files.length);
            const files = Array.from(event.target.files).slice(0, 20);
            if (files.length !== event.target.files.length) {
                DialogUtil.showQuestions(false, 'Bạn chỉ được chọn tối đa 20 ảnh.');
            }
            const newDataImages = [];
            files.forEach((file) => {
                file.effect = false;
                if (file.type.match(imageMimeType)) {
                    newDataImages.push(file);
                } else {
                    DialogUtil.showQuestions(false, 'Ảnh được chọn phải thuộc file png, jpg hoặc jpeg.');
                }
            });
            const updatedDataImages = dataImages.concat(newDataImages).slice(0, 20);
            setDataImages(updatedDataImages);
            event.target.value = null;

            if (updatedDataImages.length === 20) {
                setHasMaxImages(true);
            }
            // document.querySelector('#buttomRef')?.scrollIntoView();
        }
        setShowlistImage(true);
    };

    const disableSelectAllImage = () => {
        const data = dataListImage.map((item) => {
            if (item.isSelected) {
                item.isSelected = false;
            }
            return item;
        });
        setDataListImage(data);
        setDataDelete([]);
    };

    const onSelectAllImage = () => {
        let arrDelete = [];
        dataListImage.map((item) => {
            if (!item.isSelected) {
                arrDelete.push(item._id);
                item.isSelected = true;
            }
            return item;
        });
        setDataDelete([...dataDelete].concat(arrDelete));
    };

    const onDeleteImage = () => {
        if (dataDelete.length === 0) {
            DialogUtil.showQuestions(false, 'Chưa chọn ảnh để xóa');
            return;
        }
        DialogUtil.showConfirm(
            'Thông báo',
            'Bạn có muốn xóa ảnh đã chọn không ?',
            () => {},
            () => onActionDelete()
        );
    };

    const onActionDelete = async () => {
        const obj = {
            _id: data?._id,
            arrayImage: dataDelete
        };
        const dataRst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseDeleteImage, obj);
        if (dataRst.status === 201) {
            setDataDelete([]);
            DialogUtil.showQuestions(true, 'Xóa ảnh thành công', () => getInfo());
        } else {
            DialogUtil.showQuestions(false, 'Xóa ảnh thất bại');
        }
    };

    const actionOpenAlbum = async () => {
        setShowAlbum(true);
        setOpenModal(false);
    };

    const onClickFolder = () => {
        setOpenModal(false);
        imageRef.current.click();
    };

    const getInfo = async () => {
        setLoading(true);
        const obj = {
            _id: data?._id
        };
        const rst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseDetail, obj);
        if (rst.status === 201) {
            if (rst?.data.length > 0) {
                const listData = rst?.data[0]?.list_image ?? [];
                if (listData.length > 0) {
                    listData.forEach((element) => {
                        element.isSelected = false;
                    });
                    setDataListImage(listData);
                    setImages(listData);
                } else {
                    setDataListImage([]);
                    setImages([]);
                }
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open && data?._id) {
            getInfo();
        }
    }, [open]);

    const onChooseImage = (item) => {
        item.eff = true;
        const arrImage = [...dataImages];
        const arrLink = [...dataLinks];
        arrLink.push(item);
        arrImage.push(item);
        setDataImages(arrImage);
        setDataLinks(arrLink);
        setShowlistImage(true);
    };

    const onChooseItem = (id) => {
        const arrData = [];
        arrData.push(id);
        const arr = dataListImage;
        arr.forEach((element) => {
            if (element._id === id) {
                element.isSelected = !element.isSelected;
            }
        });
        const index = dataDelete.findIndex((f) => f === id);
        if (index === -1) {
            dataDelete.push(id);
        } else {
            dataDelete.splice(index, 1);
        }
        setDataDelete(dataDelete);
        const array = [];
        arr.forEach((file) => {
            array.push(file);
        });
        setDataListImage(array);
    };

    const onCloseModalImage = () => {
        setShowlistImage(false);
        setDataImages([]);
    };

    const showImageModal = (img) => {
        setSelectedImg(img);
        setOpenChange(true);
    };

    const closeImageModal = () => {
        setSelectedImg('');
        setOpenChange(false);
    };

    const handleChangeSelectedImage = (type) => {
        const findIndex = images.findIndex((img, index) => img.urlImage === selectedImg);

        if (type === 'prev' && findIndex === 0) {
            setSelectedImg(images[images.length - 1]?.urlImage);
        }
        if (type === 'prev' && findIndex !== 0) {
            setSelectedImg(images[findIndex - 1]?.urlImage);
        }
        if (type === 'next' && findIndex === images.length - 1) {
            setSelectedImg(images[0]?.urlImage);
        }
        if (type === 'next' && findIndex !== images.length - 1) {
            setSelectedImg(images[findIndex + 1]?.urlImage);
        }
    };

    return (
        <>
            {openChange === false && (
                <Modal
                    open={open}
                    onClose={() => {
                        onClose();
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: { xs: 'column', sm: 'row' },
                                marginBottom: '10px'
                            }}
                        >
                            <Typography 
                                sx={{
                                    wordWrap: 'break-word',
                                }}
                                id="modal-modal-title" 
                                variant="h3" component="h2"
                            >
                                Album ảnh: {data?.name ?? ''}
                            </Typography>
                            <Box
                                sx={{
                                    display: { xs: 'flex', md: 'block' },
                                    justifyContent: 'flex-end'
                                }}
                            >
                                {!type && (
                                    <Button
                                        sx={{
                                            minWidth: '104px',
                                            display: { xs: 'flex', sm: 'none' },
                                            height: { xs: '40px' },
                                            padding: '0px 5px'
                                        }}
                                        id="long-button3"
                                        aria-controls={openMenuButton ? 'fade-menu3' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenuButton ? 'true' : undefined}
                                        variant="contained"
                                        onClick={(e) => setAnchorElButton(e.currentTarget)}
                                        endIcon={<KeyboardArrowDownIcon />}
                                    >
                                        Hành động
                                    </Button>
                                )}

                                <Button
                                    sx={{
                                        display: { xs: 'flex', sm: 'none' },
                                        marginLeft: '10px'
                                    }}
                                    disableElevation
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        onClose();
                                    }}
                                    startIcon={<Close />}
                                >
                                    Đóng
                                </Button>
                                <Menu
                                    id="fade-menu3"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button3'
                                    }}
                                    anchorEl={anchorElButton}
                                    open={openMenuButton}
                                    onClose={() => setAnchorElButton(null)}
                                >
                                    {dataDelete.length > 0 ? (
                                        <MenuItem disabled={dataListImage.length < 1} onClick={disableSelectAllImage}>
                                            <ClearIcon />
                                            Bỏ chọn tất cả
                                        </MenuItem>
                                    ) : (
                                        <MenuItem disabled={dataListImage.length < 1} onClick={onSelectAllImage}>
                                            <DoneAllIcon />
                                            Chọn tất cả
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={onOpenModal}>
                                        <AddIcon />
                                        Thêm
                                    </MenuItem>
                                    <MenuItem onClick={onDeleteImage} disabled={!dataDelete.length > 0}>
                                        <DeleteIcon />
                                        Xóa
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: 'none', sm: 'flex', md: 'block' },
                                    marginTop: { xs: '10px', sm: '0px' }
                                }}
                            >
                                {!type && (
                                    <>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={onOpenModal}
                                            sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '120px', height: '38px' }}
                                            startIcon={<AddIcon />}
                                        >
                                            Thêm
                                        </Button>
                                        <input
                                            ref={imageRef}
                                            type="file"
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            onChange={(e) => onImageChange(e)}
                                            multiple
                                        />
                                    </>
                                )}
                                {!type && (
                                    <>
                                        {dataDelete.length > 0 ? (
                                            <Button
                                                disabled={dataListImage.length < 1}
                                                disableElevation
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={disableSelectAllImage}
                                                sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '120px', height: '38px' }}
                                                startIcon={<ClearIcon />}
                                            >
                                                Bỏ chọn tất cả
                                            </Button>
                                        ) : (
                                            <Button
                                                disabled={dataListImage.length < 1}
                                                disableElevation
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={onSelectAllImage}
                                                sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '120px', height: '38px' }}
                                                startIcon={<DoneAllIcon />}
                                            >
                                                Chọn tất cả
                                            </Button>
                                        )}
                                    </>
                                )}
                                {!type && (
                                    <Button
                                        disableElevation
                                        disabled={!dataDelete.length > 0}
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={onDeleteImage}
                                        startIcon={<DeleteIcon />}
                                        sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '120px', height: '38px' }}
                                    >
                                        Xóa
                                    </Button>
                                )}

                                <Button
                                    disableElevation
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        onClose();
                                    }}
                                    startIcon={<Close />}
                                >
                                    Đóng
                                </Button>
                            </Box>
                        </Stack>
                        <Grid
                            spacing={0}
                            sx={{
                                width: '100%',
                                display: 'block',
                                overflowY: 'auto',
                                height: 'calc(100vh - 270px)',
                                scrollbarWidth: '15px',
                                position: 'relative',
                                '&::-webkit-scrollbar': {
                                    width: '0.2em'
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: customization?.themeDongHo?.heading,
                                    outline: 'slategrey'
                                }
                            }}
                        >
                            {dataListImage.length > 0 ? (
                                <ImageList variant="masonry" cols={mediumScreen ? 2 : 4} gap={8} sx={{ marginBottom: '25px' }}>
                                    {!loading &&
                                        dataListImage.map((item) => (
                                            <ImageListItem key={item._id}>
                                                {/* <ModalImage
                                                className={classes.styleImg}
                                                hideDownload
                                                small={item.urlImage ? `${config.serverImage + item.urlImage}` : null}
                                                large={item.urlImage ? `${config.serverImage + item.urlImage}` : null}
                                                alt=""
                                            /> */}
                                                <img
                                                    src={item.urlImage ? `${config.severImageBase64 + item.urlImage}` : null}
                                                    alt={item?.nameImage ?? ''}
                                                    loading="lazy"
                                                    onClick={() => {
                                                        showImageModal(item.urlImage);
                                                    }}
                                                />
                                                <ImageListItemBar
                                                    sx={{
                                                        '& .MuiImageListItemBar-title': {
                                                            whiteSpace: 'normal'
                                                        },
                                                        '& .MuiImageListItemBar-subtitle': {
                                                            wordWrap: 'break-word',
                                                            whiteSpace: 'inherit',
                                                            width: '200px',
                                                            '&::-webkit-scrollbar': {
                                                                width: '1px',
                                                                height: '2px'
                                                            },
                                                            '&::-webkit-scrollbar-thumb': {
                                                                background: 'none',
                                                                borderRadius: '0.1px'
                                                            }
                                                        },
                                                        '@media (min-width: 1360px)': {
                                                            '& .MuiImageListItemBar-subtitle': {
                                                                width: '110px',
                                                                overflow: 'auto',
                                                                maxHeight: '50px'
                                                            },
                                                            '& .MuiImageListItemBar-title': {
                                                                width: '110px'
                                                            }
                                                        },
                                                        '@media (min-width: 1920px)': {
                                                            '& .MuiImageListItemBar-subtitle': {
                                                                width: '200px',
                                                                overflow: 'auto'
                                                            },
                                                            '& .MuiImageListItemBar-title': {
                                                                width: '210px'
                                                            }
                                                        },
                                                        '@media (max-width: 600px)': {
                                                            '& .MuiImageListItemBar-subtitle': {
                                                                // display: 'none'
                                                                width: '100%',
                                                                fontSize: '8px',
                                                                overflow: 'auto',
                                                                maxHeight: '15px'
                                                            },
                                                            '& .MuiImageListItemBar-title': {
                                                                fontSize: '10px'
                                                            },
                                                            '& .MuiImageListItemBar-titleWrap': {
                                                                padding: '10px 5px'
                                                            }
                                                        },
                                                        justifyContent: { xs: 'center', sm: 'end' }
                                                        // bottom: { xs: !item.nameImage ? '0' : '20px', sm: '0' }
                                                    }}
                                                    title={item.nameImage || ''}
                                                    subtitle={item.description || ''}
                                                    actionIcon={
                                                        <Box sx={{ display: type ? 'none' : 'flex ' }}>
                                                            {/* <Tooltip title="Sửa hình ảnh">
                                                            <IconButton
                                                                sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                                                                size="small"
                                                                onClick={() => handleEditImage(item._id)}
                                                            >
                                                                <EditIcon sx={{ color: 'rgba(255, 255, 255, 0.54)' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Tải hình ảnh">
                                                            <IconButton
                                                                sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                                                                size="small"
                                                                onClick={() => onCLickDownload(item._id)}
                                                            >
                                                                <FileDownloadIcon sx={{ color: 'rgba(255, 255, 255, 0.54)' }} />
                                                            </IconButton>
                                                        </Tooltip> */}
                                                            <IconButton
                                                                sx={{
                                                                    color: 'rgba(255, 255, 255, 0.54)',
                                                                    padding: { xs: '5px', sm: '8px' }
                                                                }}
                                                                onClick={() => onChooseItem(item._id)}
                                                            >
                                                                {item.isSelected ? (
                                                                    <CheckCircleOutlinedIcon sx={{ color: '#10cf10' }} />
                                                                ) : (
                                                                    <RadioButtonUncheckedOutlinedIcon />
                                                                )}
                                                            </IconButton>
                                                        </Box>
                                                    }
                                                />
                                                {/* <Typography
                                                variant="h5"
                                                component="h2"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    backgroundColor: '#ffff',
                                                    textAlign: 'center',
                                                    display: { xs: 'block', sm: 'none' }
                                                }}
                                            >
                                                {item.nameImage || ''}
                                            </Typography> */}
                                            </ImageListItem>
                                        ))}
                                </ImageList>
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {!loading && <NotFound />}
                                </Box>
                            )}
                            {loading && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '100%',
                                        height: '100%',
                                        p: 4,
                                        border: 'none'
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Grid>
                    </Box>
                </Modal>
            )}
            {openChange === true && (
                <ImageModal img={selectedImg} onCloseImageModal={closeImageModal} handleChangeSelectedImage={handleChangeSelectedImage} />
            )}
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '95%', sm: '75%', md: '35%' },
                        bgcolor: 'background.paper',
                        outline: 'none',
                        boxShadow: 24,
                        p: 2
                    }}
                >
                    <Stack spacing={1}>
                        <Typography variant="h3" component="h2">
                            Chọn ảnh
                        </Typography>
                        {/* <Divider sx={{ marginTop: '10px', marginBottom: '15px' }} /> */}
                        <div style={{ border: '0.5px solid #eeeeee', marginTop: '15px', marginBottom: '20px' }} />
                        <Box position="absolute" top={0} right={0}>
                            <Button
                                disableElevation
                                fullWidth
                                size="small"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenModal(false)}
                                sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '80px', marginTop: '10px' }}
                                startIcon={<Close />}
                            >
                                Đóng
                            </Button>
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginBottom: 10,
                                marginTop: 10
                            }}
                        >
                            <Button
                                onClick={actionOpenAlbum}
                                color="primary"
                                variant="contained"
                                startIcon={<FolderIcon />}
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                            >
                                Thêm ảnh từ album dòng họ
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                className="mr-2"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => onClickFolder()}
                                startIcon={<PhotoAlbumIcon />}
                            >
                                Tải ảnh lên
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Modal>
            {/* <ModalImageAlbum open={showAlbum} onCloseModal={() => setShowAlbum(false)} onSlelect={onChooseImage} /> */}
            <ModalListImage
                dataImageProp={dataImages}
                open={showListImage}
                onCloseModal={() => onCloseModalImage()}
                onSlelect={onChooseImage}
                imageFatherRef={imageRef}
                data={data}
                onReload={() => getInfo()}
            />
            <ModalImageAlbum open={showAlbum} onCloseModal={() => setShowAlbum(false)} onSlelect={onChooseImage} />
        </>
    );
};

export default ModalAlbumImage;

const ImageModal = ({ img, onCloseImageModal, handleChangeSelectedImage }) => {
    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: '0',
                left: '0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.9)',
                zIndex: 9999
            }}
        >
            <IconButton
                sx={{ position: 'fixed', top: '10px', right: '10px', color: 'white', zIndex: 99999, fontSize: '20px' }}
                onClick={onCloseImageModal}
            >
                <CloseIcon />
            </IconButton>
            <Box>
                <ArrowBackIosNewIcon
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: '10px',
                        color: 'white',
                        zIndex: 99999,
                        fontSize: '25px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        handleChangeSelectedImage('prev');
                    }}
                />
                <ArrowForwardIosIcon
                    sx={{
                        position: 'fixed',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '10px',
                        color: 'white',
                        zIndex: 99999,
                        fontSize: '25px',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        handleChangeSelectedImage('next');
                    }}
                />
            </Box>
            <img style={{ maxHeight: '95vh', maxWidth: '95vw' }} src={`${config.severImageBase64 + img}`}></img>
        </Box>
    );
};
