import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import { dataUser,DATA_GENRE, DATA_RELIGION, DATA_NATIONAL, DATA_ETHNIC, DATA_FAMILY_TITLE, DATA_FAMILY_LOGO, DATA_FONT, DATA_ATHOUR_ROLE, DATA_LANGUAGE, DATA_CAREER, DATA_EDUCATION, DATA_PRIZE } from 'store/constant';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import LoadingButton from '@mui/lab/LoadingButton';

import config from '../../../../config';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { DATA_USER, DATA_FAMILY, ID_USER } from 'store/action/actions';
import fetchClient from '../../../utilities/RestfulAPI';
import router from '../../../../routes/router';
import DialogUtil from 'ui-component/show_confirm_action';
import ConfigRouter from 'routes/config_router';
import BackgroundRongPhuong from '../../../../assets/images/icons/RongPhuong.svg';

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({typeLogin, ...others }) => {
    const { type } = others;
    const navigate = useNavigate();
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [logoDb, setLogoDb] = useState('');
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const format = /^[0-9a-zA-Z@_' '.]+$/;

    const onGetDataCommon = async () => {
        const dataFamilyLogo = await fetchClient.postRequest(router.CONFIG_URL.getDataByConfigLogo);
        localStorage.setItem(DATA_FAMILY_LOGO, dataFamilyLogo?.data.data[0]?.config_logo || '');
        const dataFont = await fetchClient.postRequest(router.CONFIG_URL.getDataConfigByFont);
        localStorage.setItem(DATA_FONT, dataFont?.data?.data[0]?.config_title || '');
        const dataFamilyTitle = await fetchClient.postRequest(router.CONFIG_URL.getDataByConfigTitle);
        localStorage.setItem(DATA_FAMILY_TITLE, dataFamilyTitle?.data.data[0]?.config_title || '');
        const dataNational = await fetchClient.getRequest(router.CONFIG_URL.national);
        localStorage.setItem(DATA_NATIONAL, JSON.stringify(dataNational?.data.data[0].config_value || []));
        const dataReligion = await fetchClient.getRequest(router.CONFIG_URL.religion);
        localStorage.setItem(DATA_RELIGION, JSON.stringify(dataReligion?.data.data[0].config_value || []));
        const dataEthnic = await fetchClient.sendRequest(router.CONFIG_URL.ethnic, 'get');
        localStorage.setItem(DATA_ETHNIC, JSON.stringify(dataEthnic?.data?.data[0]?.config_value || []));
        const dataGenre = await fetchClient.sendRequest(router.CONFIG_URL.getAllDataByConfigKey, 'post', { config_key: 'GENRE' });
        localStorage.setItem(DATA_GENRE, JSON.stringify(dataGenre?.data?.data || []));
        const dataAuthorRole = await fetchClient.sendRequest(router.CONFIG_URL.getAllDataByConfigKey, 'post', { config_key: 'ATHOUR_ROLE' });
        if (dataAuthorRole.status === 200) {
            const dataArr = [];
            dataAuthorRole?.data?.data.map((item) => {
                dataArr.push(item.config_value);
            });
            localStorage.setItem(DATA_ATHOUR_ROLE, JSON.stringify(dataArr || []));
        }
        const dataLanguage = await fetchClient.sendRequest(router.CONFIG_URL.getAllDataByConfigKey, 'post', { config_key: 'LANGUAGE' });
        if (dataLanguage.status === 200) {
            const dataRst = [];
            dataLanguage?.data?.data.map((item) => {
                dataRst.push(item.config_value);
            });
            localStorage.setItem(DATA_LANGUAGE, JSON.stringify(dataRst || []))
        }
        const dataCareer = await fetchClient.sendRequest(router.CONFIG_URL.getAllDataByConfigKey, 'post', { config_key: 'CAREER' });
        if (dataCareer.status === 200) {
            const dataArr = [];
            dataCareer?.data?.data.map((item) => {
                dataArr.push(item.config_value);
            });
            localStorage.setItem(DATA_CAREER, JSON.stringify(dataArr || []));
        }

        const dataEducation = await fetchClient.sendRequest(router.CONFIG_URL.getAllDataByConfigKey, 'post', { config_key: 'EDUCATION' });
        if (dataEducation.status === 200) {
            const dataArr = [];
            dataEducation?.data?.data.map((item) => {
                dataArr.push(item.config_value);
            });
            localStorage.setItem(DATA_EDUCATION, JSON.stringify(dataArr || []));
        }

        const dataPrize = await fetchClient.sendRequest(router.CONFIG_URL.getAllDataByConfigKey, 'post', { config_key: 'PRIZETITLE' });
        if (dataPrize.status === 200) {
            const dataArr = [];
            dataPrize?.data?.data.map((item) => {
                dataArr.push(item.config_value);
            });
            localStorage.setItem(DATA_PRIZE, JSON.stringify(dataArr || []));
        }
        const dataFamily = await fetchClient.sendRequest(router.SUB_URL.infoFamilyAPI, 'post');

        dispatch({ type: DATA_FAMILY, dataFamily: dataFamily.data });
    };

    const removeRecaptcha = () => {
        const script = document.getElementById('recaptcha-key');
        if (script) {
            script.remove();
        }

        const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
        if (recaptchaElems.length) {
            recaptchaElems[0].remove();
        }
    };

    const checkSortkey = async () => {
        console.log('check version ');
        await fetchClient.postRequest(router.SUB_URL.check_sortkey);
    } 

    const onLogin = async (values, { setStatus, setSubmitting }) => {
        setLoading(true);
        try {
            await window.grecaptcha.ready(async () => {
                await window.grecaptcha
                    .execute(config.reCaptchaKey, { action: 'submit' })
                    .then(async (token) => {
                        const dataObject = {
                            username: values.username.trim(),
                            password: values.password,
                            recaptcha: token
                        };
                        if (scriptedRef.current) {
                            const data = await fetchClient.postRequest(router.SUB_URL.loginAPI, dataObject);
                            if (data?.status === 201) {
                                // setLoading(false);
                                await checkSortkey();
                                await onGetDataCommon();
                                localStorage.setItem('REMEMBER', data.data.user.user_name);
                                dispatch({ type: DATA_USER, dataUser: data.data.user });
                                localStorage.setItem(dataUser, JSON.stringify(data.data.user));
                                removeRecaptcha();
                                navigate(ConfigRouter.homePage);
                            } else if (data?.status === 403) {
                                setLoading(false);
                                DialogUtil.showQuestions(false, data?.message);
                            } else {
                                setLoading(false);
                                DialogUtil.showQuestions(false, 'Đăng nhập thất bại vui lòng kiểm tra lại thông tin tài khoản');
                            }

                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    })
                    .catch(() => {
                        setLoading(false);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setSubmitting(false);
                            DialogUtil.showQuestions(false, 'Đăng nhập thất bại vui lòng kiểm tra lại thông tin tài khoản');
                        }
                    });
            });
        } catch (err) {
            setLoading(false);
            if (scriptedRef.current) {
                setStatus({ success: false });
                setSubmitting(false);
                DialogUtil.showQuestions(false, 'Đăng nhập thất bại vui lòng kiểm tra lại thông tin tài khoản');
            }
        }
    };

    useEffect(() => {
        if (typeLogin !== 'newfeed') {
            const arrData = localStorage.getItem('LIST_ADDRESS') ? JSON.parse(localStorage.getItem('LIST_ADDRESS')) : [];
            const dataResident = localStorage.getItem('LIST_RESIDENT') ? JSON.parse(localStorage.getItem('LIST_RESIDENT')) : [];
            const dataTemporaryResidence = localStorage.getItem('LIST_TEMPORARYRESIDENCE') ? JSON.parse(localStorage.getItem('LIST_TEMPORARYRESIDENCE')) : [];
            const dataBirthPlace = localStorage.getItem('LIST_BIRTH_PLACE') ? JSON.parse(localStorage.getItem('LIST_BIRTH_PLACE')) : [];
            const dataDeadPlace = localStorage.getItem('LIST_DEAD_PLACE') ? JSON.parse(localStorage.getItem('LIST_DEAD_PLACE')) : [];
            const type_view_diary = localStorage.getItem('type_view_diary') ?? 'list';
            const type_view_album = localStorage.getItem('type_view_album') ?? 'list';

            const remember = localStorage.getItem('REMEMBER') ?? '';
            const theme = localStorage.getItem('theme') ?? '';
            const logo = localStorage.getItem('m_family_logo') ?? '';
            const vesion = localStorage.getItem('VERSION') ?? '';
            const font = localStorage.getItem('m_family_font') ?? '';
            // fetchClient.postRequest(router.SUB_URL.logOutAPI, 'post');
            sessionStorage.clear();
            localStorage.clear();
            localStorage.setItem('REMEMBER', remember);
            localStorage.setItem('theme', theme);
            localStorage.setItem('m_family_logo', logo);
            localStorage.setItem('type_view_diary', type_view_diary);
            localStorage.setItem('type_view_album', type_view_album);
            localStorage.setItem('LIST_ADDRESS', JSON.stringify(arrData));
            localStorage.setItem('LIST_RESIDENT', JSON.stringify(dataResident));
            localStorage.setItem('LIST_TEMPORARYRESIDENCE', JSON.stringify(dataTemporaryResidence));
            localStorage.setItem('LIST_BIRTH_PLACE', JSON.stringify(dataBirthPlace));
            localStorage.setItem('LIST_DEAD_PLACE', JSON.stringify(dataDeadPlace));
            localStorage.setItem('VERSION', vesion);
            localStorage.setItem('m_family_font', font);
            dispatch({ type: ID_USER, idUser: null });
        }
    }, []);

    useEffect(() => {
        // if (typeLogin !== 'newfeed') {
            const loadScriptByURL = (id, url, callback) => {
                const isScriptExist = document.getElementById(id);

                if (!isScriptExist) {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = url;
                    script.id = id;
                    script.onload = function () {
                        if (callback) callback();
                    };
                    document.body.appendChild(script);
                }

                if (isScriptExist && callback) callback();
            };
            // load the script by passing the URL
            loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${config.reCaptchaKey}`, () => {
                // console.log('Script recaptcha loaded!');
            });
        // }
    }, []);

    return (
        <div>
            <Formik
                initialValues={{
                    username: localStorage.getItem('REMEMBER') ? localStorage.getItem('REMEMBER') : '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string().min(3, 'Tên đăng nhập hoặc email cần lớn hơn 3 kí tự').required('Bạn vui lòng nhập Tên đăng nhập hoặc email'),
                    password: Yup.string()
                        .min(6, 'Mật khẩu không được ít hơn 6 kí tự')
                        .max(25, 'Mật khẩu không được quá 25 kí tự')
                        .required('Mật khẩu không được để trống')
                })}
                onSubmit={(values, { setErrors, setStatus, setSubmitting }) => onLogin(values, { setErrors, setStatus, setSubmitting })}
                sx={{
                    backgroundImage: `url(${BackgroundRongPhuong})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.username && errors.username)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-email-login">Tên đăng nhập hoặc email *</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                value={values.username}
                                name="username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="Tên đăng nhập hoặc email"
                                inputProps={{}}
                                autoFocus
                                required
                            />
                            {touched.username && errors.username && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.username}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Mật khẩu *</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end" variant="standard">
                                        <IconButton
                                            tabIndex={-1}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Mật khẩu"
                                inputProps={{}}
                                required
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            {/* <AnimateButton>
                                <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                                    {loading ? 'Loading...' : 'Đăng nhập'}
                                </Button>
                            </AnimateButton> */}
                            <LoadingButton
                                disableElevation
                                fullWidth
                                size="large"
                                loading={loading}
                                loadingPosition="start"
                                variant="contained"
                                type="submit"
                            >
                                {loading ? 'Đang đăng nhập...' : 'Đăng nhập'}
                            </LoadingButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default FirebaseLogin;
