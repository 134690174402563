/* eslint-disable no-underscore-dangle */
import { useEffect, useState, useRef } from 'react';
import {
    Modal,
    Box,
    Typography,
    Divider,
    IconButton,
    FormControl,
    Button,
    Stack,
    Grid,
    TextField,
    Avatar,
    useMediaQuery,
    Menu,
    MenuItem
} from '@mui/material';
import { Close } from '@mui/icons-material';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import DialogUtil from 'ui-component/show_confirm_action';
import { styled } from '@mui/material/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveIcon from '@mui/icons-material/Save';
import config from '../../config';
import { makeStyles } from '@mui/styles';
import Loading from 'ui-component/Loading';
import EditIcon from '@mui/icons-material/Edit';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { isUrlValid } from '../../utils/validate_checking';
import { getRoleButton } from 'utils/roleUility';
import ConfigRouter from 'routes/config_router';
import { useNavigate } from 'react-router';
import { ROOT } from 'constant/roleAlias';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95vw' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: { md: 2, xs: 1 }
};
const initialValidate = { invalid: false, errors: '' };
const validateFile = { invalid: false, errors: `Dung lượng tệp tin được chọn tối đa là ${config.maxUploadDocs / 1000000} MB` };
const validateNote = { invalid: false, errors: 'Mô tả tài liệu không được quá 500 ký tự' };

const showCurrentPath = (path) => {
    if (path === '') {
        return '';
    }
    const vt = path?.lastIndexOf('/');
    const newStringPath = path.slice(vt + 1, path.length);
    return newStringPath;
};

const ModalDocumentFamily = ({ role, open, closeModal, data, onReload, typeMode, reviewLink, reviewFile, onDownload }) => {
    // const imageRef = useRef();
    const inputRef = useRef(null);
    const [nameDocument, setNameDocument] = useState('');
    const [content, setContent] = useState('');
    const [path, setPath] = useState('');
    const [linkDocument, setLinkDocument] = useState(null);
    const [dataDocument, setDataDocument] = useState({});
    const [validateName, setValidateName] = useState(initialValidate);
    const [validateContent, setValidateContent] = useState(validateNote);
    const [validatePath, setValidatePath] = useState(validateFile);
    const [validateLink, setValidateLink] = useState(initialValidate);
    const [isLoading, setIsLoading] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [type, setType] = useState('');
    const navigate = useNavigate();
    const EditIconButton = styled(Avatar)(({ theme }) => ({
        width: 30,
        height: 30,
        border: `2px solid ${theme.palette.background.paper}`
    }));
    const useStyles = makeStyles((theme) => ({
        media: {
            height: 300,
            width: '100%'
        }
    }));

    useEffect(() => {
        const stringPath = data?.url_document || '';
        const vt = stringPath?.lastIndexOf('/');
        const newStringPath = stringPath?.slice(vt + 1, stringPath.length) || '';
        setNameDocument(data?.name_document || '');
        setContent(data?.content || '');
        setLinkDocument(data?.link_document || '');
        setIsPublic(data?.isPublic ?? false);
        setPath(newStringPath);
        setType(typeMode);
        if (typeMode === 'review') {
            setValidatePath(initialValidate);
            setValidateContent(initialValidate);
        } else {
            setValidatePath(validateFile);
            setValidateContent(validateNote);
        }
    }, [data, open]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'nameDocument':
                setNameDocument(value);
                setValidateName(initialValidate);
                break;
            case 'content':
                if (value.length > 500) {
                    setValidateContent({
                        invalid: true,
                        errors: 'Mô tả tài liệu không được quá 500 ký tự'
                    });
                    return;
                }
                setContent(value);
                setValidateContent(validateNote);
                break;
            case 'linkDocument':
                setValidateLink(initialValidate);
                setLinkDocument(value);
                break;
            default:
                break;
        }
    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === 'backdropClick') {
            onClose();
            return;
        }
    };

    const onClose = () => {
        setIsPublic(false);
        setNameDocument('');
        setValidateName(initialValidate);
        setValidateContent(validateNote);
        setValidatePath(validateFile);
        setValidateLink(initialValidate);
        setDataDocument({});
        setPath('');
        setContent('');
        setLinkDocument(null);
        closeModal();
    };

    const onCloseReload = () => {
        onClose();
        onReload();
    };

    const onPathChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (fileObj) {
            if (event.target.files[0].size > config.maxUploadDocs) {
                setDataDocument({});
                setLinkDocument(null);
                setPath('');
                setValidatePath({
                    invalid: true,
                    errors: `Tài liệu không được quá ${config.maxUploadDocs / 1000000} MB`
                });
                return;
            }
            setDataDocument(event.target.files[0]);
            setPath(fileObj.name);
            setValidatePath(validateFile);
            setValidateLink(initialValidate);
            setValidateLink(initialValidate);
        } else {
            setDataDocument({});
            setLinkDocument(null);
            setPath('');
            setValidatePath(validateFile);
        }
    };

    const actionSave = async () => {
        setIsLoading(true);
        const bodyFormData = new FormData();
        bodyFormData.append('name_document', nameDocument.trim());
        bodyFormData.append('content', content.trim());
        bodyFormData.append('document', dataDocument);
        bodyFormData.append('isPublic', isPublic);
        bodyFormData.append('link_document', linkDocument.trim());

        const dataRst = await fetchClient.postRequest(router.SUB_URL.documentFamily_create, bodyFormData);

        if (dataRst?.status === 201) {
            setIsLoading(false);
            DialogUtil.showQuestions(true, 'Thêm tài liệu thành công', () => onCloseReload());
        } else if (dataRst?.statusCode === 403) {
            setIsLoading(false);
            DialogUtil.showQuestions(false, dataRst.message);
        } else if (dataRst?.statusCode === 406) {
            setIsLoading(false);
            DialogUtil.showQuestionsTwoAction(
                role?.roleAlias,
                false,
                dataRst?.message,
                'Nâng cấp ngay',
                'Đóng',
                () => {
                    navigate(ConfigRouter.listService);
                },
                () => {}
            );
        } else {
            setIsLoading(false);
            DialogUtil.showQuestions(
                false,
                dataRst?.message === 'Validation failed (expected size is less than 50000)'
                    ? `Tài liệu không được quá ${config.maxUploadDocs / 1000000} MB`
                    : 'Thêm tài liệu thất bại'
            );
        }
    };

    const onSave = async () => {
        if (!path && !linkDocument) {
            setValidatePath({
                invalid: true,
                errors: 'Vui lòng chọn file hoặc đường dẫn'
            });
            setValidateLink({
                invalid: true,
                errors: 'Vui lòng chọn file hoặc đường dẫn'
            });
            return;
        }

        if (dataDocument.name && dataDocument.size > config.maxUploadDocs) {
            setValidatePath({
                invalid: true,
                errors: `Tài liệu không được quá ${config.maxUploadDocs / 1000000} MB`
            });
            return;
        }

        if (nameDocument.trim() === '' || nameDocument.trim() === null) {
            setValidateName({
                invalid: true,
                errors: 'Tên tài liệu không được để trống'
            });
            return;
        }

        if (linkDocument && !isUrlValid(linkDocument)) {
            setValidateLink({
                invalid: true,
                errors: 'Đường dẫn không đúng định dạng vui lòng kiểm tra lại'
            });
            return;
        }

        if (nameDocument.length > 250) {
            setValidateName({
                invalid: true,
                errors: 'Tên tài liệu không được quá 250 ký tự'
            });
            return;
        }
        if (content.length > 500) {
            setValidateContent({
                invalid: true,
                errors: 'Mô tả tài liệu không được quá 500 ký tự'
            });
            return;
        }

        DialogUtil.showConfirm(
            'Thông báo',
            'Bạn có muốn thêm tài liệu không ?',
            () => {},
            () => actionSave()
        );
    };

    const actionUpdate = async () => {
        const bodyFormData = new FormData();
        bodyFormData.append('name_document', nameDocument.trim());
        bodyFormData.append('content', content.trim());
        bodyFormData.append('document', dataDocument);
        bodyFormData.append('isPublic', isPublic);
        bodyFormData.append('link_document', linkDocument.trim());
        bodyFormData.append('_id', data._id);
        const routerSend = `${router.SUB_URL.documentFamily_update}`;
        const dataReturn = await fetchClient.sendRequest(routerSend, 'post', bodyFormData);

        if (dataReturn.status === 201) {
            DialogUtil.showQuestions(true, 'Thay đổi tài liệu thành công', () => onCloseReload());
        } else if (dataReturn?.statusCode === 406) {
            setIsLoading(false);
            DialogUtil.showQuestionsTwoAction(
                role?.roleAlias,
                false,
                dataReturn?.message,
                'Nâng cấp ngay',
                'Đóng',
                () => {
                    navigate(ConfigRouter.listService);
                },
                () => {}
            );
        } else {
            DialogUtil.showQuestions(false, 'Thay đổi tài liệu thất bại');
        }
    };

    const handleUploadFile = () => {
        inputRef.current.click();
    };

    const onUpdate = async () => {
        if (!nameDocument && !linkDocument) {
            setValidatePath({
                invalid: true,
                errors: 'Vui lòng chọn file hoặc đường dẫn'
            });
            setValidateLink({
                invalid: true,
                errors: 'Vui lòng chọn file hoặc đường dẫn'
            });
            return;
        }

        if (dataDocument.name && dataDocument.size > config.maxUploadDocs) {
            setValidatePath({
                invalid: true,
                errors: `Tài liệu không được quá ${config.maxUploadDocs / 1000000} MB`
            });
            return;
        }

        if (linkDocument && !isUrlValid(linkDocument)) {
            setValidateLink({
                invalid: true,
                errors: 'Đường dẫn không đúng định dạng vui lòng kiểm tra lại'
            });
            return;
        }

        if (nameDocument.trim() === '' || nameDocument.trim() === null) {
            setValidateName({
                invalid: true,
                errors: 'Tên tài liệu không được để trống'
            });
            return;
        }
        if (nameDocument.length > 250) {
            setValidateName({
                invalid: true,
                errors: 'Tên tài liệu không được quá 250 ký tự'
            });
            return;
        }
        if (content.length > 500) {
            setValidateContent({
                invalid: true,
                errors: 'Mô tả tài liệu không được quá 500 ký tự'
            });
            return;
        }
        DialogUtil.showConfirm(
            'Thông báo',
            'Bạn có muốn sửa tài liệu không ?',
            () => {},
            () => actionUpdate()
        );
    };

    const onEdit = () => {
        setType('add');
        setValidatePath(validateFile);
        setValidateContent(validateNote);
    };

    const matchDownMd = useMediaQuery('(max-width:1440px)');

    const actionReviewFile = () => {
        reviewFile(data);
    };

    const actionReviewLink = () => reviewLink(data);

    const actionDownload = () => onDownload(data);

    const [anchorEl, setAnchorEl] = useState(null);
    const openDropdown = Boolean(anchorEl);
    const handleOpenDropDown = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseDropDown = () => {
        setAnchorEl(null);
    };
    const handleChangePublic = () => {
        setIsPublic(!isPublic);
    };

    return (
        <>
            <Modal open={open} onClose={() => handleCloseModal()}>
                <Box sx={style} fullWidth>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        {data ? (type === 'review' ? 'Chi tiết tài liệu' : 'Cập nhật tài liệu') : 'Thêm tài liệu'}
                    </Typography>
                    <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                    <Box position="absolute" top={0} right={0}>
                        <IconButton onClick={() => onClose()}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Grid spacing={3}>
                        <Stack>
                            <FormControl>
                                <Grid container>
                                    <Grid item xs={9.5} md={9} xl={type === 'add' && (role?.create || role?.update) ? 10 : 9}>
                                        <TextField
                                            fullWidth
                                            sx={{
                                                disabled: 'disabled',
                                                marginBottom: '0px !important'
                                                // width: matchDownMd ? '60%' : '100%'
                                            }}
                                            disabled={type === 'review'}
                                            type="text"
                                            error={validatePath.invalid}
                                            margin="normal"
                                            label={data ? 'Đường dẫn lưu trữ file tài liệu' : 'Đường dẫn lưu trữ file tài liệu (*)'}
                                            value={path}
                                            name="path"
                                            helperText={validatePath.errors}
                                            placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                            // disabled
                                        />
                                    </Grid>
                                    <>
                                        {type === 'add' && (role?.create || role?.update) ? (
                                            <Grid item xs={2.5} md={matchDownMd ? 3 : 1.5}>
                                                <input style={{ display: 'none' }} ref={inputRef} type="file" onChange={onPathChange} />
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        height: '46px',
                                                        marginTop: '18px',
                                                        marginLeft: '6px',
                                                        // width: '95%',
                                                        whiteSpace: 'nowrap',
                                                        display: { md: 'flex', xs: 'none' }
                                                    }}
                                                    color="primary"
                                                    onClick={handleUploadFile}
                                                    startIcon={<AttachFileIcon />}
                                                >
                                                    Chọn File
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        height: '46px',
                                                        marginTop: '18px',
                                                        marginLeft: '6px',
                                                        // width: '95%',
                                                        whiteSpace: 'nowrap',
                                                        display: { md: 'none', xs: 'flex' }
                                                    }}
                                                    // disabled={!data?.url_document}
                                                    color="primary"
                                                    onClick={handleUploadFile}
                                                >
                                                    <AttachFileIcon />
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid md={1.5} xs={0}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            height: '46px',
                                                            marginTop: '18px',
                                                            marginLeft: '3px',
                                                            width: '100%',
                                                            whiteSpace: 'nowrap',
                                                            display: { md: 'flex', xs: 'none' }
                                                        }}
                                                        disabled={!data?.url_document}
                                                        color="primary"
                                                        onClick={actionReviewFile}
                                                        startIcon={<RemoveRedEyeIcon />}
                                                    >
                                                        Xem
                                                    </Button>
                                                </Grid>
                                                {role?.export && (
                                                    <Grid md={1.5} xs={0}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                height: '46px',
                                                                marginTop: '18px',
                                                                marginLeft: '6px',
                                                                width: '100%',
                                                                whiteSpace: 'nowrap',
                                                                display: { md: 'flex', xs: 'none' }
                                                            }}
                                                            disabled={!data?.url_document}
                                                            color="primary"
                                                            onClick={actionDownload}
                                                            startIcon={<DownloadIcon />}
                                                        >
                                                            Tải xuống
                                                        </Button>
                                                    </Grid>
                                                )}
                                                <Grid item xs={1} md={3} sx={{ display: 'flex' }}>
                                                    <Box>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            sx={{
                                                                borderBlockColor: 'black',
                                                                height: '46px',
                                                                marginTop: '18px',
                                                                marginLeft: '6px',
                                                                display: { md: 'none', xs: 'flex' }
                                                            }}
                                                            onClick={handleOpenDropDown}
                                                        >
                                                            <MoreHorizIcon />
                                                        </Button>
                                                        <Menu
                                                            id="long-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'long-button'
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={openDropdown}
                                                            onClose={handleCloseDropDown}
                                                        >
                                                            <MenuItem
                                                                disabled={!data?.url_document}
                                                                onClick={() => actionReviewFile()}
                                                                sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px' }}
                                                            >
                                                                <RemoveRedEyeIcon sx={{ marginRight: 1 }} />
                                                                Xem
                                                            </MenuItem>
                                                            {role?.export && (
                                                                <MenuItem
                                                                    disabled={!data?.url_document}
                                                                    onClick={(e) => {
                                                                        actionDownload();
                                                                    }}
                                                                    sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px' }}
                                                                >
                                                                    <DownloadIcon sx={{ marginRight: 1 }} />
                                                                    Tải
                                                                </MenuItem>
                                                            )}
                                                        </Menu>
                                                    </Box>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                </Grid>
                            </FormControl>
                        </Stack>

                        <Stack>
                            <FormControl>
                                <Grid container>
                                    <Grid item xs={type === 'review' ? 9.5 : 12} md={type === 'review' ? 9 : 12}>
                                        <TextField
                                            error={validateLink.invalid}
                                            margin="normal"
                                            label="Đường dẫn trực tuyến"
                                            fullWidth
                                            disabled={type === 'review'}
                                            value={linkDocument}
                                            name="linkDocument"
                                            onChange={handleChange}
                                            helperText={validateLink.errors}
                                            placeholder="Nhập đường dẫn trực tuyến..."
                                        />
                                    </Grid>
                                    {type === 'review' ? (
                                        <Grid item xs={1} md={type === 'review' ? 3 : 12}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    height: '46px',
                                                    marginTop: '18px',
                                                    marginLeft: '3px',
                                                    width: '100%',
                                                    whiteSpace: 'nowrap',
                                                    display: { md: 'flex', xs: 'none' }
                                                }}
                                                disabled={!data?.link_document}
                                                color="primary"
                                                onClick={actionReviewLink}
                                                startIcon={<OpenInNewIcon />}
                                            >
                                                Mở đường dẫn
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    height: '46px',
                                                    marginTop: '18px',
                                                    marginLeft: '6px',
                                                    // width: '95%',
                                                    whiteSpace: 'nowrap',
                                                    display: { md: 'none', xs: 'flex' }
                                                }}
                                                disabled={!data?.link_document}
                                                color="primary"
                                                onClick={actionReviewLink}
                                            >
                                                <OpenInNewIcon />
                                            </Button>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </FormControl>
                        </Stack>

                        <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                            {/* <FormControl> */}
                            <TextField
                                sx={{ width: { xs: '70%', md: '80%', xl: '85%' } }}
                                disabled={type === 'review'}
                                error={validateName.invalid}
                                margin="normal"
                                label="Tên tài liệu (*)"
                                value={nameDocument}
                                onChange={handleChange}
                                name="nameDocument"
                                helperText={validateName.errors}
                                placeholder="Nhập tên tài liệu..."
                            />
                            {/* </FormControl> */}
                            <Button
                                disabled={type === 'review'}
                                onClick={handleChangePublic}
                                startIcon={isPublic === true ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                            >
                                Công khai
                            </Button>
                        </Stack>

                        <Stack>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={type === 'review'}
                                    error={validateContent.invalid}
                                    margin="normal"
                                    label="Mô tả tài liệu"
                                    multiline
                                    rows={5}
                                    // maxRows={7}
                                    fullWidth
                                    value={content}
                                    onChange={handleChange}
                                    name="content"
                                    helperText={validateContent.errors}
                                    placeholder="Nhập mô tả tài liệu..."
                                />
                            </FormControl>
                        </Stack>
                        <Box sx={{ mt: 2 }} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                            {type === 'add' ? (
                                <>
                                    {data && role?.update ? (
                                        <Button
                                            disableElevation
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<SaveIcon />}
                                            onClick={onUpdate}
                                        >
                                            Cập nhật
                                        </Button>
                                    ) : (
                                        role?.create && (
                                            <Button
                                                disableElevation
                                                size="medium"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<SaveIcon />}
                                                onClick={onSave}
                                            >
                                                Lưu
                                            </Button>
                                        )
                                    )}
                                </>
                            ) : null}
                        </Box>
                    </Grid>
                </Box>
            </Modal>
            <Loading show={isLoading} />
        </>
    );
};
export default ModalDocumentFamily;
