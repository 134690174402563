// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconUser, IconLock, IconSmartHome } from '@tabler/icons';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import ConstructionIcon from '@mui/icons-material/Construction';
import ConfigRouter from 'routes/config_router';
import DnsIcon from '@mui/icons-material/Dns';
import ListIcon from '@mui/icons-material/List';
import PortraitIcon from '@mui/icons-material/Portrait';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import CategoryIcon from '@mui/icons-material/Category';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconUser,
    IconLock,
    IconSmartHome,
    FamilyRestroomIcon,
    PersonIcon,
    LockIcon,
    PeopleIcon,
    ConstructionIcon,
    DnsIcon,
    PortraitIcon,
    ListIcon,
    SettingsSuggestIcon,
    AutoAwesomeMosaicIcon,
    NoteAltIcon,
    CategoryIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Thông tin người dùng',
    type: 'group',
    children: [
        {
            id: ConfigRouter.configMaster,
            title: 'Quản lý hệ thống',
            type: 'collapse',
            // url: ConfigRouter.diaryFamily,
            icon: icons.SettingsSuggestIcon,
            // breadcrumbs: false,
            spMobile: true,
            children: [
                {
                    id: ConfigRouter.configMaster,
                    title: 'Quản lý dữ liệu',
                    type: 'item',
                    url: ConfigRouter.configMaster,
                    icon: icons.DnsIcon,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.listAccount,
                    title: 'Quản lý tài khoản',
                    type: 'item',
                    url: ConfigRouter.listAccount,
                    icon: icons.PortraitIcon,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.listService,
                    title: 'Quản lý dịch vụ',
                    type: 'item',
                    url: ConfigRouter.listService,
                    icon: icons.ListIcon,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.settingNewfeed,
                    title: 'Quản lý trang chủ',
                    type: 'item',
                    url: ConfigRouter.settingNewfeed,
                    icon: icons.AutoAwesomeMosaicIcon,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.listSymstemLog,
                    title: 'Nhật ký hệ thống',
                    type: 'item',
                    url: ConfigRouter.listSymstemLog,
                    icon: icons.NoteAltIcon,
                    breadcrumbs: false,
                    spMobile: true
                }
            ]
        },
        {
            id: ConfigRouter.userInfo,
            title: 'Trang cá nhân',
            type: 'item',
            url: ConfigRouter.userInfo,
            icon: icons.PersonIcon,
            breadcrumbs: false,
            spMobile: true
        },
        {
            id: ConfigRouter.listOtherProduct,
            title: 'Sản phẩm khác',
            type: 'item',
            url: ConfigRouter.listOtherProduct,
            icon: icons.CategoryIcon,
            breadcrumbs: false,
            spMobile: true
        }
    ]
};

export default utilities;
