import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import PagesNewfeed from './page';
import { Link } from '@mui/material';
import { useSelector } from 'react-redux';

function MobileAppBar({ dataSetting, dataMenu }) {
    const DATA = [
        { href: `${PagesNewfeed.home}`, title: 'Trang Chủ', show: true },
        { href: `${PagesNewfeed.introduction}`, title: 'Giới Thiệu', show: dataSetting?.introduction?.isShow },
        { href: `${PagesNewfeed.album}`, title: 'Album Ảnh', show: dataSetting?.data_exists?.album && dataMenu?.numAlbum > 0 },
        { href: `${PagesNewfeed.diary}`, title: 'Phả Ký', show: dataSetting?.data_exists?.diary && dataMenu?.numDiary > 0 },
        { href: `${PagesNewfeed.ancestral_house}`, title: 'Danh sách nhà thờ', show: dataSetting?.data_exists?.ancestral_house},
        { href: `${PagesNewfeed.statistical}`, title: 'Thống Kê', show: dataSetting?.data_exists?.gen_static },
        { href: `${PagesNewfeed.contact}`, title: 'Liên Hệ', show: dataSetting?.data_exists?.reply }
    ];
    const customization = useSelector((state) => state.customization);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleScroll = (sectionId) => {
        console.log(sectionId);
        const targetElement = document.getElementById(sectionId);
        if (targetElement) {
            const offset = 100; // Độ cao cách 1 khoảng
            const targetPosition = targetElement.offsetTop - offset;
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth' // Hiệu ứng cuộn mượt
            });
        }
    };
    return (
        <Box>
            <button
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                variant="contained"
                style={{
                    backgroundColor: customization?.themeDongHo?.backgroundColor,
                    color: 'white',
                    padding: '4px 8px',
                    marginRight: '10px',
                    border: 'none',
                    borderRadius: '5px'
                }}
            >
                <MenuIcon />
            </button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: 'block', md: 'none' }
                }}
            >
                {DATA.map(
                    (page) =>
                        page.show && (
                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                                <Typography
                                    variant="h5"
                                    underline="none"
                                    sx={{ marginRight: '20px', fontSize: '16px', fontWeight: 'bold', cursor: 'pointer' }}
                                    onClick={() => {
                                        handleScroll(page.href);
                                    }}
                                    textAlign="center"
                                >
                                    {page.title}
                                </Typography>
                            </MenuItem>
                        )
                )}
            </Menu>
        </Box>
    );
}
export default MobileAppBar;
