import * as roleAlias from 'constant/roleAlias';
import { DF_ROLE_SCREEN } from 'constant/dfRoleScreen';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import ConfigMenuAlias from 'views/utilities/config_menu_alias';
import DialogUtil from 'ui-component/show_confirm_action';

export const getAliasScreen = (pathname) => {
    const lengthPath = pathname.length;
    const result = ConfigMenuAlias.filter((item) => {
        let sub = lengthPath - item.url.length;
        if (sub > 0) {
            return pathname.includes(item.url);
        } else if (sub === 0) {
            return item.url === pathname;
        } else {
            return item.url.includes(pathname);
        }
    });
    return result.length > 0 ? result[0].alias : '';
};

export const checkRoleByKey = (arr, key) => {
    if (!arr || arr.length <= 0) {
        return false;
    }
    return arr.find((roleItem) => roleItem.key === key)?.condition;
};
export const roleLogout = () => {
    const remember = localStorage.getItem('REMEMBER') ?? '';
    const theme = localStorage.getItem('theme') ?? '';
    const logo = localStorage.getItem('m_family_logo') ?? '';
    const type_view_diary = localStorage.getItem('type_view_diary') ?? 'list';
    const type_view_album = localStorage.getItem('type_view_album') ?? 'list';
    const vesion = localStorage.getItem('VERSION') ?? '';
    const font = localStorage.getItem('m_family_font') ?? '';
    localStorage.clear();
    localStorage.setItem('REMEMBER', remember);
    localStorage.setItem('m_family_logo', logo);
    localStorage.setItem('theme', theme);
    localStorage.setItem('type_view_diary', type_view_diary);
    localStorage.setItem('type_view_album', type_view_album);
    localStorage.setItem('VERSION', vesion);
    localStorage.setItem('m_family_font', font);
    window.location.reload();
    
    return;
};

export async function getRoleButton() {
    // declear type return for all buttons or one
    let result = null;

    const user = localStorage.getItem('DATA_USER');

    // GET ROLE in LOCAL STORE HERE
    let roleBase = roleAlias.VIEWER;
    if (user) {
        const dataUser = JSON.parse(user);
        if (dataUser && dataUser.type) {
            roleBase = dataUser.type;
        }
    }
    // GET SCREEN HERE same as get SCREEN ALIAS
    let menuBase = getAliasScreen(window.location.pathname);

    try {
        // 1rd : call API to get the data
        // ==>>>>
        const obj = {
            screen: menuBase
        };
        const res = await fetchClient.postRequest(router.SUB_URL.getPermisionByScreen, obj);
        if (res?.status === 200) {
            if (res?.data?.data?.length > 0) {
                let role = res?.data?.data[0];
                if (role.roleAlias !== roleBase) {
                    return DialogUtil.showQuestions(
                        false,
                        'Quyền truy cập của bạn đã bị thay đổi, vui lòng đăng nhập lại để sử dụng hệ thống !',
                        () => {
                            roleLogout();
                        }
                    );
                } else {
                    // console.log('role', role);
                    return role;
                }
            }
        }
        // 2rd : Get data from client default side data
        result = DF_ROLE_SCREEN.filter((role) => (role.roleAlias == roleBase) & (role.menuAlias == menuBase));
    } catch {
        return {
            read: true,
            create: false,
            update: false,
            delete: false,
            export: false,
            roleAlias: roleAlias.VIEWER
        };
    }
    return result;
}
