import blueTheme from './layout/Customization/themeCustom/blueTheme';
// import redTheme from './layout/Customization/themeCustom/redTheme';

// const HOST_API_URL = 'http://localhost:8080/api/';
const HOST_API_URL = '/api/';
// const HOST_API_URL = 'http://fuji.akb.vn:8088/api/';
// const HOST_API_URL = 'http://192.168.1.161:8080/api/';

const config = {
    basename: '/',
    defaultPath: '/dashboard/genealogy',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 4,
    themeDongHo: blueTheme,
    api: HOST_API_URL,
    serverImageUrl: `${HOST_API_URL}auth/profile-image/`,
    serverAvatarUrl: `${HOST_API_URL}auth/avatar_profile/`,
    serverImage: `${HOST_API_URL}album_family/image_album?url=`,
    serverAvatarMember: `${HOST_API_URL}member/avatar?url=`,
    severImageBase64: `${HOST_API_URL}auth/album_image?url=`,
    timeOut: 50000,
    // reCaptchaKey: '6Le9QTklAAAAAOL2vYzxH1s3LpwNxYF80Yk6gKXF', // localhost
    // reCaptchaKey: '6Le2kDklAAAAANGMG-hbMr8bJP4KNlWtZTNhfxtN', // fuji.akb.vn
    reCaptchaKey: '6Ld5jzklAAAAAJZzWBkVlaGX83W-jZuREIsrjjo4', // trangiadongtru
    maxUploadDocs: 50000000,
    numberMembers: 300
    /// Client Captcha Key: localhost: 6Le9QTklAAAAAOL2vYzxH1s3LpwNxYF80Yk6gKXF
    // Client Captcha Key: trangiadongtru: 6Ld5jzklAAAAAJZzWBkVlaGX83W-jZuREIsrjjo4
    // Client Captcha Key: fuji.akb.vn: 6Le2kDklAAAAANGMG-hbMr8bJP4KNlWtZTNhfxtN
};

export default config;
