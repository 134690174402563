import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Modal,
    Box,
    ImageList,
    IconButton,
    Typography,
    Divider,
    Button,
    ImageListItem,
    ImageListItemBar,
    Grid,
    Stack,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    useMediaQuery
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { Close } from '@mui/icons-material';
import config from 'config';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import NotFound from 'ui-component/404/404';
import FolderIcon from '@mui/icons-material/Folder';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import ModalImageAlbum from 'ui-component/family/ModalImageAlbum';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import styleImage from './ModalListImage.css';
import DialogUtil from 'ui-component/show_confirm_action';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { xs: '80%', sm: '50%' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 2,
    height: { md: '95vh', xs: '85vh' }
};

const ModalListImage = ({ open, onCloseModal, image, imageFatherRef, dataImageProp, data, onReload }) => {
    const smallScreen = useMediaQuery('(max-width: 600px)');
    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: '6px 16px',
            cursor: 'pointer'
        },
        secondaryTail: {
            backgroundColor: theme.palette.secondary.main
        },
        adddICon: {
            '& svg': {
                fontSize: 50
            }
        },
        styleImg: {
            width: '100%',
            position: 'relative',
            objectFit: 'contain'
        },
        img: {
            filter: 'none',
            WebkitFilter: `grayscale(${100})`,
            '&:hover': {
                filter: `grayscale(${0})`,
                WebkitFilter: `grayscale(${0})`
            },
            minHeight: '100px',
            width: '100%'
        }
    }));
    const customization = useSelector((state) => state.customization);
    const [dataImages, setDataImages] = useState([]);
    const classes = useStyles();
    const [errors, setErrors] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [showAlbum, setShowAlbum] = useState(false);

    const actionClose = () => {
        setDataImages([]);
        setShowAlbum(false);
        setOpenModal(false);
        onCloseModal();
        onCloseModal();
    };

    const handleCloseModal = (event, reason) => {
        if (reason && reason === 'backdropClick') {
            actionClose();
            return;
        }
    };

    const onSave = async () => {
        const dataFiles = [];
        const dataLinks = [];
        const arr = [];
        dataImages.forEach((element) => {
            if (element?.urlImage) {
                dataLinks.push(element);
            } else {
                dataFiles.push(element);
                const object = {};
                object.name = element.name;
                object.descImage = element.descImage !== undefined ? element.descImage : '';
                arr.push(object);
            }
        });
        const formData = new FormData();
        for (let i = 0; i < dataFiles.length; i++) {
            formData.append('list_image', dataFiles[i], dataFiles[i].name);
        }
        const dataObj = {
            _id: data?._id,
            dataLinks: dataLinks,
            dataFile: arr
        };
        formData.append('dataSend', JSON.stringify(dataObj));
        const rst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseUpload, formData);
        console.log('rst -----', rst);
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, 'Thêm thông tin nhà thờ thành công', () => {
                actionClose();
                onReload();
            });
        }
        // if (dataImages.length === 0) {
        //     DialogUtil.showQuestions(false, 'Vui lòng chọn ảnh');
        //     return;
        // }
        // if (dataImages.length > 20) {
        //     DialogUtil.showQuestions(false, 'Số ảnh đang upload quá 20 ảnh');
        //     return;
        // }
        // DialogUtil.showConfirm(
        //     'Thông báo',
        //     `${!image ? 'Bạn có muốn thêm ảnh vào album không?' : 'Bạn có muốn chỉnh sửa thông tin hình ảnh này không?'}`,
        //     () => {},
        //     () => (!image ? actionSave() : acitonUpdate(image))
        // );
    };

    const validateDesc = (desc) => {
        if (desc.length > 100) {
            return 'Mô tả chỉ cho phép từ 100 kí tự trở xuống';
        }
        return '';
    };

    const onChangeImage = (item, e) => {
        const dataArr = dataImages;
        const newErrors = { ...errors };
        dataArr.map((element, index) => {
            if (element.name === item.name) {
                if (e.target.name === 'descImage') {
                    if (e.target.value.length > 101) {
                        return;
                    }
                    element.descImage = e.target.value;
                    const descError = validateDesc(e.target.value);
                    newErrors[index] = { ...newErrors[index], desc: descError };
                }
            }
        });
        const array = [];
        dataArr.forEach((file) => {
            array.push(file);
        });
        setErrors(newErrors);
        setDataImages(array);
    };

    useEffect(() => {
        setDataImages(dataImageProp);
    }, [dataImageProp]);

    const actionOpenAlbum = async () => {
        setShowAlbum(true);
        setOpenModal(false);
    };

    const onClickFolder = () => {
        setOpenModal(false);
        imageFatherRef.current.click();
    };

    const onChooseImage = (item) => {
        item.eff = true;
        const arrImage = [...dataImages];
        arrImage.push(item);
        setDataImages(arrImage);
    };

    const onRemoveFile = (item) => {
        if (item?._id) {
            const arrImage = [...dataImages];
            const index = arrImage.findIndex((f) => f._id === item._id);
            arrImage.splice(index, 1);
            setDataImages(arrImage);
        } else {
            const dataArr = dataImages;
            const index = dataArr.findIndex((f) => f.name === item.name);
            dataArr.splice(index, 1);
            const arr = [];
            dataImages.forEach((file) => {
                arr.push(file);
            });
            setDataImages(arr);
        }
    };

    return (
        <>
            <Modal open={open} onClose={() => handleCloseModal()}>
                <Box sx={style} fullWidth>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={9}>
                            <Typography id="modal-modal-title" variant="h3" component="h2">
                                {!image ? 'Thêm ảnh cho nhà thờ dòng họ' : 'Sửa ảnh'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} direction="row" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {!image && (
                                <>
                                    <Button
                                        onClick={() => setOpenModal(true)}
                                        disableElevation
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        sx={{ marginRight: '8px' }}
                                    >
                                        Thêm
                                    </Button>
                                </>
                            )}
                            <Button
                                // disabled={
                                //     !dataImages.length > 0 ||
                                //     Object.values(errors).every(
                                //         (error) => (error.name === '' || !error.name) && (error.desc === '' || !error.desc)
                                //     ) === false
                                // }
                                disableElevation
                                sx={{ marginRight: '8px' }}
                                size="small"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={onSave}
                                startIcon={<SaveIcon />}
                            >
                                Lưu
                            </Button>
                            <Button
                                disableElevation
                                size="small"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => actionClose()}
                                startIcon={<Close />}
                            >
                                Hủy
                            </Button>
                        </Grid>
                    </Grid>

                    <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                    <Box position="absolute" top={0} right={10} />
                    <Box
                        id="my_modal"
                        sx={{
                            width: '100%',
                            height: { md: 'calc(85vh - 100px)', xs: 'calc(85vh - 100px)' },
                            overflow: 'auto',
                            textAlign: 'center',
                            '&::-webkit-scrollbar': {
                                width: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: customization?.themeDongHo?.heading,
                                outline: 'slategrey'
                            }
                        }}
                    >
                        {dataImages?.length > 0 ? (
                            <ImageList
                                variant="standard"
                                rowHeight="300px"
                                // eslint-disable-next-line no-nested-ternary
                                cols={!image ? (smallScreen ? 1 : 3) : 1}
                                gap={!image ? 8 : 5}
                                sx={{ margin: { xs: '0px 5px', sm: '0px 15px' } }}
                            >
                                {dataImages.map((item, index) => (
                                    <ImageListItem key={index} cols={1}>
                                        <img
                                            // src={image === null ? URL.createObjectURL(item) : `${config.serverImage + item.urlImage}`}
                                            src={item?.urlImage ? `${config.serverImage + item.urlImage}` : URL.createObjectURL(item)}
                                            alt="Candle"
                                            loading="lazy"
                                            className={classes.styleImg}
                                            style={{ objectFit: 'contain' }}
                                        />
                                        <span
                                            style={{
                                                position: 'absolute',
                                                width: '23px',
                                                height: '23px',
                                                borderRadius: '50%',
                                                backgroundColor: 'white',
                                                color: 'red',
                                                fontSize: '16px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                top: '8px',
                                                left: '12px'
                                            }}
                                        >
                                            {index + 1}
                                        </span>
                                        <ImageListItemBar
                                            sx={{
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
                                            }}
                                            position="top"
                                            actionIcon={
                                                !image && (
                                                    <IconButton
                                                        sx={{ color: 'white' }}
                                                        aria-label={`star ${item.title}`}
                                                        onClick={() => onRemoveFile(item)}
                                                    >
                                                        <HighlightOffOutlinedIcon />
                                                    </IconButton>
                                                )
                                            }
                                            actionPosition="right"
                                        />

                                        <div
                                            className="input-group"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            <textarea
                                                className="input-area"
                                                id="myTextArea"
                                                aria-invalid={!!errors[index]?.desc}
                                                aria-describedby={`descError-${index}`}
                                                rows={5}
                                                required
                                                value={item.descImage !== undefined ? item.descImage : item.description}
                                                onChange={(e) => onChangeImage(item, e)}
                                                name="descImage"
                                                data-id="descImage"
                                                style={{
                                                    margin: '1px 0px 3px 0px',
                                                    width: '100%',
                                                    border: `${errors[index]?.desc ? '1.5px solid #f44336' : '1px solid #ccc'}`,
                                                    borderRadius: '4px',
                                                    boxSizing: 'border-box',
                                                    color: '#212121',
                                                    font: 'inherit',
                                                    fontWeight: 500,
                                                    animationDuration: '10ms',
                                                    background: '#fafafa'
                                                }}
                                                onFocus={(e) => {
                                                    e.target.style.border = `2px solid ${customization?.themeDongHo?.heading}`;
                                                    e.target.placeholder = 'Nhập mô tả hình ảnh...';
                                                }}
                                                onBlur={(e) => {
                                                    e.target.style.borderColor = '#ccc';
                                                    e.target.placeholder = '';
                                                }}
                                            />
                                            <span
                                                className="label"
                                                style={{
                                                    marginBottom: '1px',
                                                    fontSize: '12px',
                                                    fontFamily: 'Roboto',
                                                    fontWeight: 400,
                                                    color: errors[index]?.desc ? '#f44336' : null,
                                                    background: '#ffffff'
                                                }}
                                            >
                                                Mô tả
                                            </span>
                                            {errors[index]?.desc && (
                                                <div
                                                    id={`descError-${index}`}
                                                    role="alert"
                                                    style={{
                                                        color: '#f44336',
                                                        fontSize: '0.75rem',
                                                        fontWeight: 400,
                                                        fontFamily: 'Roboto',
                                                        marginTop: '4px',
                                                        lineHeight: '1.66',
                                                        marginLeft: '14px'
                                                    }}
                                                >
                                                    {errors[index]?.desc}
                                                </div>
                                            )}
                                        </div>
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        ) : (
                            <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} colSpan={12}>
                                <NotFound />
                            </Stack>
                        )}
                        {/* <div id="buttomRef" ref={buttomRef} /> */}
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '95%', sm: '75%', md: '35%' },
                        bgcolor: 'background.paper',
                        outline: 'none',
                        boxShadow: 24,
                        p: 2
                    }}
                >
                    <Stack spacing={1}>
                        <Typography variant="h3" component="h2">
                            Chọn ảnh
                        </Typography>
                        {/* <Divider sx={{ marginTop: '10px', marginBottom: '15px' }} /> */}
                        <div style={{ border: '0.5px solid #eeeeee', marginTop: '15px', marginBottom: '20px' }} />
                        <Box position="absolute" top={0} right={0}>
                            {/* <IconButton onClick={() => setOpenModal(false)}>
                                <Close />
                            </IconButton> */}
                            <Button
                                disableElevation
                                fullWidth
                                size="small"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenModal(false)}
                                sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '80px', marginTop: '10px' }}
                                startIcon={<Close />}
                            >
                                Đóng
                            </Button>
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginBottom: 10,
                                marginTop: 10
                            }}
                        >
                            <Button
                                onClick={actionOpenAlbum}
                                color="primary"
                                variant="contained"
                                startIcon={<FolderIcon />}
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                            >
                                Thêm ảnh từ album dòng họ
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                className="mr-2"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => onClickFolder()}
                                startIcon={<PhotoAlbumIcon />}
                            >
                                Tải ảnh lên
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Modal>
            <ModalImageAlbum open={showAlbum} onCloseModal={() => setShowAlbum(false)} onSlelect={onChooseImage} />
        </>
    );
};

export default ModalListImage;
