import { lazy } from 'react';
import { AnonymousRoute } from './AnonymousRoute';
// project imports
import Loadable from 'ui-component/Loadable';

import { Navigate } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import ConfigRouter from './config_router';
import PrivateRoute from './PrivateRoute';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const Splash = Loadable(lazy(() => import('views/pages/authentication/Splash')));

// MainRoutes
const DetailUser = Loadable(lazy(() => import('views/pages/user/detail_user')));
const DetailFamily = Loadable(lazy(() => import('views/pages/family/detail_family')));
const MConfiguration = Loadable(lazy(() => import('views/pages/m_configuration/m_configuration')));
const DetailMember = Loadable(lazy(() => import('views/pages/member/Detail_Member')));
const ListMember = Loadable(lazy(() => import('views/pages/member/List_Member')));
const BuildGenealogy = Loadable(lazy(() => import('views/pages/build_genealogy/BuildGenealogy')));
const TreeViewer = Loadable(lazy(() => import('views/pages/tree/tree-viewer')));
const EventManager = Loadable(lazy(() => import('views/pages/event/EventManager')));
const ListAccount = Loadable(lazy(() => import('views/pages/list_account/List_Account')));
const ListService = Loadable(lazy(() => import('views/pages/list_service/List_Service')));
const AuthorList = Loadable(lazy(() => import('views/pages/creator/authorList/AuthorList')));
// ForgotPasswordRoutes
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/AuthForgotPassword')));
const AuthForgotPasswordFrom = Loadable(lazy(() => import('views/pages/authentication/authentication3/AuthForgotPasswordFrom')));
const AncestralHouse = Loadable(lazy(() => import('views/pages/ancestral_house/AncestralHouse'))); 

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = [
    {
        path: '/',
        element: <Splash />,
        children: []
    },

    {
        path: '/login',
        element: (
            <AnonymousRoute>
                <AuthLogin3 />
            </AnonymousRoute>
        )
    },

    // MainRoutes
    {
        // path: '/',
        element: (
            <PrivateRoute>
                <MainLayout />
            </PrivateRoute>
        ),
        children: [
            {
                path: ConfigRouter.genealogy,
                element: (
                    <PrivateRoute>
                        <BuildGenealogy />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.treeviewer,
                element: (
                    <PrivateRoute>
                        <TreeViewer />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.userInfo,
                element: (
                    <PrivateRoute>
                        <DetailUser />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.detailFamily,
                element: (
                    <PrivateRoute>
                        <DetailFamily />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.detaiMember,
                element: (
                    <PrivateRoute>
                        <DetailMember />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.listMember,
                element: (
                    <PrivateRoute>
                        <ListMember />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.configMaster,
                element: (
                    <PrivateRoute>
                        <MConfiguration />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.listAccount,
                element: (
                    <PrivateRoute>
                        <ListAccount />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.listService,
                element: (
                    <PrivateRoute>
                        <ListService />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.event,
                element: (
                    <PrivateRoute>
                        <EventManager />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.listAccount,
                element: (
                    <PrivateRoute>
                        <AuthorList />
                    </PrivateRoute>
                )
            },
            {
                path: ConfigRouter.ancestralHouseList,
                element: (
                    <PrivateRoute>
                        <AncestralHouse />
                    </PrivateRoute>
                )
            }
        ]
    },

    // ForgotPasswordRoutes
    {
        path: ConfigRouter.forgotPassword,
        element: (
            <AnonymousRoute>
                <AuthForgotPassword />
            </AnonymousRoute>
        )
    },
    {
        path: 'form/forgot_password',
        element: (
            <AnonymousRoute>
                <AuthForgotPasswordFrom />
            </AnonymousRoute>
        )
    },

    {
        path: '*',
        element: <Navigate to="/" />
    }
];

export default AuthenticationRoutes;
