/* eslint-disable consistent-return */
import config from '../../config';
import router from '../../routes/router';
import axios from 'axios';
import ConfigRouter from '../../routes/config_router';
import { getAliasScreen, roleLogout } from 'utils/roleUility';
import { MENUALIAS } from 'constant/roleAlias';
import DialogUtil from 'ui-component/show_confirm_action';

const sendRequest = async (url, method, data = undefined) => {
    try {
        if (!url) throw new Error('Route is undefined');
        const currentPath = window.location.pathname;
        const screen = getAliasScreen(currentPath);

        const fullRoute = config.api + url;
        const instance = axios.create({
            withCredentials: true,
            timeout: config.timeOut
        });

        instance.interceptors.request.use((rq) => {
            rq.headers[MENUALIAS] = `${screen}`;
            return rq;
        });

        instance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (url === router.SUB_URL.logOutAPI) {
                    return;
                }

                if (error.response.status === 401) {
                    // DialogUtil.Errors('Quý khách không có quyền truy cập', () => {
                    //     window.location.href = ConfigRouter.login;
                    // });
                    const remember = localStorage.getItem('REMEMBER') ?? '';
                    const logo = localStorage.getItem('m_family_logo') ?? '';
                    const type_view_diary = localStorage.getItem('type_view_diary') ?? 'list';
                    const type_view_album = localStorage.getItem('type_view_album') ?? 'list';
                    const arrData = localStorage.getItem('LIST_ADDRESS') ? JSON.parse(localStorage.getItem('LIST_ADDRESS')) : [];
                    const vesion = localStorage.getItem('VERSION') ?? '';
                    const font = localStorage.getItem('m_family_font') ?? '';
                    sessionStorage.clear();
                    localStorage.clear();

                    localStorage.setItem('type_view_diary', type_view_diary);
                    localStorage.setItem('type_view_album', type_view_album);
                    localStorage.setItem('REMEMBER', remember);
                    localStorage.setItem('m_family_logo', logo);
                    localStorage.setItem('LIST_ADDRESS', JSON.stringify(arrData));
                    localStorage.setItem('VERSION', vesion);
                    localStorage.setItem('m_family_font', font);
                    window.location.href = ConfigRouter.login;
                }

                if (error.response.status === 416) {
                    DialogUtil.showQuestions(false, error?.response?.data?.message, async () => {
                        const user = await localStorage.getItem('DATA_USER');
                        let dataUser = null;
                        if (user) {
                            dataUser = JSON.parse(user);
                        }
                        if (dataUser?.type === 'ROOT') {
                            window.location.href = ConfigRouter.listService;
                            return;
                        } else {
                            roleLogout();
                        }
                    });
                }
                return Promise.reject(error);
            }
        );
        let response;
        if (data) {
            response = await instance[method](fullRoute, data);
        } else {
            response = await instance[method](fullRoute);
        }

        return response;
    } catch (error) {
        // if (error?.response?.data?.statusCode === 416) {
        //     DialogUtil.showQuestions(false, error?.response?.data?.message, async () => {
        //         const user = await localStorage.getItem('DATA_USER');
        //         let dataUser = null;
        //         if (user) {
        //             dataUser = JSON.parse(user);
        //         }
        //         console.log('dataUser', dataUser);
        //         if (dataUser?.type === 'ROOT') {
        //             window.location.href = ConfigRouter.listService;
        //             return;
        //         } else {
        //             roleLogout();
        //         }
        //     });
        // }
        return error?.response?.data;
    }
};

function downloadFile(url, param = undefined) {
    const fullRoute = config.api + url;
    const currentPath = window.location.pathname;
    const asliasScreen = getAliasScreen(currentPath);
    axios({
        url: fullRoute,
        method: 'post',
        headers: {
            'Menu-Alias': `${asliasScreen}`
        },
        responseType: 'blob',
        withCredentials: true,
        data: param
    }).then((response, error) => {
        if (error) {
            console.log(error.response.data);
        } else {
            const headerLine = response.headers['content-disposition'];
            if (headerLine) {
                const filename = headerLine.split('filename=')[1].split(';')[0];
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        }
        return response;
    });
}

const postRequest = async (url, data = undefined) => sendRequest(url, 'post', data);

const getRequest = async (url, data = undefined) => sendRequest(url, 'get', data);

const fetchClient = {
    sendRequest,
    downloadFile,
    postRequest,
    getRequest
};
export default fetchClient;
