import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, CardMedia } from '@mui/material';
import { useDispatch } from 'react-redux';
import { MENU_OPEN } from 'store/action/actions';
// project imports
import config from '../../../config';
// import Logo from 'ui-component/Logo';
import Logo from '../../../assets/images/logo.webp';

// ==============================|| MAIN LOGO ||============================== //

const LogoNewfeed = ({ linkLogo }) => {
    const onClickLogo = () => {
        console.log('click logo');
    };
    return (
        <ButtonBase
            // sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
            disableRipple
            component={Link}
            to={config.defaultPath}
            onClick={() => onClickLogo()}
        >
            <CardMedia component="img" sx={{ width: '50px' }} image={linkLogo ? config.severImageBase64 + linkLogo : Logo} />
            {/* {logo ? (
                <CardMedia
                    sx={{ marginLeft: '5px', maxWidth: '210px', height: '50px', objectFit: 'initial' }}
                    component="img"
                    image={config.severImageBase64 + logo}
                />
            ) : (
                <CardMedia component="img" height="50" width="70" image={Logo} />
            )} */}
        </ButtonBase>
    );
};

export default LogoNewfeed;
