import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery, Tooltip } from '@mui/material';

// project imports
import { MENU_OPEN, SET_IS_TEXTING, SET_MENU } from 'store/action/actions';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DialogUtil from 'ui-component/show_confirm_action';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level, open, handleHover, handMouseLeave, show }) => {
    const largeScreen = useMediaQuery('(min-width: 1200px)');
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();

    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size={level > 1 ? '10px' : '1.3rem'} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }
    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }
    const itemHandler = (e, id) => {
        if (customization?.isTexting === true) {
            e.preventDefault();
            DialogUtil.showConfirm(
                'Thông Báo',
                'Dữ liệu chưa được cập nhật, bạn có muốn quay lại không?',
                () => {},
                () => {
                    navigate(id);
                    dispatch({ type: MENU_OPEN, id });
                    dispatch({ type: SET_IS_TEXTING, isTexting: false });
                    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
                    handMouseLeave();
                }
            );
        } else {
            dispatch({ type: MENU_OPEN, id });
            if (matchesSM) dispatch({ type: SET_MENU, opened: false });
            handMouseLeave();
        }
    };

    // active menu item on page load
    useEffect(() => {
        // console.log("SPH pathname", document.location.pathname)
        // const currentIndex = document.location.pathname
        //     .toString()
        //     .split('/')
        //     .findIndex((id) => id === item.id);
        // if (currentIndex > -1) {
        //     dispatch({ type: MENU_OPEN, id: item.id });
        // }

        if (item.id === document.location.pathname) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
    }, []);

    // const TextOnlyTooltip = styled(({ className, ...props }) => <Tooltip {...props} componentsProps={{ tooltip: { className } }} />)();

    return (
        // <TextOnlyTooltip
        //     title={item.title}
        //     placement="right"
        //     // open
        //     sx={{
        //         borderRadius: 0,
        //         fontSize: '1rem',
        //         padding: '18px 35px',
        //         color: theme.palette.text.primary,
        //         background: 'rgb(255, 248, 169)',
        //         ml: '0px !important',
        //         pl: '5px',
        //         minWidth: '195px',
        //         cursor: 'pointer'
        //     }}
        //     disableHoverListener={open}
        //     onClick={() => itemHandler(item.id)}
        // >
        // </TextOnlyTooltip>
        // <>
        //     {show ? (
        //         <ListItemButton
        //             {...listItemProps}
        //             disabled={!largeScreen && !item.spMobile}
        //             sx={{
        //                 mb: 0.5,
        //                 justifyContent: open ? 'initial' : 'center',
        //                 pl: !open ? '20px' : `${level * 20}px`
        //             }}
        //             selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
        //             onClick={(e) => itemHandler(e, item.id)}
        //             onMouseOver={handleHover}
        //         >
        //             <ListItemIcon
        //                 sx={{
        //                     minWidth: 0,
        //                     mr: level == 1 ? 1.75 : 2,
        //                     justifyContent: 'center'
        //                 }}
        //             >
        //                 {itemIcon}
        //             </ListItemIcon>
        //             <ListItemText
        //                 primary={
        //                     <Typography
        //                         variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
        //                         color={customization?.themeDongHo?.darkTextSecondary}
        //                     >
        //                         {item.title}
        //                     </Typography>
        //                 }
        //                 sx={{ opacity: open ? 1 : 0 }}
        //             />
        //             {item.chip && (
        //                 <Chip
        //                     color={item.chip.color}
        //                     variant={item.chip.variant}
        //                     size={item.chip.size}
        //                     label={item.chip.label}
        //                     avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        //                 />
        //             )}
        //         </ListItemButton>
        //     ) : null}

        // </>
        <ListItemButton
                    {...listItemProps}
                    disabled={!largeScreen && !item.spMobile}
                    sx={{
                        mb: 0.5,
                        justifyContent: open ? 'initial' : 'center',
                        pl: !open ? '20px' : `${level * 20}px`
                    }}
                    selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
                    onClick={(e) => itemHandler(e, item.id)}
                    onMouseOver={handleHover}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: level == 1 ? 1.75 : 2,
                            justifyContent: 'center'
                        }}
                    >
                        {itemIcon}
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography
                                variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
                                color={customization?.themeDongHo?.darkTextSecondary}
                            >
                                {item.title}
                            </Typography>
                        }
                        sx={{ opacity: open ? 1 : 0 }}
                    />
                    {item.chip && (
                        <Chip
                            color={item.chip.color}
                            variant={item.chip.variant}
                            size={item.chip.size}
                            label={item.chip.label}
                            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                        />
                    )}
                </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
