import { Box, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import ConfigRouter from 'routes/config_router';
import PagesNewfeed from './page';
import { useNavigate } from 'react-router';


const Navbar = ({ dataSetting, dataMenu, checkLogin }) => {
    const DATA = [
        { href: `${PagesNewfeed.home}`, title: 'Trang Chủ', show: true },
        { href: `${PagesNewfeed.introduction}`, title: 'Giới Thiệu', show: dataSetting?.introduction?.isShow },
        { href: null, title: 'Phả Đồ', show: checkLogin, url: ConfigRouter.treeviewer },
        { href: `${PagesNewfeed.album}`, title: 'Album Ảnh', show: dataSetting?.data_exists?.album && dataMenu?.numAlbum > 0 },
        { href: `${PagesNewfeed.diary}`, title: 'Phả Ký', show: dataSetting?.data_exists?.diary && dataMenu?.numDiary > 0 },
        { href: `${PagesNewfeed.ancestral_house}`, title: 'Danh sách nhà thờ', show: dataSetting?.data_exists?.ancestral_house },
        { href: `${PagesNewfeed.creator}`, title: 'Danh sách Tác giả/ Tác phẩm', show: dataSetting?.data_exists?.creator },
        { href: `${PagesNewfeed.statistical}`, title: 'Thống Kê', show: dataSetting?.data_exists?.gen_static },
        { href: null, title: 'Thông Tin Dòng Họ', show: checkLogin, url: ConfigRouter.detailFamily },
        { href: null, title: 'Sự Kiện', show: checkLogin, url: ConfigRouter.event },
        { href: `${PagesNewfeed.contact}`, title: 'Liên Hệ', show: dataSetting?.data_exists?.reply },
    ];

    const navigate = useNavigate();

    const handleScroll = (item) => {
        if (item?.href) {
            // console.log(sectionId);
            const targetElement = document.getElementById(item?.href);
            if (targetElement) {
                const offset = 100; // Độ cao cách 1 khoảng
                const targetPosition = targetElement.offsetTop - offset;
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth' // Hiệu ứng cuộn mượt
                });
            }
        } else {
            navigate(item.url);
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {DATA.map(
                    (item, index) =>
                        item.show && (
                            <Typography
                                variant="h5"
                                underline="none"
                                sx={{ marginRight: '20px', fontSize: '16px', fontWeight: 'bold', cursor: 'pointer' }}
                                onClick={() => {
                                    handleScroll(item);
                                }}
                            >
                                {item.title}
                            </Typography>
                        )
                )}
            </Box>
        </>
    );
};

export default Navbar;
