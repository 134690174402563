import 'tippy.js/dist/tippy.css'
import './index.css'
import { useRef, useState, useEffect, useLayoutEffect, forwardRef, useImperativeHandle } from 'react'
import {
  createPlateUI,
  HeadingToolbar,
  MentionCombobox,
  Plate,
  PlateContent,
  createAlignPlugin,
  createAutoformatPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createCodeBlockPlugin,
  createCodePlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createKbdPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  createDndPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
  createComboboxPlugin,
  createMentionPlugin,
  createIndentPlugin,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createDeserializeMdPlugin,
  createDeserializeCsvPlugin,
  createNormalizeTypesPlugin,
  createFontSizePlugin,
  createHorizontalRulePlugin,
  createDeserializeDocxPlugin,
  PlateEventProvider,
  AutoformatPlugin,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  ELEMENT_OL,
  ELEMENT_TABLE,
  ELEMENT_TD,
  ELEMENT_TH,
  ELEMENT_TR,
  StyledElement,
  withProps
} from '@udecode/plate'
import {
  createExcalidrawPlugin,
  ELEMENT_EXCALIDRAW,
  ExcalidrawElement,
} from '@udecode/plate-ui-excalidraw'
import { createJuicePlugin } from '@udecode/plate-juice'
import { MarkBallonToolbar, ToolbarButtons } from './config/components/Toolbars'
import { withStyledPlaceHolders } from './config/components/withStyledPlaceHolders'
import { withStyledDraggables } from './config/components/withStyledDraggables'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { MENTIONABLES } from './config/mentionables'
import { CONFIG } from './config/config'
import { VALUES } from './config/values/values'
import { createDragOverCursorPlugin } from './config/plugins'
import { CursorOverlayContainer } from './config/components/CursorOverlayContainer'
import {
  createMyPlugins,
  MyEditor,
  MyPlatePlugin,
  MyValue,
} from './config/typescript'

import { syncDataContent } from 'utils/helper';
import { getNodesWithRandomId } from './config/values/utils'

import MenuDiary from '../module/MenuDiary'
import { maxHeight } from '@mui/system'
import useKey from '../module/UseKey'

const id = 'Examples/Playground'

let components = createPlateUI({
  [ELEMENT_EXCALIDRAW]: ExcalidrawElement,
  [ELEMENT_CODE_BLOCK]: StyledElement,
  // customize your components by plugin key
  [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
    styles: {
      root: {
        margin: '10px 0 10px',
        fontWeight: '400',
        fontSize: '19px',
        // fontFamily: 'Noto Serif',
        fontFamily: 'Noto Serif,Regular,Times New Roman',
        color: 'black',
        lineHeight: '32px'
      },
    },
  }),
  [ELEMENT_H1]: withProps(StyledElement, {
    styles: {
      root: {
        margin: '20px 0 10px',
        fontFamily: 'Montserrat,Raleway,sans-serif',
        fontWeight: '700',
        fontSize: '30px',
        lineHeight: '3rem'
      },
    },
  }),
  [ELEMENT_H2]: withProps(StyledElement, {
    styles: {
      root: {
        margin: '20px 0 10px',
        fontFamily: 'Montserrat,Raleway,sans-serif',
        fontWeight: '700',
        fontSize: '26px',
        lineHeight: '1.5'
      },
    },
  }),
  [ELEMENT_H3]: withProps(StyledElement, {
    styles: {
      root: {
        margin: '20px 0 10px',
        fontFamily: 'Montserrat,Raleway,sans-serif',
        fontWeight: '700',
        fontSize: '22px',
        lineHeight: '1.5'
      },
    },
  }),
});

components = withStyledPlaceHolders(components)
components = withStyledDraggables(components)

const plugins = createMyPlugins(
  [
    createParagraphPlugin(),
    createBlockquotePlugin(),
    createTodoListPlugin(),
    createHeadingPlugin(),
    createImagePlugin(),
    createHorizontalRulePlugin(),
    createLinkPlugin(),
    createListPlugin(),
    createTablePlugin(),
    createMediaEmbedPlugin(),
    createExcalidrawPlugin() as MyPlatePlugin,
    createCodeBlockPlugin(),
    createAlignPlugin(CONFIG.align),
    createBoldPlugin(),
    createCodePlugin(),
    createItalicPlugin(),
    createHighlightPlugin(),
    createUnderlinePlugin(),
    createStrikethroughPlugin(),
    createSubscriptPlugin(),
    createSuperscriptPlugin(),
    createFontColorPlugin(),
    createFontBackgroundColorPlugin(),
    createFontSizePlugin(),
    createKbdPlugin(),
    createNodeIdPlugin(),
    createDndPlugin(),
    createDragOverCursorPlugin(),
    createIndentPlugin(CONFIG.indent),
    createAutoformatPlugin<
      AutoformatPlugin<MyValue, MyEditor>,
      MyValue,
      MyEditor
    >(CONFIG.autoformat),
    createResetNodePlugin(CONFIG.resetBlockType),
    createSoftBreakPlugin(CONFIG.softBreak),
    createExitBreakPlugin(CONFIG.exitBreak),
    createNormalizeTypesPlugin(CONFIG.forceLayout),
    createTrailingBlockPlugin(CONFIG.trailingBlock),
    createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
    createComboboxPlugin(),
    // createMentionPlugin(),
    // createDeserializeMdPlugin(),
    // createDeserializeCsvPlugin(),
    createDeserializeDocxPlugin(),
    createJuicePlugin() as MyPlatePlugin,
  ],
  {
    components,
  }
)

const initialValue =
[
  {
      type: 'p',
      children: [
          {
              text: '1',
          },
      ]
  }
] as MyValue;

type BlogEditorProps = {
  autoSaveAction(valuePlate: any): void;
  getEditorValue(valuePlate: any): void;
  backToList(): void;
  isViewOnly: string;
  dataBlog: MyValue;
  loadingSave: boolean,
  idPost: string
};

const BlogEditor: React.FunctionComponent<BlogEditorProps> = forwardRef(({isViewOnly, dataBlog, autoSaveAction, getEditorValue, backToList, loadingSave, idPost, ...props}, ref:any) => {
  const containerRef = useRef<HTMLInputElement>(null)



  const [valuePlate, setValuePlate] = useState(null);
  const [editorHeight, setEditorHeight] = useState(0);

  const fncOnchange = (value: any) => {
    // console.log("fncOnchange", value)
    syncDataContent(value);
    getEditorValue(value);
    setValuePlate(value);
  };

  useLayoutEffect (() => {
      const myTimeout = setTimeout(()=>{
        if (containerRef.current && isViewOnly) {
          // console.log(containerRef.current.clientHeight)
          setEditorHeight(containerRef.current.clientHeight);
        }
      }, 1000);
  })

  useEffect(() => {
    if (valuePlate !== null) {
        const delayDebounceFn = setTimeout(async () => {
            autoSaveAction(valuePlate);
        }, 3000);
        return () => clearTimeout(delayDebounceFn);
    }
    return;
  }, [valuePlate]);

  const onBackToListPost = () => {
    console.log('onBackToListPost')
    backToList()
  }

  const onSavePost = () => {
    console.log('onSavePost')
    if (valuePlate !== null) {
      autoSaveAction(valuePlate)
    }
  }

  const handleSubmit = () => {
    console.log('Ctrl+S fired!')
    if (valuePlate !== null) {
      autoSaveAction(valuePlate)
    }
  }

  useKey('ctrls', handleSubmit);

  // console.log("VALUES.playground", VALUES.playground);
  // console.log('dataBlog -----', dataBlog);
  return (
    <DndProvider backend={HTML5Backend}>
      <PlateEventProvider>
        {!isViewOnly &&
          <HeadingToolbar style={{backgroundColor: 'white', margin: '0px', padding: '5px', position: 'relative'}}>
            <ToolbarButtons
              backToListPost={onBackToListPost}
              savePost={onSavePost}
              loadingSave={loadingSave}
              idPost={idPost}
            />
          </HeadingToolbar>
        }
      </PlateEventProvider>

      <div
        style={{
          // backgroundColor: '#F9FBFF',
          position: 'relative',
          overflowY: 'auto',
          width: '100%',
          minHeight: '55vh',
          maxHeight: `${isViewOnly ? '' : '75vh'}`,
          fontWeight: '400',
          fontSize: '19px',
          fontFamily: 'Noto Serif,Regular,Times New Roman',
          color: 'black',
          lineHeight: '32px'
        }}
        id="EditorSPH"
        ref={ref}
      >
        {isViewOnly && <div style={{ position: 'absolute', cursor: 'default', zIndex: '1000', top: 0, left: 0, width: '100%', minHeight: '55vh', height: '100%' }}> </div> }
        <div
          ref={containerRef}
          // ref={ref}
          style={{
            position: 'relative'
          }}
        >
          <Plate<MyValue>
            // ref={plateRef}
            id={id}
            editableProps={CONFIG.editableProps}
            // initialValue={VALUES.playground}
            initialValue={getNodesWithRandomId(dataBlog)}
            plugins={plugins}
            onChange={fncOnchange}
          >
            <MarkBallonToolbar />

            {/* <MentionCombobox items={MENTIONABLES} /> */}

            <CursorOverlayContainer containerRef={containerRef} />
          </Plate>
        </div>
      </div>
      {/* <MenuDiary></MenuDiary> */}
    </DndProvider>
  )
})

export default BlogEditor;
