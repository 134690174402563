import { Close } from '@mui/icons-material';
import { Box, Divider, IconButton, Modal, Typography } from '@mui/material';
import BlogEditor from 'views/pages/diary/diaryBlog';
import { convertTime } from 'utils/helper';
import { useEffect, useRef, useState } from 'react';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { sm: '80%', md: '80%', xs: '95vw' },
    height: '95vh',
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: { md: 2, xs: 1 }
};

const ModalDetailDiary = ({ open, onCloseModal, diary, getEditorValue, blogEditorRef, dataContents }) => {
    return (
        <Modal open={open} onClose={() => onCloseModal()} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
            <Box sx={styleModal}>
                <Box position="absolute" sx={{ zIndex: 9999, top: '4px', right: { md: '15px', xs: '8px' } }}>
                    <IconButton onClick={() => onCloseModal()}>
                        <Close />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        maxHeight: '90%',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '2px'
                        },
                        '&::-webkit-scrollbar-thumb ': {
                            backgroundColor: '#003168'
                        }
                    }}
                >
                    <Typography id="modal-modal-title" sx={{ marginRight: '30px' }} variant="h3" component="h2">
                        {diary?.title ?? ''}
                    </Typography>
                    <Typography variant="span" component="span" sx={{ marginTop: '5px' }}>
                        <span style={{ fontWeight: 700, marginRight: '5px', fontStyle: 'italic' }}>
                            {diary?.author ? diary?.author : 'Ẩn danh'}
                        </span>
                        {' - '}
                        {convertTime(diary?.update_at)}
                    </Typography>
                    <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                    <BlogEditor
                        isViewOnly={true}
                        dataBlog={dataContents}
                        ref={blogEditorRef}
                        autoSaveAction={() => {}}
                        getEditorValue={(value) => getEditorValue(value)}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalDetailDiary;
