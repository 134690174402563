import { Box, Button, Link, Typography } from '@mui/material';

import QRCode from 'qrcode.react';
import BgQrcode from '../../homepage/asset/mobile_bg_btn.webp';
import Logo from '../../../../assets/images/logo.webp';
import ConfigRouter from 'routes/config_router';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';


const QRCodeLogin = ({ type }) => {
    const navigate = useNavigate();
    const [version, setVersion] = useState(null);

    useEffect(() => {
        const version = localStorage.getItem('VERSION') ? localStorage.getItem('VERSION') : null;
        setVersion(version)
    }, []);
    const handleClick = () => {
        navigate(ConfigRouter.login);
    };
    const currentURL = window.location.origin + ConfigRouter.login;
    const getDataUser = localStorage.getItem('DATA_USER') || '';
    return (
        <>
            <Box
                sx={{
                    minWidth: '215px',
                    width: '215px',
                    height: '190px',
                    display: { md: 'flex', xs: 'none' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                    margin: 'auto'
                }}
            >
                <img src={BgQrcode} style={{ width: '100%', height: '100%' }} alt="qr" />
                <Box sx={{ position: 'absolute', marginTop: '5px', marginLeft: '5px' }}>
                    <QRCode
                        size={130}
                        value={currentURL}
                        level="H"
                        imageSettings={{
                            src: Logo,
                            excavate: true,
                            height: 40,
                            width: 40
                        }}
                    />
                </Box>
            </Box>
            {type === 'newfeed' && (
                <Box>
                    {/* <Button variant="contained" startIcon={<LoginIcon />}></Button> */}
                    <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                            textAlign: 'center',
                            marginY: 2,
                            fontWeight: 'bold',
                            color: '#8B0000',
                            fontSize: '16px',
                            display: { md: 'block', xs: 'none' }
                        }}
                    >
                        Quét mã QR Code để truy cập Gia Phả Số trên các thiết bị di động
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', marginY: 2, display: { md: 'block', xs: 'none' } }}>
                        hoặc
                    </Typography>
                    <Button variant="contained" onClick={handleClick} startIcon={<LoginIcon />}>
                        {getDataUser !== '' ? 'Đến trang menu chính' : 'Đến trang đăng nhập'}
                    </Button>
                </Box>
            )}
            {type !== 'newfeed' && (
                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', marginY: 2, color: '#8B0000' }}>
                {version ? ` Gia Phả Số: ${version} | Quét mã QR Code để truy cập trên các thiết bị di động `: "Quét mã QR Code để truy cập trên các thiết bị di động"}
                </Typography>
            )}
        </>
    );
};

export default QRCodeLogin;
