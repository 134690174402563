import { Box, Button, Grid, Link, Toolbar, Typography, useMediaQuery } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.css';
import style from './contact.module.css';
import { useEffect, useState } from 'react';

const handleGroupData = (arr, numOfGroup) => {
    const res = arr.reduce((acc, item, index) => {
        const groupIndex = Math.floor(index / numOfGroup);
        if (!acc[groupIndex]) {
            acc[groupIndex] = [];
        }
        acc[groupIndex].push(item);
        return acc;
    }, []);
    console.log(res);
    return res;
};

const checkMobile = () => {
    if (window.innerWidth <= 768) {
        return true;
    } else {
        // Đây có thể là một máy tính
        return false;
    }
};

const getLengthGridMd = (length) => {
    return 12 / parseInt(length);
};
const textColor = 'rgba(255, 255, 255, 0.75)';
const boderStyle = '1px solid rgba(255, 255, 255, 0.2)';
const getIcon = (type) => {
    switch (type) {
        case 'facebook':
            return <FacebookIcon />;
        case 'phone':
            return <PhoneIcon />;
        case 'mail':
            return <EmailIcon />;

        default:
            return <LanguageIcon />;
    }
};
const Contact = ({ data }) => {
    const mediumScreen = useMediaQuery('(max-width: 900px)');
    const [dataContact, setDataContact] = useState([]);
    useEffect(() => {
        if (data) {
            const dataNew = data.filter((item) => item?.type !== 'youtube');
            setDataContact(handleGroupData(dataNew, checkMobile() ? 1 : 4));
        } // nhóm 4 phần tử vào 1 arr
    }, [data]);
    const onClick = (item) => {
        switch (item?.type) {
            case 'facebook':
            case 'web':
                window.open(item?.url ?? '/', '_blank');
                break;
            case 'zalo':
                window.location = 'tel:' + item?.url;
                break;
            case 'phone':
                window.location = 'tel:' + item?.url;
                break;
            case 'mail':
                window.location = 'mailto:' + item?.url;
                break;

            default:
                window.open(item?.url ?? '/', '_blank');
                break;
        }
    };
    return (
        <>
            {dataContact?.length > 0 ? (
                <Box sx={{ backgroundColor: '#630707', padding: '5px 0px' }}>
                    <Box sx={{ maxHeight: '100%', width: { xs: '95%', lg: '95%', xl: '70%' }, margin: 'auto' }}>
                        <Swiper
                            className={style.swiper_wrapper_custom}
                            onSwiper={(swiper) => {}}
                            modules={[Autoplay]}
                            slidesPerView={1}
                            spaceBetween={30}
                            autoplay={{ delay: 10000, disableOnInteraction: false }}
                            speed={9000}
                            loop
                        >
                            {dataContact.map((contact, i) => (
                                <SwiperSlide className={style.slider_custom}>
                                    <Grid container spacing={2}>
                                        {contact.map((item, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                md={contact?.length > 3 ? 3 : 12 / contact.length}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Box
                                                    onClick={() => {
                                                        onClick(item);
                                                    }}
                                                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                >
                                                    <Box
                                                        sx={{
                                                            color: textColor,
                                                            border: boderStyle,

                                                            marginRight: '10px'
                                                        }}
                                                    >
                                                        {getIcon(item.type)}
                                                    </Box>
                                                    <Typography variant="h6" sx={{ color: textColor }} component="h6">
                                                        {item.title} {item.title === '' ? '' : ` : `} {item.url}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </Box>
            ) : null}
        </>
    );
};

export default Contact;
