import { Box, Modal, Button, IconButton, Stack, Typography, Grid, Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Close } from '@mui/icons-material';
import Logo from '../assets/images/logo_gray.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '350px',
    transform: 'translate(-50%, -50%)',
    maxWidth: '350px',
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 2,
    borderRadius: 2
};

export default function PreventModal({ openModal, setOpenModal }) {
    const onCloseModal = () => {
        setOpenModal(false);
    };
    return (
        <Modal open={openModal} onClose={onCloseModal}>
            <Box sx={style}>
                <Stack spacing={1}>
                    <Box position="absolute" top={0} right={0}>
                        <IconButton onClick={onCloseModal}>
                            <Close />
                        </IconButton>
                    </Box>
                </Stack>

                <Grid container direction="column" spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                            <img
                                style={{ justifySelf: 'center', alignSelf: 'center', marginBottom: 8 }}
                                width="120px"
                                height="120px"
                                alt="dvo-logo"
                                src={Logo}
                            />
                            <Typography variant="h2" component="h2">
                                RẤT TIẾC!
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                            <Typography variant="body1" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                                Chúng tôi chưa hỗ trợ chức năng này cho các thiết bị di động. Xin vui lòng truy cập bằng máy tính để có thể
                                sử dụng chức năng này.
                            </Typography>
                            <Button
                                variant="contained"
                                color="info"
                                size="small"
                                sx={{
                                    color: '#fff',
                                    outline: 'none'
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onCloseModal();
                                }}

                                // startIcon={<DownloadIcon />}
                            >
                                Đóng
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}
