import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import PreventModal from '../../../../../ui-component/prevent_modal';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item, open, handleHover, handMouseLeave }) => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} openDrawer={open} menu={menu} level={1} handleHover={handleHover} handMouseLeave={handMouseLeave} />;
            case 'item':
                return (
                    <NavItem key={menu.id} item={menu} level={1} open={open} handleHover={handleHover} handMouseLeave={handMouseLeave} />
                );
            // }
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
            // subheader={
            //     item.title &&
            //     open && (
            //         <Typography
            //             variant="caption"
            //             sx={{ ...theme.typography.menuCaption }}
            //             display="block"
            //             gutterBottom
            //             pt="10px !important"
            //         >
            //             {item.title}
            //             {item.caption && (
            //                 <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
            //                     {item.caption}
            //                 </Typography>
            //             )}
            //         </Typography>
            //     )
            // }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 1.25, borderColor: '#bdbdbd' }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
