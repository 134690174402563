/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    return {
        mode: theme?.customization?.navType,
        common: {
            black: theme?.customization?.themeDongHo?.common.black
        },
        primary: {
            light: theme?.customization?.themeDongHo?.primary.light,
            main: theme?.customization?.themeDongHo?.primary.main,
            dark: theme?.customization?.themeDongHo?.primary.dark,
            200: theme?.customization?.themeDongHo?.primary.x200,
            800: theme?.customization?.themeDongHo?.primary.x800
        },
        secondary: {
            light: theme?.customization?.themeDongHo?.secondary.light,
            main: theme?.customization?.themeDongHo?.secondary.main,
            dark: theme?.customization?.themeDongHo?.secondary.dark,
            200: theme?.customization?.themeDongHo?.secondary.x200,
            800: theme?.customization?.themeDongHo?.secondary.x800
        },
        error: {
            light: theme?.customization?.themeDongHo?.error.light,
            main: theme?.customization?.themeDongHo?.error.main,
            dark: theme?.customization?.themeDongHo?.error.dark
        },
        orange: {
            light: theme?.customization?.themeDongHo?.orange.light,
            main: theme?.customization?.themeDongHo?.orange.main,
            dark: theme?.customization?.themeDongHo?.orange.dark
        },
        warning: {
            light: theme?.customization?.themeDongHo?.warning.light,
            main: theme?.customization?.themeDongHo?.warning.main,
            dark: theme?.customization?.themeDongHo?.warning.dark
        },
        success: {
            light: theme?.customization?.themeDongHo?.success.light,
            200: theme?.customization?.themeDongHo?.success.x200,
            main: theme?.customization?.themeDongHo?.success.main,
            dark: theme?.customization?.themeDongHo?.success.dark
        },
        grey: {
            50: theme?.customization?.themeDongHo?.grey.x50,
            100: theme?.customization?.themeDongHo?.grey.x100,
            500: theme?.customization?.themeDongHo?.grey.x500,
            600: theme?.customization?.themeDongHo?.grey.x600,
            700: theme?.customization?.themeDongHo?.grey.x700,
            900: theme?.customization?.themeDongHo?.grey.x900
        },
        dark: {
            light: theme?.customization?.themeDongHo?.dark.light,
            main: theme?.customization?.themeDongHo?.dark.main,
            dark: theme?.customization?.themeDongHo?.dark.dark,
            800: theme?.customization?.themeDongHo?.dark.x800,
            900: theme?.customization?.themeDongHo?.dark.x900
        },
        text: {
            primary: theme?.customization?.themeDongHo?.text.primary,
            secondary: theme?.customization?.themeDongHo?.text.secondary,
            dark: theme?.customization?.themeDongHo?.text.dark,
            hint: theme?.customization?.themeDongHo?.text.hint
        },
        background: {
            paper: theme?.customization?.themeDongHo?.background.paper,
            default: theme?.customization?.themeDongHo?.background.default
        }
    };
}
