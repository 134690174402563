import { useEffect, useState, useRef } from 'react';
import {
    Paper,
    Button,
    Stack,
    Grid,
    TextField,
    TablePagination,
    Typography,
    Tooltip,
    Box,
    Menu,
    ToggleButtonGroup,
    ToggleButton,
    CircularProgress,
    ButtonGroup,
    useMediaQuery,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    MenuItem,
    ListItemIcon,
    TableHead,
    Chip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewListIcon from '@mui/icons-material/ViewList';
import MainCard from 'ui-component/cards/MainCard';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import NotFound from 'ui-component/404/404';
import { styled, useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { ShortCutName, shortenName } from 'utils/helper';
import ModalAddAuthor from './modal/ModalAddAuthor';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import axios from 'axios';
import config from 'config';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DialogUtil from 'ui-component/show_confirm_action';
import BookIcon from '@mui/icons-material/Book';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ModalListWorks from '../workList/modal/ModalListWorks';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getAliasScreen, getRoleButton } from 'utils/roleUility';
import InfoIcon from '@mui/icons-material/Info';
import ModalInfoWork from './modal/ModalInfoWork';
import ModalAddWorkByAuthor from './modal/ModalAddWorkByAuthor';
import { get } from 'lodash';

const TYPE = {
    create: 'THEM_TG',
    update: 'CAP_NHAP_TG',
    view: 'XEM_THONG_TIN_TG',
    delete: 'XOA_TG',
    view_book: 'XEM_TAC_PHAM'
};

const WORK = 'TAC_PHAM';
const AUTHOR = 'TAC_GIA';

const columns = [
    { id: 'stt', label: 'STT', minWidth: 50, align: 'center', rowAlign: 'center', xsDisplay: 'none', width: '50px' },
    {
        id: 'content',
        label: 'Bút danh',
        minWidth: { md: '200px', xs: '150px' },
        align: 'center',
        rowAlign: 'left',
        sx: { position: 'sticky', left: '0px', zIndex: '99', borderRight: { md: 'none', xs: '1px solid white' } }
    },
    { id: 'name_author', label: 'Tên thật', minWidth: { md: 200, xs: 150 }, align: 'center', rowAlign: 'left' },
    { id: 'birth', label: 'Năm sinh/mất', minWidth: { md: 110, xs: 110 }, align: 'center', rowAlign: 'left' },
    { id: 'isPublic', label: 'Giai đoạn sáng tác', minWidth: { md: 250, xs: 0 }, align: 'center', rowAlign: 'left', xsDisplay: 'none' },
    { id: 'create_by', label: 'Thể loại', minWidth: { md: 250, xs: 150 }, align: 'center', rowAlign: 'left' },
    { id: 'update_by', label: 'Tác phẩm', minWidth: { md: 250, xs: 120 }, align: 'center', rowAlign: 'left' },
    { id: 'status', label: 'Trạng thái', width: 120, align: 'center', rowAlign: 'left' }
];

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: '#0daeff'
            }
        }
    }
}));

const RIGHT_CLICK_OPTIONS = [
    {
        name: 'Thông tin tác giả',
        alias: 'TT_TAC_GIA',
        icon: <InfoIcon fontSize="small" key="QUAN_HE_HT" />,
        show: true
    },
    {
        name: 'Các tác phẩm',
        alias: 'TT_THANH_VIEN',
        icon: <LibraryBooksIcon fontSize="small" />,
        key: 'DA_TP',
        show: true
    }
];

const AuthorList = ({ typeView, changeTab }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const timer = useRef(null);
    const customization = useSelector((state) => state.customization);
    const [listAuthor, setListAuthor] = useState([]);
    const [open, setOpen] = useState(false);
    const [authorSelect, setAuthortSelect] = useState(null);
    const [authorName, setAuthorName] = useState('');
    const [viewType, setViewType] = useState(typeView);
    const [typeModel, setTypeModel] = useState(null);
    const [idAuthor, setIdAuthor] = useState('');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotalRows] = useState(0);
    const [showList, setShowList] = useState(false);
    const dataGenry = JSON.parse(localStorage.getItem('m_genre'));
    const [roles, setRole] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [workSelect, setWorkSelect] = useState(null);
    const [detailWork, setDetailWork] = useState(false);

    const dispatch = useDispatch();

    // const searchInputRef = useRef(null);
    const openSelect = Boolean(anchorEl);
    const openDropdown = Boolean(anchorElMenu);

    const handleOpenDropDown = (event) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleCloseDropDown = () => {
        setAnchorElMenu(null);
    };

    const onDeleteAuthor = async () => {
        const pr = {
            _id: authorSelect?._id
        };
        const urlApi = router.SUB_URL.delete_author;
        const rst = await fetchClient.postRequest(urlApi, pr);
        // console.log('rst', rst')
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, 'Xóa tác giả thành công', () => {
                getData(page, rowsPerPage);
            });
        } else {
            DialogUtil.showQuestions(false, 'Xóa tác giả thất bại');
        }
    };

    const handleDeleteAuthor = () => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn xoá tác giả ${authorSelect?.name_author} không ?`,
            () => {},
            () => onDeleteAuthor()
        );
    };

    const onPublicAuthor = async (row) => {
        const pr = {
            id: row?._id,
            is_public: (row?.is_public !== null ? !row?.is_public : true)
        };
        const urlApi = router.SUB_URL.update_author;
        const formData = new FormData();
        formData.append('dataAuthor', JSON.stringify(pr))
        const rst = await fetchClient.postRequest(urlApi, formData);
        // console.log('rst', rst')
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, (((row?.is_public !== null ? !row?.is_public : true)?'Công khai': 'Hủy công khai')+' thông tin tác giả thành công'), () => {
                getData(page, rowsPerPage);
            });
        } else {
            DialogUtil.showQuestions(false, ((row?.is_public !== null ? !row?.is_public : true)?'Công khai': 'Hủy công khai')+'thông tin tác giả thất bại');
        }
    };


    const handlePublicAuthor = (row) => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn ${row?.is_public ? 'hủy công khai': 'công khai'} thông tin tác giả ${row?.name_author} không ?`,
            () => {},
            () => onPublicAuthor(row)
        );
    };

    const onReload = async () => {
        getData(page, rowsPerPage);
    };

    const handleClick = (item) => {
        setAuthortSelect(item);
        setIdAuthor(item?._id);
    };

    const setSearch = (value) => {
        setAuthorName(value);
        if (timer) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            setPage(0);
            setRowsPerPage(10);
            getData(0, 10, value);
        }, 1000);
    };

    const handleChangeTypeView = (event, nextView) => {
        // console.log('aaa', nextView);
        changeTab(nextView);
        setViewType(nextView);
    };

    const onClickAddAuthor = () => {
        setTypeModel(TYPE.create);
        setIdAuthor('');
        setAuthortSelect(null);
        setOpen(true);
    };

    const handleCloseModalAuthor = () => {
        setOpen(false);
        setIdAuthor('');
        setAuthortSelect(null);
        onReloadData(page, rowsPerPage, authorName);
    };

    const closeModalList = () => {
        setShowList(false);
        setIdAuthor('');
        setAuthortSelect(null);
    };

    const getDataChangePage = async (page, rowsPerPage) => {
        let urlApi = router.SUB_URL.list_authors;
        const pr = {
            rowsPerpage: rowsPerPage,
            currentPage: page
        };
        const rst = await fetchClient.postRequest(urlApi, pr);
        if (rst.status === 201) {
            setListAuthor(rst?.data.original ?? []);
            setTotalRows(rst?.data?.total);
        } else {
            DialogUtil.showQuestions(false, 'Lấy danh sách tác giả thất bại');
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getDataChangePage(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        getDataChangePage(0, +event.target.value);
    };

    const getData = async (page, rowsPerPage, name) => {
        setLoading(true);
        let urlApi = router.SUB_URL.list_authors;
        const pr = {
            search: name,
            rowsPerpage: rowsPerPage,
            currentPage: page
        };
        const rst = await fetchClient.postRequest(urlApi, pr);
        if (rst.status === 201) {
            setLoading(false);
            setListAuthor(rst?.data.original ?? []);
            setTotalRows(rst?.data?.total);
            setIdAuthor('');
            setAuthortSelect(null);
        } else {
            setLoading(false);
        }
    };

    const onReloadData = async () => {
        let urlApi = router.SUB_URL.list_authors;
        const pr = {
            search: authorName,
            rowsPerpage: rowsPerPage,
            currentPage: page
        };
        const rst = await fetchClient.postRequest(urlApi, pr);
        if (rst.status === 201) {
            setListAuthor(rst?.data.original ?? []);
            setTotalRows(rst?.data?.total);
            setIdAuthor('');
            setAuthortSelect(null);
        } else {
            DialogUtil.showQuestions(false, 'Lấy danh sách tác giả thất bại');
        }
    };

    const findName = (value) => {
        const rst = dataGenry
            ?.filter((r) => {
                return r?.config_value.genre_id === value;
            })
            .map((m) => {
                return m?.config_value.genre_name;
            });
        return rst;
    };

    const getRoleButtonScreen = async () => {
        const rs = await getRoleButton();
        setRole(rs);
    };

    useEffect(() => {
        if (viewType === 'TAC_GIA') {
            getData(page, rowsPerPage);
        }
    }, [viewType, rowsPerPage, page]);

    useEffect(() => {
        if (viewType === 'TAC_GIA') {
            getRoleButtonScreen();
        }
    }, []);

    const download = (url, param = undefined) => {
        const fullRoute = config.api + url;
        axios({
            url: fullRoute,
            method: 'post',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Menu-Alias': getAliasScreen(window.location.pathname)
            },
            withCredentials: true,
            data: param
        }).then((response, error) => {
            if (error) {
                // setShowLoad(false);
            } else {
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = type;
                link.click();
            }
        });
    };

    const onExportExcel = async () => {
        const pr = {};
        await download(router.SUB_URL.excel_creator, pr);
    };

    const onExcel = async () => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn tải file excel danh sách tác giả, tác phẩm không ?`,
            () => {},
            () => onExportExcel()
        );
    };

    const onClickDrop = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onEditAuthor = () => {
        setTypeModel(TYPE.update);
        setOpen(true);
    };

    const handleCloseDrop = (event) => {
        event.preventDefault();
        setAnchorEl(null);
    };

    const onClickMenuOption = (option) => {
        switch (option) {
            case 'TT_TAC_GIA':
                setTypeModel('XEM_THONG_TIN_TG');
                setOpen(true);
                break;
            case 'TT_THANH_VIEN':
                setShowList(true);
                break;
            default:
                break;
        }
    };

    const dateTimeAuthor = (bitrh, dead) => {
        let strBirth = '';
        let strDead = '';
        strBirth = [bitrh?.day, bitrh?.month, bitrh?.year]
            .map((i) => {
                if (i) {
                    return i;
                }
                return '--';
            })
            .join('/');
        if (dead?.day || dead?.month || dead?.year) {
            strDead = [dead?.day, dead?.month, dead?.year]
                .map((i) => {
                    if (i) {
                        return i;
                    }
                    return '--';
                })
                .join('/');
        }
        return strDead ? `${strBirth}<br />-<br />${strDead}` : strBirth;
    };

    const openModalDetailWork = (word) => {
        // console.log('word', word);
        setWorkSelect(word);
        setDetailWork(true);
    };

    const onCloseModalDetailWork = () => {
        setIdAuthor('');
        setDetailWork(false);
    };

    if (roles?.read === false) {
        return <NotFound text="Bạn không có quyền truy cập vào chức năng này." />;
    }

    if (!loading) {
        return (
            <>
                <Stack>
                    <Grid container spacing={1}>
                        <Grid item md={11} xs={12}>
                            <div
                                style={{
                                    display: isMobile ? 'inline' : 'flex',
                                    marginBottom: '10px'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        marginY: { md: 0, xs: '10px' },
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <TextField
                                        // ref={searchInputRef}
                                        sx={{ width: { md: '60%', xs: '100%' }, marginRight: { md: '0px', xs: '5px' } }}
                                        id="search-bar"
                                        className="text"
                                        value={authorName}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder="Tìm kiếm theo tên, bút danh ..."
                                        fullWidth
                                    />
                                    {isMobile && (
                                        <Box sx={{ display: { md: 'none', xs: 'flex', justifyContent: 'flex-end' } }}>
                                            <Button
                                                color="primary"
                                                size="small"
                                                variant="contained"
                                                sx={{ borderBlockColor: 'black', width: '100%' }}
                                                onClick={handleOpenDropDown}
                                            >
                                                <MoreHorizIcon />
                                            </Button>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button'
                                                }}
                                                anchorEl={anchorElMenu}
                                                open={openDropdown}
                                                onClose={handleCloseDropDown}
                                            >
                                                {roles?.create && (
                                                    <MenuItem
                                                        onClick={() => {
                                                            handleCloseDropDown();
                                                            onClickAddAuthor();
                                                        }}
                                                    >
                                                        <AddIcon sx={{ marginRight: 1 }} />
                                                        Thêm tác giả
                                                    </MenuItem>
                                                )}
                                                {roles?.read && (
                                                    <MenuItem
                                                        disabled={authorSelect ? false : true}
                                                        onClick={() => {
                                                            handleCloseDropDown();
                                                            setTypeModel('XEM_THONG_TIN_TG');
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        <InfoIcon sx={{ marginRight: 1 }} />
                                                        Xem thông tin tác giả
                                                    </MenuItem>
                                                )}
                                                {roles?.read && (
                                                    <MenuItem
                                                        disabled={authorSelect ? false : true}
                                                        onClick={() => {
                                                            handleCloseDropDown();
                                                            setShowList(true);
                                                        }}
                                                    >
                                                        <LibraryBooksIcon sx={{ marginRight: 1 }} />
                                                        Xem tác phẩm của tác giả
                                                    </MenuItem>
                                                )}
                                                {roles?.update && (
                                                    <MenuItem
                                                        disabled={authorSelect ? false : true}
                                                        onClick={() => {
                                                            handleCloseDropDown();
                                                            onEditAuthor();
                                                        }}
                                                    >
                                                        <EditIcon sx={{ marginRight: 1 }} />
                                                        Chỉnh sửa tác giả
                                                    </MenuItem>
                                                )}
                                                {roles?.delete && (
                                                    <MenuItem
                                                        disabled={authorSelect ? false : true}
                                                        onClick={() => {
                                                            handleCloseDropDown();
                                                            handleDeleteAuthor();
                                                        }}
                                                    >
                                                        <DeleteIcon sx={{ marginRight: 1 }} />
                                                        Xóa tác giả
                                                    </MenuItem>
                                                )}
                                                {roles?.export && (
                                                    <MenuItem
                                                        disabled={listAuthor?.length > 0 ? false : true}
                                                        onClick={() => {
                                                            handleCloseDropDown();
                                                            onExcel();
                                                        }}
                                                    >
                                                        <FileDownloadIcon sx={{ marginRight: 1 }} />
                                                        Xuất excel
                                                    </MenuItem>
                                                )}
                                                <MenuItem
                                                    onClick={() => {
                                                        handleCloseDropDown();
                                                        changeTab(WORK);
                                                    }}
                                                >
                                                    <BookIcon sx={{ marginRight: 1 }} />
                                                    Hiển thị danh sách tác phẩm
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ display: { md: 'flex', xs: 'none' }, margin: isMobile ? '10px' : '0', marginLeft: 0 }}>
                                    {roles?.create && (
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => onClickAddAuthor()}
                                            sx={{
                                                marginRight: '10px',
                                                whiteSpace: 'nowrap',
                                                minWidth: '180px'
                                            }}
                                            startIcon={<AddIcon />}
                                        >
                                            Thêm tác giả mới
                                        </Button>
                                    )}
                                    <Button
                                        id="demo-customized-button"
                                        aria-controls={openSelect ? 'demo-customized-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openSelect ? 'true' : undefined}
                                        variant="contained"
                                        disabled={authorSelect ? false : true}
                                        disableElevation
                                        onClick={onClickDrop}
                                        fullWidth
                                        sx={{ marginRight: '10px', whiteSpace: 'nowrap', minWidth: '150px' }}
                                        startIcon={<VisibilityIcon />}
                                    >
                                        Xem chi tiết
                                    </Button>
                                    <StyledMenu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'demo-customized-button'
                                        }}
                                        anchorEl={anchorEl}
                                        open={openSelect}
                                        onClose={handleCloseDrop}
                                        onClick={handleCloseDrop}
                                    >
                                        {RIGHT_CLICK_OPTIONS.map((option, index) => {
                                            if (option.show === roles?.read)
                                                return (
                                                    <MenuItem key={index.toString()} onClick={() => onClickMenuOption(option.alias)}>
                                                        <ListItemIcon>{option.icon}</ListItemIcon>
                                                        {option.name}
                                                    </MenuItem>
                                                );
                                            return null;
                                        })}
                                    </StyledMenu>
                                    {roles?.update && (
                                        <Button
                                            disableElevation
                                            disabled={authorSelect ? false : true}
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => onEditAuthor()}
                                            sx={{ marginRight: '10px', whiteSpace: 'nowrap', minWidth: '100px' }}
                                            startIcon={<EditIcon />}
                                        >
                                            Chỉnh sửa
                                        </Button>
                                    )}
                                    {roles?.delete && (
                                        <Button
                                            disableElevation
                                            disabled={authorSelect ? false : true}
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleDeleteAuthor()}
                                            sx={{ marginRight: '10px', whiteSpace: 'nowrap', minWidth: '100px' }}
                                            startIcon={<DeleteIcon />}
                                        >
                                            Xóa
                                        </Button>
                                    )}

                                    {roles?.export && (
                                        <Button
                                            disableElevation
                                            disabled={listAuthor?.length > 0 ? false : true}
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => onExcel()}
                                            sx={{ whiteSpace: 'nowrap', minWidth: '50px' }}
                                            startIcon={<FileDownloadIcon />}
                                        >
                                            Xuất excel
                                        </Button>
                                    )}
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={1} sx={{ display: isMobile ? 'none' : 'flex', flexDirection: 'column' }}>
                            <ToggleButtonGroup
                                sx={{ display: 'flex', justifyContent: 'center' }}
                                value={viewType}
                                onChange={handleChangeTypeView}
                                exclusive
                            >
                                <Tooltip title="Đang hiển thị danh sách tác giả" placement="top">
                                    <span>
                                        <ToggleButton
                                            disabled={viewType === AUTHOR}
                                            size="medium"
                                            onClick={() => changeTab(AUTHOR)}
                                            selected={viewType === AUTHOR}
                                            value={AUTHOR}
                                            aria-label={AUTHOR}
                                        >
                                            <PeopleAltIcon />
                                        </ToggleButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Hiển thị danh sách tác phẩm" placement="top">
                                    <span>
                                        <ToggleButton
                                            size="medium"
                                            onClick={() => changeTab(WORK)}
                                            selected={viewType === WORK}
                                            value={WORK}
                                            aria-label={WORK}
                                        >
                                            <BookIcon />
                                        </ToggleButton>
                                    </span>
                                </Tooltip>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: '10px', width: '100% !important', marginLeft: '0px' }}>
                        <Paper id="list" sx={{ width: '100%', overflow: 'hidden', borderRadius: 0 }}>
                            <TableContainer
                                sx={{
                                    '&::-webkit-scrollbar': {
                                        width: { md: '0.4em', xs: '3px' },
                                        height: { md: '5px', xs: '3px' }
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: customization?.themeDongHo?.heading,
                                        outline: 'slategrey'
                                    },
                                    maxHeight: isMobile ? 'calc(100vh - 240px)' : 'calc(100vh - 290px)'
                                }}
                            >
                                <Table
                                    sx={{
                                        borderRadius: 0,
                                        ...(isMobile && listAuthor.length === 0 && { display: 'none' })
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(
                                                (column) =>
                                                    ((isMobile && column.xsDisplay !== 'none') || !isMobile) && (
                                                        <TableCell
                                                            variant="head"
                                                            key={column.id}
                                                            align={column.align}
                                                            sx={{
                                                                minWidth: column.minWidth,
                                                                backgroundColor: 'rgb(144, 202, 249)',
                                                                color: 'black',
                                                                width: column?.width,
                                                                ...column.sx
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    )
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    {listAuthor?.length > 0 ? (
                                        <>
                                            {/* <Stack> */}
                                            <TableBody>
                                                {listAuthor.map((row, index) => (
                                                    <TableRow
                                                        key={row._id}
                                                        sx={{
                                                            // backgroundColor: idChoose === row.ref_member ? '#b3c7e8' : '#f0f0f0',
                                                            cursor: 'pointer',
                                                            '&&.Mui-selected': {
                                                                backgroundColor: '#b3c7e8'
                                                            },
                                                            '&.MuiTableRow-hover:hover': {
                                                                backgroundColor: !isMobile && 'rgba(0, 0, 255, 0.3)'
                                                            }
                                                        }}
                                                        selected={idAuthor === row._id}
                                                        onClick={() => handleClick(row)}
                                                    >
                                                        {!isMobile && (
                                                            <TableCell
                                                                style={{ height: 'auto !important', verticalAlign: 'middle' }}
                                                                align="center"
                                                            >
                                                                {index + 1 + page * (rowsPerPage - 0)}
                                                            </TableCell>
                                                        )}
                                                        <TableCell
                                                            sx={{
                                                                wordBreak: 'break-all',
                                                                verticalAlign: 'top',
                                                                height: 'auto !important',
                                                                position: { md: 'relative', xs: 'sticky' },
                                                                left: '0px',
                                                                zIndex: '9',
                                                                ...(isMobile && {
                                                                    backgroundColor: idAuthor === row._id ? '#b3c7e8' : 'white'
                                                                })
                                                            }}
                                                            align="left"
                                                        >
                                                            {row?.pseudonym ? row.pseudonym : 'Ẩn danh'}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                height: 'auto !important',
                                                                wordBreak: 'break-all',
                                                                verticalAlign: 'top'
                                                            }}
                                                            align="left"
                                                        >
                                                            {row?.name_author ? shortenName(row.name_author, 25, 14, 250) : 'Ẩn danh'}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                height: 'auto !important',
                                                                wordBreak: 'break-all',
                                                                verticalAlign: 'top'
                                                            }}
                                                            align="left"
                                                        >
                                                            {/* {dateTimeAuthor(row.birth_day_solar, row.dead_day_solar)} */}
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: dateTimeAuthor(row.birth_day_solar, row.dead_day_solar)
                                                                }}
                                                            />
                                                        </TableCell>
                                                        {!isMobile && (
                                                            <TableCell
                                                                style={{
                                                                    height: 'auto !important',
                                                                    wordBreak: 'break-all',
                                                                    verticalAlign: 'top'
                                                                }}
                                                                align="left"
                                                            >
                                                                <Typography> {row?.creative_phase}</Typography>
                                                            </TableCell>
                                                        )}
                                                        <TableCell
                                                            style={{
                                                                height: 'auto !important',
                                                                wordBreak: 'break-all',
                                                                verticalAlign: 'top'
                                                            }}
                                                            align="left"
                                                        >
                                                            <Typography> {row?.type.flatMap((m) => findName(m)).join(', ')}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important', verticalAlign: 'top' }} align="left">
                                                            {/* <Typography> {row?.award}</Typography> */}
                                                            {row?.works?.map((item) => {
                                                                return (
                                                                    <Tooltip
                                                                        placement="top-start"
                                                                        title={`Xem thông tin tác phẩm ${item?.name_work}`}
                                                                    >
                                                                        <Chip
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                openModalDetailWork(item);
                                                                                setIdAuthor(row._id);
                                                                            }}
                                                                            key={item.id_work}
                                                                            label={ShortCutName(item.name_work, 80)}
                                                                            sx={{
                                                                                position: 'relative',
                                                                                zIndex: '20',
                                                                                cursor: 'pointer',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#003168',
                                                                                    color: 'white'
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                );
                                                            })}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <Tooltip
                                                                placement="top-start"
                                                                title={(row?.is_public?'Hủy công khai': 'Công khai') + ' tác giả '+ row?.name_author}
                                                            >
                                                                <Chip
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        setIdAuthor(row._id);
                                                                        handlePublicAuthor(row);
                                                                    }}
                                                                    sx={{
                                                                        position: 'relative',
                                                                        zIndex: '20',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    color={row?.is_public? "info": "default"}
                                                                    label={row?.is_public? "Đã công khai": "Chưa công khai"}
                                                                />
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </>
                                    ) : (
                                        <TableRow>
                                            <TableCell sx={{ display: 'table-cell', textAlign: 'center' }} colSpan={12}>
                                                <NotFound />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{
                                    ...(listAuthor?.length === 0
                                        ? { display: 'none' }
                                        : {
                                              overflow: 'hidden',
                                              '.MuiTablePagination-toolbar': {
                                                  paddingLeft: { xs: '0px', sm: '16px' }
                                              },
                                              '.MuiInputBase-root': {
                                                  marginRight: { xs: '5px', sm: '32px' },
                                                  marginLeft: { xs: '3px', sm: '8px' }
                                              },
                                              '.MuiTablePagination-actions': {
                                                  marginLeft: { xs: '5px', sm: '20px' }
                                              },
                                              '.MuiButtonBase-root': {
                                                  padding: { xs: '3px ', sm: '8px' }
                                              }
                                          })
                                }}
                                labelRowsPerPage="Phân trang"
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                showFirstButton
                                showLastButton
                            />
                        </Paper>
                    </Grid>
                </Stack>
                <ModalAddAuthor
                    open={open}
                    handleClose={handleCloseModalAuthor}
                    typeModel={typeModel}
                    idAuthor={idAuthor}
                    onReload={onReload}
                    roles={roles}
                />
                <ModalListWorks
                    show={showList}
                    idAuthor={idAuthor}
                    handleClose={closeModalList}
                    dataInfoAuthor={authorSelect}
                    roles={roles}
                />
                <ModalAddWorkByAuthor
                    open={detailWork}
                    handleClose={onCloseModalDetailWork}
                    typeModal={null}
                    dataAuthor={authorSelect}
                    onReload={onReloadData}
                    idAuthor={idAuthor}
                    dataInfoWork={workSelect}
                    typeView={'VIEW'}
                />
                {/* <ModalInfoWork
                    open={openDetailWork}
                    handleClose={handleCloseModalWork}
                    typeModal={'VIEW'}
                    dataWork={workSelect}
                    onReload={onReload}
                    typeMode={typeMode}
                /> */}
            </>
        );
    } else {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    p: 4,
                    border: 'none'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
};

export default AuthorList;
