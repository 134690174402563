import 'tippy.js/animations/scale.css'
import 'tippy.js/dist/tippy.css'
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt'
import { CodeBlock } from '@styled-icons/boxicons-regular/CodeBlock'
import { Highlight } from '@styled-icons/boxicons-regular/Highlight'
import { Subscript } from '@styled-icons/foundation/Subscript'
import { Superscript } from '@styled-icons/foundation/Superscript'
import { BorderAll } from '@styled-icons/material/BorderAll'
import { BorderBottom } from '@styled-icons/material/BorderBottom'
import { BorderClear } from '@styled-icons/material/BorderClear'
import { BorderLeft } from '@styled-icons/material/BorderLeft'
import { BorderRight } from '@styled-icons/material/BorderRight'
import { BorderTop } from '@styled-icons/material/BorderTop'
import { FormatAlignCenter } from '@styled-icons/material/FormatAlignCenter'
import { FormatAlignJustify } from '@styled-icons/material/FormatAlignJustify'
import { FormatAlignLeft } from '@styled-icons/material/FormatAlignLeft'
import { FormatAlignRight } from '@styled-icons/material/FormatAlignRight'
import { FormatBold } from '@styled-icons/material/FormatBold'
import { FormatIndentDecrease } from '@styled-icons/material/FormatIndentDecrease'
import { FormatIndentIncrease } from '@styled-icons/material/FormatIndentIncrease'
import { FormatItalic } from '@styled-icons/material/FormatItalic'
import { FormatListBulleted } from '@styled-icons/material/FormatListBulleted'
import { FormatListNumbered } from '@styled-icons/material/FormatListNumbered'
import { FormatQuote } from '@styled-icons/material/FormatQuote'
import { FormatStrikethrough } from '@styled-icons/material/FormatStrikethrough'
import { FormatUnderlined } from '@styled-icons/material/FormatUnderlined'
import { Keyboard } from '@styled-icons/material/Keyboard'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import { Looks3 } from '@styled-icons/material/Looks3'
import { Looks4 } from '@styled-icons/material/Looks4'
import { Looks5 } from '@styled-icons/material/Looks5'
import { Looks6 } from '@styled-icons/material/Looks6'
import { LooksOne } from '@styled-icons/material/LooksOne'
import { LooksTwo } from '@styled-icons/material/LooksTwo'
import { Check } from '@styled-icons/material/Check'
import { TippyProps } from '@tippyjs/react'

import { TextHeader1 } from '@styled-icons/fluentui-system-filled/TextHeader1'
import { TextHeader2 } from '@styled-icons/fluentui-system-filled/TextHeader2'
import { TextHeader3 } from '@styled-icons/fluentui-system-filled/TextHeader3'
import { Paragraph } from '@styled-icons/boxicons-regular/Paragraph'

import {
  addColumn,
  addRow,
  BalloonToolbar,
  deleteColumn,
  deleteRow,
  deleteTable,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_OL,
  ELEMENT_UL,
  getPluginType,
  indent,
  insertTable,
  MARK_BG_COLOR,
  MARK_BOLD,
  MARK_CODE,
  MARK_COLOR,
  MARK_HIGHLIGHT,
  MARK_ITALIC,
  MARK_KBD,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
  outdent,
  AlignToolbarButton,
  ToolbarButton,
  CodeBlockToolbarButton,
  ColorPickerToolbarDropdown,
  BlockToolbarButton,
  ImageToolbarButton,
  LinkToolbarButton,
  ListToolbarButton,
  MarkToolbarButton,
  MediaEmbedToolbarButton,
  TableToolbarButton,
  ELEMENT_PARAGRAPH,
  getNodeChild,
  getNode,
  getNodes,
  getChildren,
  getNodeElements,
  getNodeEntry,
  getNodeProps
} from '@udecode/plate'
import { Link } from '@styled-icons/material/Link'
import { Image } from '@styled-icons/material/Image'
import { OndemandVideo } from '@styled-icons/material/OndemandVideo'
import { FontDownload } from '@styled-icons/material/FontDownload'
import { FormatColorText } from '@styled-icons/material/FormatColorText'
import { Save } from '@styled-icons/boxicons-regular/Save'
import { SaveSync } from '@styled-icons/fluentui-system-filled/SaveSync'
import { useMyPlateEditorRef } from '../typescript'
import { useState } from 'react'
import { Container } from "react-modal-promise";

import {modalPhoto} from 'views/pages/diary/module/ModalPhoto';
import { size } from 'lodash'

export const BasicElementToolbarButtons = () => {
  const editor = useMyPlateEditorRef()!

  return (
    <>
      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_H1)}
        icon={<TextHeader1 />}
        tooltip={{ content: 'Đầu đề 1' }}
      />
      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_H2)}
        icon={<TextHeader2 />}
        tooltip={{ content: 'Đầu đề 2' }}
      />
      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_H3)}
        icon={<TextHeader3 />}
        tooltip={{ content: 'Đầu đề 3' }}
      />
      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_PARAGRAPH)}
        icon={<Paragraph />}
        tooltip={{ content: 'Đoạn văn bản' }}
      />
      {/* <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_H5)}
        icon={<Looks5 />}
      />
      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_H6)}
        icon={<Looks6 />}
      /> */}
      {/* <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_BLOCKQUOTE)}
        icon={<FormatQuote />}
        tooltip={{ content: 'Trích dẫn' }}
      /> */}
      {/* <CodeBlockToolbarButton
        type={getPluginType(editor, ELEMENT_CODE_BLOCK)}
        icon={<CodeBlock />}
      /> */}
    </>
  )
}

export const IndentToolbarButtons = () => {
  const editor = useMyPlateEditorRef()!

  return (
    <>
      <ToolbarButton
        onMouseDown={(e) => {
          if (!editor) return

          outdent(editor)
          e.preventDefault()
        }}
        icon={<FormatIndentDecrease />}
        tooltip={{ content: 'Giảm thụt lề' }}
      />
      <ToolbarButton
        onMouseDown={(e) => {
          if (!editor) return

          indent(editor)
          e.preventDefault()
        }}
        icon={<FormatIndentIncrease />}
        tooltip={{ content: 'Tăng thụt lề' }}
      />
    </>
  )
}

export const ListToolbarButtons = () => {
  const editor = useMyPlateEditorRef()!
  return (
    <>
      <ListToolbarButton
        type={getPluginType(editor, ELEMENT_UL)}
        icon={<FormatListBulleted />}
        tooltip={{ content: 'Dấu đầu dòng' }}
      />
      <ListToolbarButton
        type={getPluginType(editor, ELEMENT_OL)}
        icon={<FormatListNumbered />}
        tooltip={{ content: 'Đánh số' }}
      />
    </>
  )
}

export const AlignToolbarButtons = () => {
  return (
    <>
      <AlignToolbarButton 
        value="left" 
        icon={<FormatAlignLeft />} 
        tooltip={{ content: 'Căn trái' }}
      />
      <AlignToolbarButton 
        value="center" 
        icon={<FormatAlignCenter />} 
        tooltip={{ content: 'Căn giữa' }}
      />
      <AlignToolbarButton 
        value="right" 
        icon={<FormatAlignRight />}
        tooltip={{ content: 'Căn phải' }}
      />
      <AlignToolbarButton 
        value="justify" 
        icon={<FormatAlignJustify />} 
        tooltip={{ content: 'Căn đều' }}
      />
    </>
  )
}

export const BasicMarkToolbarButtons_1 = () => {
  const editor = useMyPlateEditorRef()!

  return (
    <>
      <MarkToolbarButton
        type={getPluginType(editor, MARK_BOLD)}
        icon={<FormatBold />}
        tooltip={{ content: 'Đậm (Ctrl+B)' }}
        onMouseDown={(e) => {
          if (!editor) return
          // let elementSPH = getNodeChild(editor, 12);
          // console.log("getNodeElements", elementSPH);

          // console.log("elementSPH.id", elementSPH.id);
          // console.log("elementSPH.children", elementSPH.children);
          // elementSPH.children.scrollTop = 0;
          // var myDiv = document.getElementById(elementSPH.id || '');
          // if (myDiv != null) myDiv.scrollTop = 0;

          // for (const span of document.querySelectorAll('span')) {
          const editorElement = document.getElementById("EditorSPH");
          if(editorElement) {
            for (const span of editorElement.querySelectorAll('span')) {
              if(span?.textContent) {
                console.log("span.textContent", span.textContent);
                // if (span.textContent.includes("Chương III")) {
                if (span.textContent == "Chương III:") {
                  console.log("SPAN FOUND", span);
                  span.scrollIntoView({behavior: "smooth"});
                  break
                }
              }
            }
          }
          e.preventDefault()
        }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_ITALIC)}
        icon={<FormatItalic />}
        tooltip={{ content: 'Nghiêng (Ctrl+I)' }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_UNDERLINE)}
        icon={<FormatUnderlined />}
        tooltip={{ content: 'Gạch dưới (Ctrl+U)' }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_STRIKETHROUGH)}
        icon={<FormatStrikethrough />}
        tooltip={{ content: 'Gạch ngang chữ' }}
      />
    </>
  )
}

export const BasicMarkToolbarButtons_2 = () => {
  const editor = useMyPlateEditorRef()!

  return (
    <>
      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_BLOCKQUOTE)}
        icon={<FormatQuote />}
        tooltip={{ content: 'Trích dẫn' }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_CODE)}
        icon={<CodeAlt />}
        tooltip={{ content: 'Tạo bóng ký tự' }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_SUPERSCRIPT)}
        clear={getPluginType(editor, MARK_SUBSCRIPT)}
        icon={<Superscript />}
        tooltip={{ content: 'Chỉ số trên' }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_SUBSCRIPT)}
        clear={getPluginType(editor, MARK_SUPERSCRIPT)}
        icon={<Subscript />}
        tooltip={{ content: 'Chỉ số dưới' }}
      />
    </>
  )
}

export const KbdToolbarButton = () => {
  const editor = useMyPlateEditorRef()!

  return (
    <MarkToolbarButton
      type={getPluginType(editor, MARK_KBD)}
      icon={<Keyboard />}
    />
  )
}

export const HighlightToolbarButton = () => {
  const editor = useMyPlateEditorRef()!

  return (
    <MarkToolbarButton
      type={getPluginType(editor, MARK_HIGHLIGHT)}
      icon={<Highlight />}
    />
  )
}

export const TableToolbarButtons = () => (
  <>
    <TableToolbarButton 
      icon={<BorderAll />} 
      transform={insertTable} 
      tooltip={{ content: 'Tạo bảng' }}
    />
    <TableToolbarButton 
      icon={<BorderClear />} 
      transform={deleteTable} 
      tooltip={{ content: 'Xóa bảng' }}
    />
    <TableToolbarButton 
      icon={<BorderBottom />} 
      transform={addRow} 
      tooltip={{ content: 'Thêm hàng' }}
    />
    <TableToolbarButton 
      icon={<BorderTop />} 
      transform={deleteRow} 
      tooltip={{ content: 'Xóa hàng' }}
    />
    <TableToolbarButton 
      icon={<BorderLeft />} 
      transform={addColumn} 
      tooltip={{ content: 'Thêm cột' }}
    />
    <TableToolbarButton 
      icon={<BorderRight />} 
      transform={deleteColumn} 
      tooltip={{ content: 'Xóa cột' }}
    />
  </>
)

export const MarkBallonToolbar = () => {
  const editor = useMyPlateEditorRef()!

  const arrow = false
  const theme = 'dark'
  const tooltip: TippyProps = {
    arrow: true,
    delay: 0,
    duration: [200, 0],
    hideOnClick: false,
    offset: [0, 17],
    placement: 'top',
  }

  return (
    <BalloonToolbar
      popperOptions={{
        placement: 'top',
      }}
      theme={theme}
      arrow={arrow}
    >
      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_H1)}
        icon={<TextHeader1 />}
        tooltip={{ content: 'Đầu đề 1', ...tooltip }}
      />

      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_H2)}
        icon={<TextHeader2 />}
        tooltip={{ content: 'Đầu đề 2', ...tooltip }}
      />

      <BlockToolbarButton
        type={getPluginType(editor, ELEMENT_H3)}
        icon={<TextHeader3 />}
        tooltip={{ content: 'Đầu đề 3', ...tooltip }}
      />

      <BlockToolbarButton 
        type={getPluginType(editor, ELEMENT_PARAGRAPH)}
        icon={<Paragraph />}
        tooltip={{ content: 'Đoạn văn bản', ...tooltip }}
      />  

      <div style={{ marginInline:'10px', height: '25px', width: '1px', borderRight:'1px solid white'}}> </div>

      <MarkToolbarButton
        type={getPluginType(editor, MARK_BOLD)}
        icon={<FormatBold />}
        tooltip={{ content: 'Đậm (Ctrl+B)', ...tooltip }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_ITALIC)}
        icon={<FormatItalic />}
        tooltip={{ content: 'Nghiêng (Ctrl+I)', ...tooltip }}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_UNDERLINE)}
        icon={<FormatUnderlined />}
        tooltip={{ content: 'Gạch dưới (Ctrl+U)', ...tooltip }}
      />
    </BalloonToolbar>
  )
}

const getImageUrl = () => {
  // console.log("getImageUrl")
  return Promise.resolve("SONPH");
}

export const ToolbarButtons: React.FunctionComponent<any> = ({loadingSave, backToListPost, savePost, idPost}) => {
  return (
  <>
    <BasicElementToolbarButtons />
    <div style={{ marginInline:'10px', height: '25px', width: '1px', borderRight:'1px solid black'}}> </div>

    <BasicMarkToolbarButtons_1 />
    <div style={{ marginInline:'10px', height: '25px', width: '1px', borderRight:'1px solid black'}}> </div>

    <ListToolbarButtons />
    <IndentToolbarButtons />
    <div style={{ marginInline:'10px', height: '25px', width: '1px', borderRight:'1px solid black'}}> </div>

    <AlignToolbarButtons />
    <div style={{ marginInline:'10px', height: '25px', width: '1px', borderRight:'1px solid black'}}> </div>

    <BasicMarkToolbarButtons_2 />
    <div style={{ marginInline:'10px', height: '25px', width: '1px', borderRight:'1px solid black'}}> </div>

    <ColorPickerToolbarDropdown
      pluginKey={MARK_COLOR}
      icon={<FormatColorText />}
      selectedIcon={<Check />}
      tooltip={{ content: 'Thay đổi màu văn bản' }}
    />
    <ColorPickerToolbarDropdown
      pluginKey={MARK_BG_COLOR}
      icon={<FontDownload />}
      selectedIcon={<Check />}
      tooltip={{ content: 'Màu tô sáng văn bản' }}
    />
    <div style={{ marginInline:'10px', height: '25px', width: '1px', borderRight:'1px solid black'}}> </div>

    <LinkToolbarButton 
      icon={<Link />} 
      tooltip={{ content: 'Chèn liên kết' }}
    />
    <ImageToolbarButton 
      icon={<Image />}
      tooltip={{ content: 'Chèn hình ảnh' }}
      // onMouseDown={() => setOpen(true)}
      // getImageUrl={() => {return Promise.resolve(url)}}
      getImageUrl={() => {
        return modalPhoto({id: idPost} as any)
        .then((str)=> {
            console.log(str || 'SUCCESS');
            return Promise.resolve(str)
        })
        .catch(()=> {      
            console.log('FAIL');
            return false;
            // return Promise.resolve('FAIL')
        });
      }}
    />
    <MediaEmbedToolbarButton 
      icon={<OndemandVideo />} 
      tooltip={{ content: 'Chèn video trực tuyến' }}
    />

    <div style={{ marginInline:'10px', height: '25px', width: '1px', borderRight:'1px solid black'}}> </div>
    <TableToolbarButtons />

    <div style={{ marginInline:'10px', height: '25px', width: '1px', borderRight:'1px solid black'}}> </div>
    
    <div style={{ display: 'flex', flexDirection: 'row', position:'absolute', right: 20}}>
      <ToolbarButton
        onMouseDown={(e) => {
          savePost()
          e.preventDefault()
        }}
        icon={loadingSave ? <SaveSync color={'#1AA7EC'}/> : <Save color={'black'}/>}
        tooltip={{ content: loadingSave ? "Đang lưu..." : "Lưu bài viết" }}
      />
                                  
      <ToolbarButton
        onMouseDown={(e) => {
          backToListPost()
          e.preventDefault()
        }}
        icon={<ArrowBack color='black' />}
        tooltip={{ content: 'Quay lại' }}
      />
    </div>

    <Container />
  </>
  )
}
