import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography, Avatar } from '@mui/material';
import SplitButton from 'ui-component/select_box/SplitButton';

const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

const MainCardIcon = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            dataOptions = [],
            sx = {},
            title,
            icon,
            handleItemClick,
            showICon = false,
            showBackGround,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        return (
            <div style={{ position: 'relative' }}>
                <Card
                    ref={ref}
                    {...others}
                    sx={{
                        border: border ? '1px solid' : 'none',
                        borderColor: theme.palette.primary[200] + 75,
                        ':hover': {
                            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                        },
                        ...sx
                    }}
                >
                    {/* card header and action */}
                    {!darkTitle && title && (
                        <CardHeader
                            style={{ padding: '13px', backgroundColor: showBackGround ? 'rgb(144, 202, 249)' : 'white', height: '50px' }}
                            sx={headerSX}
                            title={
                                <div>
                                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar
                                                style={{
                                                    width: 16,
                                                    height: 16,
                                                    background: 'transparent',
                                                    float: 'left',
                                                    marginRight: '6px'
                                                }}
                                                variant="rounded"
                                                src={icon}
                                            />
                                            <Typography style={{ float: 'left' }} variant="h4">
                                                {title}
                                            </Typography>
                                            <div style={{ clear: 'both' }} />
                                        </div>
                                        {dataOptions.length > 0 ? (
                                            <SplitButton dataOptions={dataOptions} handleItemClick={handleItemClick} showICon={showICon} />
                                        ) : null}
                                    </div>
                                </div>
                            }
                            action={secondary}
                        />
                    )}
                    {darkTitle && title && (
                        <CardHeader sx={headerSX} action={secondary}>
                            <Typography variant="h3">{title}</Typography>
                        </CardHeader>
                    )}

                    {/* content & header divider */}
                    {title && <Divider />}

                    {/* card content */}
                    {content && (
                        <CardContent sx={contentSX} className={contentClass}>
                            {children}
                        </CardContent>
                    )}
                    {!content && children}
                </Card>
            </div>
        )
    }
)
MainCardIcon.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    showICon: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    dataOptions: PropTypes.array,
    handleItemClick: PropTypes.func
};
export default MainCardIcon;
