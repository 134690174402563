import Logo from '../../../assets/images/logo.webp';

import config from 'config';

export default function Loading() {
    const logo = localStorage.getItem('m_family_logo')?.trim() || '';

    return (
        <div
            style={{
                backgroundColor: '#fff8a9',
                // backgroundImage: `url(${BackgroundRongPhuong})`,
                // backgroundRepeat: 'no-repeat',
                // backgroundSize: 'contain',
                backgroundPosition: 'center',
                minHeight: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                display: 'flex'
            }}
        >
            <img
                style={{
                    justifySelf: 'center',
                    alignSelf: 'center',
                    animation: 'enlargeImage 1.5s ease-in-out',
                    maxWidth: '300px'
                }}
                // width="auto"
                maxHeight="150px"
                alt="dvo-logo"
                src={logo ? config.severImageBase64 + logo : Logo}
            />
        </div>
    );
}
