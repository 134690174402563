// assets
import colors from '../../../assets/scss/_themes-vars.module.scss';

const blueTheme = {
    backgroundColor: '#003168',
    navBarColor: '#fff8a9',
    heading: '#003168',
    menuSelected: '#003168',
    common: {
        black: colors?.darkPaper
    },
    primary: {
        light: '#fff8a9',
        main: '#003168',
        dark: '#003168',
        x200: colors?.primary200,
        x800: colors?.primary800
    },
    secondary: {
        light: '#fff8a9',
        main: '#003168',
        dark: '#003168',
        x200: colors?.secondary200,
        x800: colors?.secondary800
    },
    error: {
        light: colors?.errorLight,
        main: colors?.errorMain,
        dark: colors?.errorDark
    },
    orange: {
        light: colors?.orangeLight,
        main: colors?.orangeMain,
        dark: colors?.orangeDark
    },
    warning: {
        light: colors?.warningLight,
        main: colors?.warningMain,
        dark: colors?.warningDark
    },
    success: {
        light: colors?.successLight,
        x200: colors?.success200,
        main: colors?.successMain,
        dark: colors?.successDark
    },
    grey: {
        x50: colors?.grey50,
        x100: colors?.grey100,
        x500: colors?.darkTextSecondary,
        x600: colors?.grey900,
        x700: colors?.darkTextPrimary,
        x900: colors.grey900
    },
    dark: {
        light: colors?.darkTextPrimary,
        main: colors?.darkLevel1,
        dark: colors?.darkLevel2,
        x800: colors?.darkBackground,
        x900: colors?.darkPaper
    },
    text: {
        primary: '#003168',
        secondary: '#003168',
        dark: '#003168',
        hint: colors?.grey100
    },
    background: {
        paper: colors?.paper,
        default: colors?.paper
    }
};

export default blueTheme;
