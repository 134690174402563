import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import ConfigRouter from './config_router';
import { AnonymousRoute } from './AnonymousRoute';

const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/AuthForgotPassword')));
const AuthForgotPasswordFrom = Loadable(lazy(() => import('views/pages/authentication/authentication3/AuthForgotPasswordFrom')));

// ==============================|| MAIN ROUTING ||============================== //

const ForgotPasswordRoutes = {
    path: '/',
    children: [
        {
            path: ConfigRouter.forgotPassword,
            element: (
                <AnonymousRoute>
                    <AuthForgotPassword />
                </AnonymousRoute>
            )
        },
        {
            path: 'form/forgot_password',
            element: (
                <AnonymousRoute>
                    <AuthForgotPasswordFrom />
                </AnonymousRoute>
            )
        }
    ]
};

export default ForgotPasswordRoutes;
