const ConfigRouter = {
    newFeed: '/',

    login: '/login',

    homePage: '/trang-chu',
    dashboard: '/trang-chu/auto',
    genealogy: '/trang-chu/pha-he',

    treeviewer: '/treeviewer',
    treeviewerShare: '/treeviewer/share',

    event: '/quan-ly-su-kien',

    detailFamily: '/dong-ho/thong-tin-dong-ho',
    listPatriarch: '/dong-ho/danh-sach-toc-truong',
    statisticalFamily: '/dong-ho/thong-ke-dong-ho',
    historyFamily: '/dong-ho/lich-su-dong-ho',
    graveFamily: '/dong-ho/thong-tin-mo-phan',
    ancestralHouseList: '/dong-ho/danh-sach-nha-tho',

    detaiMember: '/thanh-vien/chi-tiet-thanh-vien',
    listMember: '/thanh-vien/danh-sach-thanh-vien',

    userInfo: '/tai-khoan/thong-tin-tai-khoan',
    changePassword: '/tai-khoan/doi-mat-khau',

    configMaster: '/cau-hinh/du-lieu',

    forgotPassword: '/quen-mat-khau',
    logOut: '/dang-xuat',

    diaryFamily: '/pha-ky/bai-viet-pha-ky',
    albumFamily: '/pha-ky/album-dong-ho',
    documentFamily: '/pha-ky/tai-lieu-dong-ho',
    listCreator: 'pha-ky/danh-sach-tac-gia-tac-pham',

    listAccount: '/cau-hinh/tai-khoan',
    settingNewfeed: '/cau-hinh/trang-gioi-thieu',
    listService: '/cau-hinh/dich-vu',
    listSymstemLog: '/cau-hinh/nhat-ky-he-thong',
    listOtherProduct: '/san-pham-khac',
   
};

// const ConfigRouter = {
//     login: '/',
//     homePage: '/homepage',
//     dashboard: '/dashboard/default',
//     genealogy: '/dashboard/genealogy',
//     treeviewer: '/treeviewer',
//     treeviewerShare: '/treeviewer/share',
//     event: '/event/event_manager',
//     detailFamily: '/family/info_family',
//     detaiMember: '/member/detail_member',
//     listMember: '/member/list_member',
//     configMaster: '/m_configuration',
//     userInfo: '/user/info_user',
//     changePassword: '/user/change_password',
//     forgotPassword: '/forgot_password',
//     diaryFamily: '/diary_family',
//     logOut: '/logout',
//     listPatriarch: '/family/list_patriarch',
//     statisticalFamily: '/family/statistical',
//     historyFamily: '/family/history_family',
//     graveFamily: '/family/grave',
//     albumFamily: '/diary_family/album',
//     documentFamily: '/diary_family/document'
// };

export default ConfigRouter;
