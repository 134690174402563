/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Stack,
    ImageList,
    ImageListItem,
    CardMedia,
    CardActionArea,
    CardContent,
    Grid,
    Divider,
    IconButton,
    useMediaQuery
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import config from 'config';
import ImageDefault from '../../assets/images/users/background.jpg';
import { ConvertName, InvokeFunction, shortenName } from 'utils/helper';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import { Close } from '@mui/icons-material';
import Logo from '../../assets/images/no_image.jpg';
import NotFound from 'ui-component/404/404';

const styles = {
    paperContainer: {
        backgroundImage: `linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)),url(${Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundColor: '#fae29c',
        border: '1px solid #ccc'
    }
};

const ModalImageAlbum = ({ open, onCloseModal, onSlelect }) => {
    const [handleAlbum, setHandleAlbum] = useState(false);
    const [dataAlbum, setDataAlbum] = useState('');
    const [dataListImage, setDataListImage] = useState([]);
    const [dataList, setDataList] = useState([]);

    const smallScreen = useMediaQuery('(max-width: 900px)');

    const onClickAlbum = async (data) => {
        setDataAlbum(data);
        const obj = {
            _id: data?._id,
            search: ''
        };
        const dataRst = await fetchClient.postRequest(router.SUB_URL.albumFamily_ListImage, obj);
        // console.log('dataRst ', dataRst);
        const listData = dataRst?.data ?? [];
        setDataListImage(listData);
        setHandleAlbum(true);
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: 500,
            minWidth: 100,
            margin: '1rem',
            border: '1px solid #ccc',
            borderRadius: '15px',
            backgroundColor: 'transparent'
        },
        media: {
            height: 300,
            width: '100%',
            objectFit: 'contain'
        },
        adddICon: {
            '& svg': {
                fontSize: 100
            }
        },
        styleImg: {
            minHeight: '100px',
            width: '100%'
        },
        img: {
            filter: 'none',
            WebkitFilter: `grayscale(${100})`,
            '&:hover': {
                filter: `grayscale(${0})`,
                WebkitFilter: `grayscale(${0})`
            },
            minHeight: '100px',
            width: '100%'
        }
    }));
    const classes = useStyles();

    const onChooseImage = (image, e) => {
        if (!e.target.title) {
            InvokeFunction(onSlelect, image);
            setHandleAlbum(false);
            setDataAlbum('');
            setDataListImage([]);
            setDataList([]);
            onCloseModal();
        }
    };

    const getData = async () => {
        const obj = {
            nameSearch: ''
        };
        const rst = await fetchClient.postRequest(router.SUB_URL.albumFamily_list, obj);
        setDataList(rst?.data || []);
    };

    useEffect(() => {
        if (open === true) {
            getData();
        }
    }, [open]);

    // console.log('handleAlbum', dataList.length);

    return (
        <>
            <Modal open={open} onClose={onCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '95%', sm: '70%', md: '1000px' },
                        bgcolor: 'background.paper',
                        outline: 'none',
                        boxShadow: 24,
                        p: 2
                    }}
                >
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Ảnh nhà thờ
                    </Typography>
                    <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                    <Box position="absolute" top={0} right={0}>
                        {/* <IconButton onClick={onCloseModal}>
                            <Close />
                        </IconButton> */}
                        <Button
                            disableElevation
                            fullWidth
                            size="small"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={onCloseModal}
                            sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '80px', marginTop: '10px' }}
                            startIcon={<Close />}
                        >
                            Đóng
                        </Button>
                    </Box>
                    {handleAlbum ? (
                        <Box sx={{ width: '100%' }}>
                            <Stack sx={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                                <Typography id="modal-modal-title" variant="h2" component="h2" sx={{ marginBottom: '10px' }}>
                                    {dataAlbum?.name_album || ''}
                                </Typography>
                            </Stack>
                            <Stack style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: '10px' }}>
                                <Button
                                    disableElevation
                                    fullWidth
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setHandleAlbum(false);
                                        setDataListImage([]);
                                    }}
                                    sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '120px' }}
                                    startIcon={<ArrowBackIcon />}
                                >
                                    Quay lại
                                </Button>
                            </Stack>
                            <Grid
                                spacing={0}
                                sx={{
                                    width: '100%',
                                    display: 'block',
                                    overflowY: 'auto',
                                    height: 'calc(100vh - 350px)',
                                    scrollbarWidth: '15px'
                                }}
                            >
                                <ImageList variant="masonry" cols={smallScreen ? 2 : 4} gap={8} sx={{ marginBottom: '25px' }}>
                                    {dataListImage.map((item) => (
                                        <ImageListItem key={item._id} style={styles.paperContainer}>
                                            <CardMedia
                                                component="img"
                                                id={item._id}
                                                onClick={(e) => onChooseImage(item, e)}
                                                className={item.effect === true ? classes.img : classes.styleImg}
                                                src={item.urlImage ? `${config.serverImage + item.urlImage}` : null}
                                                onError={(e) => {
                                                    e.target.src = Logo;
                                                    e.target.title = 'error';
                                                    // e.target.className = 'image-error';
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Grid>
                        </Box>
                    ) : (
                        <Stack>
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                justifyContent="flex-start"
                                sx={{
                                    width: '100%',
                                    display: 'block',
                                    overflowY: 'auto',
                                    height: 'calc(100vh - 350px)',
                                    scrollbarWidth: '15px'
                                    // background: '#ccc'
                                }}
                            >
                                {dataList?.length > 0 ? (
                                    <ImageList sx={{ width: '100%' }} cols={smallScreen ? 1 : 3} rowHeight="100%">
                                        {dataList.map((card, index) => (
                                            <ImageListItem key={index} className={classes.root}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        sx={{ borderBottom: '1px solid #ccc' }}
                                                        component="img"
                                                        onClick={() => onClickAlbum(card)}
                                                        className={classes.media}
                                                        src={card?.avatar_album ? config.serverImage + card?.avatar_album : ImageDefault}
                                                        onError={(e) => {
                                                            e.target.setAttribute('disabled', true);
                                                            e.target.src = ImageDefault;
                                                        }}
                                                    />
                                                    <CardContent>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={12} md={12}>
                                                                <Typography
                                                                    gutterBottom
                                                                    variant="h4"
                                                                    component="h2"
                                                                    sx={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis'
                                                                    }}
                                                                >
                                                                    {/* {ConvertName(card.name_album, 25)} */}
                                                                    {card.name_album}
                                                                </Typography>
                                                                <Typography variant="h5" component="h2" color="textSecondary">
                                                                    Người tạo:{' '}
                                                                    {card?.create_by?.full_name
                                                                        ? shortenName(card?.create_by?.full_name, 23, 14, 180)
                                                                        : ''}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </CardActionArea>
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                ) : (
                                    <NotFound text="Không có album để hiển thị" />
                                )}
                            </Box>
                        </Stack>
                    )}
                </Box>
            </Modal>
        </>
    );
};
export default ModalImageAlbum;
