import { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    TableCell,
    TableBody,
    Stack,
    Divider,
    Grid,
    Button,
    CircularProgress
} from '@mui/material';
import { Close } from '@mui/icons-material';
import NotFound from 'ui-component/404/404';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import ModalPreviewDocument from './ModalPreviewDocument';
import axios from 'axios';
import config from 'config';
import router from 'routes/router';
import DialogUtil from 'ui-component/show_confirm_action';
import { getAliasScreen, getRoleButton } from 'utils/roleUility';
import fetchClient from 'views/utilities/RestfulAPI';
import { ConvertName } from 'utils/helper';

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { md: '55%', xs: '98%' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 2
};

const useStyles = makeStyles((theme) => ({
    tableRow: {
        height: 30,
        '&:hover': {
            backgroundColor: '#a9f1fc !important'
        },
        [theme.breakpoints.down('md')]: {
            '&:hover': {
                backgroundColor: 'transparent'
            }
        }
    },
    tableCell: {
        padding: '8px'
    }
}));

const VIEW = 'view';
const image = ['jpe', 'jpg', 'jpeg', 'png'];
const pdf = ['pdf'];
const txt = ['txt'];
const word = ['doc', 'docx'];
const excel = ['xls', 'xlsx'];

const ModalFileWork = ({ dataFile, show, handleClose, idWork, nameWork, type, roles }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [response, setResponse] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [listFile, setListFile] = useState(dataFile || []);
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState(roles);
    // const [currentFileIndex, setCurrentFileIndex] = useState(0);

    const columns = [
        { id: 'stt', label: 'STT', minWidth: 70, align: 'center', rowAlign: 'center', xsDisplay: 'none' },
        {
            id: 'work_name',
            label: 'Tên file',
            width: 170,
            align: 'center',
            rowAlign: 'center',
            color: 'black',
            sx: { position: 'sticky', left: '0px', zIndex: '99', borderRight: { md: 'none', xs: '1px solid white' } }
        },
        { id: 'type', label: 'Loại file', width: 150, align: 'center', rowAlign: 'center', color: 'black' }
    ];

    // const handleNext = () => {
    //     if (currentFileIndex < dataFile.length - 1) {
    //         setCurrentFileIndex(currentFileIndex + 1);
    //     }
    // };

    // const handlePrev = () => {
    //     if (currentFileIndex > 0) {
    //         setCurrentFileIndex(currentFileIndex - 1);
    //     }
    // };

    // const handleDownload = () => {
    //     const currentFile = dataFile[currentFileIndex];
    //     window.open(currentFile?.urlFile, '_blank');
    // };

    const onselectFile = (row) => () => {
        setSelectedFile(row);
    };

    const checkTypeFile = (nameFile) => {
        const parts = nameFile.split('.');
        const fileExtension = parts[parts.length - 1];
        return fileExtension;
    };

    const reviewFile = () => {
        // console.log('item', item);
        const typeFile = checkTypeFile(selectedFile?.urlFile);
        if (['jpe', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'png', 'ico', 'pdf', 'txt', 'svg', 'html'].includes(`${typeFile}`.toLowerCase())) {
            setResponse(selectedFile._id);
            setOpenPreview(true);
        } else {
            setShowModal(true);
        }
    };

    const onDownloadClick = () => {
        const param = {
            idFile: selectedFile._id,
            _id: idWork
        };
        const fullRoute = config.api + router.SUB_URL.download_work;
        axios({
            url: fullRoute,
            method: 'post',
            responseType: 'blob',
            withCredentials: true,
            data: param
        }).then((response, error) => {
            if (response.status === 201) {
                const headerLine = response.headers['content-disposition'];
                if (headerLine) {
                    const filename = headerLine.split('filename=')[1].split(';')[0];
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }
            } else {
                DialogUtil.showQuestions(false, 'Tải tài liệu thất bại');
            }
        });
    };

    const onCloseModal = () => {
        setSelectedFile(null);
        handleClose();
    };

    const onClosePreview = () => {
        setResponse({});
        setSelectedFile(null);
        setOpenPreview(false);
    };

    const handleDeleteFile = () => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn xóa tệp ${selectedFile?.nameFile} không?`,
            () => {},
            () => onDeleteFile()
        );
    };

    const onDeleteFile = async () => {
        const obj = {
            idWork: idWork,
            idFile: selectedFile?._id
        };
        const result = await fetchClient.sendRequest(router.SUB_URL.delete_file, 'post', obj);
        if (result.status === 201) {
            DialogUtil.showQuestions(true, 'Xóa file thành công!', () => getListFile());
        } else {
            DialogUtil.showQuestions(false, 'Xóa file thất bại');
        }
    };

    const getListFile = async () => {
        setLoading(true);
        const obj = {
            id: idWork
        };
        const dataObject = await fetchClient.sendRequest(router.SUB_URL.info_work, 'post', obj);
        if (dataObject.status === 201) {
            setListFile(dataObject?.data?.file_upload);
            setLoading(false);
        } else if(!roles.is_client) {
            setLoading(false);
            DialogUtil.showQuestions(false, 'Lấy danh sách file thất bại');
        }
    };

    const getRoleButtonScreen = async () => {
        const rs = await getRoleButton();
        setRole(rs);
    };

    useEffect(() => {
        if (show) {
            if(!roles.is_client)
            {
                getListFile();
                getRoleButtonScreen();
            }else{
                setRole({...role, read: true});
            }
        }
    }, [show]);

    return (
        <>
            <Modal open={show} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
                <Box sx={style} fullWidth>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        {`Danh sách file của tác phẩm ${ConvertName(nameWork, 40)}`}
                    </Typography>
                    <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                    <Box position="absolute" top={0} right={0}>
                        <IconButton onClick={() => onCloseModal()}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Stack spacing={1}>
                        <Box sx={{ display: 'block', textAlign: 'end', alignItems: 'end' }}>
                            {role?.read && (
                                <Button
                                    onClick={reviewFile}
                                    disabled={selectedFile === null}
                                    sx={{ mr: 1 }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={<RemoveRedEyeIcon />}
                                >
                                    Xem file
                                </Button>
                            )}
                            {role?.delete && type === VIEW && (
                                <Button
                                    onClick={handleDeleteFile}
                                    disabled={selectedFile === null}
                                    sx={{ mr: 1 }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={<FolderDeleteIcon />}
                                >
                                    Xóa
                                </Button>
                            )}
                            {role?.export && (
                                <Button
                                    onClick={onDownloadClick}
                                    disabled={selectedFile === null}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    startIcon={<DownloadIcon />}
                                >
                                    Tải xuống
                                </Button>
                            )}
                        </Box>
                        {loading ? (
                            <Stack
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 'calc(100dvh - 600px)',
                                    width: { xs: '100%', md: 'auto' }
                                }}
                                colSpan={12}
                            >
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <Paper>
                                {listFile?.length > 0 ? (
                                    <TableContainer
                                        sx={{
                                            '&::-webkit-scrollbar': {
                                                width: { md: '0.4em', xs: '3px' },
                                                height: { md: 'auto', xs: '3px' }
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                            },
                                            // '&::-webkit-scrollbar-thumb': {
                                            //     backgroundColor: customization?.themeDongHo?.heading,
                                            //     outline: 'slategrey'
                                            // },
                                            maxHeight: 440,
                                            position: 'relative'
                                        }}
                                    >
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map(
                                                        (column) =>
                                                            ((!isMobile && column.mdDisplay !== 'none') ||
                                                                (isMobile && column.xsDisplay !== 'none')) && (
                                                                <TableCell
                                                                    variant="head"
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    sx={{
                                                                        top: 1,
                                                                        minWidth: column.width,
                                                                        color: column.color,
                                                                        ...column.sx,
                                                                        background: 'rgb(144, 202, 249)'
                                                                    }}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            )
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {listFile.map((row, index) => (
                                                    <TableRow
                                                        //  member === row.id ? 'black' : idChoose === row._id ? '' :
                                                        hover
                                                        className={!isMobile && classes.tableRow}
                                                        role="checkbox"
                                                        key={row._id}
                                                        tabIndex={-1}
                                                        style={{
                                                            height: 5,
                                                            // eslint-disable-next-line no-underscore-dangle
                                                            backgroundColor: selectedFile?._id === row._id ? '#a9f1fc' : ''
                                                        }}
                                                        onClick={onselectFile(row)}
                                                    >
                                                        {!isMobile && (
                                                            <TableCell style={{ height: 'auto !important' }} align="center">
                                                                {index + 1}
                                                            </TableCell>
                                                        )}
                                                        <TableCell
                                                            sx={{
                                                                height: 'auto !important',
                                                                position: { md: 'relative', xs: 'sticky' },
                                                                left: '0px',
                                                                zIndex: '9',
                                                                background: selectedFile?._id === row._id ? '#a9f1fc' : 'white'
                                                            }}
                                                            align="left"
                                                        >
                                                            {row?.nameFile}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="center">
                                                            {image?.includes(checkTypeFile(row?.nameFile).toLowerCase())
                                                                ? 'Ảnh'
                                                                : excel.includes(checkTypeFile(row?.nameFile).toLowerCase())
                                                                ? 'excel'
                                                                : word.includes(checkTypeFile(row?.nameFile).toLowerCase())
                                                                ? 'word'
                                                                : txt.includes(checkTypeFile(row?.nameFile).toLowerCase())
                                                                ? 'txt'
                                                                : 'pdf'}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <NotFound />
                                )}
                            </Paper>
                        )}
                    </Stack>
                </Box>
            </Modal>
            <Modal open={showModal} onClose={() => setShowModal(false)} hideBackdrop>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { md: '40%', xs: '95vw' },
                        bgcolor: 'background.paper',
                        outline: 'none',
                        boxShadow: 24,
                        p: 2
                    }}
                >
                    <Grid spacing={3}>
                        <Typography>Định dạng tệp tin không hỗ trợ xem trước, bạn có thể tải để xem tệp tin </Typography>
                    </Grid>
                    <Grid spacing={3}>
                        <Box sx={{ mt: 2 }} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                            <Button
                                disableElevation
                                size="medium"
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={<DownloadIcon />}
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => onDownloadClick()}
                            >
                                Tải xuống
                            </Button>

                            <Button
                                disableElevation
                                size="medium"
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={<Close />}
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => setShowModal(false)}
                            >
                                Đóng
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Modal>
            <ModalPreviewDocument open={openPreview} closeModal={onClosePreview} response={response} idWork={idWork} />
        </>
    );
    // return (
    //     <Modal open={show} onClose={onCloseModal}>
    //         <Box
    //             sx={{
    //                 padding: '40px',
    //                 backgroundColor: '#fff',
    //                 position: 'absolute',
    //                 top: '50%',
    //                 left: '50%',
    //                 transform: 'translate(-50%, -50%)',
    //                 width: '80%',
    //                 maxHeight: '80%',
    //                 overflow: 'auto',
    //                 borderRadius: '10px'
    //             }}
    //         >
    //             <Typography variant="h4" component="div" gutterBottom>
    //                 {/* {currentFile.work_name} */}
    //                 Tên ảnh
    //             </Typography>
    //             {supportedFormats?.includes(fileExtension) ? (
    //                 fileExtension === 'pdf' ? (
    //                     <embed src={currentFile?.urlFile} type="application/pdf" width="100%" height="600px" />
    //                 ) : (
    //                     <img src={currentFile?.urlFile} style={{ width: '100%', height: 'auto' }} />
    //                 )
    //             ) : (
    //                 <Typography variant="body1" color="error">
    //                     Định dạng file không được hỗ trợ
    //                 </Typography>
    //             )}
    //             <Button variant="contained" color="primary" onClick={handlePrev} disabled={currentFileIndex === 0}>
    //                 Prev
    //             </Button>
    //             <Button variant="contained" color="primary" onClick={handleNext} disabled={currentFileIndex === dataFile.length - 1}>
    //                 Next
    //             </Button>
    //             <Button variant="contained" color="primary" onClick={handleDownload}>
    //                 Download
    //             </Button>
    //         </Box>
    //     </Modal>
    // );
};

export default ModalFileWork;
