/* eslint-disable prefer-promise-reject-errors */

import { getLunarDate } from 'ui-component/calendarFull/LibraryDate';

/* eslint-disable no-plusplus */
export function InvokeFunction(fn, ...args) {
    if (!fn || typeof fn !== 'function') {
        return null;
    }
    return Function.prototype.call.call(fn, {}, ...args);
}

export function ConvertName(data, lengths) {
    let dataText = data;
    if (data !== null && lengths !== null) {
        if (data?.length > lengths) {
            const front = data.substr(0, lengths);
            dataText = `${front}...`;
        }
    }
    return dataText;
}

export function ShortCutName(str, length) {
    if (!str) {
        return str;
    }

    let nameConvert = str;
    const maxLength = length || 20;

    if (str.length > maxLength && nameConvert !== null) {
        const arrSplit = str.split(' ');
        if (arrSplit.length > 1) {
            let first = arrSplit[0];
            const last = arrSplit[arrSplit.length - 1];
            const midle = arrSplit.slice(1, -1);
            const arrMidle = [];

            for (let index = 0; index < midle.length; index += 1) {
                if (midle[index]) {
                    arrMidle.push(midle[index][0]);
                }
            }

            const acronym = arrMidle.join('.');

            nameConvert = first + Array(1).fill('\xa0').join('') + acronym + Array(1).fill('\xa0').join('') + last;

            if (nameConvert.length > maxLength) {
                first = first[0];
                nameConvert = first + Array(1).fill('\xa0').join('') + acronym + Array(1).fill('\xa0').join('') + last;
            }

            if (nameConvert.length > maxLength) {
                nameConvert = first + Array(1).fill('\xa0').join('') + acronym + Array(1).fill('\xa0').join('');
                const subIndex = maxLength - nameConvert.length;
                if (subIndex > 0) {
                    nameConvert += `${last.substr(0, subIndex)}...`;
                } else {
                    nameConvert = first + Array(1).fill('\xa0').join('');
                    nameConvert += last.length > maxLength ? `${last.substr(0, maxLength)}...` : last;
                }
            }
        } else {
            nameConvert = `${str.substr(0, maxLength)}...`;
        }
    }
    return nameConvert
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

export function ShowDateTime(d, m, y, defaultShow = 'Không rõ', note = '') {
    const strD = d || '--';
    const strM = m || '--';
    const strY = y || '--';

    return d || m || y ? [strD.padStart(2, '0'), strM.padStart(2, '0'), strY.padStart(2, '0')].join('/') + note : defaultShow;
}

export function getDateTime(d, m, y) {
    let date = d;
    let month = m;
    let year = y;

    if (d !== '' && m !== '' && y !== '') {
        return `${d}/${m}/${y}`;
    }
    if (d === '' && m === '' && y === '') {
        return '';
    }
    if (d === '') {
        date = '01';
    }
    if (m === '') {
        month = '01';
    }
    if (y === '') {
        year = '1000';
    }
    return `${date}/${month}/${year}`;
}

export function stringDBToDate(text) {
    const date = new Date(text);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    return formattedDate;
}

export function convertTime(timestamp) {
    const date = new Date(timestamp);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return `${formattedTime} ${formattedDate}`;
}

export function titleCase(str) {
    return str.toLowerCase().replace(/(^|\s)\S/g, (l) => l.toUpperCase());
}

export function getWidthOfText(fontSize, text) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const scale = window.devicePixelRatio || 1;
    context.font = `600 ${fontSize}px 'Roboto', sans-serif`;
    const width = context.measureText(text).width / scale;
    return Number(width);
}

export function ShortName(value, max, widthPixel, widthDiv) {
    let newName = value;

    if (newName.length > max || widthPixel > widthDiv) {
        const getName = newName.split(' ');
        for (let i = 1; i < getName.length - 1; i + 1) {
            if (getName[i].length !== 1) {
                getName[i] = getName[i].slice(0, 1);
                newName = getName.join(' ');
                if (newName.length > max) {
                    ShortName(newName, max);
                    i += 1;
                }
            } else {
                return newName;
            }
        }
    }

    const newWidthPixel = getWidthOfText(newName);
    if (newName.length > max || newWidthPixel > 169) {
        const getName = newName.split(' ');
        return `${getName[0]} ${getName[getName.length - 1]}`;
    }

    return newName;
}

export function ShortName2(value, max, widthPixel, widthDiv) {
    const newName = value;

    if (newName.length <= max && (!widthPixel || !widthDiv || widthPixel <= widthDiv)) {
        return newName;
    }

    const words = newName.split(' ');
    const first = words.shift();
    const last = words.pop();

    if (first.length + last.length >= max) {
        return `${first} ${last}`;
    }

    let middle = '';

    for (let i = 0; i < words.length; i++) {
        const nextMiddle = middle ? `${middle} ${words[i]}` : words[i];
        const newString = `${first} ${nextMiddle} ${last}`;

        if (newString.length <= max && (!widthPixel || !widthDiv || widthPixel <= widthDiv)) {
            middle = nextMiddle;
        } else {
            break;
        }
    }

    if (middle) {
        return `${first} ${middle.slice(0, 1)}. ${last}`;
    }
    return `${first} ${last}`;
}

export function getTextWidth(text) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '21.344px Roboto';
    const width = context.measureText(text).width;
    return Number(width);
}

export function moveContent(content) {
    // const editorElement = document.getElementById("EditorSPH");
    // if(editorElement) {
    //   for (const span of editorElement.querySelectorAll('li')) {
    //     if(span?.textContent) {
    //       console.log("span.textContent", span.textContent);
    //       // if (span.textContent.includes("Chương III")) {
    //       if (span.textContent == content) {
    //         console.log("SPAN FOUND", span);
    //         span.scrollIntoView({behavior: "smooth"});
    //         break
    //       }
    //     }
    //   }
    // }
    console.log(content);
}

export function shortenName(fullName, maxLength, fontSize, widthDiv) {
    if (!fullName) return '';

    let widthPixel = getWidthOfText(fontSize, fullName);
    let n = 1;

    while (widthPixel > widthDiv || fullName.length > maxLength) {
        const words = fullName.split(' ');
        if (words.length === 1) {
            break;
        } else if (words.length - 1 === n) {
            fullName = `${words[0]} ${words[n]}`;
            break;
        } else if (words.length > n) {
            words[n] = Array.from(words[n])[0];
            fullName = words.join(' ');
            n += 1;
        }

        widthPixel = getWidthOfText(fontSize, fullName);
    }

    return fullName;
}

export async function getBase64Image(img) {
    const res = await fetch(img);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                resolve(reader.result);
            },
            false
        );

        reader.onerror = () => reject(this);
        reader.readAsDataURL(blob);
    });
}

export function toDataURL(src, callback) {
    // console.log('src ', src);
    let canvas = document.createElement('CANVAS');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        callback.call(this, dataURL);
        // Clean up
        canvas = null;
    };
    // console.log('img ', img);
    img.src = src;
}

export function toDataURLImage(src, callback) {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        callback(dataURL);
    };
    image.src = src;

    if (image.complete || image.complete === undefined) {
        image.src = 'data:image/png;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        image.src = src;
    }
}

export function getTextLength(dataContents) {
    let length = 0;
    for (let i = 0; i < dataContents.length; i++) {
        const content = dataContents[i];
        if (content.children) {
            for (let j = 0; j < content.children.length; j++) {
                const child = content.children[j];
                if (child.children) {
                    length += getTextLength(child.children);
                } else if (child.text) {
                    length += child.text.length;
                    length += 1;
                }
            }
        }
    }
    return length;
}

export function syncDataContent(dataContents) {
    for (let i = 0; i < dataContents.length; i++) {
        const content = dataContents[i];
        if (content.children) {
            for (let j = 0; j < content.children.length; j++) {
                const child = content.children[j];
                if (child.children) {
                    syncDataContent(child.children);
                } else if (child?.fontSize) {
                    delete child?.fontSize;
                }
            }
        }
    }
}

export function calculateReadingTime(textLength, wordsPerMinute) {
    const readingTime = textLength / wordsPerMinute;

    return Math.ceil(readingTime);
}

export function copyPathToClipboard(url) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
            .writeText(url)
            .then(function () {
                console.log('Đã sao chép đường link thành công.');
            })
            .catch(function (error) {
                console.error('Lỗi khi sao chép đường link:', error);
            });
    } else {
        var tempInput = document.createElement('input');
        tempInput.value = url;
        document.body.appendChild(tempInput);

        tempInput.select();
        tempInput.setSelectionRange(0, 99999);

        document.execCommand('copy');
        document.body.removeChild(tempInput);

        console.log('Đã sao chép đường link thành công.');
    }
}
// export function ShortName(value, max, widthPixel = null, widthDiv = null) {
//     let newName = value;

//     if (newName.length > max || (widthPixel && widthDiv && widthPixel > widthDiv)) {
//         const getName = newName.split(' ');
//         for (let i = 1; i < getName.length - 1; i + 1) {
//             if (getName[i].length !== 1) {
//                 getName[i] = getName[i].slice(0, 1);
//                 newName = getName.join(' ');
//                 if (newName.length > max) {
//                     newName = ShortName(newName, max, widthPixel, widthDiv);
//                     i += 1;
//                 }
//             } else {
//                 return newName;
//             }
//         }
//     }

//     if (newName.length > max || (widthPixel && widthDiv && widthPixel > widthDiv)) {
//         const getName = newName.split(' ');
//         return `${getName[0]} ${getName[getName.length - 1]}`;
//     }

//     return newName;
// }
export default function getExtesionFileFromMime(mime) {
    const defaultEx = '.jpg';
    if (!mime) {
        return defaultEx;
    }
    switch (mime) {
        case 'image/png':
            return '.png';

        case 'image/jpeg':
            return '.jpg';

        case 'image/webp':
            return '.webp';

        case 'image/gif':
            return '.gif';

        default:
            return defaultEx;
    }
}

export function checkNowIsBirthDeadDay(dataMember) {
    const monthNow = new Date().getMonth() + 1;
    const dateNow = new Date().getDate();
    const yearNow = new Date().getFullYear();
    if (dataMember?.dead_flag) {
        //đã mất
        const monthDead = dataMember?.dead_day_lunar?.month;
        const dateDead = dataMember?.dead_day_lunar?.day;
        const lunarDayNow = getLunarDate(dateNow, monthNow, yearNow);
        if (monthDead && dateDead && lunarDayNow?.day === parseInt(dateDead) && lunarDayNow?.month === parseInt(monthDead)) {
            return 'dead';
        }
    } else {
        //còn sống
        const monthBir = dataMember?.birth_day_solar?.month;
        const dateBir = dataMember?.birth_day_solar?.day;
        if (monthBir && dateBir && monthNow === parseInt(monthBir) && dateNow === parseInt(dateBir)) {
            return 'bir';
        }
    }
    return null;
}
