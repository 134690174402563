import ConfigRouter from 'routes/config_router';
import * as roleAlias from 'constant/roleAlias';

const ConfigMenuAlias = [
    { alias: roleAlias.GENEALOGY, url: ConfigRouter.genealogy, name: 'Xây dựng phả hệ' },
    { alias: roleAlias.TREE, url: ConfigRouter.treeviewer, name: 'Phả đồ' },
    { alias: roleAlias.INFO_FAMILY, url: ConfigRouter.detailFamily, name: 'Thông tin chung' },
    { alias: roleAlias.DIARY, url: ConfigRouter.diaryFamily, name: 'Bài viết phả ký' },
    { alias: roleAlias.LIST_MEMBER, url: ConfigRouter.listMember, name: 'Danh sách thành viên' },
    { alias: roleAlias.DETAILMEMBER, url: ConfigRouter.detaiMember, name: 'Chi tiết thành viên' },
    { alias: roleAlias.EVENT, url: ConfigRouter.event, name: 'Sự kiện dòng họ' },
    { alias: roleAlias.MASTER, url: ConfigRouter.configMaster, name: 'Quản lý dữ liệu' },
    { alias: roleAlias.HOMEPAGE, url: ConfigRouter.homePage, name: 'Trang chủ' },
    { alias: roleAlias.LISTPATRIACRCH, url: ConfigRouter.listPatriarch, name: 'Danh sách tộc trưởng' },
    { alias: roleAlias.STATISTICAL, url: ConfigRouter.statisticalFamily, name: 'Thống kê dòng họ' },
    { alias: roleAlias.HISTORY_FAMILY, url: ConfigRouter.historyFamily, name: 'Lịch sử dòng họ' },
    { alias: roleAlias.GRAVE, url: ConfigRouter.graveFamily, name: 'Thông tin mộ phần' },
    { alias: roleAlias.ALBUM, url: ConfigRouter.albumFamily, name: 'Album dòng họ' },
    { alias: roleAlias.DOCUMENT, url: ConfigRouter.documentFamily, name: 'Tư liệu dòng họ' },
    { alias: roleAlias.LISTACCOUNT, url: ConfigRouter.listAccount, name: 'Quản lý tài khoản' },
    { alias: roleAlias.SERVICE, url: ConfigRouter.listService, name: 'Quản lý dịch vụ' },
    { alias: roleAlias.SETTING_HOMEPAGE, url: ConfigRouter.settingNewfeed, name: 'Quản lý trang chủ' },
    { alias: roleAlias.CREATOR, url: ConfigRouter.listCreator, name: 'Tác giả/ Tác phẩm' },
    { alias: roleAlias.SYSTEM_LOG, url: ConfigRouter.listSymstemLog, name: 'Nhật ký hệ thống' },
    { alias: roleAlias.ANCESTRALHOUSE, url: ConfigRouter.ancestralHouseList, name: 'Danh sách nhà thờ' },
    // { alias: roleAlias.TOPIC_POST, url: ConfigRouter.topicPost, name: 'Chủ đề bài viết'}
];

export default ConfigMenuAlias;
