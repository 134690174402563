import { Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { useEffect, useState } from 'react';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import moment from 'moment';

const NotificationNewFeed = ({ dataNoti }) => {
    // const getData = async () => {
    //     const rst = await fetchClient.postRequest(router.SUB_URL.getSettingHomePage, { type: 'notification' });
    //     if (rst?.status === 201) {
    //         setDataNoti(rst?.data?.notification ?? []);
    //     } else {
    //         console.log('rst', rst);
    //     }
    // };
    // useEffect(() => {
    //     getData();
    // }, []);
    return (
        <>
            {dataNoti && dataNoti.length > 0 ? (
                <Box
                    sx={{
                        height: '20px',
                        padding: '5px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        color: 'white',
                        backgroundColor: '#8B0000'
                    }}
                >
                    <marquee>
                        {dataNoti.map((item, index) => (
                            <>
                                <span>{item?.time_notification ? `${moment(item?.time_notification).format('DD/MM/YYYY')} - ` : ''} {item.content}</span>
                                {index !== dataNoti.length - 1 && (
                                    <span style={{ margin: '5px 20px 0px 20px' }}>
                                        <Brightness1Icon sx={{ fontSize: '10px' }} />
                                    </span>
                                )}
                            </>
                        ))}
                    </marquee>
                </Box>
            ) : null}
        </>
    );
};
export default NotificationNewFeed;
