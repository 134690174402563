import { 
    TablePagination, 
    TableContainer, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    Paper, 
    TableBody, 
    useMediaQuery, 
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Button,
    TextField,
    Typography,
    Tooltip,
    IconButton
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import NotFound from 'ui-component/404/404';
import MainCard from 'ui-component/cards/MainCard';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import LoadingTable from 'ui-component/LoadingTable';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DialogUtil from 'ui-component/show_confirm_action';
import config from '../../../config';
import axios from 'axios';
import SyncIcon from '@mui/icons-material/Sync';
import { getRoleButton } from 'utils/roleUility';
import ConfigMenuAlias from 'views/utilities/config_menu_alias';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

const customStyles = (index) => ({
    control: (provided, state) => ({
        ...provided,
        height: '40px'
    }),

    option: (provided, state) => ({
        ...provided,
        // zIndex: index,
        borderBottom: '1px dotted pink',
        opacity: 1
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: index,
        opacity: 1
    }),
    container: (base) => ({
        ...base,
        flex: 1
    })
});

const arr = [
    { key: 'create', name: 'Thêm mới' },
    { key: 'update', name: 'Cập nhật' },
    { key: 'delete', name: 'Xóa' },
    { key: 'status', name: 'Trạng thái' },
    { key: 'export', name: 'Xuất file' },
];

const columns = [
    { id: 'stt', label: 'STT', width: '30px', align: 'center', rowAlign: 'left', hidden: true },
    {
        id: 'username',
        label: 'Người thực hiện',
        align: 'center',
        rowAlign: 'center',
        width: '120px',
        sx: { position: 'sticky', left: '0px', zIndex: '99' }
    },
    { id: 'time', label: 'Thời gian', align: 'center', rowAlign: 'center', textAlign: 'center', width: '40px', },

    {
        id: 'content',
        label: 'Nội dung',
        align: 'center',
        rowAlign: 'center',
        width: '150px'
    },
    {
        id: 'event',
        label: 'Hành động',
        width: '90px',
        align: 'center',
        rowAlign: 'center'
    },
    {
        id: 'name_screen',
        label: 'Màn hình',
        width: '80px',
        align: 'center',
        rowAlign: 'center'

    },
    {
        id: 'ip',
        label: 'Địa chỉ ip',
        align: 'center',
        rowAlign: 'center',
        width: '50px'
        // format: (value) => value.toFixed(2)
    },
    {
        id: 'browser',
        label: 'Trình duyệt',
        align: 'center',
        rowAlign: 'center',
        width: '120px'
        // format: (value) => value.toFixed(2)
    }
];

const SystemLog = () => {
    const timer = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);

    const [page, setPage] = useState(0);
    const [total, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dataList, setDataList] = useState([]);
    const [dataSearch, setDataSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataAccounts, setDataAccounts] = useState([]);
    const [idAccount, setIdAccount] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [valueEvent, setValueEvent] = useState('');
    const [role, setRole] = useState();
    const [timeStatic, setTimeStatic] = useState('');
    const [check, setCheck] = useState(true);

    const getListAccount = async () => {
        const rst = await fetchClient.postRequest('auth/list_acount', {});
        if (rst.status === 201) {
            setDataAccounts(rst?.data?.original ?? []);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        getListAccount();
    }, [])
    

    const getListDiries = async (dataSearch, fromDate, toDate, idAccount, valueEvent, page, rowsPerPage) => {
        const obj = {
            search: dataSearch,
            startTime: fromDate,
            endTime: toDate,
            rowsPerpage: rowsPerPage,
            userId: idAccount,
            ctx: valueEvent,
            currentPage: page
        };
        const rst = await fetchClient.postRequest(router.SUB_URL.list_diary, obj);
        if (rst.status === 201) {
            setDataList(rst?.data?.original ?? []);
            setTotalRows(rst.data.total);
            setLoading(false);
            setCheck(rst.data.hasvalue);
        }
    }

    const getTimeStatic = async () => {
        const rst = await fetchClient.postRequest(router.SUB_URL.time_diary);
        if (rst.status === 201) {
            const data = rst.data;
            if (data.length > 0) {
                setTimeStatic(data[0]?.t ?? '');
            }
        }
    }

    useEffect(() => {
        async function getRoleButtonScreen() {
            setLoading(true);
            const rs = await getRoleButton();
            if (rs?.read) {
                // getTimeStatic();
                getListDiries( dataSearch, fromDate, toDate, idAccount, valueEvent, page, rowsPerPage);
            }
            setRole(rs);
            setLoading(false);
        }
        getRoleButtonScreen();
    }, []);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getListDiries( dataSearch, fromDate, toDate, idAccount, valueEvent, newPage, rowsPerPage);

    };

    const handleChangeRowsPerPage = (event) => {
        document.getElementById('list')?.scrollTo(0, 0);
        setRowsPerPage(+event.target.value);
        setPage(0);
        getListDiries( dataSearch, fromDate, toDate, idAccount, valueEvent, page, +event.target.value);
    };

    const getLabel = (value) => {
        let label = '';
        let color = '';
        switch (value) {
            case 'status':
                label = 'Thay đổi';
                color = 'info';
                break;
            case 'create':
                label = 'Thêm mới';
                color = 'success';
                break;
            case 'update':
                label = 'Cập nhật';
                color = 'primary';
                break;
            case 'delete':
                label = 'Xóa';
                color = 'error';
                break;
            case 'export':
                label = 'Xuất file';
                color = 'warning';
                break;        
            default:
                break;
        };
        return {
            label: label,
            color: color
        };
    }

    const onChooseAccount = (e) => {
        setIdAccount(e.target.value);
        getListDiries( dataSearch, fromDate, toDate, e.target.value, valueEvent, page, rowsPerPage);
    }

    const onChooseEvent = (e) => {
        setValueEvent(e.target.value);
        getListDiries( dataSearch, fromDate, toDate, idAccount, e.target.value, page, rowsPerPage);
    }

    const download = (url, param = undefined) => {
        const fullRoute = config.api + url;
        axios({
            url: fullRoute,
            method: 'post',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            data: param
        }).then((response, error) => {
            if (error) {
                setLoading(false);
            } else {
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = type;
                link.click();
            }
        });
    };

    const onExecl = async () => {
        const pr = {
            search: dataSearch,
            startTime: fromDate,
            endTime: toDate,
            userId: idAccount,
            ctx: valueEvent,
        };
        await download(router.SUB_URL.excel_system, pr);
    }

    const handleChangeDate = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'fromDate') {
            setFromDate(value);
            getListDiries( dataSearch, value, toDate, idAccount, valueEvent, page, rowsPerPage);
        } else {
            setToDate(value);
            getListDiries( dataSearch, fromDate, value, idAccount, valueEvent, page, rowsPerPage);
        }
        
    }

    const exportExcelDocument = () => {
        DialogUtil.showConfirm(
            'Xác nhận',
            `Bạn có muốn tiếp tục tải file excel danh sách nhật ký hệ thống không ?`,
            () => {},
            () => onExecl()
        );
    }

    const handleChangeSearch = (e) => {
        const searchValue = e.target.value.trim();
        setDataSearch(e.target.value);
        if (timer) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            getListDiries(searchValue, fromDate, toDate, idAccount, valueEvent, page, rowsPerPage)
        }, 1000);
    }

    const onRefresh = () => {
        setDataSearch('');
        setIdAccount('');
        setFromDate('');
        setToDate('');
        setValueEvent('');
        setPage(0);
        setRowsPerPage(10);
        getListDiries('', '', '', '', '', 0, 10);
    }

    const getNameScreen = (value) => {
        let rst = '';
        if (value) {
            ConfigMenuAlias.filter((item) => {
                if (item.alias === value) {
                    rst = item.name;
                }
            })
        } else {
            rst = 'Trang cá nhân';
        }
        return rst;
    }

    const convertRule = (rule) => {
        if (rule === 'ROOT') {
            return 'Quản trị viên';
        }
        if (rule === 'EDITOR') {
            return 'Người chỉnh sửa';
        }
        if (rule === 'VIEWER') {
            return 'Người xem';
        }
        return '';
    };

    const getTypeAccount = (value) => {
        let name = '';
        if (dataAccounts.length > 0) {
            dataAccounts.filter((item) => {
                if (item._id === value) {
                    name = convertRule(item.type)
                }
            })
        }
        return name;
    }

    if (loading) {
        return (<LoadingTable></LoadingTable>)
    } else {
        return (
            <>
                {
                    role?.read ? (
                        <MainCard
                            sx={{
                                height: { sm: 'calc(100dvh - 122px)', xs: 'calc(100dvh - 100px)' },
                                overflow: 'auto',
                                overflowX: 'hidden',
                                '&::-webkit-scrollbar': {
                                    width: { md: '0.4em', xs: '3px' },
                                    height: { md: 'auto', xs: '3px' }
                                },
                                '&::-webkit-scrollbar-track': {
                                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: customization?.themeDongHo?.heading,
                                    outline: 'slategrey'
                                }
                            }}
                            contentSX={{
                                padding: { xs: '10px !important', sm: '24px !important' },
                                height: { xs: '100%', sm: 'auto' },
                                overflowX: 'hidden'
                            }}
                        >
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap'
                            }}>
                                <div 
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start'
                                        // justifyContent: 'start',
                                    }}>
                                    
                                    <Button
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        color="primary">
                                            <Tooltip title={
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="subtitle1" sx={{ color: 'white', fontSize: '14px' }}>
                                                        {/* Thời điểm thống kê: {timeStatic ? moment(timeStatic).format('DD/MM/YYYY HH:mm:ss') : '07/03/2024'} */}
                                                        *Nhật ký hệ thống được ghi nhận từ: 17:30 08/03/2024
                                                    </Typography>
                                                    <Typography variant="subtitle1" sx={{ color: 'white', fontSize: '14px' }}>
                                                        {/* Thời điểm thống kê: {timeStatic ? moment(timeStatic).format('DD/MM/YYYY HH:mm:ss') : '07/03/2024'} */}
                                                        *Đến thời điểm: {moment().format('HH:mm DD/MM/YYYY')}
                                                    </Typography>
                                                </Box>
                                            }>
                                                <InfoIcon />
                                            </Tooltip>
                                    </Button>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        // justifyContent: 'end',

                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <FormControl 
                                        sx={{ 
                                                mr: 1, 
                                                // height: '100%', 
                                                minWidth: { md: 200, xs: '47%' }, 
                                                marginBottom: '20px' 
                                            }} 
                                        size="small"
                                    >
                                        <TextField
                                            type='text'
                                            label='Tìm kiếm theo nội dung ...'
                                            value={dataSearch ?? ''}
                                            onChange={handleChangeSearch}
                                            name='dataSearch'
                                            size='small'
                                        />
                                    </FormControl>
                                    <FormControl sx={{ mr: 1, width: '200px', marginBottom: '20px' }} size="small">
                                        <InputLabel id="account">Danh sách tài khoản</InputLabel>
                                        <Select
                                            sx={{height:'40px', backgroundColor:'transparent'}}
                                            labelId="account"
                                            id="account"
                                            label="Danh sách tài khoản"
                                            value={idAccount}
                                            onChange={onChooseAccount}
                                        >
                                            {dataAccounts.map((item, index) => (
                                                <MenuItem key={index.toString()} value={item._id}>
                                                    {item.user_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ mr: 1, width: '150px', marginBottom: '20px' }} size="small">
                                        <InputLabel id="event">Hành động</InputLabel>
                                        <Select
                                            sx={{height:'40px', backgroundColor:'transparent'}}
                                            labelId="event"
                                            id="event"
                                            label="Hành động"
                                            value={valueEvent}
                                            onChange={onChooseEvent}
                                        >
                                            {arr.map((item, index) => (
                                                <MenuItem key={index.toString()} value={item.key}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ mr: 1, minWidth: { md: 150, xs: '47%' }, marginBottom: '20px' }} size="small">
                                        <TextField
                                            type='date'
                                            label='Từ ngày'
                                            value={fromDate ?? ''}
                                            onChange={handleChangeDate}
                                            name='fromDate'
                                            size='small'
                                            inputFormat="dd/MM/yyyy"
                                            InputLabelProps={{ 
                                                shrink: true
                                            }}
                                            inputProps={{
                                                max: toDate
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ mr: 1, minWidth: { md: 150, xs: '47%' }, marginBottom: '20px' }} size="small">
                                        <TextField
                                            label='đến ngày'
                                            name='toDate'
                                            type='date'
                                            value={toDate ?? ''}
                                            size='small'
                                            inputFormat="dd/MM/yyyy"
                                            onChange={handleChangeDate}
                                            InputLabelProps={{ 
                                                shrink: true
                                            }}
                                            inputProps={{
                                                min: fromDate
                                            }} 
                                        />
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => onRefresh()}
                                        sx={{ marginRight: '10px', whiteSpace: 'nowrap', maxWidth: '100px', height: '40px', marginBottom: '20px' }}
                                        startIcon={<SyncIcon />}
                                    >
                                        Làm mới
                                    </Button>
                                    { role?.export && (
                                        <Button
                                            disableElevation
                                            fullWidth
                                            disabled={dataList.length > 0 ? false : true}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={exportExcelDocument}
                                            sx={{ whiteSpace: 'nowrap', maxWidth: '100px', height: '40px', marginBottom: '20px', marginRight: '10px' }}
                                            startIcon={<FileOpenIcon />}
                                        >
                                            Xuất excel
                                        </Button>
                                    )}
                                    
                                </div>
                            </div>
                            
                            

                            <Paper sx={{ width: '100%', borderRadius: 0 }}>
                                <TableContainer
                                    sx={{
                                        maxHeight: { xs: 'calc(100dvh - 220px)', md: 'calc(100dvh - 270px)' },
                                        overflow: 'auto',
                                        position: 'relative',
                                        '&::-webkit-scrollbar': {
                                            width: { md: '0.4em', xs: '3px' },
                                            height: { md: 'auto', xs: '3px' }
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: customization?.themeDongHo?.heading,
                                            outline: 'slategrey'
                                        }
                                    }}
                                >
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead sx={{ borderRadius: 0 }}>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        sx={{
                                                            minWidth: column.minWidth,
                                                            backgroundColor: 'rgb(144, 202, 249)',
                                                            color: 'black',
                                                            width: column?.width,
                                                            display: isMobile && column.hidden ? 'none' : 'default',
                                                            ...(column.sx && column.sx)
                                                        }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        {dataList.length > 0 ? (
                                            <TableBody>
                                                {dataList.map((row, index) => (
                                                    <TableRow
                                                        key={row._id}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            '&&.Mui-selected': {
                                                                backgroundColor: '#b3c7e8'
                                                            },
                                                            '&.MuiTableRow-hover:hover': {
                                                                backgroundColor: 'rgba(0, 0, 255, 0.3)'
                                                            }
                                                        }}
                                                    >
                                                        <TableCell
                                                            sx={{ height: 'auto !important', ...(isMobile && { display: 'none' }) }}
                                                            align="center"
                                                        >
                                                            {index + 1 + page * (rowsPerPage - 0)}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                height: 'auto !important',
                                                                position: { md: 'relative', xs: 'sticky' },
                                                                left: '0px',
                                                                zIndex: '9',
                                                                backgroundColor: { md: 'transparent', xs: 'white' }
                                                            }}
                                                            align="center"
                                                        >
                                                            {row?.u_id?.u_name ?? ''}
                                                            <div>{`( ${getTypeAccount(row?.u_id?.u_id)} )`}</div>
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                height: 'auto !important',
                                                                textAlign: 'center',
                                                                width: '50px !important'
                                                            }}
                                                            align="left"
                                                        >
                                                            {row?.t ? moment(row?.t).format('DD/MM/YYYY HH:mm:ss') : ''}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="left">
                                                            {row.msg}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="center">
                                                            <Chip label={getLabel(row.ctx).label} color={getLabel(row.ctx).color} />
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align='center'>
                                                            {getNameScreen(row?.co_name ?? '')}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="center">
                                                            {row.ip}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="center">
                                                            {row.ag}
                                                        </TableCell>
                                                        {/* <TableCell style={{ height: 'auto !important' }} align="center">
                                                            {convertRule(row?.type)}
                                                        </TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableRow>
                                                <TableCell sx={{ display: 'table-cell', textAlign: 'center' }} colSpan={12}>
                                                    <NotFound 
                                                        text={check ? 'Không có dữ liệu để hiển thị' : 'Hệ thống chưa ghi nhận hoạt động nào'}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    labelRowsPerPage="Phân trang"
                                    rowsPerPageOptions={[10, 20, 30]}
                                    component="div"
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    showFirstButton
                                    showLastButton
                                    sx={{
                                        ...(isMobile && dataList?.length === 0
                                            ? { display: 'none' }
                                            : {
                                                '.MuiInputBase-root': {
                                                    marginRight: { xs: '10px', sm: '32px' }
                                                },
                                                '.MuiToolbar-root': {
                                                    padding: { xs: '0px', sm: 'auto' }
                                                },
                                                '.MuiTablePagination-actions': {
                                                    marginLeft: { xs: '5px', sm: '20px' }
                                                },
                                                overflowX: 'hidden',
                                                '.MuiButtonBase-root': {
                                                    padding: { xs: '3px ', sm: '8px' }
                                                }
                                            })
                                    }}
                                />
                            </Paper>
                        </MainCard>
                    ) : (
                        <MainCard
                            sx={{
                                height: { md: 'calc(100dvh - 122px)', xs: 'calc(100dvh - 102px)' },
                            }}
                            contentSX={{ 
                                height: '100%',
                                padding: { md: '24px', xs: '4px' }
                            }}
                        >
                            <div 
                                style={{ height: '100%', 
                                display: 'flex', 
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <NotFound text={'Chỉ quản trị viên truy cập chức năng này'}/>
                            </div>
                        
                        </MainCard>
                    )
                }
            </>
            
        )
    }
    
}

export default SystemLog;