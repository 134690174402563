/* eslint-disable jsx-a11y/alt-text */
import { Typography, CardMedia, Box } from '@mui/material';
import Logo from '../../assets/images/logo_gray.png';

const NotFound = ({ text }) => (
    <Box className="not-found" sx={{ textAlign: 'center', marginTop: '50px', marginBottom: '50px' }}>
        <img height="120px" width="120px" src={Logo} />
        <Typography
            variant="span"
            component="span"
            sx={{ fontSize: '13px', color: 'dark.dark', display: 'block', marginTop: '8px' }}
            gutterBottom
        >
            {text || 'Không có dữ liệu để hiển thị'}
        </Typography>
    </Box>
);

export default NotFound;
