import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import useMediaQuery from '@mui/material/useMediaQuery';
import { drawerWidth } from 'store/constant';
import MenuList from './MenuList';
import { ButtonBase, Avatar, CardMedia, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IconMenu2 } from '@tabler/icons';
import TopMenu from '../../../assets/images/icons/top-danh-ba.jpg';
import TopMenuMini from '../../../assets/images/icons/top-danh-ba-mini.jpg';
import BottomMenu from '../../../assets/images/icons/bottom-danh-ba.jpg';
import BottomMenuMini from '../../../assets/images/icons/bottom-danh-ba-mini.jpg';
import MiddleMenu from '../../../assets/images/icons/partern-danhmuc.jpg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SET_MENU } from 'store/action/actions';

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
        [theme.breakpoints.down('md')]: {
            '& .MuiDrawer-paper': { height: '100% !important' },
            position: 'absolute'
        }
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
        [theme.breakpoints.down('md')]: {
            '& .MuiDrawer-paper': { display: 'none !important' },
            display: 'none !important'
        }
    })
}));

export default function MiniDrawer({ drawerOpen, drawerToggle, window }) {
    const theme = useTheme();
    const dispatch = useDispatch();

    const customization = useSelector((state) => state.customization);

    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [openDrawer, setOpenDrawer] = React.useState(drawerOpen);

    React.useEffect(() => {
        setOpenDrawer(drawerOpen);
    }, [drawerOpen]);

    const handleHover = () => {
        if (!drawerOpen) {
            setOpenDrawer(true);
        }
    };

    const handMouseLeave = () => {
        if (!drawerOpen) {
            setOpenDrawer(false);
        }
    };

    const handleCloseDrawerLeft = () => {
        dispatch({ type: SET_MENU, opened: false });
    };

    const drawer = (
        <List sx={{ marginBottom: '20px' }}>
            <Box sx={{ textAlign: 'right', display: { lg: 'none' } }}>
                <Button variant="text" onClick={handleCloseDrawerLeft}>
                    <CloseIcon />
                </Button>
            </Box>
            <CardMedia
                image={openDrawer ? TopMenu : TopMenuMini}
                sx={{
                    height: 30,
                    width: '100%',
                    marginTop: '10px'
                }}
            />
            <Box
                sx={{
                    marginLeft: '8px',
                    marginRight: '8px',
                    backgroundImage: `url(${MiddleMenu})`,
                    overflow: 'auto',
                    overflowX: 'hidden',
                    height: 'calc(100vh - 185px)',
                    '&::-webkit-scrollbar': {
                        // width: '0.4em'
                        width: '0'
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: customization?.themeDongHo?.heading,
                        outline: 'slategrey'
                    }
                }}
            >
                <MenuList open={openDrawer} handleHover={handleHover} handMouseLeave={handMouseLeave} />
            </Box>
            <CardMedia
                image={openDrawer ? BottomMenu : BottomMenuMini}
                sx={{
                    height: 30,
                    width: '100%'
                }}
            />
        </List>
    );

    return (
        <Box
            component="nav"
            sx={{ display: 'flex', flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
            onMouseLeave={handMouseLeave}
        >
            <CssBaseline />
            <Drawer
                id="drawer-sidebar"
                variant="permanent"
                open={openDrawer}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        borderRadius: theme.typography.mainContent.borderRadius,
                        [theme.breakpoints.up('md')]: {
                            top: '82px'
                        },
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 75px)',
                        backgroundColor: customization?.themeDongHo?.navBarColor,
                        '&::-webkit-scrollbar': {
                            width: '0.4em'
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: customization?.themeDongHo?.heading,
                            outline: 'slategrey'
                        }
                    },
                    display: { xs: 'none', lg: 'block' }
                }}
            >
                {drawer}
                {!drawerOpen && openDrawer ? (
                    <></>
                ) : (
                    <ButtonBase
                        sx={{
                            borderRadius: '12px',
                            overflow: 'hidden',
                            position: 'fixed',
                            bottom: 10,
                            // width: 'inherit',
                            left: '15px',
                            display: { xs: 'none', lg: 'block' }
                        }}
                    >
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={drawerToggle}
                            color="inherit"
                        >
                            {openDrawer ? (
                                <ArrowBackIosNewIcon stroke={1.5} size="1.3rem" />
                            ) : (
                                <ArrowForwardIosIcon stroke={1.5} size="1.3rem" />
                            )}
                        </Avatar>
                    </ButtonBase>
                )}
            </Drawer>
        </Box>
    );
}
