import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customization.reducer';
import userReducer from './user.reducer';
import dataCommonReducer from './dataComon.redcuer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    dataUser: userReducer,
    dataCommon: dataCommonReducer
});

export default reducer;
