import React, { useEffect, useRef, useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { Button, Modal, Stack, Box, Typography, CardMedia } from '@mui/material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SaveIcon from '@mui/icons-material/Save';
import DialogUtil from 'ui-component/show_confirm_action';
import { Close } from '@mui/icons-material';
import ImageDefault from '../../../../assets/images/users/background.jpg';
import router from 'routes/router';
import config from '../../../../config';
import fetchClient from 'views/utilities/RestfulAPI';
import ModalImageAlbum from 'ui-component/family/ModalImageAlbum';
import { toDataURLImage } from 'utils/helper';
import ConfigRouter from 'routes/config_router';
import { useNavigate } from 'react-router';
import { getRoleButton } from 'utils/roleUility';
import getExtesionFileFromMime from '../../../../utils/helper';
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const MyModalPhoto = ({ isOpen, onResolve, onReject, text, title, id }) => {
    const imageRef = useRef();
    const [role, setRole] = useState();
    const [showAlbum, setShowAlbum] = useState(false);
    const [fileDiary, setFileDiary] = useState(null);
    const [dataFile, setDataFile] = useState(null);
    const [pathImage, setPathImage] = useState(null);
    const navigate = useNavigate();
    const submit = (str) => onResolve(str || 'resolved!!!');
    const reject = () => onReject('rejected!!!');

    const onClickFolder = () => {
        // setOpenModal(false);
        imageRef.current.click();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].size > 5e6) {
                DialogUtil.showQuestions(false, 'Vui lòng chọn file ảnh có dung lượng dưới 5mb');
                return;
            }
            if (!event.target.files[0].type.match(imageMimeType)) {
                DialogUtil.showQuestions(false, 'Định dạng file không đúng vui lòng kiểm tra lại');
                return;
            }
            setDataFile(event.target.files[0]);
            setFileDiary(URL.createObjectURL(event.target.files[0]));
            setPathImage(null);
        }
    };

    const handleClose = () => {
        setDataFile(null);
        setFileDiary(null);
        setPathImage(null);
        setShowAlbum(false);
        reject();
    };

    const uploadFileDiary = async () => {
        const obj = {
            idPost: id
        };

        // console.log('id', id);
        const formData = new FormData();
        formData.append('diary_file', dataFile);
        formData.append('dataObj', JSON.stringify(obj));
        // console.log(dataFile);
        const rst = await fetchClient.postRequest(router.SUB_URL.postFamily_upload, formData);
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, 'Lưu ảnh thành công.', () => setPathImage(rst.data));
        } else if (rst?.statusCode === 406) {
            DialogUtil.showQuestionsTwoAction(
                role?.roleAlias,
                false,
                rst?.message,
                'Nâng cấp ngay',
                'Đóng',
                () => {
                    navigate(ConfigRouter.listService);
                },
                () => {}
            );
        } else {
            DialogUtil.showQuestions(false, 'Lưu ảnh thất bại.', () => setPathImage(null));
        }
    };

    const copyPathImage = async () => {
        const obj = {
            idPost: id
        };

        // console.log('id', id);
        const formData = new FormData();
        formData.append('diary_file', dataFile);
        formData.append('dataObj', JSON.stringify(obj));
        // console.log(dataFile);
        const rst = await fetchClient.postRequest(router.SUB_URL.postFamily_upload, formData);
        if (rst.status === 201) {
            const fullPath = config.severImageBase64 + rst?.data;
            DialogUtil.showQuestions(true, 'Chèn hình ảnh thành công.', () => submit(fullPath));
        } else if (rst?.statusCode === 406) {
            DialogUtil.showQuestionsTwoAction(
                role?.roleAlias,
                false,
                rst?.message,
                'Nâng cấp ngay',
                'Đóng',
                () => {
                    navigate(ConfigRouter.listService);
                },
                () => {}
            );
        } else {
            DialogUtil.showQuestions(false, 'Chèn hình ảnh thất bại.');
        }
        // const fullPath = config.severImageBase64 + pathImage;
        // onSelectImage(fullPath)

        // console.log('fullpath', fullPath)
        // navigator.clipboard.writeText(fullPath)
        //   .then(() => {
        //     DialogUtil.showQuestions(true, 'Sao chép đường dẫn ảnh thành công.', () => handleClose());
        //   })
        //   .catch((err) => {
        //     DialogUtil.showQuestions(false, 'Sao chép đường dẫn ảnh thất bại.');
        //   });
        // console.log('path', fullPath);
        // submit(fullPath);
    };

    const actionOpenAlbum = async () => {
        setShowAlbum(true);
    };

    const dataUrlToFile = async (dataUrl) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        // eslint-disable-next-line no-plusplus
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], `image${getExtesionFileFromMime(mime)}`, { type: mime });
    };

    const onChooseImage = (item) => {
        toDataURLImage(`${config.severImageBase64 + item.urlImage}`, async (dataUrl) => {
            const imageData = await dataUrlToFile(dataUrl);
            setDataFile(imageData);
            setFileDiary(dataUrl);
            setPathImage(null);
        });
    };

    useEffect(() => {
        async function getRoleButtonScreen() {
            const rs = await getRoleButton();
            setRole(rs);
        }
        getRoleButtonScreen();
    }, []);
    return (
        <>
            <input ref={imageRef} type="file" style={{ display: 'none' }} accept="image/*" onChange={onImageChange} />
            <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '95%', sm: '75%', md: '60%' },
                        bgcolor: 'background.paper',
                        outline: 'none',
                        boxShadow: 24,
                        p: 2
                    }}
                >
                    <Stack spacing={1}>
                        <Typography variant="h3" component="h2">
                            Chọn ảnh
                        </Typography>
                        {/* <Divider sx={{ marginTop: '10px', marginBottom: '15px' }} /> */}
                        <div style={{ border: '0.5px solid #eeeeee', marginTop: '15px', marginBottom: '20px' }} />
                        <Box position="absolute" top={0} right={0}>
                            {/* <IconButton onClick={() => setOpenModal(false)}>
                                <Close />
                            </IconButton> */}
                            <Button
                                disableElevation
                                fullWidth
                                size="small"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                                sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '80px', marginTop: '10px' }}
                                startIcon={<Close />}
                            >
                                Đóng
                            </Button>
                        </Box>
                        <CardMedia component="img" height="500" width="500" image={fileDiary || ImageDefault} alt="green iguana" />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: 10,
                                marginTop: 10
                            }}
                        >
                            <div>
                                <Button
                                    onClick={actionOpenAlbum}
                                    color="primary"
                                    variant="contained"
                                    startIcon={<PhotoAlbumIcon />}
                                    style={{ marginRight: 5, borderBlockColor: 'black' }}
                                >
                                    Chọn từ Album ảnh
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="mr-2"
                                    style={{ marginRight: 5, borderBlockColor: 'black' }}
                                    onClick={onClickFolder}
                                    startIcon={<FileUploadIcon />}
                                >
                                    Chọn từ bộ nhớ
                                </Button>
                            </div>
                            {/* <Button
                                disabled={!dataFile}
                                color="primary"
                                variant="contained"
                                className="mr-2"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={uploadFileDiary}
                                startIcon={<SaveIcon />}
                            >
                            Tải ảnh lên
                            </Button> */}
                            <Button
                                disabled={!dataFile}
                                color="primary"
                                variant="contained"
                                className="mr-2"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={copyPathImage}
                                startIcon={<CopyAllIcon />}
                            >
                                Chèn vào bài viết
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Modal>
            {/* <ModalImage
                showModal={openModalImage}
                imageFile={dataImageCrop}
                typeModalImage={typeModalImage}
                onCloseModal={handleCloseModalImage}
                onUpdateImage={onUpdateImage}
            /> */}
            <ModalImageAlbum open={showAlbum} onCloseModal={() => setShowAlbum(false)} onSlelect={onChooseImage} />
        </>
    );
};

export const modalPhoto = create(MyModalPhoto);
