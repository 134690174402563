import moment from 'moment';

const validateEmail = (email) => {
    // RFC 5322 email format
    // https://emailregex.com/
    const EMAIL_REGEX =
        /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EMAIL_REGEX.test(email);
};

const validatePassWord = (password) => {
    password = password?.trim();
    return password?.length >= 6 && password?.length <= 20;
};

const validateUserName = (username) => {
    const UserNameRegex = {
        expect: /[ ]/
    };
    return !UserNameRegex.expect.test(username) && username.length >= 4;
};
const validateImage = (image) => {
    const ImageRegex = /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/;
    return ImageRegex.test(image);
};

const validatePhone = (phone) => {
    // const re = /^\+?([0-9]{1,5})\)?[-. ]?([0-9]{1,5})[-. ]?([0-9]{1,5})$/;
    // return re.test(phone);
    const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phone.match(phoneno)) {
        return true;
    }
    return false;
};

const isInt = (value) => {
    const er = /^-?[0-9]+$/;
    return er.test(value);
};

// const isLetter = (str) => str?.length === 1 && str.match(/^[A-Za-z]+$/);

const convertViToEn = (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, ' ');
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\\=|\\<|\\>|\?|\/|,|\.|\\:|\\;|\\'|\\"|\\&|\\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');

    // chữ thủy không đọc được.
    return str;
};

const isLetter = (str) => {
    const letters = /^[A-Za-z]+$/;
    const data = convertViToEn(str);
    if (data.replace(/\s/g, '').match(letters)) {
        return true;
    }
    return false;
};

const getExtension = (filename) => {
    const parts = filename.split('.');
    return parts[parts.length - 1].toLowerCase();
};

const getIconFileStyle = (filename) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
        case 'pdf':
            return 'fa fa-file-pdf-o fa-lg';
        case 'jpg':
        case 'gif':
        case 'bmp':
        case 'png':
            return 'fa fa-file-picture-o fa-lg';
        case 'm4v':
        case 'avi':
        case 'mpg':
        case 'mp4':
            return 'fa fa-film fa-lg';
        case 'xlsx':
        case 'xls':
        case 'xltx':
        case 'xml':
        case 'xlt':
        case 'xlw':
            return 'fa fa-file-excel-o fa-lg';
        case 'doc':
        case 'docx':
        case 'dot':
        case 'odt':
        case 'txt':
        case 'dotm':
        case 'dotx':
            return 'fa fa-file-word-o fa-lg';
        case 'mp3':
        case 'm4a':
        case 'm4b':
        case 'wma':
        case 'msv':
            return 'fa fa-file-sound-o fa-lg';
        case 'zip':
        case 'rar':
        case 'raw':
            return 'fa fa-file-zip-o fa-lg';
        default:
            break;
    }
    return 'fa fa-file-o fa-lg';
};

const validateDateInput = (date) => {
    const DATE_REGEX = /^-?[0-9]|[-/]+$/;
    return DATE_REGEX.test(date);
};

const checkMaxLength = (data, max = 0) => (data?.length ?? 0) >= max;

const isEmpty = (data) => {
    if (typeof data === 'string') {
        data = data.trim();
    }
    if (data?.length <= 0) {
        return true;
    }
    switch (data) {
        case []:
        case ['']:
        case '':
        case 0:
        case '0':
        case null:
        case false:
            return true;
        default:
            return typeof data === 'undefined';
    }
};
const isEmptySpace = (data) => {
    if (data?.length <= 0) {
        return true;
    }
    switch (data) {
        case []:
        case ['']:
        case '':
        case 0:
        case '0':
        case null:
        case false:
            return true;
        default:
            return typeof data === 'undefined';
    }
};
const validateIdentifyCode = (code) => {
    const format = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/;
    const regex = /^[a-zA-Z0-9@._-]+$/g;
    return !format.test(code) && regex;
};

const isDateTime = (value) => {
    const dateWrapper = new Date(value);
    return !Number.isNaN(dateWrapper.getDate());
};

const shrinkString = (originStr, maxChars, trailingCharCount) => {
    let shrinkStr = originStr;
    const shrinkLength = maxChars - trailingCharCount - 3;
    console.log('shrinkLength ', shrinkLength);
    if (originStr.length > shrinkLength) {
        const front = originStr.substr(0, shrinkLength);
        const mid = '...';
        const end = originStr.substr(-trailingCharCount);
        shrinkStr = front + mid + end;
    }
    return shrinkStr;
};

const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

const checkVidateStr = (str) => {
    const format = /[@!#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]+/;

    if (format.test(str)) {
        return true;
    }
    return false;
};

const checkDisabled = (date) => {
    const today = new Date();
    const newDate = new Date(date);
    if (today.getTime() > newDate.getTime()) {
        return true;
    }
    return false;
};

const comapareDate = (dateBirth, dateDead) => {
    const date1 = new Date(dateBirth);
    const date2 = new Date(dateDead);
    console.log('dateBirth', dateBirth);
    console.log('dateDead', dateDead);
    if (date1.getTime() > date2.getTime()) {
        return true;
    }
    return false;
};

// eslint-disable-next-line no-restricted-globals
// const checkDate = (date) => date instanceof Date && !isNaN(date.valueOf());

const checkDate = (year, month, day) => {
    const date = [year, month, day].filter((i) => i).join('-');
    const m = moment(date, 'YYYY-MM-DD', true);
    return m.isValid();
};

const stringToDate = (date) => {
    if (date !== '') {
        const dataSplit = date.split('/');
        let dateConverted;
        if (dataSplit[2].split(' ').length > 1) {
            const hora = dataSplit[2].split(' ')[1].split(':');
            dataSplit[2] = dataSplit[2].split(' ')[0];
            dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0], hora[0], hora[1]);
        } else {
            dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
        }
        return dateConverted;
    }
    return false;
};

const isUrlValid = (str) => {
    // const pattern = new RegExp(
    //     '^([a-zA-Z]+:\\/\\/)?' + // protocol
    //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    //     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    //     '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    //     '(\\#[-a-z\\d_]*)?$', // fragment locator
    //     'i'
    // );
    const pattern = new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/);
    return pattern.test(str);
};

export {
    validateEmail,
    validateUserName,
    validatePassWord,
    validateImage,
    convertViToEn,
    validatePhone,
    isInt,
    isLetter,
    getIconFileStyle,
    validateDateInput,
    isEmpty,
    checkMaxLength,
    validateIdentifyCode,
    isDateTime,
    shrinkString,
    isEmptyObject,
    checkVidateStr,
    getExtension,
    checkDisabled,
    isEmptySpace,
    comapareDate,
    checkDate,
    stringToDate,
    isUrlValid
};
