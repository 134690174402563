import * as roleAlias from './roleAlias';

export const DF_ROLE_SCREEN = [
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.GENEALOGY,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.TREE,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.INFO_FAMILY,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.LISTPATRIACRCH,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: true,
        menuAlias: roleAlias.STATISTICAL,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.HISTORY_FAMILY,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.GRAVE,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.DIARY,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.ALBUM,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.DOCUMENT,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: true,
        menuAlias: roleAlias.LIST_MEMBER,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: true,
        menuAlias: roleAlias.EVENT,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.MASTER,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.ACCOUNT,
        roleAlias: roleAlias.ROOT
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.ACCOUNT,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.MASTER,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: true,
        menuAlias: roleAlias.EVENT,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: true,
        menuAlias: roleAlias.LIST_MEMBER,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.DOCUMENT,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.ALBUM,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.DIARY,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.GRAVE,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.HISTORY_FAMILY,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: true,
        menuAlias: roleAlias.STATISTICAL,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.LISTPATRIACRCH,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.INFO_FAMILY,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.TREE,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.GENEALOGY,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.GENEALOGY,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.TREE,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.INFO_FAMILY,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.LISTPATRIACRCH,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.STATISTICAL,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.HISTORY_FAMILY,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.GRAVE,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.DIARY,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.ALBUM,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.DOCUMENT,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.LIST_MEMBER,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.EVENT,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.MASTER,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.ACCOUNT,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.SETTING_HOMEPAGE,
        roleAlias: roleAlias.VIEWER
    },
    {
        [roleAlias.CREATE]: false,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: false,
        [roleAlias.DELETE]: false,
        [roleAlias.EXPORT]: false,
        menuAlias: roleAlias.SETTING_HOMEPAGE,
        roleAlias: roleAlias.EDITOR
    },
    {
        [roleAlias.CREATE]: true,
        [roleAlias.READ]: true,
        [roleAlias.UPDATE]: true,
        [roleAlias.DELETE]: true,
        [roleAlias.EXPORT]: true,
        menuAlias: roleAlias.SETTING_HOMEPAGE,
        roleAlias: roleAlias.ROOT
    }
];
