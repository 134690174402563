import { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    IconButton,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Divider,
    Button,
    Grid
} from '@mui/material';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NotFound from 'ui-component/404/404';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalAddWorkByAuthor from '../../authorList/modal/ModalAddWorkByAuthor';
import ModalPreview from '../modal/ModalPreview';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95vw' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: { md: 2, xs: 1 }
};

const styleBox = {
    maxHeight: { xs: '75vh', sm: '87vh' },
    overflowY: 'auto'
};
const columns = [
    { id: 'stt', label: 'STT', align: 'center', rowAlign: 'leff', color: 'black', xsDisplay: 'none' },
    {
        id: 'name_work',
        label: 'Tên tác phẩm',
        // minWidth: { md: 200, xs: 150 },
        align: 'center',
        rowAlign: 'left',
        color: 'black',
        sx: { position: 'sticky', left: '0px', zIndex: '99', borderRight: { md: 'none', xs: '1px solid white' } }
    },
    { id: 'role', label: 'Vai trò tác giả', align: 'center', rowAlign: 'left', color: 'black' },
    { id: 'time', label: 'Thời điểm tham gia', align: 'center', rowAlign: 'left', color: 'black' },
    {
        id: 'type',
        label: 'Thể loại',
        // minWidth: { md: 200, xs: 150 },
        align: 'center',
        rowAlign: 'left',
        color: 'black'
    },
    {
        id: 'year',
        label: 'Năm sáng tác ',
        // minWidth: { md: 200, xs: 150 },
        align: 'center',
        rowAlign: 'left',
        color: 'black'
    }
];
const ModalListWorks = ({ show, idAuthor, handleClose, dataInfoAuthor, roles }) => {
    const theme = useTheme();
    const [dataAuthor, setDataAuthor] = useState([]);
    const [nameAuthor, setNameAuthor] = useState('');
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const dataAthourRole = localStorage.getItem('m_athour_role') ? JSON.parse(localStorage.getItem('m_athour_role')) : [];
    const dataGenre = localStorage.getItem('m_genre') ? JSON.parse(localStorage.getItem('m_genre')) : [];
    const [dataWork, setDataWork] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalPreview, setOpenModalPreview] = useState(false);

    const getDataInfo = async () => {
        let urlApi = router.SUB_URL.info_author;
        const obj = {
            id: idAuthor
        };
        const dataRst = await fetchClient.postRequest(urlApi, obj);
        console.log('data', dataRst);
        setNameAuthor(dataRst?.data[0]?.name_author);
        setDataAuthor(dataRst?.data[0]?.list_work ?? []);
    };
    useEffect(() => {
        if (show === true && idAuthor) {
            getDataInfo();
        }
    }, [show, idAuthor]);

    const handleCloseModalAuthor = () => {
        setDataAuthor([]);
        setNameAuthor('');
        setDataWork(null);
        handleClose();
    };

    const findRole = (data) => {
        let nameRole = '';
        if (data.length > 0) {
            let rst = '';
            data.map((item) => {
                if (item.id_author === idAuthor) {
                    rst = item.role;
                }
            });
            if (rst) {
                dataAthourRole.map((element) => {
                    if (element.athour_id === rst) {
                        nameRole = element.athour_name;
                    }
                });
            }
        }
        return nameRole;
    };

    const findNameRole = (value) => {
        const rst = dataAthourRole
            .filter((r) => {
                return r?.athour_id === value;
            })
            .map((m) => {
                return m?.athour_name;
            });

        return rst;
    };

    const findTimeWrite = (data) => {
        let timeWrite = '';
        if (data.length > 0) {
            data.map((item) => {
                if (item.id_author === idAuthor) {
                    timeWrite = item.time_write;
                }
            });
        }
        return timeWrite;
    };

    const findName = (value) => {
        const rst = dataGenre
            ?.filter((r) => {
                return r?.config_value.genre_id === value;
            })
            .map((m) => {
                return m?.config_value.genre_name;
            });
        return rst;
    };

    const handleClick = (item) => {
        setDataWork(item);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const onClickInfo = () => {
        setOpenModal(true);
    };

    const OnReviewContent = () => {
        setOpenModalPreview(true);
    };

    console.log('dataAuthor', dataAuthor);
    return (
        <>
            <Modal open={show}>
                <Box sx={style}>
                    <Typography
                        // id="modal-modal-title"
                        variant="h3"
                        component="h2"
                        sx={{ maxWidth: '85%', marginTop: { xs: '10px', md: '0px' } }}
                    >
                        Danh sách tác phẩm của tác giả {nameAuthor}
                    </Typography>
                    <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                    <Box
                        sx={{
                            ...styleBox,
                            '&::-webkit-scrollbar': {
                                width: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: customization?.themeDongHo?.heading,
                                outline: 'slategrey'
                            }
                        }}
                    >
                        <Box position="absolute" top={0} right={0}>
                            <IconButton onClick={() => handleCloseModalAuthor()}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 10 }}>
                        {roles?.read && (
                            <>
                                <Button
                                    disabled={dataWork ? false : true}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => OnReviewContent()}
                                    sx={{ marginRight: '5px', borderBlockColor: 'black', marginTop: { md: '0px', xs: '5px' } }}
                                    startIcon={<VisibilityIcon />}
                                >
                                    Xem nội dung tác phẩm
                                </Button>
                                <Button
                                    disabled={dataWork ? false : true}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => onClickInfo()}
                                    sx={{ marginRight: '5px', borderBlockColor: 'black', marginTop: { md: '0px', xs: '5px' } }}
                                    startIcon={<VisibilityIcon />}
                                >
                                    Xem thông tin
                                </Button>
                            </>
                        )}
                    </div>
                    <Grid
                        container
                        spacing={1}
                        // ref={listRef}
                        sx={{
                            backgroundColor: 'none',
                            paddingTop: '5px',
                            overflow: 'auto',
                            marginTop: { xs: '0px', md: '0px' },
                            maxHeight: {
                                // xs:
                                //     mobileFilterList.filter((item) => item.condition === true).length != 0
                                //         ? 'calc(100dvh - 280px)'
                                //         : 'calc(100dvh - 210px)',
                                sm: 'calc(100dvh - 270px)',
                                md: 'calc(100dvh - 250px)'
                            },
                            // width: '100%',
                            marginLeft: 0,
                            '&::-webkit-scrollbar': {
                                width: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: customization?.themeDongHo?.heading,
                                outline: 'slategrey'
                            }
                        }}
                    >
                        <Paper id="list" sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer
                                sx={{
                                    maxHeight: { md: '440', xs: 'auto' },
                                    position: 'relative',
                                    overflowX: { xs: 'scroll', md: 'hidden' },
                                    '&::-webkit-scrollbar': {
                                        width: { md: '0.4em', xs: '3px' },
                                        height: { md: '5px', xs: '3px' }
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: customization?.themeDongHo?.heading,
                                        outline: 'slategrey'
                                    }
                                }}
                            >
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: '#90caf9' }}>
                                            {columns.map(
                                                (column) =>
                                                    ((!isMobile && column.mdDisplay !== 'none') ||
                                                        (isMobile && column.xsDisplay !== 'none')) && (
                                                        <TableCell
                                                            variant="head"
                                                            key={column.id}
                                                            align={column.align}
                                                            sx={{
                                                                minWidth: column.width,
                                                                color: column.color,
                                                                backgroundColor: '#90caf9',

                                                                ...column.sx
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    )
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataAuthor.length > 0 ? (
                                            <>
                                                {dataAuthor.map((row, index) => (
                                                    <TableRow
                                                        id={`${row.idMember}`}
                                                        hover
                                                        role="checkbox"
                                                        key={row.stt}
                                                        sx={{
                                                            cursor: 'pointer',
                                                            '&&.Mui-selected': {
                                                                backgroundColor: '#b3c7e8'
                                                            },
                                                            ...(!isMobile && {
                                                                '&.MuiTableRow-hover:hover': {
                                                                    backgroundColor: 'rgba(0, 0, 255, 0.3)'
                                                                }
                                                            })
                                                        }}
                                                        selected={dataWork?._id === row?._id}
                                                        onClick={() => handleClick(row)}
                                                    >
                                                        {!isMobile && (
                                                            <TableCell
                                                                style={{
                                                                    height: 'auto !important',
                                                                    position: { md: 'relative', xs: 'sticky' },
                                                                    left: { md: '0px', xs: '45px' },
                                                                    zIndex: '9',
                                                                    borderRight: { md: 'none', xs: '1px solid white' }
                                                                }}
                                                                align="center"
                                                            >
                                                                {index + 1}
                                                            </TableCell>
                                                        )}
                                                        <TableCell
                                                            sx={{
                                                                height: 'auto !important',
                                                                wordBreak: 'break-all',
                                                                position: { md: 'relative', xs: 'sticky' },
                                                                left: '0px',
                                                                zIndex: '999999'
                                                            }}
                                                            align="left"
                                                        >
                                                            {row?.name_work}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="left">
                                                            {/* {findRole(row?.authors ?? [])} */}
                                                            {row?.authors?.filter((author) => author.id_author === idAuthor).length > 0 &&
                                                                row?.authors
                                                                    ?.filter((author) => author.id_author === idAuthor)
                                                                    .map((author) =>
                                                                        Array.isArray(author.role) ? (
                                                                            author?.role?.map((role) => (
                                                                                <Typography variant="span" sx={{ display: 'block' }}>
                                                                                    {findNameRole(role ?? [])}
                                                                                </Typography>
                                                                            ))
                                                                        ) : (
                                                                            <Typography variant="span">
                                                                                {findNameRole(author?.role)}
                                                                            </Typography>
                                                                        )
                                                                    )}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="left">
                                                            {findTimeWrite(row?.authors ?? [])}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="left">
                                                            {row?.type.flatMap((m) => findName(m)).join(', ')}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="left">
                                                            {row?.publishing_year}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        ) : (
                                            <TableRow>
                                                <TableCell sx={{ display: 'table-cell', textAlign: 'center' }} colSpan={12}>
                                                    <NotFound />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Box>
            </Modal>
            <ModalAddWorkByAuthor
                open={openModal}
                handleClose={handleCloseModal}
                typeModal={null}
                dataAuthor={dataInfoAuthor}
                onReload={() => {}}
                idAuthor={idAuthor}
                dataInfoWork={dataWork}
                typeView={'VIEW'}
            />
            <ModalPreview isOpen={openModalPreview} onClose={() => setOpenModalPreview(false)} dataWork={dataWork} />
        </>
    );
};

export default ModalListWorks;
