import { useState, useEffect, useRef } from 'react';
import {
    Modal,
    Box,
    Typography,
    CardMedia,
    FormControl,
    Button,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Chip,
    IconButton,
    Tooltip,
    Autocomplete,
    FormControlLabel,
    Switch
} from '@mui/material';

import Loading from 'ui-component/Loading';
import DialogUtil from 'ui-component/show_confirm_action';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import book from '../../../../../assets/images/users/book.png';
import upload from '../../../../../assets/images/users/upload.png';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import router from 'routes/router';
import config from '../../../../../config';
import fetchClient from 'views/utilities/RestfulAPI';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { getRoleButton } from 'utils/roleUility';
import ConfigRouter from 'routes/config_router';
import { useNavigate } from 'react-router';
import { ConvertName } from '../../../../../utils/helper';
import { useSelector } from 'react-redux';
import MainCardIcon from 'ui-component/cards/MainCardIcon';
import ModalDiary from '../../workList/modal/ModalDiary';
import ModalDocument from '../../workList/modal/ModalDocument';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { isUrlValid } from 'utils/validate_checking';
import InfoIcon from '@mui/icons-material/Info';
import ModalPreview from '../../workList/modal/ModalPreview';
import { isMobile } from 'react-device-detect';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95vw' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: { md: 2, xs: 1 }
};

const styleBox = {
    maxHeight: { xs: '75vh', sm: '87vh' },
    overflowY: 'auto'
};
const VIEW = 'view';
const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 90
        }
    }
};

const initial = {
    inherit: false,
    error: ''
};

const imageMimeType = /image\/(png|jpg|jpeg|PNG|JPG|JPEG)/i;

const role = {
    create: true,
    update: true,
    delete: true
};

function getStyles(name, typeName, theme) {
    return {
        fontWeight: typeName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    };
}

const ModalInfoWork = ({ open, handleClose, typeModal, dataWork, onReload, typeMode }) => {
    const theme = useTheme();
    const inputRef = useRef(null);
    const customization = useSelector((state) => state.customization);
    const [isLoading, setIsLoading] = useState(false);
    const [nameWork, setNameWork] = useState('');
    const [storageLocation, setStorageLocation] = useState('');
    const [language, setLanguage] = useState('');
    const [type, setType] = useState('');
    const [year, setYear] = useState('');
    const [topic, setTopic] = useState('');
    const [content, setContent] = useState('');
    const [note, setNote] = useState('');
    const [award, setAward] = useState('');
    const [fileWork, setFileWork] = useState(null);

    const [selectedValues, setSelectedValues] = useState([]);
    const [validateWork, setValidateWork] = useState(initial);
    const [validateStorageLocation, setValidateStorageLocation] = useState(initial);
    const [validateLanguage, setValidateLanguage] = useState(initial);
    const [validateType, setValidateType] = useState(initial);
    const [validateYear, setValidateYear] = useState(initial);
    const [validateTopic, setValidateTopic] = useState(initial);
    const [validateContent, setValidateContent] = useState(initial);
    const [validateNote, setValidateNote] = useState(initial);
    const [validateAward, setValidateAward] = useState(initial);

    const [fileUpload, setFileUpload] = useState([]);
    const [idDiary, setIdDiary] = useState('');
    const [idDocument, setIdDocument] = useState('');
    const [pathFile, setPathFile] = useState([]);
    const [validateFileUpload, setValidateFileUpload] = useState(initial);
    const [urlLink, setUrlLink] = useState('');
    const [validateUrlLink, setValidateUrlLink] = useState(initial);

    const [typeName, setTypeName] = useState([]);

    const [openDiary, setOpenDiary] = useState(false);
    const [openDocument, setOpenDocument] = useState(false);
    const [dataFiles, setDataFiles] = useState([]);

    const [showSavePath, setShowSavePath] = useState(false);
    const [showWork, setShowWork] = useState(true);
    const [isChangeInfo, setIsChangeInfo] = useState(false);
    const navigate = useNavigate();
    const [hasNewFile, setHasNewFile] = useState(false);
    const [openModalPreview, setOpenModalPreview] = useState(false);

    const [publicWork, setPublicWork] = useState('');

    // const [dataInfoWorks, setDataInfoWorks] = useState([]);

    const dataType = localStorage.getItem('m_genre') ? JSON.parse(localStorage.getItem('m_genre')) : [];
    const dataLanguage = localStorage.getItem('m_language') ? JSON.parse(localStorage.getItem('m_language')) : [];

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setTypeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );

        if (value.length > 0) {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    const handleChangeLanguage = (event) => {
        const {
            target: { value }
        } = event;
        setLanguage(value);
        if (value !== '') {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    const openModalDiary = () => {
        setOpenDiary(true);
    };

    const openModalDocument = () => {
        setOpenDocument(true);
    };

    const handleCloseModalWork = () => {
        setNameWork('');
        setStorageLocation('');
        setLanguage('');
        setType('');
        setYear('');
        setTopic('');
        setContent('');
        setNote('');
        setAward('');
        setIdDiary('');
        setIdDocument('');
        setPathFile('');
        setUrlLink('');
        setTypeName([]);
        setIsChangeInfo(false);
        setHasNewFile(false);
        setFileUpload([]);
        setDataFiles([]);
        // setAuthor('');
        // setListAuthor([]);
        setValidateWork(initial);
        setValidateFileUpload(initial);
        setValidateStorageLocation(initial);
        setValidateLanguage(initial);
        setValidateType(initial);
        setValidateYear(initial);
        setValidateTopic(initial);
        setValidateContent(initial);
        setValidateNote(initial);
        setValidateAward(initial);
        setValidateUrlLink(initial);

        setShowWork(true);
        setShowSavePath(false);

        handleClose();
    };

    const onCloseModalWork = () => {
        if (isChangeInfo) {
            DialogUtil.showConfirm(
                'Thông báo',
                `Thông tin tác phẩm chưa được lưu, bạn có muốn thoát không?`,
                () => {},
                () => {
                    handleCloseModalWork();
                }
            );
        } else {
            handleCloseModalWork();
        }
    };

    const findName = (value) => {
        const rst = dataType
            .filter((r) => {
                return r.config_value.genre_id === value;
            })
            .map((m) => {
                return m.config_value.genre_name;
            });
        return rst;
    };

    const handleUploadFile = () => {
        inputRef.current.click();
    };

    const onPathChange = (event) => {
        const newFiles = Array.from(event.target.files);
        if (newFiles.length === 0) {
            return;
        }
        // const existingFileNames = dataFiles.map((file) => file.name);
        // const uniqueNewFiles = newFiles.filter((file) => !existingFileNames.includes(file.name));
        // const allFiles = [...dataFiles, ...uniqueNewFiles];
        // setDataFiles(allFiles);
        setHasNewFile(true);
        // let validFiles = [];
        // let invalidFiles = [];
        // let fileNames = [];

        // for (let i = 0; i < allFiles.length; i++) {
        //     if (allFiles[i].size > config.maxUploadDocs) {
        //         invalidFiles.push(allFiles[i]);
        //     } else {
        //         validFiles.push(allFiles[i]);
        //         fileNames.push(allFiles[i].name);
        //     }
        // }
        if (event.target.files && event.target.files[0]) {
            const newDataImages = [...fileUpload];
            const dataFile = [...dataFiles];
            const files = Array.from(event.target.files).slice(0, 20);
            files.forEach((file) => {
                file.eff = true;
                // if (file.type.match(imageMimeType)) {
                newDataImages.push(file);
                dataFile.push(file);
                // }
            });
            setFileUpload(newDataImages);
            setDataFiles(dataFile);
        }
        // if (invalidFiles.length > 0) {
        //     setFileUpload([]);
        //     setValidateFileUpload({
        //         invalid: true,
        //         errors: `Tài liệu không được quá ${config.maxUploadDocs / 1000000} MB`
        //     });
        //     setIsChangeInfo(true);
        // } else {
        //     setFileUpload(validFiles);
        //     setPathFile(fileNames.join(', '));
        //     setValidateFileUpload(initial);
        //     setIsChangeInfo(false);
        // }
        event.target.value = null;
    };

    const handleCloseModalDocument = () => {
        setOpenDocument(false);
    };
    const handleCloseModalDiary = () => {
        setOpenDiary(false);
    };

    const onChooseDiary = (data) => {
        setIdDiary(data);
        setIsChangeInfo(true);
    };

    const onChooseDocument = (data) => {
        setIdDocument(data);
        setIsChangeInfo(true);
    };

    const handleChangeInput = (e) => {
        const name = e.target?.name;
        const value = e.target?.value;
        switch (name) {
            case 'nameWork':
                if (value.trim() === '') {
                    setNameWork(value);
                    setValidateWork({
                        invalid: true,
                        errors: 'Tên tác phẩm không được bỏ trống'
                    });
                    return;
                }
                if (value.length > 100) {
                    setValidateWork({
                        invalid: true,
                        errors: 'Tên tác phẩm không được vượt quá 100 ký tự'
                    });
                    return;
                }
                setNameWork(value);
                setValidateWork(initial);
                break;
            case 'storageLocation':
                if (value.length > 500) {
                    setValidateStorageLocation({
                        invalid: true,
                        errors: 'Nơi lưu trữ không được vượt quá 500 ký tự'
                    });
                    return;
                }

                setStorageLocation(value);
                setValidateStorageLocation(initial);
                break;
            case 'language':
                if (value.length > 100) {
                    setValidateLanguage({
                        invalid: true,
                        errors: 'Ngôn ngữ không được vượt quá 100 ký tự'
                    });
                    return;
                }
                setLanguage(value);
                setValidateLanguage(initial);
                break;
            case 'year':
                setYear(value);
                break;
            case 'topic':
                if (value.length > 100) {
                    setValidateTopic({
                        invalid: true,
                        errors: 'Chủ đề không được vượt quá 100 ký tự'
                    });
                    return;
                }
                setTopic(value);
                setValidateTopic(initial);
                break;
            case 'content':
                if (value.length > 500) {
                    setValidateContent({
                        invalid: true,
                        errors: 'Tóm tắt không được vượt quá 500 ký tự'
                    });
                    return;
                }

                setContent(value);
                setValidateContent(initial);
                break;
            case 'note':
                if (value.length > 500) {
                    setValidateNote({
                        invalid: true,
                        errors: 'Ghi chú không được vượt quá 500 ký tự'
                    });

                    return;
                }
                setNote(value);
                setValidateNote(initial);
                break;
            case 'award':
                if (value.length > 500) {
                    setValidateAward({
                        invalid: true,
                        errors: 'Thành tựu không được vượt quá 500 ký tự'
                    });
                    return;
                }
                setAward(value);
                setValidateAward(initial);
                break;
            case 'urlLink':
                setUrlLink(value);
                setValidateUrlLink(initial);
                break;
            default:
                break;
        }

        if (value !== '') {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    // const urlToBlob = async (url) => {
    //     const response = await fetch(url);
    //     const blob = await response.blob();
    //     return blob;
    // };

    // // This function appends a file to a FormData object
    // const appendFileToFormData = async (formData, fileUrl, fileName) => {
    //     const blob = await urlToBlob(fileUrl);
    //     formData.append('file', blob, fileName);
    // };

    const onSaveWork = async () => {
        const dataWork = {
            name_work: nameWork,
            language: language,
            publishing_year: year,
            topic: topic,
            storageLocation: storageLocation,
            type: typeName,
            content: content,
            award: award,
            note: note,
            is_public: publicWork,
            document: {
                id_document: idDocument?._id ?? null,
                name_document: idDocument?.name_document ?? null,
                url_document: idDocument?.url_document ?? null
            },
            diary: {
                id_diary: idDiary?._id ?? null,
                title: idDiary?.title ?? null
            },
            link: urlLink
        };
        let urlApi = router.SUB_URL.create_work;
        const formData = new FormData();
        for (let i = 0; i < dataFiles.length; i++) {
            formData.append('file_work', dataFiles[i], dataFiles[i].name);
        }
        formData.append('dataWork', JSON.stringify(dataWork));

        const dataReturn = await fetchClient.sendRequest(urlApi, 'post', formData);
        if (dataReturn.status === 201) {
            DialogUtil.showQuestions(true, `Thêm mới tác phẩm thành công`, () => {
                handleCloseModalWork();
                onReload();
            });
        } else {
            if (dataReturn.statusCode === 406) {
                DialogUtil.showQuestions(false, dataReturn.message);
            } else {
                DialogUtil.showQuestions(false, 'Thêm mới tác phẩm thất bại');
            }
        }
    };

    const handleSaveWork = async () => {
        if (nameWork.trim() === '') {
            setValidateWork({
                invalid: true,
                errors: 'Tên tác phẩm không được bỏ trống'
            });
            return;
        }

        if (urlLink !== '' && !isUrlValid(urlLink)) {
            setValidateUrlLink({
                invalid: true,
                errors: 'Đường dẫn không đúng định dạng vui lòng kiểm tra lại'
            });
            return;
        }

        if (dataFiles.length > 20) {
            setValidateFileUpload({
                invalid: true,
                errors: 'Số lượng file upload không được vượt quá 20 file'
            });
            return;
        }

        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn thêm mới tác phẩm không?`,
            () => {},
            () => {
                onSaveWork();
            }
        );
    };

    const handleUpdateWork = () => {
        if (nameWork.trim() === '') {
            setValidateWork({
                invalid: true,
                errors: 'Tên tác phẩm không được bỏ trống'
            });
            return;
        }

        if (urlLink !== '' && !isUrlValid(urlLink)) {
            setValidateUrlLink({
                invalid: true,
                errors: 'Đường dẫn không đúng định dạng vui lòng kiểm tra lại'
            });
            return;
        }

        if (dataFiles.length > 20) {
            setValidateFileUpload({
                invalid: true,
                errors: 'Số lượng file upload không được vượt quá 20 file'
            });
            return;
        }

        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn cập nhập tác phẩm ${dataWork.name_work} không?`,
            () => {},
            () => {
                onUpdateWork();
            }
        );
    };

    const onUpdateWork = async () => {
        const dataWorks = {
            name_work: nameWork,
            language: language,
            publishing_year: year,
            topic: topic,
            storageLocation: storageLocation,
            type: typeName,
            content: content,
            is_public: publicWork,
            award: award,
            note: note,
            document: {
                id_document: idDocument?._id ? idDocument._id : idDocument.id_document,
                name_document: idDocument?.name_document ?? null,
                url_document: idDocument?.url_document ?? null
            },
            diary: {
                id_diary: idDiary?._id ? idDiary._id : idDiary.id_diary,
                title: idDiary?.title ?? null
            },
            link: urlLink,
            id: dataWork._id
        };
        let urlApi = router.SUB_URL.update_work;
        const formData = new FormData();
        if (hasNewFile && dataFiles.length > 0) {
            for (let i = 0; i < dataFiles.length; i++) {
                formData.append('file_work', dataFiles[i], dataFiles[i].name);
            }
        }

        formData.append('dataWork', JSON.stringify(dataWorks));

        const dataReturn = await fetchClient.sendRequest(urlApi, 'post', formData);
        if (dataReturn.status === 201) {
            DialogUtil.showQuestions(true, `Cập nhập tác phẩm thành công`, () => {
                handleCloseModalWork();
                onReload();
            });
        } else {
            if (dataReturn.statusCode === 406) {
                DialogUtil.showQuestionsTwoAction(
                    role?.roleAlias,
                    false,
                    dataReturn?.message,
                    'Nâng cấp ngay',
                    'Đóng',
                    () => {
                        navigate(ConfigRouter.listService);
                    },
                    () => {}
                );
                DialogUtil.showQuestions(false, dataReturn.message);
            } else {
                DialogUtil.showQuestions(false, 'Cập nhập tác phẩm thất bại');
            }
        }
    };

    const arrayToDataTransfer = (array) => {
        const dataTransfer = new DataTransfer();
        array.forEach((file) => {
            dataTransfer.items.add(file);
        });
        return dataTransfer.files;
    };

    const getDataWorkInfo = async () => {
        setNameWork(dataWork?.name_work ?? '');
        setStorageLocation(dataWork?.storageLocation ?? '');
        setLanguage(dataWork?.language ?? '');
        setYear(dataWork?.publishing_year ?? '');
        setTopic(dataWork?.topic ?? '');
        setContent(dataWork?.content ?? '');
        setPublicWork(dataWork?.is_public);
        setNote(dataWork?.note ?? '');
        setUrlLink(dataWork?.link ?? '');
        setAward(dataWork?.award ?? '');
        setIdDiary(dataWork?.diary ?? '');
        setIdDocument(dataWork?.document ?? '');
        setPathFile(dataWork?.file_upload.length > 0 ? dataWork?.file_upload.flatMap((m) => m.nameFile).join(', ') : '');
        setTypeName(dataWork?.type ?? []);
        setFileUpload(dataWork?.file_upload.length > 0 ? dataWork?.file_upload?.filter((m) => m.delete_at === null) : []);
        setDataFiles([]);
    };
    const onShowWork = () => {
        setShowWork(true);
    };

    const onHideWork = () => {
        setShowWork(false);
    };

    const onShowSavePath = () => {
        setShowSavePath(true);
    };

    const onHideSavePath = () => {
        setShowSavePath(false);
    };

    useEffect(() => {
        if (open && dataWork) {
            getDataWorkInfo();
            onReloadDeleteFile();
        }
    }, [open, dataWork]);

    const checkType = () => {
        if (typeModal === 'THEM_TP') {
            setShowWork(true);
            setShowSavePath(false);
        } else {
            setShowWork(true);
            setShowSavePath(false);
        }
    };

    const openModalContentWork = (e) => {
        setOpenModalPreview(true);
    };

    const handleDeleteFile = (fileToDelete) => {
        if (fileToDelete.eff === true) {
            setFileUpload((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
            setDataFiles((prevFiles) => {
                const filesArray = Array.from(prevFiles);

                const newFilesArray = filesArray.filter((file) => file !== fileToDelete);

                const newFileList = new DataTransfer();
                newFilesArray.forEach((file) => newFileList.items.add(file));
                return newFileList.files;
            });
        } else {
            onDeleteFileWork(fileToDelete);
        }
        //setDataFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    };

    const onDeleteFileWork = (file) => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn xóa tệp ${file?.nameFile} không?`,
            () => {},
            () => onDeleteFile(file)
        );
    };

    const onDeleteFile = async (file) => {
        const obj = {
            idWork: dataWork?._id,
            idFile: file?._id
        };
        const result = await fetchClient.sendRequest(router.SUB_URL.delete_file, 'post', obj);
        if (result.status === 201) {
            DialogUtil.showQuestions(true, 'Xóa file thành công!', () => onReloadDeleteFile());
        } else {
            DialogUtil.showQuestions(false, 'Xóa file thất bại');
        }
    };

    const onReloadDeleteFile = async () => {
        const obj = {
            id: dataWork?._id
        };
        const result = await fetchClient.postRequest(router.SUB_URL.info_work, obj);
        console.log('result', result);
        if (result.status === 201) {
            setFileUpload(result.data.file_upload);
        }
    };

    useEffect(() => {
        checkType();
    }, [typeModal]);

    // console.log('setPathFile', pathFile);
    // console.log('fileUpload', fileUpload);
    // console.log('dataFiles', dataFiles);

    // const onDeleteFileWork = (file) => {
    //     DialogUtil.showConfirm(
    //         'Thông báo',
    //         `Bạn có muốn xóa tài liệu ${file.nameFile} không?`,
    //         () => {},

    //     );
    // }

    return (
        <>
            <Modal
                open={open}
                // onClose={() => handleCloseModal()}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginY: '15px',
                            padding: { md: '0px', xs: '10px' }
                        }}
                    >
                        <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ maxWidth: '60%' }}>
                            {/* {typeModal == 'THEM_TP' ? 'Thêm mới tác phẩm' : `Chỉnh sửa tác phẩm ${dataWork?.name_work}`} */}
                            {typeMode === VIEW
                                ? `Xem thông tin tác phẩm ${ConvertName(dataWork?.name_work, 50)}`
                                : dataWork?._id
                                ? `Chỉnh sửa tác phẩm ${ConvertName(dataWork?.name_work, 40)}`
                                : 'Thêm mới tác phẩm'}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' }, justifyContent: 'flex-end' }}>
                            {typeMode !== VIEW && (
                                <Button
                                    onClick={() => (typeModal == 'THEM_TP' ? handleSaveWork() : handleUpdateWork())}
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    sx={{ marginRight: '5px', borderBlockColor: 'black', marginTop: { md: '0px', xs: '5px' } }}
                                >
                                    Lưu
                                </Button>
                            )}
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<CloseIcon />}
                                className="mr-2"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => onCloseModalWork()}
                            >
                                Đóng
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            ...styleBox,
                            '&::-webkit-scrollbar': {
                                width: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: customization?.themeDongHo?.heading,
                                outline: 'slategrey'
                            }
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{ marginBottom: 2 }}>
                                    <MainCardIcon
                                        handleItemClick={showWork ? () => onHideWork() : () => onShowWork()}
                                        title="Thông tin tác phẩm"
                                        icon={book}
                                        showBackGround={true}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            sx={{ position: 'absolute', top: 10, right: 10 }}
                                            onClick={showWork ? onHideWork : onShowWork}
                                            startIcon={
                                                showWork ? <KeyboardArrowUpIcon size="large" /> : <KeyboardArrowDownIcon size="large" />
                                            }
                                        >
                                            {showWork ? 'Thu gọn' : 'Mở rộng'}
                                        </Button>
                                        {showWork && (
                                            <Grid container style={{ paddingLeft: 0 }}>
                                                <Grid md={12} sm={12} xs={12} item spacing={1}>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            sx={{ml: '10px'}}
                                                            value="start"
                                                            checked={publicWork}
                                                            onChange={(e)=>{setPublicWork(e.target.checked)}}
                                                            control={<Switch color="primary" />}
                                                            label="Công khai thông tin tác phẩm trên trang chủ dòng họ"
                                                            labelPlacement="start"
                                                        />
                                                    </Grid>
                                                    <Box sx={{ display: { md: 'flex', xs: 'block' } }}>
                                                        <Grid container spacing={{ md: 2, xs: 1 }}>
                                                            <Grid item md={8} xs={12}>
                                                                <TextField
                                                                    // //disabled={selectedWork === true}
                                                                    error={validateWork.invalid}
                                                                    margin="normal"
                                                                    label="Tên tác phẩm (*)"
                                                                    fullWidth
                                                                    value={nameWork}
                                                                    onChange={handleChangeInput}
                                                                    name="nameWork"
                                                                    helperText={validateWork.errors}
                                                                    sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                                />
                                                            </Grid>
                                                            <Grid item md={4} xs={12}>
                                                                <FormControl sx={{ width: '100%', margin: { md: '15px 0', xs: '0px' } }}>
                                                                    <InputLabel
                                                                        id="id_language"
                                                                        sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                                    >
                                                                        Ngôn ngữ
                                                                    </InputLabel>
                                                                    <Select
                                                                        // //disabled={selectedWork === true}
                                                                        labelId="id_language"
                                                                        id="id_language_select"
                                                                        value={language}
                                                                        onChange={handleChangeLanguage}
                                                                        sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                                        input={<OutlinedInput id="select-language-chip" label="Ngôn ngữ" />}
                                                                        MenuProps={MenuProps}
                                                                    >
                                                                        {dataLanguage?.length > 0 ? (
                                                                            dataLanguage.map((element, index) => (
                                                                                <MenuItem
                                                                                    // //disabled={selectedWork === true}
                                                                                    key={index.toString()}
                                                                                    value={element?.key}
                                                                                    // style={getStyles(
                                                                                    //     element?.config_value?.key,
                                                                                    //     typeName,
                                                                                    //     theme
                                                                                    // )}
                                                                                    sx={{ zIndex: 999999 }}
                                                                                >
                                                                                    {element?.name}
                                                                                </MenuItem>
                                                                            ))
                                                                        ) : (
                                                                            <MenuItem sx={{ pointerEvents: 'none' }}>
                                                                                Chưa có ngôn ngữ nào
                                                                            </MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box sx={{ display: { md: 'flex', xs: 'block' } }}>
                                                        <Grid container spacing={{ md: 2, xs: 1 }}>
                                                            <Grid item md={8} xs={12}>
                                                                <FormControl
                                                                    sx={{ width: '100%', margin: { md: '15px 0', xs: '15px 0px 0px' } }}
                                                                >
                                                                    <InputLabel
                                                                        id="demo-multiple-chip-label"
                                                                        sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                                        // sx={{
                                                                        //     WebkitTextFillColor: `${
                                                                        //         selectedWork === true ? 'rgba(0,0,0,0.38)' : 'none'
                                                                        //     }`
                                                                        // }}
                                                                    >
                                                                        Thể loại
                                                                    </InputLabel>
                                                                    <Select
                                                                        // //disabled={selectedWork === true}
                                                                        labelId="demo-multiple-chip-label"
                                                                        id="demo-multiple-chip"
                                                                        multiple
                                                                        value={typeName}
                                                                        onChange={handleChange}
                                                                        sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                                        input={<OutlinedInput id="select-multiple-chip" label="Thể loại" />}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                {selected.map((value) => {
                                                                                    if (findName(value).length !== 0) {
                                                                                        return <Chip key={value} label={findName(value)} />;
                                                                                    }
                                                                                    return null;
                                                                                })}
                                                                            </Box>
                                                                        )}
                                                                        MenuProps={MenuProps}
                                                                    >
                                                                        {dataType?.length > 0 ? (
                                                                            dataType.map((element, index) => (
                                                                                <MenuItem
                                                                                    // //disabled={selectedWork === true}
                                                                                    key={index.toString()}
                                                                                    value={element?.config_value?.genre_id}
                                                                                    style={getStyles(
                                                                                        element?.config_value?.genre_id,
                                                                                        typeName,
                                                                                        theme
                                                                                    )}
                                                                                    sx={{ zIndex: 999999 }}
                                                                                >
                                                                                    {element?.config_value?.genre_name}
                                                                                </MenuItem>
                                                                            ))
                                                                        ) : (
                                                                            <MenuItem sx={{ pointerEvents: 'none' }}>
                                                                                Chưa có thể loại nào
                                                                            </MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={4} xs={12}>
                                                                <TextField
                                                                    //disabled={selectedWork === true}
                                                                    sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                                    error={validateTopic.invalid}
                                                                    margin="normal"
                                                                    label="Chủ đề"
                                                                    fullWidth
                                                                    value={topic}
                                                                    onChange={handleChangeInput}
                                                                    name="topic"
                                                                    helperText={validateTopic.errors}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Grid item>
                                                        <TextField
                                                            // //disabled={selectedWork === true}
                                                            sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                            error={validateStorageLocation.invalid}
                                                            margin="normal"
                                                            label="Nơi lưu trữ"
                                                            // maxRows={7}
                                                            fullWidth
                                                            value={storageLocation}
                                                            onChange={handleChangeInput}
                                                            name="storageLocation"
                                                            helperText={validateStorageLocation.errors}
                                                        />
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <TextField
                                                            // //disabled={selectedWork === true}
                                                            sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                            error={validateYear.invalid}
                                                            margin="normal"
                                                            label="Thời gian phát hành"
                                                            multiline
                                                            rows={1}
                                                            fullWidth
                                                            value={year}
                                                            onChange={handleChangeInput}
                                                            name="year"
                                                            helperText={validateYear.errors}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            // //disabled={selectedWork === true}
                                                            sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                            error={validateContent.invalid}
                                                            margin="normal"
                                                            label="Tóm tắt/Giới thiệu sơ lược"
                                                            multiline
                                                            rows={3}
                                                            // maxRows={7}
                                                            fullWidth
                                                            value={content}
                                                            onChange={handleChangeInput}
                                                            name="content"
                                                            helperText={validateContent.errors}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            // //disabled={selectedWork === true}
                                                            sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                            error={validateAward.invalid}
                                                            margin="normal"
                                                            label="Thành tựu"
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            value={award}
                                                            onChange={handleChangeInput}
                                                            name="award"
                                                            helperText={validateAward.errors}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            // //disabled={selectedWork === true}
                                                            sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                            error={validateNote.invalid}
                                                            margin="normal"
                                                            label="Nhận xét/Đánh giá tác phẩm"
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            value={note}
                                                            onChange={handleChangeInput}
                                                            name="note"
                                                            helperText={validateNote.errors}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </MainCardIcon>
                                    <MainCardIcon
                                        title="Lưu trữ nội dung tác phẩm"
                                        sx={{ marginTop: '14px' }}
                                        showBackGround={true}
                                        icon={upload}
                                    >
                                        {typeMode !== VIEW && (
                                            <Tooltip
                                                placement="top-start"
                                                title={
                                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: 'white', fontSize: '14px', marginBottom: '10px' }}
                                                        >
                                                            * Đường dẫn lưu trữ file tài liệu chỉ hiển thị file pdf, img.
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: 'white', fontSize: '14px', marginBottom: '10px' }}
                                                        >
                                                            * Đường dẫn lưu trữ file tài liệu chỉ upload được tối đa 20 file.
                                                        </Typography>
                                                    </Box>
                                                }
                                            >
                                                <IconButton size="1px" sx={{ position: 'absolute', top: 5, left: 235 }}>
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {/* <IconButton
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ position: 'absolute', top: -10, right: 2 }}
                                            onClick={showSavePath ? onHideSavePath : onShowSavePath}
                                        >
                                            {showSavePath ? <CloseIcon sx={{ fontSize: '45px' }} /> : <AddIcon sx={{ fontSize: '45px' }} />}
                                        </IconButton> */}
                                        {showSavePath && typeMode === VIEW && (
                                            <Button
                                                startIcon={<VisibilityIcon />}
                                                onClick={openModalContentWork}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                sx={{ position: 'absolute', top: 10, right: 115, display: { md: 'flex', xs: 'none' } }}
                                            >
                                                Xem nội dung
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            sx={{ position: 'absolute', top: 10, right: 10 }}
                                            onClick={showSavePath ? onHideSavePath : onShowSavePath}
                                            startIcon={
                                                showSavePath ? <KeyboardArrowUpIcon size="large" /> : <KeyboardArrowDownIcon size="large" />
                                            }
                                        >
                                            {showSavePath ? 'Thu gọn' : 'Mở rộng'}
                                        </Button>
                                        {showSavePath && (
                                            <Grid container style={{ paddingLeft: 0, flexDirection: 'row' }}>
                                                {typeMode === VIEW && (
                                                    <Button
                                                        startIcon={<VisibilityIcon />}
                                                        onClick={openModalContentWork}
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        sx={{ display: { md: 'none', xs: 'flex' } }}
                                                    >
                                                        Xem nội dung
                                                    </Button>
                                                )}
                                                {/* <Grid md={12} item style={{ paddingLeft: 0, flexDirection: 'column' }}> */}
                                                <Grid item xs={12}>
                                                    <TextField
                                                        //disabled={selectedWork === true}
                                                        sx={{ pointerEvents: typeMode === VIEW ? 'none' : '' }}
                                                        error={validateUrlLink.invalid}
                                                        margin="normal"
                                                        label="Đường dẫn trực tuyến"
                                                        fullWidth
                                                        value={urlLink}
                                                        onChange={handleChangeInput}
                                                        name="urlLink"
                                                        helperText={validateUrlLink.errors}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Autocomplete
                                                            open={false}
                                                            sx={{
                                                                marginTop: '15px',
                                                                disabled: 'disabled',
                                                                marginBottom: '0px !important',
                                                                pointerEvents: typeMode === VIEW ? 'none' : '',
                                                                width: isMobile && typeMode !== VIEW ? '78% !important' : '100% !important',
                                                                '& .MuiAutocomplete-clearIndicator': {
                                                                    display: 'none'
                                                                }
                                                            }}
                                                            fullWidth
                                                            multiple={fileUpload?.length > 0 ? true : false}
                                                            // placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                                            helperText={validateFileUpload.errors}
                                                            error={validateFileUpload.invalid}
                                                            value={fileUpload?.length > 0 ? fileUpload : []}
                                                            clearOnBlur={false}
                                                            options={[]}
                                                            getOptionLabel={(option) =>
                                                                option.name ? option.name : option.nameFile ? option.nameFile : ''
                                                            }
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <Chip
            
                                                                        variant="outlined"
                                                                        label={
                                                                            option.name
                                                                                ? option.name
                                                                                : option.nameFile
                                                                                ? option.nameFile
                                                                                : ''
                                                                        }
                                                                        {...getTagProps({ index })}
                                                                        deleteIcon={
                                                                            <CloseIcon style={{ color: option?.eff ? 'blue' : 'red' }} />
                                                                        }
                                                                        // onDelete={() => handleDeleteFile(option)}
                                                                        onDelete={() => handleDeleteFile(option)}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    sx={{
                                                                        disabled: 'disabled',
                                                                        marginBottom: '0px !important',
                                                                        pointerEvents: typeMode === VIEW ? 'none' : ''
                                                                        // width: matchDownMd ? '60%' : '100%'
                                                                    }}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    label="Đường dẫn lưu trữ file"
                                                                    placeholder={
                                                                        fileUpload.length === 0
                                                                            ? 'Nhập đường dẫn lưu trữ file tài liệu...'
                                                                            : ''
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <input
                                                            style={{ display: 'none' }}
                                                            ref={inputRef}
                                                            type="file"
                                                            multiple
                                                            onChange={onPathChange}
                                                        />
                                                        {typeMode !== VIEW && (
                                                            <>
                                                                <Button
                                                                    //disabled={selectedWork === true}
                                                                    variant="contained"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        pointerEvents: typeMode === VIEW ? 'none' : '',
                                                                        display: { md: 'flex', xs: 'none' }
                                                                    }}
                                                                    // size={isMobile}
                                                                    color="primary"
                                                                    onClick={handleUploadFile}
                                                                    startIcon={<AttachFileIcon />}
                                                                >
                                                                    {typeMode === VIEW ? 'Xem' : 'Chọn File'}
                                                                </Button>
                                                                <Button
                                                                    onClick={handleUploadFile}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        pointerEvents: typeMode === VIEW ? 'none' : '',
                                                                        display: { md: 'none', xs: 'flex' }
                                                                    }}
                                                                >
                                                                    <AttachFileIcon />
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <TextField
                                                            //disabled={selectedWork === true}
                                                            fullWidth
                                                            sx={{
                                                                disabled: 'disabled',
                                                                marginBottom: '0px !important',
                                                                pointerEvents: typeMode === VIEW ? 'none' : ''
                                                            }}
                                                            type="text"
                                                            // error={validateFileUpload.invalid}
                                                            margin="normal"
                                                            label={'Lưu trữ từ phả kí dòng họ'}
                                                            value={idDiary.title ?? ''}
                                                            name="path"
                                                            // helperText={validateFileUpload.errors}
                                                            placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                                        />
                                                        {typeMode !== VIEW && (
                                                            <>
                                                                <Button
                                                                    //disabled={selectedWork === true}
                                                                    variant="contained"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        display: { md: 'flex', xs: 'none' },
                                                                        pointerEvents: typeMode === VIEW ? 'none' : ''
                                                                    }}
                                                                    color="primary"
                                                                    onClick={() => openModalDiary()}
                                                                    startIcon={<AttachFileIcon />}
                                                                >
                                                                    Chọn từ phả kí
                                                                </Button>
                                                                <Button
                                                                    //disabled={selectedWork === true}
                                                                    variant="contained"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        display: { md: 'none', xs: 'flex' },
                                                                        pointerEvents: typeMode === VIEW ? 'none' : ''
                                                                    }}
                                                                    color="primary"
                                                                    onClick={() => openModalDiary()}
                                                                    // startIcon={<AttachFileIcon />}
                                                                >
                                                                    {/* Chọn từ phả kí */}
                                                                    <AttachFileIcon />
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <TextField
                                                            //disabled={selectedWork === true}
                                                            fullWidth
                                                            sx={{
                                                                disabled: 'disabled',
                                                                marginBottom: '0px !important',
                                                                pointerEvents: typeMode === VIEW ? 'none' : ''
                                                                // width: matchDownMd ? '60%' : '100%'
                                                            }}
                                                            type="text"
                                                            // error={validateFileUpload.invalid}
                                                            margin="normal"
                                                            label={'Lưu trữ từ tư liệu dòng họ'}
                                                            value={idDocument.name_document ?? ''}
                                                            name="path"
                                                            // helperText={validateFileUpload.errors}
                                                            placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                                            // disabled
                                                        />
                                                        {typeMode !== VIEW && (
                                                            <>
                                                                <Button
                                                                    //disabled={selectedWork === true}
                                                                    variant="contained"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        display: { md: 'flex', xs: 'none' },
                                                                        pointerEvents: typeMode === VIEW ? 'none' : ''
                                                                    }}
                                                                    color="primary"
                                                                    onClick={() => openModalDocument()}
                                                                    startIcon={<AttachFileIcon />}
                                                                >
                                                                    Chọn từ tài liệu
                                                                </Button>
                                                                <Button
                                                                    //disabled={selectedWork === true}
                                                                    variant="contained"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        display: { md: 'none', xs: 'flex' },
                                                                        pointerEvents: typeMode === VIEW ? 'none' : ''
                                                                    }}
                                                                    color="primary"
                                                                    onClick={() => openModalDocument()}
                                                                    // startIcon={<AttachFileIcon />}
                                                                >
                                                                    {/* Chọn từ tài liệu */}
                                                                    <AttachFileIcon />
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Grid>
                                                {/* </Grid> */}
                                            </Grid>
                                        )}
                                    </MainCardIcon>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
            <ModalDiary show={openDiary} handleClose={handleCloseModalDiary} onChooseDiary={onChooseDiary} diary={idDiary} />
            <ModalDocument
                show={openDocument}
                handleClose={handleCloseModalDocument}
                onChooseDocument={onChooseDocument}
                documents={idDocument}
            />
            <ModalPreview isOpen={openModalPreview} onClose={() => setOpenModalPreview(false)} dataWork={dataWork} />
            <Loading show={isLoading} />
        </>
    );
};

export default ModalInfoWork;
