// material-ui
// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { IconLogout } from '@tabler/icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DialogUtil from 'ui-component/show_confirm_action';
import ConfigRouter from 'routes/config_router';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ open, handleHover, handMouseLeave }) => {
    const customization = useSelector((state) => state.customization);
    const largeScreen = useMediaQuery('(min-width: 1200px)');
    const navigate = useNavigate();
    
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} open={open} handleHover={handleHover} handMouseLeave={handMouseLeave} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const handleLogout = async () => {
        const arrData = localStorage.getItem('LIST_ADDRESS') ? JSON.parse(localStorage.getItem('LIST_ADDRESS')) : [];
        const dataResident = localStorage.getItem('LIST_RESIDENT') ? JSON.parse(localStorage.getItem('LIST_RESIDENT')) : [];
        const dataTemporaryResidence = localStorage.getItem('LIST_TEMPORARYRESIDENCE') ? JSON.parse(localStorage.getItem('LIST_TEMPORARYRESIDENCE')) : [];
        const dataBirthPlace = localStorage.getItem('LIST_BIRTH_PLACE') ? JSON.parse(localStorage.getItem('LIST_BIRTH_PLACE')) : [];
        const dataDeadPlace = localStorage.getItem('LIST_DEAD_PLACE') ? JSON.parse(localStorage.getItem('LIST_DEAD_PLACE')) : [];
        const remember = localStorage.getItem('REMEMBER') ?? '';
        const theme = localStorage.getItem('theme') ?? '';
        const logo = localStorage.getItem('m_family_logo') ?? '';
        const type_view_diary = localStorage.getItem('type_view_diary') ?? 'list';
        const type_view_album = localStorage.getItem('type_view_album') ?? 'list';
        const vesion = localStorage.getItem('VERSION') ?? '';
        const font = localStorage.getItem('m_family_font') ?? '';
        await localStorage.clear();
        localStorage.setItem('REMEMBER', remember);
        localStorage.setItem('m_family_logo', logo);
        localStorage.setItem('theme', theme);
        localStorage.setItem('type_view_diary', type_view_diary);
        localStorage.setItem('type_view_album', type_view_album);
        localStorage.setItem('LIST_ADDRESS', JSON.stringify(arrData));
        localStorage.setItem('LIST_RESIDENT', JSON.stringify(dataResident));
        localStorage.setItem('LIST_TEMPORARYRESIDENCE', JSON.stringify(dataTemporaryResidence));
        localStorage.setItem('LIST_BIRTH_PLACE', JSON.stringify(dataBirthPlace));
        localStorage.setItem('LIST_DEAD_PLACE', JSON.stringify(dataDeadPlace));
        localStorage.setItem('VERSION', vesion);
        localStorage.setItem('m_family_font', font);
        await navigate(ConfigRouter.newFeed);
    };

    const onLogout = () => {
        DialogUtil.showConfirm(
            'Thông báo',
            'Bạn có muốn đăng xuất tài khoản ?',
            () => {},
            () => handleLogout()
        );
    };

    return (
        <>
            {navItems}
            <ListItemButton
                // disabled={!largeScreen}
                onMouseOver={handleHover}
                sx={{
                    minHeight: 50,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5
                }}
                onClick={() => onLogout()}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center'
                    }}
                >
                    <IconLogout stroke={1.5} size="1.5rem" />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant="body1" color={customization?.themeDongHo?.darkTextSecondary}>
                            Đăng xuất
                        </Typography>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                />
            </ListItemButton>
        </>
    );
};

export default MenuList;
