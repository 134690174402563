import { useState, useEffect, useRef } from 'react';
import {
    Modal,
    Box,
    Typography,
    FormControl,
    Button,
    Avatar,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    FormControlLabel,
    Checkbox,
    Chip,
    IconButton,
    Tooltip,
    Autocomplete,
    CircularProgress,
    Switch
} from '@mui/material';

import Loading from 'ui-component/Loading';
import DialogUtil from 'ui-component/show_confirm_action';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import book from '../../../../../assets/images/users/book.png';
import upload from '../../../../../assets/images/users/upload.png';
import book_user from '../../../../../assets/images/users/bookuser.png';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { isUrlValid } from '../../../../../utils/validate_checking';
import router from 'routes/router';
import config from '../../../../../config';
import fetchClient from 'views/utilities/RestfulAPI';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { getRoleButton } from 'utils/roleUility';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import MainCardIcon from 'ui-component/cards/MainCardIcon';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ModalDiary from '../../workList/modal/ModalDiary';
import ModalDocument from '../../workList/modal/ModalDocument';
import ModalListWork from '../../workList/modal/ModalListWork';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalPreview from '../../workList/modal/ModalPreview';
import { ConvertName } from 'utils/helper';
import { isMobile } from 'react-device-detect';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95vw' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: { md: 2, xs: 1 }
};

const styleBox = {
    maxHeight: { xs: '75vh', sm: '87vh' },
    overflowY: 'auto'
};

const totalDaySolar = 31;
const monthInYear = 12;
const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 90
        }
    }
};

const initial = {
    inherit: false,
    error: ''
};

function getStyles(name, typeName, theme) {
    return {
        fontWeight: typeName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    };
}

const ModalAddWorkByAuthor = ({ open, handleClose, typeModal, dataAuthor, onReload, idAuthor, dataInfoWork, typeView }) => {
    const dataLanguage = localStorage.getItem('m_language') ? JSON.parse(localStorage.getItem('m_language')) : [];
    const theme = useTheme();
    const inputRef = useRef(null);
    const customization = useSelector((state) => state.customization);
    const [isLoading, setIsLoading] = useState(false);
    const [nameWork, setNameWork] = useState('');
    const [storageLocation, setStorageLocation] = useState('');
    const [publicWork, setPublicWork] = useState('');
    const [language, setLanguage] = useState('');
    const [type, setType] = useState('');
    const [year, setYear] = useState('');
    const [topic, setTopic] = useState('');
    const [content, setContent] = useState('');
    const [note, setNote] = useState('');
    const [award, setAward] = useState('');
    const [fileWork, setFileWork] = useState(null);

    const [selectedValues, setSelectedValues] = useState([]);
    const [validateWork, setValidateWork] = useState(initial);
    const [validateStorageLocation, setValidateStorageLocation] = useState(initial);
    const [validateLanguage, setValidateLanguage] = useState(initial);
    const [validateType, setValidateType] = useState(initial);
    const [validateYear, setValidateYear] = useState(initial);
    const [validateTopic, setValidateTopic] = useState(initial);
    const [validateContent, setValidateContent] = useState(initial);
    const [validateNote, setValidateNote] = useState(initial);
    const [validateAward, setValidateAward] = useState(initial);

    const [fileUpload, setFileUpload] = useState([]);
    const [idDiary, setIdDiary] = useState('');
    const [idDocument, setIdDocument] = useState('');
    const [pathFile, setPathFile] = useState([]);
    const [validateFileUpload, setValidateFileUpload] = useState(initial);
    const [urlLink, setUrlLink] = useState('');
    const [validateUrlLink, setValidateUrlLink] = useState(initial);

    const [all, setAll] = useState(true);
    const [part, setPart] = useState(false);

    const [showConnect, setShowConnect] = useState(false);
    const [showSavePath, setShowSavePath] = useState(false);
    const [showWork, setShowWork] = useState(true);

    const [typeName, setTypeName] = useState([]);
    const [dataType, setDataType] = useState([]);
    const [dataRole, setDataRole] = useState([]);
    const [selectedWork, setSelectedWork] = useState(false);

    const [partWriting, setPartWriting] = useState('');
    const [timeWriting, setTimeWriting] = useState('');
    const [validatePartWriting, setValidatePartWriting] = useState(initial);
    const [validateTimeWriting, setValidateTimeWriting] = useState(initial);

    const [openDiary, setOpenDiary] = useState(false);
    const [openDocument, setOpenDocument] = useState(false);
    const [openModalListWork, setOpenModalListWork] = useState(false);
    const [openModalPreview, setOpenModalPreview] = useState(false);

    const [dataWork, setDataWork] = useState([]);
    const [idWork, setIdWork] = useState('');
    const [dataFiles, setDataFiles] = useState([]);
    const [roleAuthor, setRoleAuthor] = useState([]);
    const [isChangeInfo, setIsChangeInfo] = useState(false);
    const [hasNewFile, setHasNewFile] = useState(false);
    const [dataInfoWorks, setDataInfoWorks] = useState(dataInfoWork);

    const onShowWork = () => {
        setShowWork(true);
    };

    const onHideWork = () => {
        setShowWork(false);
    };

    const onShowSavePath = () => {
        setShowSavePath(true);
    };

    const onHideSavePath = () => {
        setShowSavePath(false);
    };

    const onShowConnect = () => {
        setShowConnect(true);
    };

    const onHideConnect = () => {
        setShowConnect(false);
    };

    const handleOpenModalListWork = () => {
        setOpenModalListWork(true);
    };

    const handleCloseModalListWork = () => {
        setOpenModalListWork(false);
    };

    const onSelectedWork = (data) => {
        setIdWork(data?._id);
        setNameWork(data.name_work);
        setStorageLocation(data.storageLocation);
        setLanguage(data.language);
        setYear(data.publishing_year);
        setTopic(data.topic);
        setContent(data.content);
        setNote(data.note);
        setAward(data.award);
        setSelectedWork(true);
        setDataWork(data);
        setTypeName(data?.type);
        // setOpenModalMember(false);
    };

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setTypeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );

        if (value.length > 0) {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    const openModalDiary = () => {
        setOpenDiary(true);
    };

    const openModalDocument = () => {
        setOpenDocument(true);
    };

    const handleCloseModalWork = () => {
        setIdWork('');
        setNameWork('');
        setStorageLocation('');
        setLanguage('');
        setTypeName([]);
        setYear('');
        setTopic('');
        setContent('');
        setNote('');
        setAward('');
        setIdDiary('');
        setIdDocument('');
        setPathFile('');
        setUrlLink('');
        setPartWriting('');
        setTimeWriting('');
        setRoleAuthor([]);
        setIsChangeInfo(false);
        setFileUpload([]);

        setValidateFileUpload(initial);
        setValidateWork(initial);
        setValidateStorageLocation(initial);
        setValidateLanguage(initial);
        setValidateType(initial);
        setValidateYear(initial);
        setValidateTopic(initial);
        setValidateContent(initial);
        setValidateNote(initial);
        setValidateAward(initial);
        setValidatePartWriting(initial);
        setValidateTimeWriting(initial);

        setShowWork(true);
        setShowConnect(false);
        setShowSavePath(false);
        setSelectedWork(false);
        setHasNewFile(false);

        handleClose();
    };

    const findName = (value) => {
        const rst = dataType
            .filter((r) => {
                return r.config_value.genre_id === value;
            })
            .map((m) => {
                return m.config_value.genre_name;
            });
        return rst;
    };

    const findNameRole = (value) => {
        const rst = dataRole
            .filter((r) => {
                return r?.athour_id === value;
            })
            .map((m) => {
                return m?.athour_name;
            });

        return rst;
    };

    const onChangeAction = (e) => {
        const name = e.target.name;
        switch (name) {
            case 'all':
                setAll(true);
                setPart(false);
                break;
            case 'part':
                setAll(false);
                setPart(true);
                break;
            default:
                break;
        }
        setIsChangeInfo(true);
    };

    const handleUploadFile = () => {
        inputRef.current.click();
    };

    const onPathChange = (event) => {
        const newFiles = Array.from(event.target.files);
        if (newFiles.length === 0) {
            return;
        }
        setHasNewFile(true);
        if (event.target.files && event.target.files[0]) {
            const newDataImages = [...fileUpload];
            const dataFile = [...dataFiles];
            const files = Array.from(event.target.files).slice(0, 20);
            files.forEach((file) => {
                file.eff = true;
                // if (file.type.match(imageMimeType)) {
                newDataImages.push(file);
                dataFile.push(file);
                // }
            });
            setFileUpload(newDataImages);
            setDataFiles(dataFile);
        }
        event.target.value = null;
    };

    const handleCloseModalDocument = () => {
        setOpenDocument(false);
    };
    const handleCloseModalDiary = () => {
        setOpenDiary(false);
    };

    const onChooseDiary = (data) => {
        setIdDiary(data);
        setIsChangeInfo(true);
    };

    const onChooseDocument = (data) => {
        setIdDocument(data);
        setIsChangeInfo(true);
    };

    const handleChangeInput = (e) => {
        const name = e.target?.name;
        const value = e.target?.value;
        switch (name) {
            case 'nameWork':
                if (value.trim() === '') {
                    setNameWork(value);
                    setValidateWork({
                        invalid: true,
                        errors: 'Tên tác phẩm không được bỏ trống'
                    });
                    return;
                }
                if (value.length > 100) {
                    setValidateWork({
                        invalid: true,
                        errors: 'Tên tác phẩm không được vượt quá 100 ký tự'
                    });
                    return;
                }
                setNameWork(value);
                setValidateWork(initial);
                break;
            case 'storageLocation':
                if (value.length > 500) {
                    setValidateStorageLocation({
                        invalid: true,
                        errors: 'Nơi lưu trữ không được vượt quá 500 ký tự'
                    });
                    return;
                }

                setStorageLocation(value);
                setValidateStorageLocation(initial);
                break;
            case 'language':
                if (value.length > 100) {
                    setValidateLanguage({
                        invalid: true,
                        errors: 'Ngôn ngữ không được vượt quá 100 ký tự'
                    });
                    return;
                }
                setLanguage(value);
                setValidateLanguage(initial);
                break;
            case 'year':
                setYear(value);
                break;
            case 'topic':
                if (value.length > 100) {
                    setValidateTopic({
                        invalid: true,
                        errors: 'Chủ đề không được vượt quá 100 ký tự'
                    });
                    return;
                }
                setTopic(value);
                setValidateTopic(initial);
                break;
            case 'content':
                if (value.length > 500) {
                    setValidateContent({
                        invalid: true,
                        errors: 'Tóm tắt không được vượt quá 500 ký tự'
                    });
                    return;
                }

                setContent(value);
                setValidateContent(initial);
                break;
            case 'note':
                if (value.length > 500) {
                    setValidateNote({
                        invalid: true,
                        errors: 'Ghi chú không được vượt quá 500 ký tự'
                    });

                    return;
                }
                setNote(value);
                setValidateNote(initial);
                break;
            case 'award':
                if (value.length > 500) {
                    setValidateAward({
                        invalid: true,
                        errors: 'Giải thưởng không được vượt quá 500 ký tự'
                    });
                    return;
                }
                setAward(value);
                setValidateAward(initial);
                break;
            case 'part_writing':
                if (value.length > 500) {
                    setValidatePartWriting({
                        invalid: true,
                        errors: 'Phần tham gia không được vượt quá 500 ký tự'
                    });
                    return;
                }
                setPartWriting(value);
                setValidatePartWriting(initial);
                break;
            case 'time_writing':
                if (value.length > 500) {
                    setValidateTimeWriting({
                        invalid: true,
                        errors: 'Thời điểm tham gia không được vượt quá 500 ký tự'
                    });
                    return;
                }
                setTimeWriting(value);
                setValidateTimeWriting(initial);
                break;
            case 'urlLink':
                setUrlLink(value);
                break;
            default:
                break;
        }
        if (value !== '') {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    const handleSave = () => {
        if (nameWork.trim() === '') {
            setValidateWork({
                invalid: true,
                errors: 'Tên tác phẩm không được bỏ trống'
            });
            return;
        }
        if (nameWork?.length > 100) {
            setValidateWork({
                invalid: true,
                errors: 'Tên tác phẩm không được vượt quá 100 ký tự'
            });
            return;
        }
        if (storageLocation?.length > 500) {
            setValidateStorageLocation({
                invalid: true,
                errors: 'Nơi lưu trữ không được vượt quá 500 ký tự'
            });
            return;
        }
        if (language?.length > 100) {
            setValidateLanguage({
                invalid: true,
                errors: 'Ngôn ngữ không được vượt quá 100 ký tự'
            });
            return;
        }

        if (topic?.length > 100) {
            setValidateTopic({
                invalid: true,
                errors: 'Chủ đề không được vượt quá 100 ký tự'
            });
            return;
        }
        if (content?.length > 500) {
            setValidateContent({
                invalid: true,
                errors: 'Tóm tắt không được vượt quá 500 ký tự'
            });
            return;
        }
        if (note?.length > 500) {
            setValidateNote({
                invalid: true,
                errors: 'Ghi chú không được vượt quá 500 ký tự'
            });
            return;
        }
        if (award?.length > 500) {
            setValidateAward({
                invalid: true,
                errors: 'Giải thưởng không được vượt quá 500 ký tự'
            });
            return;
        }

        if (partWriting?.length > 500) {
            setValidatePartWriting({
                invalid: true,
                errors: 'Phần tham gia không được vượt quá 500 ký tự'
            });
            return;
        }

        if (timeWriting?.length > 500) {
            setValidateTimeWriting({
                invalid: true,
                errors: 'Thời điểm tham gia không được vượt quá 500 ký tự'
            });
            return;
        }

        if (urlLink !== '' && !isUrlValid(urlLink)) {
            setValidateUrlLink({
                invalid: true,
                errors: 'Đường dẫn trực tuyến không hợp lệ'
            });
            return;
        }

        if (dataFiles.length > 20) {
            setValidateFileUpload({
                invalid: true,
                errors: 'Số lượng file upload không được vượt quá 20 file'
            });
            return;
        }

        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn thêm mới tác phẩm không?`,
            () => {},
            () => {
                onSave();
            }
        );
    };

    const onSave = async () => {
        const dataWork = {
            idWork: idWork,
            name_work: nameWork,
            language: language,
            publishing_year: year,
            topic: topic,
            storageLocation: storageLocation,
            type: typeName,
            content: content,
            award: award,
            note: note,
            is_public: publicWork,
            id_author: dataAuthor?._id ?? null,
            role: roleAuthor,
            part_write: part === false ? null : partWriting,
            time_write: timeWriting,
            participate_write: all ? 'ALL' : null || part ? 'PART' : null,
            document: {
                id_document: idDocument?._id ?? null,
                name_document: idDocument?.name_document ?? null,
                url_document: idDocument?.url_document ?? null
            },
            diary: {
                id_diary: idDiary?._id ?? null,
                title: idDiary?.title ?? null
            },
            link: urlLink
        };
        let urlApi = router.SUB_URL.create_work;
        const formData = new FormData();
        formData.append('dataWork', JSON.stringify(dataWork));
        for (let i = 0; i < dataFiles.length; i++) {
            formData.append('file_work', dataFiles[i], dataFiles[i].name);
        }

        const dataReturn = await fetchClient.sendRequest(urlApi, 'post', formData);
        if (dataReturn?.status === 201) {
            DialogUtil.showQuestions(true, `Thêm mới tác phẩm thành công`, async () => {
                await onReload();
                handleCloseModalWork();
            });
        } else {
            DialogUtil.showQuestions(false, dataReturn?.message ? dataReturn?.message : `Thêm mới tác phẩm thất bại`);
        }
    };

    const getDataType = async () => {
        const dataObject = {
            config_key: 'GENRE'
        };
        let urlApi = router.CONFIG_URL.getAllDataByConfigKey;
        const result = await fetchClient.sendRequest(urlApi, 'post', dataObject);
        if (result?.status === 200) {
            setDataType(result?.data?.data);
        } else {
            DialogUtil.showQuestions(false, 'Lấy danh sách thể loại thất bại');
        }
    };

    const getDataRole = async () => {
        const dataObject = {
            config_key: 'ATHOUR_ROLE'
        };
        let urlApi = router.CONFIG_URL.getAllDataByConfigKey;
        const result = await fetchClient.sendRequest(urlApi, 'post', dataObject);
        if (result?.status === 200) {
            const dataArr = [];
            result?.data?.data.map((item) => {
                dataArr.push(item.config_value);
            });
            setDataRole(dataArr);
        } else {
            DialogUtil.showQuestions(false, 'Lấy danh sách thể loại thất bại');
        }
    };

    const handleClearListWork = () => {
        setIdWork('');
        setNameWork('');
        setStorageLocation('');
        setLanguage('');
        setYear('');
        setTopic('');
        setContent('');
        setNote('');
        setAward('');
        setType('');
        setTypeName([]);
        setSelectedWork(false);
    };

    const getDataWorkInfo = async () => {
        setIsLoading(true);
        const obj = {
            id: dataInfoWork?._id || dataInfoWork?.id_work
        };
        const dataObject = await fetchClient.sendRequest(router.SUB_URL.info_work, 'post', obj);
        console.log('dataObject', dataObject);
        if (dataObject.status === 201) {
            setDataInfoWorks(dataObject?.data);
            setNameWork(dataObject?.data?.name_work);
            setStorageLocation(dataObject?.data?.storageLocation);
            setLanguage(dataObject?.data?.language);
            setYear(dataObject?.data?.publishing_year);
            setTopic(dataObject?.data?.topic);
            setPublicWork(dataObject?.data?.is_public);
            setContent(dataObject?.data?.content);
            setNote(dataObject?.data?.note);
            setAward(dataObject?.data?.award);
            setType(dataObject?.data?.type);
            setTypeName(dataObject?.data?.type);
            setUrlLink(dataObject?.data?.link ?? '');
            setIdDiary(dataObject?.data?.diary ?? '');
            setIdDocument(dataObject?.data?.document ?? '');
            setPathFile(
                dataObject?.data?.file_upload.length > 0 ? dataObject?.data?.file_upload.flatMap((m) => m.nameFile).join(', ') : ''
            );
            setFileUpload(dataObject?.data?.file_upload);
            if (dataObject?.data?.authors?.length > 0) {
                dataObject?.data?.authors.map((item) => {
                    if (item?.id_author === idAuthor) {
                        console.log('aaaaa');
                        setPartWriting(item?.part_write ?? '');
                        setTimeWriting(item?.time_write ?? '');
                        setAll(item?.participate_write === 'ALL' ? true : false);
                        setPart(item?.participate_write === 'PART' ? true : false);
                        setRoleAuthor(item?.role);
                    } else {
                        console.log('bbbbbbbbb');
                    }
                });
            }
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (open === true) {
            getDataType();
            getDataRole();
            if (dataInfoWork) {
                getDataWorkInfo();
            }
        }
    }, [open]);

    const handleChangeAuthorRole = async (e) => {
        // setRoleAuthor(e.target.value);
        const {
            target: { value }
        } = e;
        setRoleAuthor(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );

        if (value.length > 0) {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    const handleUpdateWork = () => {
        if (nameWork.trim() === '') {
            setValidateWork({
                invalid: true,
                errors: 'Tên tác phẩm không được bỏ trống'
            });
            return;
        }

        if (urlLink !== '' && !isUrlValid(urlLink)) {
            setValidateUrlLink({
                invalid: true,
                errors: 'Đường dẫn không đúng định dạng vui lòng kiểm tra lại'
            });
            return;
        }

        if (dataFiles.length > 20) {
            setValidateFileUpload({
                invalid: true,
                errors: 'Số lượng file upload không được vượt quá 20 file'
            });
            return;
        }

        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn cập nhập tác phẩm ${dataInfoWork.name_work} không?`,
            () => {},
            () => {
                onUpdateWork();
            }
        );
    };

    const onUpdateWork = async () => {
        const dataWorks = {
            name_work: nameWork,
            language: language,
            publishing_year: year,
            topic: topic,
            storageLocation: storageLocation,
            type: typeName,
            content: content,
            award: award,
            note: note,
            is_public: publicWork,
            document: {
                id_document: idDocument?._id ? idDocument._id : idDocument.id_document,
                name_document: idDocument?.name_document ?? null,
                url_document: idDocument?.url_document ?? null
            },
            diary: {
                id_diary: idDiary?._id ? idDiary._id : idDiary.id_diary,
                title: idDiary?.title ?? null
            },
            link: urlLink,
            id: dataInfoWork?._id,
            idAuthor: idAuthor,
            role: roleAuthor,
            part_write: part === false ? null : partWriting,
            time_write: timeWriting,
            participate_write: all ? 'ALL' : null || part ? 'PART' : null
        };
        let urlApi = router.SUB_URL.update_work;
        const formData = new FormData();
        if (hasNewFile && dataFiles.length > 0) {
            for (let i = 0; i < dataFiles.length; i++) {
                formData.append('file_work', dataFiles[i], dataFiles[i].name);
            }
        }

        formData.append('dataWork', JSON.stringify(dataWorks));

        const dataReturn = await fetchClient.sendRequest(urlApi, 'post', formData);
        if (dataReturn.status === 201) {
            DialogUtil.showQuestions(true, `Cập nhập tác phẩm thành công`, async () => {
                await onReload();
                handleCloseModalWork();
            });
        } else {
            if (dataReturn.statusCode === 406) {
                DialogUtil.showQuestions(false, dataReturn.message);
            } else {
                DialogUtil.showQuestions(false, 'Cập nhập tác phẩm thất bại');
            }
        }
    };

    const handleChangeLanguage = (event) => {
        const {
            target: { value }
        } = event;
        setLanguage(value);
        if (value !== '') {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    const onCloseModalWork = () => {
        if (isChangeInfo || selectedWork) {
            DialogUtil.showConfirm(
                'Thông báo',
                `Thông tin tác phẩm chưa được lưu, bạn có muốn thoát không?`,
                () => {},
                () => {
                    handleCloseModalWork();
                }
            );
        } else {
            handleCloseModalWork();
        }
    };

    const handleDeleteFile = (fileToDelete) => {
        if (fileToDelete.eff === true) {
            setFileUpload((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
            setDataFiles((prevFiles) => {
                const filesArray = Array.from(prevFiles);

                const newFilesArray = filesArray.filter((file) => file !== fileToDelete);

                const newFileList = new DataTransfer();
                newFilesArray.forEach((file) => newFileList.items.add(file));
                return newFileList.files;
            });
        } else {
            onDeleteFileWork(fileToDelete);
        }
        //setDataFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    };

    const onDeleteFileWork = (file) => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn xóa tệp ${file?.nameFile} không?`,
            () => {},
            () => onDeleteFile(file)
        );
    };

    const onDeleteFile = async (file) => {
        const obj = {
            idWork: dataInfoWork?._id,
            idFile: file?._id
        };
        const result = await fetchClient.sendRequest(router.SUB_URL.delete_file, 'post', obj);
        if (result.status === 201) {
            DialogUtil.showQuestions(true, 'Xóa file thành công!', () => getDataWorkInfo());
        } else {
            DialogUtil.showQuestions(false, 'Xóa file thất bại');
        }
    };

    const openModalContentWork = (e) => {
        setOpenModalPreview(true);
    };

    return (
        <>
            <Modal open={open} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginY: '15px',
                            padding: { md: '0px', xs: '10px' }
                        }}
                    >
                        <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ maxWidth: '60%' }}>
                            {typeView === 'VIEW'
                                ? `Thông tin tác phẩm ${ConvertName(dataInfoWork?.name_work, 50)}`
                                : dataInfoWork?._id
                                ? `Cập nhập tác phẩm ${ConvertName(dataInfoWork?.name_work, 40)}`
                                : `Thêm mới tác phẩm cho tác giả ${dataAuthor?.name_author}`}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' }, justifyContent: 'flex-end' }}>
                            {typeView === 'VIEW' ? null : (
                                <Button
                                    onClick={dataInfoWork?._id ? () => handleUpdateWork() : () => handleSave()}
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    sx={{ marginRight: '5px', borderBlockColor: 'black', marginTop: { md: '0px', xs: '5px' } }}
                                >
                                    Lưu
                                </Button>
                            )}

                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<CloseIcon />}
                                className="mr-2"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => onCloseModalWork()}
                            >
                                Đóng
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            ...styleBox,
                            '&::-webkit-scrollbar': {
                                width: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: customization?.themeDongHo?.heading,
                                outline: 'slategrey'
                            }
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{ marginBottom: 2 }}>
                                    <MainCardIcon
                                        title="Thông tin tác phẩm"
                                        icon={book}
                                        showBackGround={true}
                                        sx={{ backgroundColor: selectedWork ? 'whitesmoke' : 'none' }}
                                    >
                                        {showWork && typeView !== 'VIEW' && !dataInfoWork?._id && (
                                            <>
                                                {!isMobile && (
                                                    <Button
                                                        startIcon={selectedWork ? <CloseIcon /> : <DoneAllIcon />}
                                                        onClick={selectedWork === true ? handleClearListWork : handleOpenModalListWork}
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        sx={{ position: 'absolute', top: 10, right: 110 }}
                                                    >
                                                        {selectedWork === true ? 'Bỏ chọn tác phẩm' : 'Chọn từ danh sách'}
                                                    </Button>
                                                )}
                                            </>
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            sx={{ position: 'absolute', top: 10, right: 10 }}
                                            onClick={showWork ? onHideWork : onShowWork}
                                            startIcon={
                                                showWork ? <KeyboardArrowUpIcon size="large" /> : <KeyboardArrowDownIcon size="large" />
                                            }
                                        >
                                            {showWork ? 'Thu gọn' : 'Mở rộng'}
                                        </Button>
                                        {showWork && (
                                            <Grid container style={{ paddingLeft: 0 }}>
                                                <Grid md={12} sm={12} xs={12} item spacing={1}>
                                                    {isMobile && (
                                                        <Button
                                                            // fullWidth
                                                            startIcon={selectedWork ? <CloseIcon /> : <DoneAllIcon />}
                                                            onClick={selectedWork === true ? handleClearListWork : handleOpenModalListWork}
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            // sx={{ display: 'flex', justifyContent:'flex-end' }}
                                                        >
                                                            {selectedWork === true ? 'Bỏ chọn tác phẩm' : 'Chọn từ danh sách'}
                                                        </Button>
                                                    )}
                                                    <Grid item>
                                                        <FormControlLabel
                                                            sx={{ml: '10px'}}
                                                            value="start"
                                                            disabled={selectedWork === true || typeView === 'VIEW'}
                                                            checked={publicWork}
                                                            onChange={(e)=>{setPublicWork(e.target.checked)}}
                                                            control={<Switch color="primary" />}
                                                            label="Công khai thông tin tác phẩm trên trang chủ dòng họ"
                                                            labelPlacement="start"
                                                        />
                                                    </Grid>
                                                    <Box sx={{ display: { md: 'flex', xs: 'block' } }}>
                                                        <Grid container spacing={{ md: 2, xs: 1 }}>
                                                            <Grid item md={8} xs={12}>
                                                                <TextField
                                                                    // disabled={selectedWork === true || typeView === 'VIEW'}
                                                                    sx={{
                                                                        pointerEvents:
                                                                            selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                    }}
                                                                    error={validateWork.invalid}
                                                                    margin="normal"
                                                                    label="Tên tác phẩm (*)"
                                                                    fullWidth
                                                                    value={nameWork}
                                                                    onChange={handleChangeInput}
                                                                    name="nameWork"
                                                                    helperText={validateWork.errors}
                                                                />
                                                            </Grid>
                                                            <Grid item md={4} xs={12}>
                                                                <FormControl sx={{ width: '100%', margin: '15px 0' }}>
                                                                    <InputLabel
                                                                        sx={{
                                                                            pointerEvents:
                                                                                selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                        }}
                                                                        id="id_language"
                                                                    >
                                                                        Ngôn ngữ
                                                                    </InputLabel>
                                                                    <Select
                                                                        // //disabled={selectedWork === true}
                                                                        sx={{
                                                                            pointerEvents:
                                                                                selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                        }}
                                                                        labelId="id_language"
                                                                        id="id_language_select"
                                                                        value={language}
                                                                        onChange={handleChangeLanguage}
                                                                        input={<OutlinedInput id="select-language-chip" label="Ngôn ngữ" />}
                                                                        MenuProps={MenuProps}
                                                                    >
                                                                        {dataLanguage?.length > 0 ? (
                                                                            dataLanguage.map((element, index) => (
                                                                                <MenuItem
                                                                                    // //disabled={selectedWork === true}
                                                                                    key={index.toString()}
                                                                                    value={element?.key}
                                                                                    // style={getStyles(
                                                                                    //     element?.config_value?.key,
                                                                                    //     typeName,
                                                                                    //     theme
                                                                                    // )}
                                                                                    sx={{ zIndex: 999999 }}
                                                                                >
                                                                                    {element?.name}
                                                                                </MenuItem>
                                                                            ))
                                                                        ) : (
                                                                            <MenuItem sx={{ pointerEvents: 'none' }}>
                                                                                Chưa có ngôn ngữ nào
                                                                            </MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box sx={{ display: { md: 'flex', xs: 'block' } }}>
                                                        <Grid container spacing={{ md: 2, xs: 1 }}>
                                                            <Grid item md={8} xs={12}>
                                                                <FormControl sx={{ width: '100%', margin: '15px 0' }}>
                                                                    <InputLabel
                                                                        // disabled={typeView === 'VIEW'}
                                                                        id="demo-multiple-chip-label"
                                                                        sx={{
                                                                            pointerEvents:
                                                                                selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                        }}
                                                                    >
                                                                        Thể loại
                                                                    </InputLabel>
                                                                    <Select
                                                                        // disabled={selectedWork === true || typeView === 'VIEW'}
                                                                        sx={{
                                                                            pointerEvents:
                                                                                selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                        }}
                                                                        labelId="demo-multiple-chip-label"
                                                                        id="demo-multiple-chip"
                                                                        multiple={Array.isArray(typeName) ? true : false}
                                                                        value={typeName}
                                                                        onChange={handleChange}
                                                                        input={<OutlinedInput id="select-multiple-chip" label="Thể loại" />}
                                                                        // renderValue={(selected) => (
                                                                        //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        //         {selected?.map((value) => {
                                                                        //             if (findName(value).length !== 0) {
                                                                        //                 return <Chip key={value} label={findName(value)} />;
                                                                        //             }
                                                                        //             return null;
                                                                        //         })}
                                                                        //     </Box>
                                                                        // )}
                                                                        renderValue={(selected) => (
                                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                {Array.isArray(selected) ? (
                                                                                    selected?.map((value) => {
                                                                                        if (findName(value).length !== 0) {
                                                                                            return (
                                                                                                <Chip key={value} label={findName(value)} />
                                                                                            );
                                                                                        }
                                                                                        return null;
                                                                                    })
                                                                                ) : (
                                                                                    <Chip key={selected} label={findName(selected)} />
                                                                                )}
                                                                            </Box>
                                                                        )}
                                                                        MenuProps={MenuProps}
                                                                    >
                                                                        {dataType?.length > 0 ? (
                                                                            dataType.map((element, index) => (
                                                                                <MenuItem
                                                                                    disabled={selectedWork === true}
                                                                                    key={index.toString()}
                                                                                    value={element?.config_value?.genre_id}
                                                                                    style={getStyles(
                                                                                        element?.config_value?.genre_id,
                                                                                        typeName,
                                                                                        theme
                                                                                    )}
                                                                                    sx={{ zIndex: 999999 }}
                                                                                >
                                                                                    {element?.config_value?.genre_name}
                                                                                </MenuItem>
                                                                            ))
                                                                        ) : (
                                                                            <MenuItem sx={{ pointerEvents: 'none' }}>
                                                                                Chưa có thể loại nào
                                                                            </MenuItem>
                                                                        )}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={4} xs={12}>
                                                                <TextField
                                                                    // disabled={selectedWork === true || typeView === 'VIEW'}
                                                                    error={validateTopic.invalid}
                                                                    margin="normal"
                                                                    label="Chủ đề"
                                                                    fullWidth
                                                                    value={topic}
                                                                    onChange={handleChangeInput}
                                                                    name="topic"
                                                                    helperText={validateTopic.errors}
                                                                    sx={{
                                                                        pointerEvents:
                                                                            selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Grid item>
                                                        <TextField
                                                            // disabled={selectedWork === true || typeView === 'VIEW'}
                                                            error={validateStorageLocation.invalid}
                                                            margin="normal"
                                                            label="Nơi lưu trữ"
                                                            fullWidth
                                                            value={storageLocation}
                                                            onChange={handleChangeInput}
                                                            name="storageLocation"
                                                            helperText={validateStorageLocation.errors}
                                                            sx={{
                                                                pointerEvents: selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <TextField
                                                            // disabled={selectedWork === true || typeView === 'VIEW'}
                                                            error={validateYear.invalid}
                                                            margin="normal"
                                                            label="Thời gian phát hành"
                                                            rows={3}
                                                            multiline
                                                            fullWidth
                                                            value={year}
                                                            onChange={handleChangeInput}
                                                            name="year"
                                                            helperText={validateYear.errors}
                                                            sx={{
                                                                pointerEvents: selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item>
                                                        <TextField
                                                            // disabled={selectedWork === true || typeView === 'VIEW'}
                                                            error={validateContent.invalid}
                                                            margin="normal"
                                                            label="Tóm tắt/Giới thiệu sơ lược"
                                                            multiline
                                                            rows={3}
                                                            // maxRows={7}
                                                            fullWidth
                                                            value={content}
                                                            onChange={handleChangeInput}
                                                            name="content"
                                                            helperText={validateContent.errors}
                                                            sx={{
                                                                pointerEvents: selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            // disabled={selectedWork === true || typeView === 'VIEW'}
                                                            error={validateAward.invalid}
                                                            margin="normal"
                                                            label="Giải thưởng"
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            value={award}
                                                            onChange={handleChangeInput}
                                                            name="award"
                                                            helperText={validateAward.errors}
                                                            sx={{
                                                                pointerEvents: selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            // disabled={selectedWork === true || typeView === 'VIEW'}
                                                            error={validateNote.invalid}
                                                            margin="normal"
                                                            label="Nhận xét/Đánh giá tác phẩm"
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            value={note}
                                                            onChange={handleChangeInput}
                                                            name="note"
                                                            helperText={validateNote.errors}
                                                            sx={{
                                                                pointerEvents: selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </MainCardIcon>
                                    <MainCardIcon
                                        title="Lưu trữ nội dung tác phẩm"
                                        sx={{ marginTop: '14px', backgroundColor: selectedWork ? 'whitesmoke' : 'none' }}
                                        showBackGround={true}
                                        icon={upload}
                                    >
                                        {typeView !== 'VIEW' && (
                                            <Tooltip
                                                placement="top-start"
                                                title={
                                                    <Box sx={{ display: { md: 'flex', xs: 'none' }, flexDirection: 'column' }}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: 'white', fontSize: '14px', marginBottom: '10px' }}
                                                        >
                                                            * Đường dẫn lưu trữ file tài liệu chỉ hiển thị file pdf, img.
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle1"
                                                            sx={{ color: 'white', fontSize: '14px', marginBottom: '10px' }}
                                                        >
                                                            * Đường dẫn lưu trữ file tài liệu chỉ upload được tối đa 20 file.
                                                        </Typography>
                                                    </Box>
                                                }
                                            >
                                                <IconButton
                                                    size="1px"
                                                    sx={{ position: 'absolute', top: 5, left: 235, display: { md: 'flex', xs: 'none' } }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {showSavePath && typeView === 'VIEW' && (
                                            <Button
                                                startIcon={<VisibilityIcon />}
                                                onClick={openModalContentWork}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                sx={{ position: 'absolute', top: 10, right: 115, display: { md: 'flex', xs: 'none' } }}
                                            >
                                                Xem nội dung
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            sx={{ position: 'absolute', top: 10, right: 10 }}
                                            onClick={showSavePath ? onHideSavePath : onShowSavePath}
                                            startIcon={
                                                showSavePath ? <KeyboardArrowUpIcon size="large" /> : <KeyboardArrowDownIcon size="large" />
                                            }
                                        >
                                            {showSavePath ? 'Thu gọn' : 'Mở rộng'}
                                        </Button>
                                        {showSavePath && (
                                            <Grid md={12} item style={{ paddingLeft: 0, flexDirection: 'column' }}>
                                                {typeView === 'VIEW' && (
                                                    <Button
                                                        startIcon={<VisibilityIcon />}
                                                        onClick={openModalContentWork}
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        sx={{ display: { md: 'none', xs: 'flex' } }}
                                                    >
                                                        Xem nội dung
                                                    </Button>
                                                )}
                                                <Grid item>
                                                    <TextField
                                                        // disabled={selectedWork === true || typeView === 'VIEW'}
                                                        error={validateUrlLink.invalid}
                                                        margin="normal"
                                                        label="Đường dẫn trực tuyến"
                                                        fullWidth
                                                        value={urlLink}
                                                        onChange={handleChangeInput}
                                                        name="urlLink"
                                                        helperText={validateUrlLink.errors}
                                                        sx={{
                                                            pointerEvents: selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Autocomplete
                                                            open={false}
                                                            sx={{
                                                                marginTop: '15px',
                                                                disabled: 'disabled',
                                                                marginBottom: '0px !important',
                                                                pointerEvents: typeView === 'VIEW' ? 'none' : '',
                                                                // width: matchDownMd ? '60%' : '100%'
                                                                '& .MuiAutocomplete-clearIndicator': {
                                                                    display: 'none'
                                                                }
                                                            }}
                                                            fullWidth
                                                            multiple={fileUpload?.length > 0 ? true : false}
                                                            // placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                                            helperText={validateFileUpload.errors}
                                                            error={validateFileUpload.invalid}
                                                            value={fileUpload?.length > 0 ? fileUpload : []}
                                                            clearOnBlur={false}
                                                            options={[]}
                                                            getOptionLabel={(option) =>
                                                                option.name ? option.name : option.nameFile ? option.nameFile : ''
                                                            }
                                                            renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => (
                                                                    <Chip
                                                                        variant="outlined"
                                                                        label={
                                                                            option.name
                                                                                ? option.name
                                                                                : option.nameFile
                                                                                ? option.nameFile
                                                                                : ''
                                                                        }
                                                                        {...getTagProps({ index })}
                                                                        // onDelete={() => handleDeleteFile(option)}
                                                                        deleteIcon={
                                                                            <CloseIcon style={{ color: option?.eff ? 'blue' : 'red' }} />
                                                                        }
                                                                        onDelete={() => handleDeleteFile(option)}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    sx={{
                                                                        disabled: 'disabled',
                                                                        marginBottom: '0px !important',
                                                                        pointerEvents: typeView === 'VIEW' ? 'none' : ''
                                                                        // width: matchDownMd ? '60%' : '100%'
                                                                    }}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    label="Đường dẫn lưu trữ tư liệu dòng họ"
                                                                    placeholder={
                                                                        fileUpload.length === 0
                                                                            ? 'Nhập đường dẫn lưu trữ file tài liệu...'
                                                                            : ''
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <input
                                                            style={{ display: 'none' }}
                                                            ref={inputRef}
                                                            type="file"
                                                            multiple
                                                            onChange={onPathChange}
                                                        />
                                                        {!selectedWork && typeView !== 'VIEW' && (
                                                            <>
                                                                <Button
                                                                    // disabled={selectedWork === true || typeView === 'VIEW'}
                                                                    variant="contained"
                                                                    name="fileUpload"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        display: { md: 'flex', xs: 'none' }
                                                                        // pointerEvents:
                                                                        //     selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                    }}
                                                                    color="primary"
                                                                    // onClick={handleUploadFile}
                                                                    onClick={handleUploadFile}
                                                                    startIcon={<AttachFileIcon />}
                                                                >
                                                                    Chọn File
                                                                </Button>
                                                                <Button
                                                                    onClick={handleUploadFile}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        // pointerEvents: typeMode === VIEW ? 'none' : '',
                                                                        display: { md: 'none', xs: 'flex' }
                                                                    }}
                                                                >
                                                                    <AttachFileIcon />
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <TextField
                                                            // disabled={selectedWork === true || typeView === 'VIEW'}
                                                            fullWidth
                                                            sx={{
                                                                disabled: 'disabled',
                                                                marginBottom: '0px !important',
                                                                pointerEvents: selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                            }}
                                                            type="text"
                                                            // error={validateFileUpload.invalid}
                                                            margin="normal"
                                                            label={'Đường dẫn lưu trữ từ phả kí dòng họ'}
                                                            value={idDiary.title ?? ''}
                                                            name="path"
                                                            // helperText={validateFileUpload.errors}
                                                            placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                                        />
                                                        {!selectedWork && typeView !== 'VIEW' && (
                                                            <>
                                                                <Button
                                                                    // disabled={selectedWork === true || typeView === 'VIEW'}
                                                                    variant="contained"
                                                                    name="fileDiary"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        display: { md: 'flex', xs: 'none' }
                                                                        // pointerEvents:
                                                                        //     selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                    }}
                                                                    color="primary"
                                                                    // onClick={() => openModalDiary()}
                                                                    onClick={openModalDiary}
                                                                    startIcon={<AttachFileIcon />}
                                                                >
                                                                    Chọn từ phả kí
                                                                </Button>
                                                                <Button
                                                                    //disabled={selectedWork === true}
                                                                    variant="contained"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        display: { md: 'none', xs: 'flex' }
                                                                        // pointerEvents: typeMode === VIEW ? 'none' : ''
                                                                    }}
                                                                    color="primary"
                                                                    onClick={() => openModalDiary()}
                                                                    // startIcon={<AttachFileIcon />}
                                                                >
                                                                    {/* Chọn từ phả kí */}
                                                                    <AttachFileIcon />
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <TextField
                                                            // disabled={selectedWork === true || typeView === 'VIEW'}
                                                            fullWidth
                                                            sx={{
                                                                disabled: 'disabled',
                                                                marginBottom: '0px !important',
                                                                pointerEvents: selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                // width: matchDownMd ? '60%' : '100%'
                                                            }}
                                                            type="text"
                                                            // error={validateFileUpload.invalid}
                                                            margin="normal"
                                                            label={'Đường dẫn lưu trữ tư liệu dòng họ'}
                                                            value={idDocument.name_document ?? ''}
                                                            name="path"
                                                            // helperText={validateFileUpload.errors}
                                                            placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                                            // disabled
                                                        />
                                                        {!selectedWork && typeView !== 'VIEW' && (
                                                            <>
                                                                <Button
                                                                    // disabled={selectedWork === true || typeView === 'VIEW'}
                                                                    variant="contained"
                                                                    name="fileDocument"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        display: { md: 'flex', xs: 'none' }
                                                                        // pointerEvents:
                                                                        //     selectedWork === true || typeView === 'VIEW' ? 'none' : ''
                                                                    }}
                                                                    color="primary"
                                                                    onClick={openModalDocument}
                                                                    startIcon={<AttachFileIcon />}
                                                                >
                                                                    Chọn từ tài liệu
                                                                </Button>
                                                                <Button
                                                                    //disabled={selectedWork === true}
                                                                    variant="contained"
                                                                    sx={{
                                                                        height: '46px',
                                                                        marginTop: '18px',
                                                                        marginLeft: '6px',
                                                                        // width: '95%',
                                                                        whiteSpace: 'nowrap',
                                                                        display: { md: 'none', xs: 'flex' }
                                                                        // pointerEvents: typeMode === VIEW ? 'none' : ''
                                                                    }}
                                                                    color="primary"
                                                                    onClick={() => openModalDocument()}
                                                                    // startIcon={<AttachFileIcon />}
                                                                >
                                                                    {/* Chọn từ tài liệu */}
                                                                    <AttachFileIcon />
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </MainCardIcon>
                                </Box>
                                <Box>
                                    <MainCardIcon title="Liên hệ tác giả và tác phẩm" icon={book_user} showBackGround={true}>
                                        {/* <IconButton
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                sx={{ position: 'absolute', top: -10, right: 2 }}
                                                onClick={showConnect ? onHideConnect : onShowConnect}
                                            >
                                                {showConnect ? <CloseIcon sx={{ fontSize: '45px' }} /> : <AddIcon sx={{ fontSize: '45px' }} />}
                                            </IconButton> */}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            sx={{ position: 'absolute', top: 10, right: 10 }}
                                            onClick={showConnect ? onHideConnect : onShowConnect}
                                            startIcon={
                                                showConnect ? <KeyboardArrowUpIcon size="large" /> : <KeyboardArrowDownIcon size="large" />
                                            }
                                        >
                                            {showConnect ? 'Thu gọn' : 'Mở rộng'}
                                        </Button>
                                        {showConnect && (
                                            <Grid container style={{ paddingLeft: 0, flexDirection: 'row' }}>
                                                <Grid md={12} item style={{ paddingLeft: 0, flexDirection: 'column' }}>
                                                    <Grid item sx={{ mt: 2 }}>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Typography
                                                                variant="h6"
                                                                gutterBottom
                                                                component="div"
                                                                sx={{
                                                                    marginRight: '10px',
                                                                    marginBottom: '10px',
                                                                    position: 'relative',
                                                                    top: '10px',
                                                                    fontSize: '14px',
                                                                    pointerEvents: typeView === 'VIEW' ? 'none' : ''
                                                                }}
                                                            >
                                                                Tham gia viết:
                                                            </Typography>

                                                            <FormControlLabel
                                                                control={<Checkbox checked={all} name="all" onChange={onChangeAction} />}
                                                                sx={{
                                                                    pointerEvents: typeView === 'VIEW' ? 'none' : ''
                                                                }}
                                                                label="Toàn bộ tác phẩm"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={part} name="part" onChange={onChangeAction} />}
                                                                sx={{
                                                                    pointerEvents: typeView === 'VIEW' ? 'none' : ''
                                                                }}
                                                                label="Một phần tác phẩm"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {part && (
                                                        <Grid item>
                                                            {/* <Checkbox onChange={onChangeAction} name="checkPartWriting" /> */}
                                                            <TextField
                                                                sx={{
                                                                    pointerEvents: typeView === 'VIEW' ? 'none' : ''
                                                                }}
                                                                error={validatePartWriting.invalid}
                                                                margin="normal"
                                                                label="Phần tham gia"
                                                                multiline
                                                                rows={3}
                                                                fullWidth
                                                                value={partWriting}
                                                                onChange={handleChangeInput}
                                                                name="part_writing"
                                                                helperText={validatePartWriting.errors}
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item>
                                                        <TextField
                                                            sx={{
                                                                pointerEvents: typeView === 'VIEW' ? 'none' : ''
                                                            }}
                                                            error={validateTimeWriting.invalid}
                                                            margin="normal"
                                                            label="Thời điểm tham gia viết"
                                                            multiline
                                                            rows={3}
                                                            fullWidth
                                                            value={timeWriting}
                                                            onChange={handleChangeInput}
                                                            name="time_writing"
                                                            helperText={validateTimeWriting.errors}
                                                        />
                                                    </Grid>
                                                    <Grid item sx={{ mt: 2 }}>
                                                        <FormControl fullWidth>
                                                            <InputLabel
                                                                sx={{
                                                                    pointerEvents: typeView === 'VIEW' ? 'none' : ''
                                                                }}
                                                                id="select"
                                                            >
                                                                Vai trò
                                                            </InputLabel>
                                                            <Select
                                                                sx={{
                                                                    pointerEvents: typeView === 'VIEW' ? 'none' : ''
                                                                }}
                                                                label="Vai trò"
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                name="type"
                                                                multiple={Array.isArray(roleAuthor) ? true : false}
                                                                value={roleAuthor}
                                                                onChange={handleChangeAuthorRole}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Vai trò" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {Array.isArray(selected) ? (
                                                                            selected?.map((value) => {
                                                                                if (findNameRole(value).length !== 0) {
                                                                                    return <Chip key={value} label={findNameRole(value)} />;
                                                                                }
                                                                                return null;
                                                                            })
                                                                        ) : (
                                                                            <Chip key={selected} label={findNameRole(selected)} />
                                                                        )}
                                                                    </Box>
                                                                )}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {dataRole.map((element) => (
                                                                    <MenuItem
                                                                        key={element.athour_id}
                                                                        value={element.athour_id}
                                                                        style={getStyles(element?.author_id, roleAuthor, theme)}
                                                                    >
                                                                        {element.athour_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </MainCardIcon>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
            <ModalDiary show={openDiary} handleClose={handleCloseModalDiary} onChooseDiary={onChooseDiary} />
            <ModalDocument show={openDocument} handleClose={handleCloseModalDocument} onChooseDocument={onChooseDocument} />
            <ModalListWork
                show={openModalListWork}
                handleClose={handleCloseModalListWork}
                onSelected={onSelectedWork}
                member={dataWork.id}
            />
            <ModalPreview isOpen={openModalPreview} onClose={() => setOpenModalPreview(false)} dataWork={dataInfoWorks} />
            <Loading show={isLoading} />
        </>
    );
};

export default ModalAddWorkByAuthor;
