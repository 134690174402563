/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    TableCell,
    TableBody,
    Button,
    Grid,
    TextField,
    Stack,
    Tooltip
} from '@mui/material';
// import { makeStyles } from '@mui/styles';
import TablePagination from '@mui/material/TablePagination';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import SearchIcon from '@mui/icons-material/Search';
import DialogUtil from 'ui-component/show_confirm_action';
import { makeStyles } from '@mui/styles';
import NotFound from 'ui-component/404/404';
import { Close } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import ReportIcon from '@mui/icons-material/Report';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loading from 'ui-component/Loading';
import { getRoleButton } from 'utils/roleUility';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    tableRow: {
        height: 30,
        '&:hover': {
            backgroundColor: '#a9f1fc !important'
        },
        [theme.breakpoints.down('md')]: {
            '&:hover': {
                backgroundColor: 'transparent'
            }
        }
    },
    tableCell: {
        padding: '8px'
    }
}));

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { md: '45%', xs: '98%' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 2
};

const ModalListMember = ({ show, handleClose, onSelected, member, type }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const classes = useStyles();
    const [dataList, setDataList] = useState([]);
    const [idChoose, setIdChoose] = useState('');
    const [nameChoose, setNameChoose] = useState('');
    const [nameSearch, setNameSearch] = useState('');
    const [dataMember, setDataMember] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);

    const getList = async () => {
        setLoading(true);
        const obj = {
            searchName: nameSearch,
            rowsPerpage: rowsPerPage,
            currentPage: page
        };
        const response = await fetchClient.postRequest(router.SUB_URL.member_listAuthorMember, obj);
        if (response?.status === 201) {
            setTotalRows(response?.data?.total);
            setDataList(response?.data?.original || []);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (show === true) {
            getList();
        }
    }, [show, rowsPerPage, page]);

    const columns = [
        { id: 'stt', label: 'STT', minWidth: 70, align: 'center', rowAlign: 'center', xsDisplay: 'none' },
        {
            id: 'full_name',
            label: 'Họ và tên',
            width: 170,
            align: 'center',
            rowAlign: 'center',
            color: 'black',
            sx: { position: 'sticky', left: '0px', zIndex: '99', borderRight: { md: 'none', xs: '1px solid white' } }
        },
        { id: 'gender', label: 'Giới tính', width: 150, align: 'center', rowAlign: 'center', color: 'black' },
        { id: 'level', label: 'Đời', width: 150, align: 'center', rowAlign: 'center', color: 'black' },
        {
            id: 'birthDay',
            label: 'Ngày sinh',
            width: 150,
            align: 'center',
            rowAlign: 'center',
            color: 'black'
        }
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onClickRow = (value) => {
        // eslint-disable-next-line no-underscore-dangle
        // console.log('value', value);
        setDataMember(value);
        setNameChoose(value.full_name);
        setIdChoose(value._id);
    };

    const onCloseModal = () => {
        setIdChoose('');
        setNameChoose('');
        setNameSearch('');
        setPage(0);
        setRowsPerPage(10);
        setTotalRows(0);
        setDataList([]);
        handleClose();
    };

    const onSave = async () => {
        // console.log('id child ', idChoose);
        const objSave = {
            id: idChoose,
            name: nameChoose,
            data: dataMember
        };

        onSelected(objSave);
        setIdChoose('');
        setNameChoose('');
        setNameSearch('');
        setPage(0);
        setRowsPerPage(10);
        setTotalRows(0);
        setDataList([]);
        handleClose();
    };

    const onChangeInput = (e) => {
        setNameSearch(e.target.value);
    };

    const onSearch = async () => {
        const obj = {
            searchName: nameSearch.trim(),
            rowsPerpage: rowsPerPage,
            currentPage: page
        };
        const response = await fetchClient.postRequest(router.SUB_URL.member_listAuthorMember, obj);
        setPage(0);
        setRowsPerPage(10);
        setTotalRows(response?.data?.total);
        setDataList(response?.data?.original || []);
        setIdChoose('');
        setNameChoose('');
    };
    useEffect(() => {
        if (nameSearch !== null) {
            const delayDebounceFn = setTimeout(async () => {
                onSearch();
            }, 1000);
            return () => clearTimeout(delayDebounceFn);
        }
        return null;
    }, [nameSearch]);

    return (
        <>
            <Modal
                open={show}
                onClose={() => onCloseModal()}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style} fullWidth>
                    <Grid container spacing={0}>
                        <Grid item xs={9} md={9}>
                            <Typography id="modal-modal-title" variant="h3" component="h2">
                                Danh sách thành viên
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            md={3}
                            direction="row"
                            sx={{
                                display: {
                                    md: 'flex',
                                    xs: 'flex'
                                },
                                flexDirection: { md: 'row', xs: 'column-reverse' },
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                disabled={idChoose === '' || dataMember?.authors_table.length > 0}
                                onClick={onSave}
                                color="primary"
                                variant="contained"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                startIcon={<SaveIcon />}
                            >
                                Chọn
                            </Button>
                            {/* )} */}
                            <Button
                                color="primary"
                                variant="contained"
                                className="mr-2"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={onCloseModal}
                                startIcon={<Close />}
                            >
                                Đóng
                            </Button>
                        </Grid>
                    </Grid>

                    <div>
                        <Grid container spacing={1} sx={{ flexDirection: 'row', marginTop: 2 }}>
                            <Grid item md={10} xs={10}>
                                <TextField
                                    label="Tìm kiếm..."
                                    fullWidth
                                    value={nameSearch}
                                    onChange={onChangeInput}
                                    sx={{ height: 5, marginRight: 1 }}
                                    placeholder="Nhập tên cần tìm..."
                                />
                            </Grid>
                            {/* <Grid item md={2} xs={2}>
                            <Button sx={{ height: 50 }} aria-label="add" variant="outlined" size="small" onClick={() => onSearch()}>
                                <SearchIcon />
                            </Button>
                            <IconButton type="submit" onClick={() => onSearch()}>
                                <SearchIcon style={{ fill: 'blue' }} />
                            </IconButton>
                        </Grid> */}
                        </Grid>
                        <Stack spacing={1}>
                            <Paper>
                                {dataList?.length > 0 ? (
                                    <TableContainer sx={{ maxHeight: 440, position: 'relative', marginTop: '50px' , '&::-webkit-scrollbar': {
                                        width: { md: '0.4em', xs: '3px' },
                                        height: { md: '5px', xs: '3px' }
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: customization?.themeDongHo?.heading,
                                        outline: 'slategrey'
                                    },}}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map(
                                                        (column) =>
                                                            ((!isMobile && column.mdDisplay !== 'none') ||
                                                                (isMobile && column.xsDisplay !== 'none')) && (
                                                                <TableCell
                                                                    variant="head"
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    sx={{
                                                                        top: 1,
                                                                        minWidth: column.width,
                                                                        color: column.color,
                                                                        ...column.sx
                                                                    }}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            )
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataList.map((row, index) => (
                                                    <TableRow
                                                        //  member === row.id ? 'black' : idChoose === row._id ? '' :
                                                        onClick={() => onClickRow(row)}
                                                        hover
                                                        className={!isMobile && classes.tableRow}
                                                        role="checkbox"
                                                        key={row._id}
                                                        tabIndex={-1}
                                                        style={{
                                                            height: 5,
                                                            // eslint-disable-next-line no-underscore-dangle
                                                            backgroundColor: idChoose === row._id ? '#b3c7e8' : '#f0f0f0'
                                                        }}
                                                        // sx={{ pointerEvents: row?.authors_table.length > 0 ? 'none' : '' }}
                                                    >
                                                        {!isMobile && (
                                                            <TableCell style={{ height: 'auto !important' }} align="center">
                                                                {index + 1 + page * (rowsPerPage - 0)}
                                                            </TableCell>
                                                        )}
                                                        <TableCell
                                                            sx={{
                                                                height: 'auto !important',
                                                                position: { md: 'relative', xs: 'sticky' },
                                                                left: '0px',
                                                                zIndex: '9',
                                                                ...(isMobile && {
                                                                    backgroundColor: idChoose === row._id ? '#b3c7e8' : '#f0f0f0'
                                                                })
                                                            }}
                                                            align="left"
                                                        >
                                                            {row?.full_name}

                                                            {row?.authors_table.length > 0 && type && (
                                                                <Tooltip title="Tác giả đã tồn tại">
                                                                    <ReportIcon sx={{ ml: 1 }}></ReportIcon>
                                                                </Tooltip>
                                                            )}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="center">
                                                            {/* {row.gender === 2 ? 'Nữ' : row.gender === 1 ? 'Nam' : 'Chưa rõ'} */}
                                                            {row?.gender === 2 ? 'Nữ' : row.gender === 1 ? 'Nam' : 'Chưa rõ'}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="center">
                                                            {row?.level}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important' }} align="center">
                                                            {[
                                                                row?.birth_day_solar?.day,
                                                                row?.birth_day_solar?.month,
                                                                row?.birth_day_solar?.year
                                                            ]
                                                                .filter((i) => i)
                                                                .join('/ ')}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <NotFound />
                                )}
                                <TablePagination
                                    sx={{
                                        overflowX: 'hidden',
                                        '.MuiInputBase-root': {
                                            marginRight: { xs: '10px', sm: '32px' }
                                        },
                                        '.MuiToolbar-root': {
                                            paddingLeft: '0px !important'
                                        },
                                        '.MuiTablePagination-actions': {
                                            marginLeft: { xs: '0px !important', sm: '20px !important' }
                                        }
                                    }}
                                    labelRowsPerPage="Phân trang"
                                    // rowsPerPageOptions={[10, 20, 30]}
                                    component="div"
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    showFirstButton
                                    showLastButton
                                />
                            </Paper>
                        </Stack>
                    </div>
                </Box>
            </Modal>
            <Loading show={loading}></Loading>
        </>
    );
};
export default ModalListMember;
