import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

const Splash = Loadable(lazy(() => import('views/pages/authentication/Splash')));

const SplashRoutes = {
    path: '/',
    element: <Splash />
};

export default SplashRoutes;
