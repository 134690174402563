import 'react-image-crop/dist/ReactCrop.css';
import 'react-image-crop/src/ReactCrop.scss';
import React, { useEffect, useState } from 'react';
import { CardMedia, Grid } from '@mui/material';
import ReactCrop, { centerCrop, makeAspectCrop, Crop } from 'react-image-crop';
function ImageCropper(props) {
    const { imageToCrop, onImageCropped, setImageFile, aspect, circularCrop = false, locked = false, mimeTypeImage = 'image/png' } = props;

    let fileUrl;

    const [cropConfig, setCropConfig] = useState(
        // default crop config
        {
            unit: '%',
            width: 100,
            height: 100,
            aspect, // hinh vuong / hinh chu nhat
            y: 0,
            x: 0
        }
    );

    const [imageRef, setImageRef] = useState();
    const [imageCroped, setImageCroped] = useState();

    const centerAspectCrop = (mediaWidth, mediaHeight, aspect) =>
        centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );

    function getCroppedImage(sourceImage, cropConfig, fileName) {
        // creating the cropped image from the source image
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        const ctx = canvas.getContext('2d');

        canvas.width = cropConfig.width * pixelRatio * scaleX;
        canvas.height = cropConfig.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        // ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY
        );
        const base64Image = canvas.toDataURL(mimeTypeImage, 1);
        return base64Image;
    }

    async function getDimensions(image) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = image;

            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
        });
    }

    async function resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = base64Str;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const MAX_WIDTH = maxWidth;
                const MAX_HEIGHT = maxHeight;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL(mimeTypeImage));
            };
        });
    }

    async function cropImage(crop) {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(
                imageRef,
                crop,
                `${Date.now()}_croppedImage.png` // destination filename
            );
            const dataStyle = getDimensions(croppedImage);

            dataStyle.then((result) => {
                if (result.width > 900 && result.height > 1200) {
                    resizeImage(croppedImage, result.width > 900 ? 900 : result.width, result.height > 1200 ? 1200 : result.height).then(
                        (rst) => {
                            setImageCroped(rst)
                            onImageCropped(rst);
                        }
                    );
                } else {
                    setImageCroped(croppedImage)
                    onImageCropped(croppedImage);
                }
            });
        }
    }

    const onImageLoad = (e) => {
        setImageRef(e.currentTarget);
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCropConfig(centerAspectCrop(width, height, aspect));
        }
    };

    const calculateSubWidthHeight = (imageWidth, imageHeight, aspect) => {
        let height = 0;
        let width = 0;
        if (imageWidth < imageHeight) {
            width = imageWidth;
            if (imageWidth / imageHeight > aspect) {
                height = imageHeight;
            } else {
                height = imageHeight * 0.75;
            }
        } else {
            if (imageWidth / imageHeight > aspect) {
                height = imageHeight;
                width = imageHeight * aspect;
            } else {
                width = imageWidth;
                height = imageWidth / aspect;
            }
        }
        return { width, height };
    };

    // lan dau upload anh thi cat anh
    useEffect(() => {
        if (imageRef) {
            cropImage({
                height: calculateSubWidthHeight(imageRef.width, imageRef.height, aspect).height,
                width: calculateSubWidthHeight(imageRef.width, imageRef.height, aspect).width,
                x: imageRef.width / 2 - calculateSubWidthHeight(imageRef.width, imageRef.height, aspect).width / 2,
                y: Math.abs(imageRef.height / 2 - calculateSubWidthHeight(imageRef.width, imageRef.height, aspect).height / 2)
            });
        }
    }, [imageRef]);

    return (
        <Grid sx={{ minWidth: '80%', maxHeight: '95%', display: 'flex', alignItems: 'center' }} container spacing={1}>
            <Grid item xs={10} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <ReactCrop
                    crop={cropConfig}
                    aspect={aspect}
                    ruleOfThirds
                    onComplete={(cropConfig) => cropImage(cropConfig)}
                    onChange={(cropConfig) => {
                        setCropConfig(cropConfig);
                    }}
                    crossorigin="anonymous" // to avoid CORS-related problems
                    circularCrop={circularCrop}
                    locked={locked}
                >
                    <CardMedia
                        component="img"
                        sx={{ maxHeight: '80vh !important' }}
                        fullWidth
                        type="file"
                        image={imageToCrop}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            </Grid>
            <Grid item xs={2} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <CardMedia
                    component="img"
                    sx={{ maxHeight: '80vh !important', border: 1}}
                    fullWidth
                    type="file"
                    image={imageCroped}
                />
            </Grid>
        </Grid>
    );
}

ImageCropper.defaultProps = {
    onImageCropped: () => {}
};

export default ImageCropper;
