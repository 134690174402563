import { useState, useEffect, useRef } from 'react';
import {
    Modal,
    Box,
    Typography,
    Badge,
    FormControl,
    Button,
    Avatar,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Chip,
    FormControlLabel,
    Checkbox,
    Icon,
    IconButton,
    Switch
} from '@mui/material';

import Loading from 'ui-component/Loading';
import DialogUtil from 'ui-component/show_confirm_action';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import user from '../../../../../assets/images/users/user.png';
import poetry from '../../../../../assets/images/users/poetry.png';
import { Close } from '@mui/icons-material';
import router from 'routes/router';
import config from '../../../../../config';
import fetchClient from 'views/utilities/RestfulAPI';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { getRoleButton } from 'utils/roleUility';
import ConfigRouter from 'routes/config_router';
import { useNavigate } from 'react-router';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector } from 'react-redux';
import MainCardIcon from 'ui-component/cards/MainCardIcon';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ListWorkCommon from '../../common/ListWorkCommon';
import ModalListMember from './ModalListMember';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isMobile } from 'react-device-detect';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95vw' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: { md: 2, xs: 1 }
};

const styleBox = {
    maxHeight: { xs: '75vh', sm: '87vh' },
    overflowY: 'auto'
};

const totalDaySolar = 31;
const monthInYear = 12;
const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const UPDATE = 'CAP_NHAP_TG';
const ADD = 'THEM_TG';
const VIEW = 'XEM_THONG_TIN_TG';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 90
        }
    }
};

const EditIconButton = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`
}));

function getStyles(name, typeName, theme) {
    return {
        fontWeight: typeName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    };
}

const BRITHDAYINITIALVALUE = {
    day: '',
    month: '',
    year: ''
};

const convert22digitStr = (n) => (n < 10 && n > -1 ? `0${n}` : n?.toString());
const imageMimeType = /image\/(png|jpg|jpeg|PNG|JPG|JPEG)/i;

const ModalAddAuthor = ({ open, handleClose, typeModel, idAuthor, onReload, roles }) => {
    const theme = useTheme();
    const imageRef = useRef();
    const showOpenFileDialog = () => imageRef.current.click();
    // const [valueBirthDay, setValueBirthDay] = useState(initialValue);
    const [award, setAward] = useState('');
    const [type, setType] = useState('');
    const [pseudonym, setPseudonym] = useState('');
    const [nameAuthor, setNameAuthor] = useState('');
    const [homeTown, setHomeTown] = useState('');
    const [biography, setBiography] = useState('');
    const [publicCreator, setPublicCreator] = useState(false);
    const [valueBirthDay, setValueBirthDay] = useState(BRITHDAYINITIALVALUE);
    const [valueDeadDay, setValueDeadDay] = useState(BRITHDAYINITIALVALUE);
    const [validateHomeTown, setValidateHomeTown] = useState({ invalid: false, errors: '' });
    const [validateBiography, setValidateBiography] = useState({ invalid: false, errors: '' });
    const [validateBirthDay, setValidateBirthDay] = useState({ invalid: false, errors: '' });
    const [validateDeadDay, setValidateDeadDay] = useState({ invalid: false, errors: '' });
    const [validateAuthor, setValidateAuthor] = useState({ invalid: false, errors: '' });
    const [validatePseudonym, setValidatePseudonym] = useState({ invalid: false, errors: '' });
    const [validateType, setValidateType] = useState({ invalid: false, errors: '' });
    const [validateAward, setValidateAward] = useState({ invalid: false, errors: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [dataImageCrop, setDataImageCrop] = useState({});
    const [typeModalImage, setTypeModalImage] = useState('add');
    const [openModalImage, setOpenModalImage] = useState(false);

    const [openModalMember, setOpenModalMember] = useState(false);
    const [idMember, setIdMember] = useState(null);
    const [selectedMember, setSelectedMember] = useState(false);
    const [avatar, setAvatar] = useState(null);
    const [authorMain, setAuthorMain] = useState(true);
    const [urlAvatar, setUrlAvatar] = useState('');
    const [secondaryAuthor, setSecondaryAuthor] = useState(false);
    const [dataWork, setDataWork] = useState({});
    const [dataAvatar, setDataAvatar] = useState({});
    const [dataAuthor, setDataAuthor] = useState([]);
    const [typeName, setTypeName] = useState([]);
    const [creativePhase, setCreativePhase] = useState('');
    const [dataInfoAuthor, setDataInfoAuthor] = useState(null);
    const [dataType, setDataType] = useState();
    const customization = useSelector((state) => state.customization);
    const [showAuthor, setShowAuthor] = useState(false);
    const [showWork, setShowWork] = useState(true);
    const [isChangeInfo, setIsChangeInfo] = useState(false);
    const [loading, setloading] = useState(false);

    const handleChangeType = (event) => {
        const {
            target: { value }
        } = event;
        setTypeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );

        setIsChangeInfo(true);
    };

    const handleClearBirthDaySolar = (type) => {
        switch (type) {
            case 'start':
                setValueBirthDay(BRITHDAYINITIALVALUE);
                break;
            case 'end':
                setValueDeadDay(BRITHDAYINITIALVALUE);
                break;
            default:
                break;
        }
        setIsChangeInfo(false);
    };

    const handleChange = (e) => {
        const name = e.target?.name;
        const value = e.target?.value;
        switch (name) {
            case 'nameAuthor':
                if (value.trim() === '') {
                    setNameAuthor(value);
                    setValidateAuthor({
                        invalid: true,
                        errors: 'Tên tác giả không được để trống'
                    });
                    return;
                }
                if (value.length > 50) {
                    setValidateAuthor({
                        invalid: true,
                        errors: 'Tiêu đề bài viết không được quá 50 ký tự'
                    });
                    return;
                }
                setNameAuthor(value);
                setValidateAuthor({ invalid: false, errors: '' });
                break;
            case 'pseudonym':
                if (value.length > 50) {
                    setValidatePseudonym({
                        invalid: true,
                        errors: 'Bút danh không được quá 50 ký tự'
                    });
                    return;
                }
                setPseudonym(value);
                setValidatePseudonym({ invalid: false, errors: '' });
                break;
            case 'type':
                if (value.length > 50) {
                    setValidateType({
                        invalid: true,
                        errors: 'Thể loại không được quá 50 ký tự'
                    });
                    return;
                }
                setType(value);
                setValidateType({ invalid: false, errors: '' });
                break;
            case 'award':
                setAward(value);
                break;
            case 'creativePhase':
                setCreativePhase(value);
                break;
            case 'homeTown':
                if (value.length > 100) {
                    setValidateHomeTown({
                        invalid: true,
                        errors: 'Quê quán không được quá 100 ký tự'
                    });
                    return;
                }
                setHomeTown(value);
                setValidateHomeTown({ invalid: false, errors: '' });
                break;
            case 'biography':
                //     if (value.length > 1000) {
                //         setValidateBiography({
                //             invalid: true,
                //             errors: 'Tiểu sử không được quá 1000 ký tự'
                //         });
                //         return;
                //     }
                setBiography(value);
                setValidateBiography({ invalid: false, errors: '' });
                break;
            default:
                break;
        }

        if (value !== '') {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    const handleChangeBirthDay = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'dayBirthDay':
                setValueBirthDay((prev) => ({
                    ...prev,
                    day: e.target.value.toString()
                }));
                setValidateBirthDay({ invalid: false, errors: '' });
                break;
            case 'monthBirthDay':
                setValueBirthDay((prev) => ({
                    ...prev,
                    month: e.target.value.toString()
                }));
                setValidateBirthDay({ invalid: false, errors: '' });
                break;
            case 'yearBirthDay':
                setValueBirthDay((prev) => ({
                    ...prev,
                    year: e.target.value.toString()
                }));
                setValidateBirthDay({ invalid: false, errors: '' });
                break;
            default:
                break;
        }

        if (value !== '') {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    const handleChangeDeadDay = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'dayDeadDay':
                setValueDeadDay((prev) => ({
                    ...prev,
                    day: e.target.value.toString()
                }));
                setValidateDeadDay({ invalid: false, errors: '' });
                break;
            case 'monthDeadDay':
                setValueDeadDay((prev) => ({
                    ...prev,
                    month: e.target.value.toString()
                }));
                setValidateDeadDay({ invalid: false, errors: '' });
                break;
            case 'yearDeadDay':
                setValueDeadDay((prev) => ({
                    ...prev,
                    year: e.target.value.toString()
                }));
                setValidateDeadDay({ invalid: false, errors: '' });
                break;
            default:
                break;
        }

        if (value !== '') {
            setIsChangeInfo(true);
        } else {
            setIsChangeInfo(false);
        }
    };

    const handleCloseModalAuthor = () => {
        setNameAuthor('');
        setPseudonym('');
        setType('');
        setAward('');
        setCreativePhase('');
        setHomeTown('');
        setBiography('');
        setIdMember(null);

        // setFileDiary(null);
        setIsChangeInfo(false);
        setAvatar(null);
        setSelectedMember(false);
        // setValueBirthDay(initialValue);\
        setTypeName([]);
        setValueBirthDay(BRITHDAYINITIALVALUE);
        setValueDeadDay(BRITHDAYINITIALVALUE);

        setValidateHomeTown({ invalid: false, errors: '' });
        setValidateBiography({ invalid: false, errors: '' });
        setValidateBirthDay({ invalid: false, errors: '' });
        setValidateAuthor({ invalid: false, errors: '' });
        setValidatePseudonym({ invalid: false, errors: '' });
        setValidateType({ invalid: false, errors: '' });
        setValidateAward({ invalid: false, errors: '' });
        setShowAuthor(false);
        setShowWork(true);
        handleClose();
        setDataInfoAuthor(null);
    };

    const onCloseModalAuthor = () => {
        if ((isChangeInfo || selectedMember) && typeModel !== VIEW) {
            DialogUtil.showConfirm(
                'Thông báo',
                `Thông tin tác giả chưa được lưu, bạn có muốn thoát không?`,
                () => {},
                () => {
                    handleCloseModalAuthor();
                }
            );
        } else {
            handleCloseModalAuthor();
        }
    };

    const findName = (value) => {
        const rst = dataType
            ?.filter((r) => {
                return r?.config_value.genre_id === value;
            })
            .map((m) => {
                return m?.config_value.genre_name;
            });
        return rst;
    };

    const handleSaveAuthor = () => {
        if (nameAuthor.trim() === '') {
            setValidateAuthor({
                invalid: true,
                errors: 'Tên tác giả không được để trống'
            });
            return;
        }
        if (nameAuthor?.length > 50) {
            setValidateAuthor({
                invalid: true,
                errors: 'Tên tác giả không được quá 50 ký tự'
            });
            return;
        }
        if (pseudonym?.length > 50) {
            setValidatePseudonym({
                invalid: true,
                errors: 'Bút danh không được quá 50 ký tự'
            });
            return;
        }
        // if (award?.length > 50) {
        //     setValidateAward({
        //         invalid: true,
        //         errors: 'Giải thưởng không được quá 50 ký tự'
        //     });
        //     return;
        // }

        if (homeTown?.length > 500) {
            setValidateHomeTown({
                invalid: true,
                errors: 'Quê quán không được quá 500 ký tự'
            });
            return;
        }
        // if (biography?.length > 1000) {
        //     setValidateBiography({
        //         invalid: true,
        //         errors: 'Tiểu sử không được quá 1000 ký tự'
        //     });
        //     return;
        // }

        if (valueBirthDay?.year !== '' && valueDeadDay?.year !== '') {
            if (valueBirthDay?.year > valueDeadDay?.year) {
                setValidateBirthDay({
                    invalid: true,
                    errors: 'Ngày mất không được nhỏ hơn ngày sinh'
                });
                setValidateDeadDay({
                    invalid: true,
                    errors: 'Ngày mất không được nhỏ hơn ngày sinh'
                });
                DialogUtil.showQuestions(false, 'Ngày mất không được nhỏ hơn ngày sinh');
                return;
            }
            if (valueBirthDay?.year === valueDeadDay?.year) {
                if (valueBirthDay?.month > valueDeadDay?.month) {
                    setValidateBirthDay({
                        invalid: true,
                        errors: 'Ngày mất không được nhỏ hơn ngày sinh'
                    });
                    setValidateDeadDay({
                        invalid: true,
                        errors: 'Ngày mất không được nhỏ hơn ngày sinh'
                    });
                    DialogUtil.showQuestions(false, 'Ngày mất không được nhỏ hơn ngày sinh');
                    return;
                }
                if (valueBirthDay?.month === valueDeadDay?.month) {
                    if (valueBirthDay?.day > valueDeadDay?.day) {
                        setValidateBirthDay({
                            invalid: true,
                            errors: 'Ngày mất không được nhỏ hơn ngày sinh'
                        });
                        setValidateDeadDay({
                            invalid: true,
                            errors: 'Ngày mất không được nhỏ hơn ngày sinh'
                        });
                        DialogUtil.showQuestions(false, 'Ngày mất không được nhỏ hơn ngày sinh');
                        return;
                    }
                }
            }
        }

        DialogUtil.showConfirm(
            'Thông báo',
            typeModel === ADD ? `Bạn có muốn thêm mới tác giả không?` : `Bạn có muốn cập nhât thông tin về tác giả ${nameAuthor} không?`,
            () => {},
            () => {
                typeModel === UPDATE ? onUpdateAuthor() : onSave();
            }
        );
    };

    const onSave = async () => {
        const data = {
            name_author: nameAuthor,
            pseudonym: pseudonym,
            type: typeName,
            award: award,
            creative_phase: creativePhase,
            id_member: idMember,
            hometown: homeTown,
            birth_day_solar: valueBirthDay,
            dead_day_solar: valueDeadDay,
            biography: biography,
            avatar: avatar,
            is_public: publicCreator,
        };

        let urlApi = router.SUB_URL.create_author;
        const formData = new FormData();
        formData.append('dataAuthor', JSON.stringify(data));
        formData.append('photo_author', dataAuthor.data_avatar);
        // console.log('formData', formData);
        const result = await fetchClient.sendRequest(urlApi, 'post', formData);
        // console.log('result -----', result);
        if (result?.status === 201) {
            DialogUtil.showQuestions(true, `Thêm mới tác giả thành công`, () => {
                handleCloseModalAuthor();
                onReload();
            });
        } else {
            DialogUtil.showQuestions(false, result?.message ? result?.message : 'Thêm mới tác giả thất bại');
        }
    };

    const onUpdateAuthor = async () => {
        const dataAuthor = {
            id: dataInfoAuthor?._id,
            name_author: nameAuthor,
            hometown: homeTown,
            pseudonym: pseudonym,
            type: typeName,
            award: award,
            biography: biography,
            birth_day_solar: valueBirthDay,
            dead_day_solar: valueDeadDay,
            creative_phase: creativePhase,
            id_member: idMember,
            avatar: avatar,
            is_public: publicCreator,
        };

        let urlApi = router.SUB_URL.update_author;
        const formData = new FormData();
        formData.append('dataAuthor', JSON.stringify(dataAuthor));
        formData.append('photo_author', dataAuthor.data_avatar);
        const result = await fetchClient.sendRequest(urlApi, 'post', formData);
        if (result?.status === 201) {
            DialogUtil.showQuestions(true, `Cập nhập tác giả thành công`, () => {
                handleCloseModalAuthor();
                onReload();
            });
        } else {
            DialogUtil.showQuestions(false, 'Cập nhập tác giả thất bại');
        }
    };

    const getDataType = async () => {
        const dataObject = {
            config_key: 'GENRE'
        };
        let urlApi = router.CONFIG_URL.getAllDataByConfigKey;
        const result = await fetchClient.sendRequest(urlApi, 'post', dataObject);
        if (result?.status === 200) {
            setDataType(result?.data?.data);
        } else {
            DialogUtil.showQuestions(false, 'Lấy danh sách thể loại thất bại');
        }
    };

    const getDataInfo = async () => {
        let urlApi = router.SUB_URL.info_author;
        const obj = {
            id: idAuthor
        };
        const dataRst = await fetchClient.postRequest(urlApi, obj);
        // console.log('dataRst', dataRst);
        if (dataRst?.data[0]?.id_member && dataRst?.data[0]?.id_member !== null) {
            setSelectedMember(true);
        }

        setNameAuthor(dataRst?.data[0]?.name_author);
        setPseudonym(dataRst?.data[0]?.pseudonym);
        setHomeTown(dataRst?.data[0]?.hometown);
        setPublicCreator(dataRst?.data[0]?.is_public);
        setType(dataRst?.data[0]?.type);
        setValueBirthDay(dataRst?.data[0]?.birth_day_solar);
        setValueDeadDay(dataRst?.data[0]?.dead_day_solar);
        setAward(dataRst?.data[0]?.award);
        setCreativePhase(dataRst?.data[0]?.creative_phase);
        setDataInfoAuthor(dataRst?.data[0] ?? null);
        setTypeName(dataRst?.data[0]?.type);
        setBiography(dataRst?.data[0]?.biography);
        setAvatar(dataRst?.data[0]?.avatar);
        setIdMember(dataRst?.data[0]?.id_member);
        setUrlAvatar(config.serverAvatarMember + dataRst?.data[0]?.avatar);
    };

    const handleOpenModalMember = () => {
        setOpenModalMember(true);
    };

    const handleCloseModalMember = () => {
        setOpenModalMember(false);
    };
    const onSelectedMember = (data) => {
        setNameAuthor(data?.name);
        setIdMember(data?.id);
        setHomeTown(data?.data?.home_town);
        setAvatar(data?.data?.url_avatar);
        setUrlAvatar(config.serverAvatarMember + data?.data?.url_avatar);
        setValueBirthDay({
            day: data?.data?.birth_day_solar?.day,
            month: data?.data?.birth_day_solar?.month,
            year: data?.data?.birth_day_solar?.year
        });
        setValueDeadDay({
            day: data?.data?.dead_day_solar?.day,
            month: data?.data?.dead_day_solar?.month,
            year: data?.data?.dead_day_solar?.year
        });
        setValidateAuthor({ invalid: false, errors: '' });
        setValidateHomeTown({ invalid: false, errors: '' });
        setValidateBirthDay({ invalid: false, errors: '' });
        setValidateDeadDay({ invalid: false, errors: '' });
        setValidateAward({ invalid: false, errors: '' });
        setValidateBiography({ invalid: false, errors: '' });
        setValidatePseudonym({ invalid: false, errors: '' });
        setValidateType({ invalid: false, errors: '' });
        setSelectedMember(true);
        // setOpenModalMember(false);
    };

    const handleClearMember = () => {
        setNameAuthor('');
        setIdMember(null);
        setHomeTown('');
        setAvatar(null);
        setValueBirthDay(BRITHDAYINITIALVALUE);
        setValueDeadDay(BRITHDAYINITIALVALUE);
        setSelectedMember(false);
        setValidateDeadDay({ invalid: false, errors: '' });
        setValidateAuthor({ invalid: false, errors: '' });
        setValidateHomeTown({ invalid: false, errors: '' });
        setValidateBirthDay({ invalid: false, errors: '' });
        setValidateAward({ invalid: false, errors: '' });
        setValidateBiography({ invalid: false, errors: '' });
        setValidatePseudonym({ invalid: false, errors: '' });
        setValidateType({ invalid: false, errors: '' });
    };

    const onHideAuthor = () => {
        setShowAuthor(false);
    };

    const onShowAuthor = () => {
        setShowAuthor(true);
    };
    const onHideWork = () => {
        setShowWork(false);
    };

    const onShowWork = () => {
        setShowWork(true);
    };

    useEffect(() => {
        if (open === true) {
            getDataType();
        }
    }, [open]);

    useEffect(() => {
        if (open === true && idAuthor) {
            getDataInfo();
        }
    }, [open, idAuthor]);

    // console.log('idMember', idMember);
    return (
        <>
            <Modal open={open} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginY: '15px',
                            padding: { md: '0px', xs: '10px' }
                        }}
                    >
                        <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ maxWidth: '60%' }}>
                            {typeModel === ADD
                                ? 'Thêm mới tác giả'
                                : typeModel === UPDATE
                                ? `Cập nhật thông tin tác giả ${nameAuthor}`
                                : `Xem thông tin tác giả ${nameAuthor}`}
                        </Typography>

                        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' }, justifyContent: 'flex-end' }}>
                            {typeModel !== VIEW && (
                                <Button
                                    onClick={() => handleSaveAuthor()}
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    sx={{ marginRight: '5px', borderBlockColor: 'black', marginTop: { md: '0px', xs: '5px' } }}
                                >
                                    Lưu
                                </Button>
                            )}
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<CloseIcon />}
                                className="mr-2"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => onCloseModalAuthor()}
                            >
                                Đóng
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            ...styleBox,
                            '&::-webkit-scrollbar': {
                                width: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: customization?.themeDongHo?.heading,
                                outline: 'slategrey'
                            }
                        }}
                    >
                        <Grid container spacing={1} direction="row" style={{ marginTop: 5, justifyContent: 'space-between' }}>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{ flexDirection: 'column' }}>
                                <Box sx={{ marginBottom: 2 }}>
                                    <MainCardIcon title="Thông tin tác giả" icon={user} sx={{ position: 'relative' }} showBackGround={true}>
                                        {typeModel === ADD && (
                                            <>
                                            {!isMobile && (
                                                <Button
                                                    startIcon={selectedMember ? <CloseIcon /> : <DoneAllIcon />}
                                                    onClick={selectedMember ? handleClearMember : handleOpenModalMember}
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ position: 'absolute', top: 5, right: 10 }}
                                                >
                                                    {selectedMember ? 'Bỏ chọn thành viên' : 'Chọn từ danh sách'}
                                                </Button>
                                            )}
                                            {isMobile && (
                                                <Button
                                                    startIcon={selectedMember ? <CloseIcon /> : <DoneAllIcon />}
                                                    onClick={selectedMember ? handleClearMember : handleOpenModalMember}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    sx={{ position: 'absolute', top: 5, right: 10 }}
                                                >
                                                    {selectedMember ? 'Bỏ chọn' : 'Chọn'}
                                                </Button>
                                            
                                            )}
                                            </>
                                        )}
                                        {typeModel === UPDATE && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                sx={{ position: 'absolute', top: 10, right: 10 }}
                                                onClick={showAuthor ? onHideAuthor : onShowAuthor}
                                                startIcon={
                                                    showAuthor ? (
                                                        <KeyboardArrowUpIcon size="large" />
                                                    ) : (
                                                        <KeyboardArrowDownIcon size="large" />
                                                    )
                                                }
                                            >
                                                {showAuthor ? 'Thu gọn' : 'Mở rộng'}
                                            </Button>
                                        )}
                                        {(typeModel === ADD || showAuthor || typeModel === VIEW) && (
                                            <>
                                                <Grid container sx={{ background: selectedMember ? 'whitesmoke' : 'none' }}>
                                                    {idMember ? (
                                                        <Grid
                                                            lg={2}
                                                            md={2}
                                                            sm={12}
                                                            xs={12}
                                                            item
                                                            style={{ paddingLeft: 0, flexDirection: 'column' }}
                                                        >
                                                            <div
                                                                style={{
                                                                    alignItems: 'center',
                                                                    alignContent: 'center',
                                                                    flexDirection: 'row',
                                                                    display: 'flex',
                                                                    justifyContent: 'center'
                                                                }}
                                                            >
                                                                <Badge
                                                                    style={{ transform: 'translate(0px, -5px)', cursor: 'pointer' }}
                                                                    overlap="circular"
                                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                >
                                                                    <Avatar
                                                                        alt="avatar"
                                                                        src={urlAvatar}
                                                                        style={{
                                                                            width: '120px',
                                                                            height: '120px',
                                                                            border: `1px solid gray`
                                                                        }}
                                                                    />
                                                                </Badge>
                                                            </div>
                                                        </Grid>
                                                    ) : null}
                                                    <Grid
                                                        md={idMember ? 10 : 12}
                                                        item
                                                        // sx={{ paddingLeft: { md: '24px', xs: '0px' }, flexDirection: 'column' }}
                                                        spacing={1}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                                                        >
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <TextField
                                                                    error={validateAuthor.invalid}
                                                                    margin="normal"
                                                                    label="Tên tác giả (*)"
                                                                    fullWidth
                                                                    value={nameAuthor}
                                                                    onChange={handleChange}
                                                                    name="nameAuthor"
                                                                    helperText={validateAuthor.errors}
                                                                    sx={{
                                                                        pointerEvents:
                                                                            typeModel === VIEW || selectedMember === true ? 'none' : ''
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <TextField
                                                                    sx={{
                                                                        pointerEvents:
                                                                            typeModel === VIEW || selectedMember === true ? 'none' : ''
                                                                    }}
                                                                    error={validateHomeTown.invalid}
                                                                    helperText={validateHomeTown.errors}
                                                                    margin="normal"
                                                                    label="Quê quán"
                                                                    fullWidth
                                                                    value={homeTown}
                                                                    onChange={handleChange}
                                                                    name="homeTown"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={1}>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item md={1} xs={12}>
                                                                        <Typography
                                                                            sx={{
                                                                                marginTop: '25px',
                                                                                display: 'inline-block'
                                                                            }}
                                                                            variant="span"
                                                                        >
                                                                            Sinh:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid sx={{ display: 'flex' }} item md={11} xs={12}>
                                                                        <FormControl
                                                                            variant="standard"
                                                                            sx={{ m: 1, minWidth: { md: 100, xs: 80 } }}
                                                                        >
                                                                            <InputLabel
                                                                                id="label-date-day_brith_Solar"
                                                                                sx={{
                                                                                    pointerEvents:
                                                                                        typeModel === VIEW || selectedMember === true
                                                                                            ? 'none'
                                                                                            : ''
                                                                                }}
                                                                            >
                                                                                Ngày
                                                                            </InputLabel>
                                                                            <Select
                                                                                name="dayBirthDay"
                                                                                labelId="label-date-day_brith_Solar"
                                                                                id="brith_day_Solar"
                                                                                value={valueBirthDay?.day}
                                                                                label="Ngày"
                                                                                MenuProps={MenuProps}
                                                                                onChange={handleChangeBirthDay}
                                                                                sx={{
                                                                                    pointerEvents:
                                                                                        typeModel === VIEW || selectedMember === true
                                                                                            ? 'none'
                                                                                            : ''
                                                                                }}
                                                                            >
                                                                                {/* <MenuItem value="" /> */}
                                                                                {Array.from(Array(totalDaySolar)).map((_x, index) => (
                                                                                    <MenuItem
                                                                                        value={convert22digitStr(index + 1)}
                                                                                        key={index}
                                                                                    >
                                                                                        {convert22digitStr(index + 1)}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                        <FormControl
                                                                            variant="standard"
                                                                            sx={{ m: 1, minWidth: { md: 100, xs: 80 } }}
                                                                        >
                                                                            <InputLabel
                                                                                id="label-date-month_brith_Solar"
                                                                                sx={{
                                                                                    pointerEvents:
                                                                                        typeModel === VIEW || selectedMember === true
                                                                                            ? 'none'
                                                                                            : ''
                                                                                }}
                                                                            >
                                                                                Tháng
                                                                            </InputLabel>
                                                                            <Select
                                                                                name="monthBirthDay"
                                                                                labelId="label-date-month_brith_Solar"
                                                                                id="brith_month_Solar"
                                                                                value={valueBirthDay?.month}
                                                                                label="Tháng"
                                                                                MenuProps={MenuProps}
                                                                                onChange={handleChangeBirthDay}
                                                                                sx={{
                                                                                    pointerEvents:
                                                                                        typeModel === VIEW || selectedMember === true
                                                                                            ? 'none'
                                                                                            : ''
                                                                                }}
                                                                            >
                                                                                {/* <MenuItem value="" /> */}
                                                                                {Array.from(Array(monthInYear)).map((_x, index) => (
                                                                                    <MenuItem
                                                                                        value={convert22digitStr(index + 1)}
                                                                                        key={index}
                                                                                    >
                                                                                        {convert22digitStr(index + 1)}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                        <TextField
                                                                            name="yearBirthDay"
                                                                            id="brith_year_Solar"
                                                                            label="Năm"
                                                                            onChange={handleChangeBirthDay}
                                                                            // type="number"
                                                                            value={valueBirthDay?.year}
                                                                            variant="standard"
                                                                            sx={{
                                                                                pointerEvents:
                                                                                    typeModel === VIEW || selectedMember === true
                                                                                        ? 'none'
                                                                                        : '',
                                                                                m: 1,
                                                                                minWidth: { md: 100, xs: 80 },
                                                                                maxWidth: { md: 200, xs: 80 }
                                                                                // pointerEvents: role?.roleAlias === VIEWER ? 'none' : ''
                                                                            }}
                                                                            inputProps={{ maxLength: 4 }}
                                                                        />
                                                                        {(valueBirthDay?.month !== '' ||
                                                                            valueBirthDay?.year !== '' ||
                                                                            valueBirthDay?.day !== '') &&
                                                                            typeof valueBirthDay?.year !== 'undefined' &&
                                                                            selectedMember === false &&
                                                                            typeModel !== VIEW && (
                                                                                <ClearIcon
                                                                                    onClick={() => {
                                                                                        handleClearBirthDaySolar('start');
                                                                                    }}
                                                                                    sx={{
                                                                                        marginTop: '30px',
                                                                                        marginLeft: '10px',
                                                                                        color: 'red',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                />
                                                                            )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item md={1} xs={12}>
                                                                        <Typography
                                                                            sx={{
                                                                                marginTop: '25px',
                                                                                display: 'inline-block'
                                                                            }}
                                                                            variant="span"
                                                                        >
                                                                            Mất:
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid sx={{ display: 'flex' }} item md={11} xs={12}>
                                                                        <FormControl
                                                                            variant="standard"
                                                                            sx={{ m: 1, minWidth: { md: 100, xs: 80 } }}
                                                                        >
                                                                            <InputLabel
                                                                                id="label-date-day_brith_Solar"
                                                                                sx={{
                                                                                    pointerEvents:
                                                                                        typeModel === VIEW || selectedMember === true
                                                                                            ? 'none'
                                                                                            : ''
                                                                                }}
                                                                            >
                                                                                Ngày
                                                                            </InputLabel>
                                                                            <Select
                                                                                name="dayDeadDay"
                                                                                labelId="label-date-day_brith_Solar"
                                                                                id="brith_day_Solar"
                                                                                value={valueDeadDay?.day}
                                                                                label="Ngày"
                                                                                MenuProps={MenuProps}
                                                                                sx={{
                                                                                    pointerEvents:
                                                                                        typeModel === VIEW || selectedMember === true
                                                                                            ? 'none'
                                                                                            : ''
                                                                                }}
                                                                                onChange={handleChangeDeadDay}
                                                                            >
                                                                                {/* <MenuItem value="" /> */}
                                                                                {Array.from(Array(totalDaySolar)).map((_x, index) => (
                                                                                    <MenuItem
                                                                                        value={convert22digitStr(index + 1)}
                                                                                        key={index}
                                                                                    >
                                                                                        {convert22digitStr(index + 1)}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                        <FormControl
                                                                            variant="standard"
                                                                            sx={{ m: 1, minWidth: { md: 100, xs: 80 } }}
                                                                        >
                                                                            <InputLabel
                                                                                id="label-date-month_brith_Solar"
                                                                                sx={{
                                                                                    pointerEvents:
                                                                                        typeModel === VIEW || selectedMember === true
                                                                                            ? 'none'
                                                                                            : ''
                                                                                }}
                                                                            >
                                                                                Tháng
                                                                            </InputLabel>
                                                                            <Select
                                                                                name="monthDeadDay"
                                                                                labelId="label-date-month_brith_Solar"
                                                                                id="brith_month_Solar"
                                                                                value={valueDeadDay?.month}
                                                                                label="Tháng"
                                                                                MenuProps={MenuProps}
                                                                                onChange={handleChangeDeadDay}
                                                                                sx={{
                                                                                    pointerEvents:
                                                                                        typeModel === VIEW || selectedMember === true
                                                                                            ? 'none'
                                                                                            : ''
                                                                                }}
                                                                            >
                                                                                {/* <MenuItem value="" /> */}
                                                                                {Array.from(Array(monthInYear)).map((_x, index) => (
                                                                                    <MenuItem
                                                                                        value={convert22digitStr(index + 1)}
                                                                                        key={index}
                                                                                    >
                                                                                        {convert22digitStr(index + 1)}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                        <TextField
                                                                            name="yearDeadDay"
                                                                            id="brith_year_Solar"
                                                                            label="Năm"
                                                                            onChange={handleChangeDeadDay}
                                                                            // type="number"
                                                                            value={valueDeadDay?.year}
                                                                            variant="standard"
                                                                            sx={{
                                                                                pointerEvents:
                                                                                    typeModel === VIEW || selectedMember === true
                                                                                        ? 'none'
                                                                                        : '',
                                                                                m: 1,
                                                                                minWidth: { md: 100, xs: 80 },
                                                                                maxWidth: { md: 200, xs: 80 }
                                                                                // pointerEvents: role?.roleAlias === VIEWER ? 'none' : ''
                                                                            }}
                                                                            inputProps={{ maxLength: 4 }}
                                                                        />
                                                                        {(valueDeadDay?.month !== '' ||
                                                                            valueDeadDay?.year !== '' ||
                                                                            valueDeadDay?.day !== '') &&
                                                                            typeof valueDeadDay?.year !== 'undefined' &&
                                                                            selectedMember === false &&
                                                                            typeModel !== VIEW && (
                                                                                <ClearIcon
                                                                                    onClick={() => {
                                                                                        handleClearBirthDaySolar('end');
                                                                                    }}
                                                                                    sx={{
                                                                                        marginTop: '30px',
                                                                                        marginLeft: '10px',
                                                                                        color: 'red',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                />
                                                                            )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing="1" style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Grid
                                                        item
                                                        sx={{
                                                            // {customization?.themeDongHo?.heading}
                                                            borderTop: `1px solid #90caf975`,
                                                            margin: '20px 0px'
                                                        }}
                                                    ></Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            sx={{ml: '10px'}}
                                                            value="start"
                                                            checked={publicCreator}
                                                            onChange={(e)=>{setPublicCreator(e.target.checked)}}
                                                            control={<Switch color="primary" />}
                                                            label="Công khai thông tin tác giả trên trang chủ dòng họ"
                                                            labelPlacement="start"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            sx={{
                                                                pointerEvents: typeModel === VIEW ? 'none' : ''
                                                            }}
                                                            error={validatePseudonym.invalid}
                                                            margin="normal"
                                                            label="Bút danh"
                                                            // maxRows={7}
                                                            fullWidth
                                                            value={pseudonym}
                                                            onChange={handleChange}
                                                            name="pseudonym"
                                                            helperText={validatePseudonym.errors}
                                                        />
                                                    </Grid>

                                                    <Grid item>
                                                        <FormControl sx={{ width: '100%', margin: '15px 0' }}>
                                                            <InputLabel
                                                                sx={{
                                                                    pointerEvents: typeModel === VIEW ? 'none' : ''
                                                                }}
                                                                id="demo-multiple-chip-label"
                                                            >
                                                                Thể loại
                                                            </InputLabel>
                                                            <Select
                                                                sx={{
                                                                    pointerEvents: typeModel === VIEW ? 'none' : ''
                                                                }}
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                multiple
                                                                value={typeName}
                                                                onChange={handleChangeType}
                                                                input={<OutlinedInput id="select-multiple-chip" label="Thể loại" />}
                                                                renderValue={(selected) => (
                                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                        {selected?.map((value) => {
                                                                            if (findName(value)?.length !== 0) {
                                                                                return <Chip key={value} label={findName(value)} />;
                                                                            }
                                                                            return null;
                                                                        })}
                                                                    </Box>
                                                                )}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {dataType?.length > 0 ? (
                                                                    dataType.map((element, index) => (
                                                                        <MenuItem
                                                                            key={index.toString()}
                                                                            value={element?.config_value?.genre_id}
                                                                            style={getStyles(
                                                                                element?.config_value?.genre_id,
                                                                                typeName,
                                                                                theme
                                                                            )}
                                                                            sx={{ zIndex: 999999 }}
                                                                        >
                                                                            {element?.config_value?.genre_name}
                                                                        </MenuItem>
                                                                    ))
                                                                ) : (
                                                                    <MenuItem sx={{ pointerEvents: 'none' }}>Chưa có thể loại nào</MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item>
                                                        <TextField
                                                            sx={{
                                                                pointerEvents: typeModel === VIEW ? 'none' : ''
                                                            }}
                                                            margin="normal"
                                                            label="Giai đoạn sáng tác"
                                                            fullWidth
                                                            value={creativePhase}
                                                            onChange={handleChange}
                                                            name="creativePhase"
                                                        />
                                                    </Grid>

                                                    <Grid item>
                                                        <TextField
                                                            sx={{
                                                                pointerEvents: typeModel === VIEW ? 'none' : '',
                                                                height: typeModel === VIEW ? '100%' : 'auto'
                                                            }}
                                                            margin="normal"
                                                            error={validateBiography.invalid}
                                                            helperText={validateBiography.errors}
                                                            label="Tiểu sử"
                                                            multiline
                                                            rows={typeModel === VIEW ? 12 : 4}
                                                            fullWidth
                                                            value={biography}
                                                            onChange={handleChange}
                                                            name="biography"
                                                        />
                                                    </Grid>

                                                    <Grid item>
                                                        <TextField
                                                            sx={{
                                                                pointerEvents: typeModel === VIEW ? 'none' : '',
                                                                height: typeModel === VIEW ? '100%' : 'auto'
                                                            }}
                                                            error={validateAward.invalid}
                                                            margin="normal"
                                                            label="Thành tựu"
                                                            multiline
                                                            rows={typeModel === VIEW ? 12 : 4}
                                                            // maxRows={7}
                                                            fullWidth
                                                            value={award}
                                                            onChange={handleChange}
                                                            name="award"
                                                            helperText={validateAward.errors}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </MainCardIcon>
                                </Box>
                                {typeModel == UPDATE && (
                                    <Box sx={{ marginBottom: 4 }}>
                                        <MainCardIcon
                                            title={!isMobile ? `Danh sách các tác phẩm của tác giả ${nameAuthor}` : `Danh sách tác phẩm`}
                                            icon={poetry}
                                            showBackGround={true}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                sx={{ position: 'absolute', top: 10, right: 10 }}
                                                onClick={showWork ? onHideWork : onShowWork}
                                                startIcon={
                                                    showWork ? <KeyboardArrowUpIcon size="large" /> : <KeyboardArrowDownIcon size="large" />
                                                }
                                            >
                                                {showWork ? 'Thu gọn' : 'Mở rộng'}
                                            </Button>
                                            {showWork && (
                                                <ListWorkCommon
                                                    dataAuthor={idAuthor}
                                                    dataInfoAuthor={dataInfoAuthor}
                                                    onReload={onReload}
                                                    roles={roles}
                                                />
                                            )}
                                        </MainCardIcon>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
            {/* <ModalMember show={openModalMember} handleClose={handleCloseModalMember} onSelected={onSelectedMember} member={idMember} /> */}
            <ModalListMember 
                show={openModalMember} 
                handleClose={handleCloseModalMember} 
                onSelected={onSelectedMember} 
                member={idMember}
                type={true}
            />
            <Loading show={isLoading} />
        </>
    );
};

export default ModalAddAuthor;
