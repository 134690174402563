import { Modal, Box, CircularProgress, Fade, Backdrop } from '@mui/material';

function Loading({ show }) {
    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        p: 4,
        border: 'none'
    };
    return (
        // <div>
        <>
            <Modal open={show} sx={{ border: 'none' }}>
                {/* <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box> */}
                <Backdrop sx={{ background: 'rgba(255, 255, 255, 0.3)', color: '#9b0000', zIndex: 9999999999999999999999999 }} open={show}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {/* </> */}
            </Modal>
        </>
    );
}

export default Loading;
