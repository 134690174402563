import React, { useCallback } from 'react';
import { Box, Zoom, Fab } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';
import useScrollTrigger from '@mui/material/useScrollTrigger';

const ScrollTop = () => {
    const trigger = useScrollTrigger({
        // Number of pixels needed to scroll to toggle `trigger` to `true`.
        threshold: 100,
        disableHysteresis: true,
    });

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Zoom in={trigger}>
            <Box
                role="presentation"
                sx={{
                    position: 'fixed',
                    bottom: 92,
                    right: 32,
                    zIndex: 1
                }}
            >
                <Fab onClick={scrollToTop} color="primary" sx={{ backgroundColor: '#003168' }} size="small" aria-label="Lên đầu trang">
                    <KeyboardArrowUp />
                </Fab>
            </Box>
        </Zoom>
    );
};

export default ScrollTop;
