import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import styles from './header.module.css';
import { ShortCutName } from 'utils/helper';

import { SET_MENU } from 'store/action/actions';
import '../../../ui-component/mConfigurationLayout/FontList.css';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const dataFamily = useSelector((state) => state.dataUser);
    const font = localStorage.getItem('m_family_font') || '';
    const hadleOpenDrawerLeft = () => {
        const ele = document.getElementById('drawer-sidebar');
        ele.style.display = 'inline';
        dispatch({ type: SET_MENU, opened: true });
    };
    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        // width: 'auto'
                    },
                    justifyContent: 'space-between',
                    marginBottom: '10px'
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', display: { lg: 'none' } }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={hadleOpenDrawerLeft}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
                <Box component="span" sx={{ display: { xs: 'flex' }, marginLeft: { xs: '10px' }, alignItems: { xs: 'center' } }}>
                    <LogoSection />
                    <Box sx={{ flexGrow: 1, paddingLeft: { xs: '15px', lg: '30px' } }}>
                        <Typography
                            variant="h1"
                            sx={{
                                color: customization?.themeDongHo?.navBarColor
                            }}
                            className={styles.cssTitle}
                            style={{ fontFamily: font ? font : 'defaultFamily' }}
                        >
                            {dataFamily?.dataFamily?.family_name ? ShortCutName(dataFamily?.dataFamily?.family_name, 30) : ''}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <NotificationSection />
                    <ProfileSection />
                </Box>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            {/* <Box sx={{ flexGrow: 1, paddingLeft: '60px' }}>
                <Typography variant="h1" sx={{ color: customization?.themeDongHo?.navBarColor }} className={styles.cssTitle}>
                    Dòng họ {dataFamily?.dataFamily?.family_name ? ShortCutName(dataFamily?.dataFamily?.family_name, 30) : ''}
                </Typography>
            </Box> */}

            {/* </Box> */}
            {/* <Box sx={{ flexGrow: 1 }} /> */}

            {/* notification & profile */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
