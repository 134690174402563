import { useState, useRef, useEffect } from 'react';

import { Navigate, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
    IconButton,
    useMediaQuery,
    Tooltip,
    Button,
    CardMedia
} from '@mui/material';
import MicrowaveIcon from '@mui/icons-material/Microwave';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import Chart from 'react-apexcharts';
import WidgetsIcon from '@mui/icons-material/Widgets';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import User1 from '../../../../assets/images/users/face.webp';
import SyncIcon from '@mui/icons-material/Sync';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

// assets
import { IconLogout, IconSettings, IconUser, IconBrowser } from '@tabler/icons';
import HomeIcon from '@mui/icons-material/Home';
import AlbumIcon from '@mui/icons-material/Album';
import ListIcon from '@mui/icons-material/List';
import { MENU_OPEN } from 'store/action/actions';

// ==============================|| PROFILE MENU ||============================== //
import config from '../../../../config';
import ConfigRouter from 'routes/config_router';
import DialogUtil from 'ui-component/show_confirm_action';
import Customization from '../../../Customization';
import AnimateButton from 'ui-component/extended/AnimateButton';

import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Logo from '../../../../assets/images/logo.webp';
import { ShortName } from '../../../../utils/helper';
import { DATA_USER, DATA_FAMILY } from 'store/action/actions';

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const [sdm, setSdm] = useState(true);
    // const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [expirationDate, setExpirationDate] = useState('');
    const [isTrial, setIsTrial] = useState('');
    const [isShow, setIsShow] = useState('');

    const smallScreen = useMediaQuery('(max-width: 600px)');

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        const arrData = localStorage.getItem('LIST_ADDRESS') ? JSON.parse(localStorage.getItem('LIST_ADDRESS')) : [];
        const dataResident = localStorage.getItem('LIST_RESIDENT') ? JSON.parse(localStorage.getItem('LIST_RESIDENT')) : [];
        const dataTemporaryResidence = localStorage.getItem('LIST_TEMPORARYRESIDENCE')
            ? JSON.parse(localStorage.getItem('LIST_TEMPORARYRESIDENCE'))
            : [];
        const dataBirthPlace = localStorage.getItem('LIST_BIRTH_PLACE') ? JSON.parse(localStorage.getItem('LIST_BIRTH_PLACE')) : [];
        const dataDeadPlace = localStorage.getItem('LIST_DEAD_PLACE') ? JSON.parse(localStorage.getItem('LIST_DEAD_PLACE')) : [];
        const remember = localStorage.getItem('REMEMBER') ?? '';
        const theme = localStorage.getItem('theme') ?? '';
        const logo = localStorage.getItem('m_family_logo') ?? '';
        const type_view_diary = localStorage.getItem('type_view_diary') ?? 'list';
        const type_view_album = localStorage.getItem('type_view_album') ?? 'list';
        const vesion = localStorage.getItem('VERSION') ?? '';
        const font = localStorage.getItem('m_family_font') ?? '';
        localStorage.clear();
        localStorage.setItem('LIST_ADDRESS', JSON.stringify(arrData));
        localStorage.setItem('LIST_RESIDENT', JSON.stringify(dataResident));
        localStorage.setItem('LIST_TEMPORARYRESIDENCE', JSON.stringify(dataTemporaryResidence));
        localStorage.setItem('LIST_BIRTH_PLACE', JSON.stringify(dataBirthPlace));
        localStorage.setItem('LIST_DEAD_PLACE', JSON.stringify(dataDeadPlace));
        localStorage.setItem('REMEMBER', remember);
        localStorage.setItem('m_family_logo', logo);
        localStorage.setItem('theme', theme);
        localStorage.setItem('type_view_diary', type_view_diary);
        localStorage.setItem('type_view_album', type_view_album);
        localStorage.setItem('VERSION', vesion);
        localStorage.setItem('m_family_font', font);

        const url = window.location.href;
        var parts = url.split('/');
        if (parts.length > 2) {
            var result = parts[3];
            if (!result) {
                window.location.reload();
            } else {
                await navigate(ConfigRouter.newFeed);
            }
        } else {
            window.location.reload();
        }
    };

    const optionsBar = {
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#b0aeae'],
        chart: {
            stacked: true,
            stackType: '100%',
            toolbar: {
                show: true,
                tools: {
                    download: false
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: true
            }
        },
        dataLabels: {
            dropShadow: {
                enabled: true
            }
        },
        stroke: {
            width: 0
        },
        xaxis: {
            categories: [''],
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        fill: {
            opacity: 1,
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'vertical',
                shadeIntensity: 0.35,
                gradientToColors: undefined,
                inverseColors: false,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [90, 0, 100]
            }
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'left'
        },
        tooltip: {
            enabled: false
        }
    };

    const onLogout = () => {
        DialogUtil.showConfirm(
            'Thông báo',
            'Bạn có muốn đăng xuất tài khoản ?',
            () => {},
            () => handleLogout()
        );
    };

    const [openRightDrawer, setOpenRightDrawer] = useState(false);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '', id) => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }

        dispatch({ type: MENU_OPEN, id });
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);

    const convertName = (value) => {
        let text = '';
        switch (value) {
            case 'album':
                text = 'Album ảnh dòng họ';
                break;
            case 'avartar':
                text = 'Ảnh thành viên';
                break;
            case 'docs':
                text = 'Tài liệu dòng họ';
                break;
            case 'grave':
                text = 'Ảnh mộ phần';
                break;
            default:
                break;
        }
        return text;
    };

    const onCalculateDay = (date) => {
        let differenceInDays = 0;
        if (date) {
            const date1 = new Date(date);
            const date2 = new Date();
            const differenceInTime = date1.getTime() - date2.getTime();

            if (differenceInTime > 0) {
                differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
            }
        }
        return differenceInDays;
    };

    const getDataStatic = async () => {
        const rst = await fetchClient.postRequest(router.SUB_URL.infoServices);
        if (rst.status === 201) {
            const ex = rst?.data[0]?.package_expiration_date;
            setExpirationDate(onCalculateDay(ex) === 0 ? 100 : onCalculateDay(ex) < 20 ? 95 : 50);
        } else {
            DialogUtil.showQuestions(false, 'Xảy ra lỗi vui lòng kiểm tra lại!');
        }
    };

    const redirectToService = () => {
        navigate(ConfigRouter.listService);
    };

    const getPacket = async () => {
        const res = await fetchClient.postRequest(router.SUB_URL.packageFamily);
        if (res.status === 200) {
            // setTypePackage(res.data.data[0].package_alias)
            if (res?.data?.data[0]?.is_trial === true) {
                setIsTrial(true);
            } else {
                setIsTrial(false);
            }
        } else {
            DialogUtil.showQuestions(false, 'Không thể lấy dữ liệu');
        }
    };

    const getDataSetting = async () => {
        const result = await fetchClient.postRequest(router.SUB_URL.getSettingHomePage);
        // console.log('result', result)
        if (result.status === 201) {
            setIsShow(result?.data?.is_show);
            // console.log('result', result?.data?.is_show)
        } else {
            DialogUtil.showQuestions(false, 'Không thể lấy dữ liệu');
        }
    };
    const [dataUser, setDataUser] = useState({});
    const getUser = async () => {
        const checkUser = localStorage.getItem('DATA_USER');
        if (checkUser) {
            getDataUser();
        }
    };
    const getDataUser = async () => {
        const data = await fetchClient.postRequest(router.SUB_URL.infoUser);
        if (data?.data && data.status === 201) {
            setDataUser(data.data);
            localStorage.setItem(dataUser, JSON.stringify(data.data));
            dispatch({ type: DATA_USER, dataUser: data.data });
        }
    };
    useEffect(() => {
        getDataStatic();
        getPacket();
        if (window.location.host !== 'dungthu.giaphaso.com') {
            getDataSetting();
        }
        getUser();
    }, [open]);

    const convertRule = (rule) => {
        if (rule === 'ROOT') {
            return 'Quản trị viên';
        }
        if (rule === 'EDITOR') {
            return 'Người chỉnh sửa';
        }
        if (rule === 'VIEWER') {
            return 'Người xem';
        }
        return '';
    };

    return (
        <>
            {smallScreen ? (
                <Avatar
                    src={dataUser.avatar_url ? config.serverImageUrl + dataUser.avatar_url : User1}
                    sx={{
                        ...theme.typography.mediumAvatar,
                        margin: '8px 0 8px 8px !important',
                        cursor: 'pointer'
                    }}
                    onClick={handleToggle}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    color="inherit"
                />
            ) : (
                <>
                    <Tooltip title={expirationDate > 90 ? 'Thông báo' : 'Tùy chọn'}>
                        <Chip
                            sx={{
                                height: '48px',
                                alignItems: 'center',
                                borderRadius: '27px',
                                transition: 'all .2s ease-in-out',
                                borderColor: theme.palette.primary.light,
                                backgroundColor: theme.palette.primary.light,
                                '&[aria-controls="menu-list-grow"], &:hover': {
                                    borderColor: theme.palette.primary.main,
                                    background: `${theme.palette.primary.main}!important`,
                                    color: theme.palette.primary.light,
                                    '& svg': {
                                        stroke: theme.palette.primary.light
                                    }
                                },
                                '& .MuiChip-label': {
                                    lineHeight: 0
                                }
                            }}
                            icon={
                                <Avatar
                                    src={dataUser.avatar_url ? config.serverImageUrl + dataUser.avatar_url : User1}
                                    sx={{
                                        ...theme.typography.mediumAvatar,
                                        margin: '8px 0 8px 8px !important',
                                        cursor: 'pointer'
                                    }}
                                    ref={anchorRef}
                                    aria-controls={open ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    color="inherit"
                                />
                            }
                            label={
                                <>
                                    {expirationDate > 90 ? (
                                        <AnimateButton type="continuousShake">
                                            <NotificationImportantIcon stroke={1.5} size="1.5rem" sx={{ color: '#9b0000' }} />
                                        </AnimateButton>
                                    ) : (
                                        <AnimateButton type="rotate">
                                            <IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />
                                        </AnimateButton>
                                    )}
                                </>
                            }
                            variant="outlined"
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            color="primary"
                        />
                    </Tooltip>
                </>
            )}
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                    sx={{ paddingBottom: 2, width: 350 }}
                                >
                                    <Box
                                        sx={{ paddingTop: 2, margin: '0px 10px 0px 8px', display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <Stack>
                                            <Stack
                                                direction="row"
                                                // spacing={0.5}
                                                alignItems="center"
                                                style={{ marginBottom: 10, overflow: 'hidden' }}
                                            >
                                                <Typography variant="h4" sx={{ marginRight: '5px' }}>
                                                    Xin chào
                                                </Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                    {ShortName(dataUser.full_name, 24)}
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={0.5}
                                                alignItems="center"
                                                style={{ marginBottom: 10, overflow: 'hidden' }}
                                            >
                                                <Typography component="span" variant="h5" sx={{ fontWeight: 400 }}>
                                                    ({convertRule(dataUser.type)})
                                                </Typography>
                                            </Stack>
                                            {/* <Typography variant="subtitle2"></Typography> */}
                                        </Stack>
                                        <CardMedia
                                            component="img"
                                            sx={{
                                                width: '50px',
                                                height: '50px'
                                            }}
                                            image={Logo}
                                        />

                                        {/* <Divider /> */}
                                    </Box>
                                    <PerfectScrollbar
                                        style={{
                                            height: '100%',
                                            maxHeight: 'calc(100vh - 250px)',
                                            overflow: 'hidden',
                                            padding: '10px'
                                        }}
                                    >
                                        <Box sx={{ overflow: 'hidden' }}>
                                            {/* <UpgradePlanCard />
                                            <Divider /> */}
                                            {/* <Card
                                                sx={{
                                                    bgcolor: theme.palette.primary.light,
                                                    my: 2
                                                }}
                                            >
                                                <CardContent>
                                                    <Grid container spacing={3} direction="column">
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Start DND Mode</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={sdm}
                                                                        onChange={(e) => setSdm(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid item container alignItems="center" justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1">Allow Notifications</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        checked={notification}
                                                                        onChange={(e) => setNotification(e.target.checked)}
                                                                        name="sdm"
                                                                        size="small"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                            <Divider /> */}
                                            <List
                                                component="nav"
                                                sx={{
                                                    // marginRight: '5px',
                                                    // marginLeft: '5px',
                                                    width: '100%',
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {/* <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) =>
                                                        handleListItemClick(event, 0, ConfigRouter.changePassword, 'change_password')
                                                    }
                                                /> */}
                                                {window.location.pathname !== ConfigRouter.newFeed &&
                                                    isTrial === false &&
                                                    isShow === true && (
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                            selected={selectedIndex === 1}
                                                            onClick={(event) => {
                                                                // document.location = ConfigRouter.newFeed;
                                                                navigate(ConfigRouter.newFeed, { replace: false });
                                                                navigate(0);
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <HomeOutlinedIcon color="primary" stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>
                                                            <ListItemText primary={<Typography variant="body2">Trang chủ</Typography>} />
                                                        </ListItemButton>
                                                    )}
                                                {window.location.pathname !== ConfigRouter.homePage && (
                                                    <ListItemButton
                                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event) => handleListItemClick(event, 1, ConfigRouter.homePage, '')}
                                                    >
                                                        <ListItemIcon>
                                                            <WidgetsOutlinedIcon color="primary" stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Menu chính</Typography>} />
                                                    </ListItemButton>
                                                )}

                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 2}
                                                    onClick={(event) =>
                                                        handleListItemClick(event, 2, ConfigRouter.userInfo, ConfigRouter.userInfo)
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <IconUser
                                                            style={{ color: customization?.themeDongHo?.backgroundColor }}
                                                            stroke={1.5}
                                                            size="1.3rem"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Grid container spacing={1} justifyContent="space-between">
                                                                <Grid item>
                                                                    <Typography variant="body2">Trang cá nhân</Typography>
                                                                </Grid>
                                                                {/* <Grid item>
                                                                    <Chip
                                                                        label="02"
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor: theme.palette.warning.dark,
                                                                            color: theme.palette.background.default
                                                                        }}
                                                                    />
                                                                </Grid> */}
                                                            </Grid>
                                                        }
                                                    />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) =>
                                                        handleListItemClick(event, 1, ConfigRouter.listService, ConfigRouter.listService)
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <ListIcon color="primary" stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Quản lý dịch vụ</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={() => setOpenRightDrawer(true)}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings
                                                            style={{ color: customization?.themeDongHo?.backgroundColor }}
                                                            stroke={1.5}
                                                            size="1.3rem"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Giao diện dòng họ</Typography>} />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={onLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout
                                                            style={{ color: customization?.themeDongHo?.backgroundColor }}
                                                            stroke={1.5}
                                                            size="1.3rem"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Đăng xuất</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                        {expirationDate > 90 && (
                                            <Box
                                                sx={{
                                                    marginTop: '20px',
                                                    // marginLeft: '20px',
                                                    width: '100%',
                                                    // maxWidth: 280,
                                                    // minWidth: 300,
                                                    // paddingLeft: '10px',
                                                    paddingBottom: '8px',
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 5
                                                    }
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        paddingTop: '8px',
                                                        display: 'block',
                                                        marginLeft: '10px',
                                                        alignItems: 'center',
                                                        marginBottom: '5px'
                                                    }}
                                                    variant="h3"
                                                >
                                                    Thông báo:
                                                </Typography>
                                                {expirationDate === 100 ? (
                                                    <Typography sx={{ marginLeft: '10px' }} variant="h5">
                                                        Gói dịch vụ bạn đã hết thời hạn sử dụng. Hãy nâng cấp để tiếp tục sử dụng dịch vụ.
                                                    </Typography>
                                                ) : (
                                                    <Typography sx={{ marginLeft: '10px' }} variant="h5">
                                                        Gói dịch vụ bạn đang dùng sắp hết thời hạn sử dụng. Hãy nâng cấp để tiếp tục sử dụng
                                                        dịch vụ.
                                                    </Typography>
                                                )}

                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        onClick={(event) =>
                                                            handleListItemClick(
                                                                event,
                                                                1,
                                                                ConfigRouter.listService,
                                                                ConfigRouter.listService
                                                            )
                                                        }
                                                        disableElevation
                                                        size="small"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        sx={{ paddingBottom: '3px', marginTop: '8px' }}

                                                        // startIcon={<SaveIcon />}
                                                    >
                                                        Nâng cấp
                                                    </Button>
                                                </div>
                                            </Box>
                                        )}
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <Customization openRightDrawer={openRightDrawer} onOpenRightDrawer={() => setOpenRightDrawer(!openRightDrawer)} />
        </>
    );
};

export default ProfileSection;
