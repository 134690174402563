import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ImageCropper from 'utils/CropImage';
import { isEmpty } from 'utils/validate_checking';
import Close from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Grid, Stack, Button, CardMedia, Typography, useMediaQuery } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DialogUtil from 'ui-component/show_confirm_action';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { xs: '95%', sm: '80%' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 3,
    minWidth: { xs: '95%', sm: '80%' }
};
const imageMimeType = /image\/(png|jpg|jpeg)/i;

const ModalImage = ({ role, imageFile, showModal, typeModalImage, onCloseModal, onUpdateImage }) => {
    const [show, setShow] = useState(showModal);
    const [imageToCropGroom, setImageToCropGroom] = useState(undefined);
    const [cropImageGroom, setCropImageGroom] = useState(undefined);
    const [dataForm, setDataForm] = useState({});
    const [check, setCheck] = useState(false);
    useEffect(() => {
        if (imageFile?.type && imageFile?.type.match(imageMimeType)) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const image = reader.result;
                setImageToCropGroom(image);
                setCropImageGroom(image);
                setDataForm({ ...dataForm, avatarGroom: [image] });
                // setCheck(true);
            });
            reader.readAsDataURL(imageFile);
        } else {
            setImageToCropGroom(imageFile);
            setCropImageGroom(imageFile);
            setDataForm({ ...dataForm, avatarGroom: [imageFile] });
            // setCheck(true);
        }
        setShow(showModal);
    }, [imageFile, showModal, typeModalImage]);

    const handleCloseModal = (event, reason) => {
        if (reason && reason === 'backdropClick') {
            return;
        }
        onCloseModal();
    };

    const onAddImage = () => {
        onUpdateImage(cropImageGroom);
    };

    const onDeleteImage = () => {
        DialogUtil.showConfirm(
            'Thông báo',
            'Bạn có muốn xóa ảnh đại diện?',
            () => {},
            () => onUpdateImage('')
        );
    };

    const onCropImage = (img) => {
        setCheck(false);
        setCropImageGroom(img);
    };

    const actionClose = () => {
        setCheck(true);
        handleCloseModal();
    };

    const smallScreen = useMediaQuery('(max-width: 900px)');

    return (
        <div>
            <Modal open={show} onClose={() => actionClose()} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box position="absolute" top="-8px" right="-8px">
                        <IconButton onClick={() => actionClose()}>
                            <Close />
                        </IconButton>
                    </Box>
                    {typeModalImage === 'view' ? (
                        <>
                            <div className="text-center">
                                <CardMedia component="img" height={smallScreen ? 'auto' : '600'} fullWidth type="file" image={imageFile} />
                            </div>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Stack direction="row" alignItems="center" justifyContent="end" spacing={1}>
                                    {role?.delete && (
                                        <Button
                                            disableElevation
                                            startIcon={<DeleteForeverIcon />}
                                            variant="contained"
                                            color="primary"
                                            onClick={onDeleteImage}
                                        >
                                            Xóa
                                        </Button>
                                    )}
                                    <Button
                                        disableElevation
                                        startIcon={<Close />}
                                        variant="contained"
                                        color="primary"
                                        onClick={onCloseModal}
                                    >
                                        Đóng
                                    </Button>
                                </Stack>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <div className="text-center">
                                <ImageCropper
                                    mimeTypeImage={imageFile?.type}
                                    aspect={3 / 4}
                                    setImageFile={(img) =>
                                        setDataForm({
                                            ...dataForm,
                                            avatarGroom: [img]
                                        })
                                    }
                                    imageToCrop={imageToCropGroom}
                                    onImageCropped={(img) => onCropImage(img)}
                                    // locked
                                />
                            </div>
                            <div>
                                <Typography variant="span" sx={{ display: 'block', fontSize: '12px' }}>
                                    <i>* Vui lòng chọn khu vực ảnh để cắt (Ảnh sẽ được lưu với tỉ lệ 3x4)</i>
                                </Typography>
                            </div>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Stack direction="row" alignItems="center" justifyContent="end" spacing={1}>
                                    <Button
                                        disableElevation
                                        startIcon={<SaveIcon />}
                                        variant="contained"
                                        color="primary"
                                        onClick={onAddImage}
                                        // disabled={check}
                                    >
                                        Lưu
                                    </Button>
                                    <Button
                                        disableElevation
                                        startIcon={<Close />}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => actionClose()}
                                    >
                                        Hủy
                                    </Button>
                                </Stack>
                            </Grid>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default ModalImage;
