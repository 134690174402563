import { useState, useEffect, useRef } from 'react';
import {
    Modal,
    Box,
    Typography,
    CardMedia,
    FormControl,
    Button,
    Avatar,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Chip,
    Badge,
    Switch
} from '@mui/material';

import Loading from 'ui-component/Loading';
import DialogUtil from 'ui-component/show_confirm_action';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import user from '../../../../../assets/images/users/user.png';
import book from '../../../../../assets/images/users/book.png';
import upload from '../../../../../assets/images/users/upload.png';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { convertViToEn, getExtension, isUrlValid } from '../../../../../utils/validate_checking';
import { ArrowForwardIosOutlined, Close } from '@mui/icons-material';
import router from 'routes/router';
import config from '../../../../../config';
import fetchClient from 'views/utilities/RestfulAPI';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ImageDefault from '../../../../../assets/images/users/background.jpg';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { getRoleButton } from 'utils/roleUility';
import ConfigRouter from 'routes/config_router';
import { useNavigate } from 'react-router';
import ModalCropThumnail from '../../../../../ui-component/diary/ModalCropThumnail';
import EditIcon from '@mui/icons-material/Edit';
import getExtesionFileFromMime from '../../../../../utils/helper';
import ClearIcon from '@mui/icons-material/Clear';
import { set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import MainCardIcon from 'ui-component/cards/MainCardIcon';
import ModalImage from 'ui-component/modalImage/ModalImage';
import ModalDiary from './ModalDiary';
import ModalDocument from './ModalDocument';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95vw' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: { md: 2, xs: 1 }
};

const styleBox = {
    maxHeight: { xs: '75vh', sm: '87vh' },
    overflowY: 'auto'
};

const totalDaySolar = 31;
const monthInYear = 12;
const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 90
        }
    }
};

const initial = {
    inherit: false,
    error: ''
};

const EditIconButton = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`
}));
const imageMimeType = /image\/(png|jpg|jpeg|PNG|JPG|JPEG)/i;
const convert22digitStr = (n) => (n < 10 && n > -1 ? `0${n}` : n?.toString());
const role = {
    create: true,
    update: true,
    delete: true
};

function getStyles(name, typeName, theme) {
    return {
        fontWeight: typeName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
    };
}

const ModalAddWork = ({ open, handleClose, typeModal, dataAuthor }) => {
    const theme = useTheme();
    const inputRef = useRef(null);
    const customization = useSelector((state) => state.customization);
    const [isLoading, setIsLoading] = useState(false);
    const [nameWork, setNameWork] = useState('');
    const [storageLocation, setStorageLocation] = useState('');
    const [language, setLanguage] = useState('');
    const [type, setType] = useState('');
    const [year, setYear] = useState('');
    const [topic, setTopic] = useState([]);
    const [content, setContent] = useState('');
    const [note, setNote] = useState('');
    const [award, setAward] = useState('');
    const [fileWork, setFileWork] = useState(null);

    const [selectedValues, setSelectedValues] = useState([]);
    const [validateWork, setValidateWork] = useState(initial);
    const [validateStorageLocation, setValidateStorageLocation] = useState(initial);
    const [validateLanguage, setValidateLanguage] = useState(initial);
    const [validateType, setValidateType] = useState(initial);
    const [validateYear, setValidateYear] = useState(initial);
    const [validateTopic, setValidateTopic] = useState(initial);
    const [validateContent, setValidateContent] = useState(initial);
    const [validateNote, setValidateNote] = useState(initial);
    const [validateAward, setValidateAward] = useState(initial);

    const [dataImageCrop, setDataImageCrop] = useState({});
    const [typeModalImage, setTypeModalImage] = useState('add');
    const [openModalImage, setOpenModalImage] = useState(false);
    const [imageDelete, setImageDelete] = useState(false);
    const [dataAvatar, setDataAvatar] = useState({});

    const [fileUpload, setFileUpload] = useState(null);
    const [idDiary, setIdDiary] = useState('');
    const [idDocument, setIdDocument] = useState('');
    const [pathFile, setPathFile] = useState([]);
    const [validateFileUpload, setValidateFileUpload] = useState(initial);
    const [urlLink, setUrlLink] = useState('');
    const [validateUrlLink, setValidateUrlLink] = useState(initial);

    const [authorMain, setAuthorMain] = useState(true);
    const [secondaryAuthor, setSecondaryAuthor] = useState(false);

    const [typeName, setTypeName] = useState([]);
    const [dataType, setDataType] = useState(localStorage.getItem('m_genre') ? JSON.parse(localStorage.getItem('m_genre')) : []);

    const [openDiary, setOpenDiary] = useState(false);
    const [openDocument, setOpenDocument] = useState(false);
    const [publicWork, setPublicWork] = useState('');

    // const [listAuthor, setListAuthor] = useState([]);
    // const [author, setAuthor] = useState();
    // const [isAuthorMain, setIsAuthorMain] = useState(true);
    // const [isSecondaryAuthor, setIsSecondaryAuthor] = useState(false);

    const imageRef = useRef();
    const showOpenFileDialog = () => imageRef.current.click();

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setTypeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
        // setTypeName(value);
    };

    const openModalDiary = () => {
        setOpenDiary(true);
    };

    const openModalDocument = () => {
        setOpenDocument(true);
    };

    const handleCloseModalWork = () => {
        setNameWork('');
        setStorageLocation('');
        setLanguage('');
        setType('');
        setYear('');
        setTopic('');
        setContent('');
        setNote('');
        setAward('');
        setIdDiary('');
        setIdDocument('');
        setPathFile('');
        setUrlLink('');

        // setAuthor('');
        // setListAuthor([]);
        setValidateWork(initial);
        setValidateStorageLocation(initial);
        setValidateLanguage(initial);
        setValidateType(initial);
        setValidateYear(initial);
        setValidateTopic(initial);
        setValidateContent(initial);
        setValidateNote(initial);
        setValidateAward(initial);
        handleClose();
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].size > 5e6) {
                DialogUtil.showQuestions(false, 'Vui lòng chọn file ảnh có dung lượng dưới 5mb');
                return;
            }
            if (!event.target.files[0].type.match(imageMimeType)) {
                DialogUtil.showQuestions(false, 'Định dạng file không đúng vui lòng kiểm tra lại');
                return;
            }

            const formatFile = getExtension(event.target.files[0].name);
            if (formatFile === 'png' || formatFile === 'jpg' || formatFile === 'jpeg') {
                setDataImageCrop(event.target.files[0]);
                // setAvatar(URL.createObjectURL(event.target.files[0]));
            } else {
                DialogUtil.showQuestions(false, ' Vui lòng tải ảnh có định dạng png, jpg, jpeg');
                return;
            }
            // setDataFile(event.target.files[0]);
            // setFileDiary(URL.createObjectURL(event.target.files[0]));

            event.target.value = null;
            setTypeModalImage('add');
            setOpenModalImage(true);
            setImageDelete(false);
        }
    };

    const handleCloseModalImage = () => {
        // console.log('function close');
        setOpenModalImage(false);
        setDataImageCrop({});
    };

    const dataUrlToFile = async (dataUrl) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        // eslint-disable-next-line no-plusplus
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], `thumnail${getExtesionFileFromMime(mime)}`, { type: mime });
    };

    const onUpdateImage = async (cropImageGroom) => {
        if (cropImageGroom !== '') {
            const imageData = await dataUrlToFile(cropImageGroom);
            setDataAvatar(imageData);
            setFileWork(URL.createObjectURL(imageData));
        } else {
            setDataAvatar({});
            setFileWork(null);
            // setUrlData('');
            setImageDelete(true);
        }
        setOpenModalImage(false);
    };

    const findName = (value) => {
        const rst = dataType
            .filter((r) => {
                return r._id === value;
            })
            .map((m) => {
                return m.title;
            });
        return rst;
    };

    const onChangeAction = (e) => {
        const name = e.target.name;
        switch (name) {
            case 'mainAuthor':
                setAuthorMain(true);
                setSecondaryAuthor(false);
                break;
            case 'secondaryAuthor':
                setSecondaryAuthor(true);
                setAuthorMain(false);
                break;
            default:
                break;
        }
    };

    const handleUploadFile = () => {
        inputRef.current.click();
    };

    const onPathChange = (event) => {
        const files = event.target.files;
        let validFiles = [];
        let invalidFiles = [];
        let fileNames = [];

        for (let i = 0; i < files.length; i++) {
            if (files[i].size > config.maxUploadDocs) {
                invalidFiles.push(files[i]);
            } else {
                validFiles.push(files[i]);
                fileNames.push(files[i].name);
            }
        }

        if (invalidFiles.length > 0) {
            setFileUpload(null);
            setValidateFileUpload({
                invalid: true,
                errors: `Tài liệu không được quá ${config.maxUploadDocs / 1000000} MB`
            });
        } else {
            setFileUpload(validFiles);
            setPathFile(fileNames.join(', '));
            setValidateFileUpload(initial);
        }
    };

    const handleCloseModalDocument = () => {
        setOpenDocument(false);
    };
    const handleCloseModalDiary = () => {
        setOpenDiary(false);
    };

    const onChooseDiary = (data) => {
        setIdDiary(data);
    };

    const onChooseDocument = (data) => {
        setIdDocument(data);
    };

    const handleChangeInput = (e) => {
        const name = e.target?.name;
        const value = e.target?.value;
        switch (name) {
            case 'nameWork':
                if (value === '') {
                    setNameWork(value);
                    setValidateWork({
                        invalid: true,
                        errors: 'Tên tác phẩm không được bỏ trống'
                    });
                    return;
                }
                if (value.length > 100) {
                    setValidateWork({
                        invalid: true,
                        errors: 'Tên tác phẩm không được vượt quá 100 ký tự'
                    });
                    return;
                }
                setNameWork(value);
                setValidateWork(initial);
                break;
            case 'storageLocation':
                if (value.length > 500) {
                    setValidateStorageLocation({
                        invalid: true,
                        errors: 'Nơi lưu trữ không được vượt quá 500 ký tự'
                    });
                    return;
                }

                setStorageLocation(value);
                setValidateStorageLocation(initial);
                break;
            case 'language':
                if (value.length > 100) {
                    setValidateLanguage({
                        invalid: true,
                        errors: 'Ngôn ngữ không được vượt quá 100 ký tự'
                    });
                    return;
                }
                setLanguage(value);
                setValidateLanguage(initial);
                break;
            case 'year':
                setYear(value);
                break;
            case 'topic':
                if (value.length > 100) {
                    setValidateTopic({
                        invalid: true,
                        errors: 'Chủ đề không được vượt quá 100 ký tự'
                    });
                    return;
                }
                setTopic(value);
                setValidateTopic(initial);
                break;
            case 'content':
                if (value.length > 500) {
                    setValidateContent({
                        invalid: true,
                        errors: 'Giải thích sơ bộ không được vượt quá 500 ký tự'
                    });
                    return;
                }

                setContent(value);
                setValidateContent(initial);
                break;
            case 'note':
                if (value.length > 500) {
                    setValidateNote({
                        invalid: true,
                        errors: 'Nhận xét/ đánh giá không được vượt quá 500 ký tự'
                    });

                    return;
                }
                setNote(value);
                setValidateNote(initial);
                break;
            case 'award':
                if (value.length > 500) {
                    setValidateAward({
                        invalid: true,
                        errors: 'Giải thưởng không được vượt quá 500 ký tự'
                    });
                    return;
                }
                setAward(value);
                setValidateAward(initial);
                break;
            default:
                break;
        }
    };

    const handleSave = () => {
        if (nameWork === '') {
            setValidateWork({
                invalid: true,
                errors: 'Tên tác phẩm không được bỏ trống'
            });
            return;
        }
        if (nameWork.length > 100) {
            setValidateWork({
                invalid: true,
                errors: 'Tên tác phẩm không được vượt quá 100 ký tự'
            });
            return;
        }
        if (storageLocation.length > 500) {
            setValidateStorageLocation({
                invalid: true,
                errors: 'Nơi lưu trữ không được vượt quá 500 ký tự'
            });
            return;
        }
        if (language.length > 100) {
            setValidateLanguage({
                invalid: true,
                errors: 'Ngôn ngữ không được vượt quá 100 ký tự'
            });
            return;
        }

        if (topic.length > 100) {
            setValidateTopic({
                invalid: true,
                errors: 'Chủ đề không được vượt quá 100 ký tự'
            });
            return;
        }
        if (content.length > 500) {
            setValidateContent({
                invalid: true,
                errors: 'Giải thích sơ bộ không được vượt quá 500 ký tự'
            });
            return;
        }
        if (note.length > 500) {
            setValidateNote({
                invalid: true,
                errors: 'Nhận xét/ đánh giá không được vượt quá 500 ký tự'
            });
            return;
        }
        if (award.length > 500) {
            setValidateAward({
                invalid: true,
                errors: 'Giải thưởng không được vượt quá 500 ký tự'
            });
            return;
        }

        // if (partWriting.length > 500) {
        //     setValidatePartWriting({
        //         invalid: true,
        //         errors: 'Phần tham gia không được vượt quá 500 ký tự'
        //     });
        //     return;
        // }

        // if (timeWriting.length > 500) {
        //     setValidateTimeWriting({
        //         invalid: true,
        //         errors: 'Thời điểm tham gia không được vượt quá 500 ký tự'
        //     });
        //     return;
        // }

        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn thêm mới tác phẩm không?`,
            () => {},
            () => {
                onSave();
            }
        );
    };

    const onSave = async () => {
        const dataWork = {
            name_work: nameWork,
            language: language,
            publishing_year: year,
            topic: topic,
            storageLocation: storageLocation,
            type: typeName,
            content: content,
            award: award,
            is_public: publicWork,
            note: note,
            // id_document:
            id_author: dataAuthor?._id ?? null,
            role: authorMain === true ? 'MAIN' : secondaryAuthor === true ? 'SECONDARY' : null
        };
        let urlApi = router.SUB_URL.create_work;
        const formData = new FormData();
        formData.append('dataWork', JSON.stringify(dataWork));
        const dataReturn = await fetchClient.sendRequest(urlApi, 'post', formData);
        if (dataReturn?.status === 201) {
            DialogUtil.showQuestions(true, `Thêm mới tác phẩm thành công`, () => {
                handleCloseModalWork();
            });
        } else {
            DialogUtil.showQuestions(false, `Thêm mới tác phẩm thất bại`);
        }
        // console.log('dataReturn------', dataReturn);
    };

    return (
        <>
            <Modal
                open={open}
                // onClose={() => handleCloseModal()}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginY: '15px',
                            padding: { md: '0px', xs: '10px' }
                        }}
                    >
                        <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ maxWidth: '60%' }}>
                            Thêm mới tác phẩm
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' }, justifyContent: 'flex-end' }}>
                            <Button
                                onClick={() => handleSave()}
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                sx={{ marginRight: '5px', borderBlockColor: 'black', marginTop: { md: '0px', xs: '5px' } }}
                            >
                                Lưu
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<CloseIcon />}
                                className="mr-2"
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => handleCloseModalWork()}
                            >
                                Đóng
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            ...styleBox,
                            '&::-webkit-scrollbar': {
                                width: '0.4em'
                            },
                            '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: customization?.themeDongHo?.heading,
                                outline: 'slategrey'
                            }
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{ marginBottom: 2 }}>
                                    <MainCardIcon title="Thông tin tác phẩm" icon={MenuBookIcon}>
                                        <Grid container style={{ paddingLeft: 0 }}>
                                            <Grid md={12} sm={12} xs={12} item spacing={1}>
                                                <Grid item style={{ paddingLeft: 0, position: 'relative' }}>
                                                    <CardMedia
                                                        component="img"
                                                        height="120"
                                                        image={fileWork || ImageDefault}
                                                        alt="green iguana"
                                                    />
                                                    <div style={{ position: 'absolute', right: 20, bottom: 10 }}>
                                                        <Button
                                                            size="medium"
                                                            sx={{ width: '20px' }}
                                                            color="primary"
                                                            variant="contained"
                                                            component="label"
                                                            onClick={showOpenFileDialog}
                                                        >
                                                            <CameraAltOutlinedIcon style={{ height: 20, width: 20 }} />
                                                        </Button>
                                                        <input
                                                            ref={imageRef}
                                                            type="file"
                                                            style={{ display: 'none' }}
                                                            accept="image/*"
                                                            onChange={onImageChange}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        sx={{ml: '10px'}}
                                                        value="start"
                                                        checked={publicWork}
                                                        onChange={(e)=>{setPublicWork(e.target.checked)}}
                                                        control={<Switch color="primary" />}
                                                        label="Công khai thông tin tác phẩm trên trang chủ dòng họ"
                                                        labelPlacement="start"
                                                    />
                                                </Grid>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={8}>
                                                            <TextField
                                                                error={validateWork.invalid}
                                                                margin="normal"
                                                                label="Tên tác phẩm (*)"
                                                                fullWidth
                                                                value={nameWork}
                                                                onChange={handleChangeInput}
                                                                name="nameWork"
                                                                helperText={validateWork.errors}
                                                            />
                                                        </Grid>
                                                        <Grid item md={4}>
                                                            <TextField
                                                                error={validateLanguage.invalid}
                                                                margin="normal"
                                                                label="Ngôn ngữ"
                                                                fullWidth
                                                                value={language}
                                                                onChange={handleChangeInput}
                                                                name="language"
                                                                helperText={validateLanguage.errors}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box sx={{ display: 'flex' }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={8}>
                                                            <FormControl sx={{ width: '100%', margin: '15px 0' }}>
                                                                <InputLabel id="demo-multiple-chip-label">Thể loại</InputLabel>
                                                                <Select
                                                                    labelId="demo-multiple-chip-label"
                                                                    id="demo-multiple-chip"
                                                                    multiple
                                                                    value={typeName}
                                                                    onChange={handleChange}
                                                                    input={<OutlinedInput id="select-multiple-chip" label="Thể loại" />}
                                                                    renderValue={(selected) => (
                                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                            {selected.map((value) => {
                                                                                if (findName(value).length !== 0) {
                                                                                    return <Chip key={value} label={findName(value)} />;
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </Box>
                                                                    )}
                                                                    MenuProps={MenuProps}
                                                                >
                                                                    {dataType?.length > 0 ? (
                                                                        dataType.map((element, index) => (
                                                                            <MenuItem
                                                                                key={index.toString()}
                                                                                value={element._id}
                                                                                style={getStyles(element._id, typeName, theme)}
                                                                                sx={{ zIndex: 999999 }}
                                                                            >
                                                                                {element.title}
                                                                            </MenuItem>
                                                                        ))
                                                                    ) : (
                                                                        <MenuItem sx={{ pointerEvents: 'none' }}>Chưa có bài viết nào</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                            
                                                        </Grid>
                                                        <Grid item md={4}>
                                                            <TextField
                                                                error={validateTopic.invalid}
                                                                margin="normal"
                                                                label="Chủ đề"
                                                                fullWidth
                                                                value={topic}
                                                                onChange={handleChangeInput}
                                                                name="topic"
                                                                helperText={validateTopic.errors}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Grid item>
                                                    <TextField
                                                        error={validateStorageLocation.invalid}
                                                        margin="normal"
                                                        label="Nơi lưu trữ"
                                                        // maxRows={7}
                                                        fullWidth
                                                        value={storageLocation}
                                                        onChange={handleChangeInput}
                                                        name="storageLocation"
                                                        helperText={validateStorageLocation.errors}
                                                    />
                                                </Grid>
                                                <Grid item md={12}>
                                                    <TextField
                                                        error={validateYear.invalid}
                                                        margin="normal"
                                                        label="Thời gian phát hành"
                                                        rows={3}
                                                        multiline
                                                        fullWidth
                                                        value={year}
                                                        onChange={handleChangeInput}
                                                        name="year"
                                                        helperText={validateYear.errors}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <TextField
                                                        error={validateContent.invalid}
                                                        margin="normal"
                                                        label="Giải thích sơ bộ"
                                                        multiline
                                                        rows={3}
                                                        // maxRows={7}
                                                        fullWidth
                                                        value={content}
                                                        onChange={handleChangeInput}
                                                        name="content"
                                                        helperText={validateContent.errors}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        error={validateAward.invalid}
                                                        margin="normal"
                                                        label="Giải thưởng"
                                                        multiline
                                                        rows={3}
                                                        fullWidth
                                                        value={award}
                                                        onChange={handleChangeInput}
                                                        name="award"
                                                        helperText={validateAward.errors}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        error={validateNote.invalid}
                                                        margin="normal"
                                                        label="Nhận xét/ đánh giá"
                                                        multiline
                                                        rows={3}
                                                        fullWidth
                                                        value={note}
                                                        onChange={handleChangeInput}
                                                        name="note"
                                                        helperText={validateNote.errors}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        error={validateUrlLink.invalid}
                                                        margin="normal"
                                                        label="Đường dẫn trực tuyến"
                                                        fullWidth
                                                        value={urlLink}
                                                        onChange={handleChangeInput}
                                                        name="urlLink"
                                                        helperText={validateUrlLink.errors}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <TextField
                                                            fullWidth
                                                            sx={{
                                                                disabled: 'disabled',
                                                                marginBottom: '0px !important'
                                                                // width: matchDownMd ? '60%' : '100%'
                                                            }}
                                                            type="text"
                                                            error={validateFileUpload.invalid}
                                                            margin="normal"
                                                            label={'Đường dẫn lưu trữ file tài liệu'}
                                                            value={pathFile}
                                                            name="path"
                                                            helperText={validateFileUpload.errors}
                                                            placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                                            // disabled
                                                        />
                                                        <input
                                                            style={{ display: 'none' }}
                                                            ref={inputRef}
                                                            type="file"
                                                            multiple
                                                            onChange={onPathChange}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                height: '46px',
                                                                marginTop: '18px',
                                                                marginLeft: '6px',
                                                                // width: '95%',
                                                                whiteSpace: 'nowrap',
                                                                display: { md: 'flex', xs: 'none' }
                                                            }}
                                                            color="primary"
                                                            onClick={handleUploadFile}
                                                            startIcon={<AttachFileIcon />}
                                                        >
                                                            Chọn File
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <TextField
                                                            fullWidth
                                                            sx={{
                                                                disabled: 'disabled',
                                                                marginBottom: '0px !important'
                                                            }}
                                                            type="text"
                                                            error={validateFileUpload.invalid}
                                                            margin="normal"
                                                            label={'Đường dẫn lưu trữ từ phả kí dòng họ'}
                                                            value={idDiary.title ?? ''}
                                                            name="path"
                                                            helperText={validateFileUpload.errors}
                                                            placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                height: '46px',
                                                                marginTop: '18px',
                                                                marginLeft: '6px',
                                                                // width: '95%',
                                                                whiteSpace: 'nowrap',
                                                                display: { md: 'flex', xs: 'none' }
                                                            }}
                                                            color="primary"
                                                            onClick={() => openModalDiary()}
                                                            startIcon={<AttachFileIcon />}
                                                        >
                                                            Chọn từ phả kí
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                <Grid item>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <TextField
                                                            fullWidth
                                                            sx={{
                                                                disabled: 'disabled',
                                                                marginBottom: '0px !important'
                                                                // width: matchDownMd ? '60%' : '100%'
                                                            }}
                                                            type="text"
                                                            error={validateFileUpload.invalid}
                                                            margin="normal"
                                                            label={'Đường dẫn lưu trữ file tài liệu'}
                                                            value={idDocument.name_document ?? ''}
                                                            name="path"
                                                            helperText={validateFileUpload.errors}
                                                            placeholder="Nhập đường dẫn lưu trữ file tài liệu..."
                                                            // disabled
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                height: '46px',
                                                                marginTop: '18px',
                                                                marginLeft: '6px',
                                                                // width: '95%',
                                                                whiteSpace: 'nowrap',
                                                                display: { md: 'flex', xs: 'none' }
                                                            }}
                                                            color="primary"
                                                            onClick={() => openModalDocument()}
                                                            startIcon={<AttachFileIcon />}
                                                        >
                                                            Chọn từ tài liệu
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MainCardIcon>
                                </Box>
                                <Box>
                                    <MainCardIcon title="Thông tin tác giả" icon={book}>
                                        <Grid container style={{ paddingLeft: 0, flexDirection: 'row' }}>
                                            <Grid lg={2} md={2} sm={12} xs={12} item style={{ paddingLeft: 0, flexDirection: 'column' }}>
                                                <div
                                                    style={{
                                                        alignItems: 'center',
                                                        alignContent: 'center',
                                                        flexDirection: 'row',
                                                        display: 'flex',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <Badge
                                                        style={{ transform: 'translate(0px, -5px)', cursor: 'pointer' }}
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        badgeContent={
                                                            <EditIconButton onClick={showOpenFileDialog}>
                                                                {dataAuthor?.avatar ? (
                                                                    <BorderColorRoundedIcon style={{ height: 20, width: 20 }} />
                                                                ) : (
                                                                    <AddCircleIcon style={{ height: 40, width: 40 }} />
                                                                )}
                                                            </EditIconButton>
                                                        }
                                                    >
                                                        <Avatar
                                                            alt="avatar"
                                                            src={dataAuthor?.avatar}
                                                            style={{ width: '120px', height: '120px', border: `1px solid gray` }}
                                                        />
                                                    </Badge>
                                                    <input
                                                        ref={imageRef}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        accept="image/*"
                                                        onChange={onImageChange}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid
                                                lg={10}
                                                md={10}
                                                sm={12}
                                                xs={12}
                                                item
                                                sx={{ paddingLeft: { md: '24px', xs: '0px' }, flexDirection: 'column' }}
                                                spacing={1}
                                            >
                                                <Grid item>
                                                    <TextField
                                                        disabled={true}
                                                        // error={validateAuthor.invalid}
                                                        margin="normal"
                                                        label="Tên tác giả (*)"
                                                        fullWidth
                                                        value={dataAuthor?.name_author ?? ''}
                                                        onChange={handleChange}
                                                        name="nameAuthor"
                                                        // helperText={validateAuthor.errors}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        disabled={true}
                                                        // error={validatePseudonym.invalid}
                                                        margin="normal"
                                                        label="Bút danh"
                                                        // maxRows={7}
                                                        fullWidth
                                                        value={dataAuthor?.pseudonym ?? ''}
                                                        onChange={handleChange}
                                                        name="pseudonym"
                                                        // helperText={validatePseudonym.errors}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControl sx={{ width: '100%', margin: '15px 0' }}>
                                                        <InputLabel id="demo-multiple-chip-label">Thể loại</InputLabel>
                                                        <Select
                                                            disabled={true}
                                                            labelId="demo-multiple-chip-label"
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            value={dataAuthor?.typeName ?? []}
                                                            // onChange={handleChangeType}
                                                            input={<OutlinedInput id="select-multiple-chip" label="Thể loại" />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => {
                                                                        if (findName(value).length !== 0) {
                                                                            return <Chip key={value} label={findName(value)} />;
                                                                        }
                                                                        return null;
                                                                    })}
                                                                </Box>
                                                            )}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {dataType?.length > 0 ? (
                                                                dataType.map((element, index) => (
                                                                    <MenuItem
                                                                        key={index.toString()}
                                                                        value={element._id}
                                                                        style={getStyles(element._id, typeName, theme)}
                                                                        sx={{ zIndex: 999999 }}
                                                                    >
                                                                        {element.title}
                                                                    </MenuItem>
                                                                ))
                                                            ) : (
                                                                <MenuItem sx={{ pointerEvents: 'none' }}>Chưa có bài viết nào</MenuItem>
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        disabled={true}
                                                        // error={validateAward.invalid}
                                                        margin="normal"
                                                        label="Giải thưởng"
                                                        multiline
                                                        rows={2}
                                                        // maxRows={7}
                                                        fullWidth
                                                        value={dataAuthor?.award ?? ''}
                                                        // onChange={handleChange}
                                                        name="award"
                                                        helperText={validateAward.errors}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        disabled={true}
                                                        margin="normal"
                                                        label="Giai đoạn sáng tác"
                                                        fullWidth
                                                        value={dataAuthor?.creative_phase ?? ''}
                                                        // onChange={handleChange}
                                                        name="creativePhase"
                                                    />
                                                </Grid>
                                                <Grid item sx={{ mt: 2 }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                // disabled={selectedRow === null}
                                                                checked={authorMain}
                                                                name="mainAuthor"
                                                                onChange={onChangeAction}
                                                            />
                                                        }
                                                        label="Tác giả chính"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                // disabled={selectedRow === null}
                                                                checked={secondaryAuthor}
                                                                name="secondaryAuthor"
                                                                onChange={onChangeAction}
                                                            />
                                                        }
                                                        label="Tác giả phụ"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MainCardIcon>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
            <ModalCropThumnail
                role={role}
                showModal={openModalImage}
                imageFile={dataImageCrop}
                typeModalImage={typeModalImage}
                onCloseModal={handleCloseModalImage}
                onUpdateImage={onUpdateImage}
            />
            <ModalDiary show={openDiary} handleClose={handleCloseModalDiary} onChooseDiary={onChooseDiary} />
            <ModalDocument show={openDocument} handleClose={handleCloseModalDocument} onChooseDocument={onChooseDocument} />
            <Loading show={isLoading} />
        </>
    );
};

export default ModalAddWork;
