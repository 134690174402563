import { useState, useRef, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Divider,
    Button,
    Grid,
    Stack,
    TextField,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    ToggleButtonGroup,
    ToggleButton,
    IconButton,
    FormControl
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Close } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { MENU_OPEN } from 'store/action/actions';
import { makeStyles } from '@mui/styles';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { isUrlValid } from 'utils/validate_checking';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import config from '../../../../config';
import StyleModal from './ModalAncestralHouse.css';
import DialogUtil from 'ui-component/show_confirm_action';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ModalDiary from '../../creator/workList/modal/ModalDiary';
import ModalListMember from 'views/pages/creator/authorList/modal/ModalListMember';
import { useNavigate } from 'react-router-dom';
import ConfigRouter from 'routes/config_router';
import ModalDetailDiary from 'views/pages/creator/workList/modal/ModalDetailDiary';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import ModalHistory from './ModalHistory';
import TimelineIcon from '@mui/icons-material/Timeline';
import ModalAlbumImage from './ModalAlbumImage';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HUONG_DAN_1 from './asset/huong-dan-1.webp';
import HUONG_DAN_2 from './asset/huong-dan-2.webp';
import HUONG_DAN_3 from './asset/huong-dan-3.webp';
import HUONG_DAN_4 from './asset/huong-dan-4.webp';

import HUONG_DAN_MOBILE_1 from './asset/huong-dan-mobile-1.webp';
import HUONG_DAN_MOBILE_2 from './asset/huong-dan-mobile-2.webp';
import HUONG_DAN_MOBILE_3 from './asset/huong-dan-mobile-3.webp';
import HUONG_DAN_MOBILE_4 from './asset/huong-dan-mobile-4.webp';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const imageMimeType = /image\/(png|jpg|jpeg)/i;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '45%', xs: '95vw' },
    maxHeight: '100%',
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 2
};

const boxImgStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    p: 1
};

const userManualStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '70%', xs: '95vw' },
    height: { md: 'auto', xs: 'auto' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 2
};

const initialValueContent = [
    {
        type: 'p',
        children: [
            {
                text: ''
            }
        ]
    }
];

const initialValidate = { invalid: false, errors: '' };
const ModalAncestralHouse = ({ open, onClose, data, onReload, type }) => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: '6px 16px',
            cursor: 'pointer'
        },
        secondaryTail: {
            backgroundColor: theme.palette.secondary.main
        },
        adddICon: {
            '& svg': {
                fontSize: 50
            }
        },
        styleImg: {
            width: '100%',
            position: 'relative',
            objectFit: 'contain'
        },
        img: {
            filter: 'none',
            WebkitFilter: `grayscale(${100})`,
            '&:hover': {
                filter: `grayscale(${0})`,
                WebkitFilter: `grayscale(${0})`
            },
            minHeight: '100px',
            width: '100%'
        }
    }));
    const divRef = useRef(null);
    const detailView = ConfigRouter.detaiMember;
    const theme = useTheme();
    const blogEditorRef = useRef();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const customization = useSelector((state) => state.customization);
    const [name, setName] = useState('');
    const [validateName, setValidateName] = useState(initialValidate);
    const [address, setAddress] = useState('');
    const [validateAddress, setValidateAddress] = useState(initialValidate);
    const [addressMap, setAddressMap] = useState('');
    const [validateLink, setValidateLink] = useState(initialValidate);
    const [yearBuilt, setYearBuilt] = useState('');
    const [restorationYear, setRestorationYear] = useState('');
    const [rank, setRank] = useState('');
    const [dataImages, setDataImages] = useState([]);
    const [note, setNote] = useState('');
    const [user, setUser] = useState(null);

    const [dataLinks, setDataLinks] = useState([]);
    const [dataFiles, setDataFiles] = useState([]);
    const [openDiary, setOpenDiary] = useState(false);
    const [showDiary, setShowDiary] = useState(false);
    const [idDiary, setIdDiary] = useState(null);
    const [openModalMember, setOpenModalMember] = useState(false);
    const [imageSelect, setImageSelect] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [diary, setDiary] = useState({});
    const [dataContents, setDataContents] = useState(initialValueContent);
    const [utilities, setUtilities] = useState('');
    const [validateUtilities, setValidateUtilities] = useState(initialValidate);
    const [validateRank, setValidateRank] = useState(initialValidate);
    const [validateYear, setValidateYear] = useState(initialValidate);
    const [dataDelete, setDataDelete] = useState([]);
    const [mobileMode, setMobileMode] = useState('info');
    const [dataList, setDataList] = useState([]);
    const [selectedBox, setSelectedBox] = useState();
    const [openHistory, setOpenHistory] = useState(false);
    const [openImage, setOpenImage] = useState(false);
    const [mode, setMode] = useState('thong-tin');
    const [userManualIndex, setUserManualIndex] = useState(1);
    const [isPublic, setIsPublic] = useState(false);

    const classes = useStyles();
    const styleModal = {
        width: 'auto',
        maxWidth: isMobile ? '95vw' : '70vw',
        height: 'auto',
        maxHeight: isMobile ? '95vh' : '70vh'
    };

    const userManualList = [
        {
            index: 1,
            content: !isMobile ? 'Truy cập vào Google Map bằng đường link sau: ' : 'Truy cập vào ứng dụng Google Map',
            link: !isMobile && 'https://www.google.com/maps',
            img: !isMobile ? HUONG_DAN_1 : HUONG_DAN_MOBILE_1,
            // img: HUONG_DAN_1,
            mobile: false,
            next: true,
            prev: false
        },
        {
            index: 2,
            content: !isMobile
                ? 'Tìm kiếm địa điểm cần lấy đường link ở thanh tìm kiếm góc trái phía trên.'
                : 'Tìm kiếm vị trí trên thanh tìm kiếm phía trên sau đó ấn vào nút chia sẻ',
            img: !isMobile ? HUONG_DAN_2 : HUONG_DAN_MOBILE_2,
            // img: HUONG_DAN_2,
            mobile: false,
            next: true,
            prev: true
        },
        {
            index: 3,
            content: !isMobile ? 'Bấm vào nút chia sẻ sau đó ấn vào nút sao chép đường liên kết để sao chép đường dẫn' : 'Tại nút chép URL ấn vào để sao chép đường dẫn',
            img: !isMobile ? HUONG_DAN_3 : HUONG_DAN_MOBILE_3,
            // img: HUONG_DAN_3,
            mobile: false,
            next: true,
            prev: true
        },
        {
            index: 4,
            content: !isMobile
                ? 'Dán đường linh vào ô nhập đường link google map.'
                : 'Quay lại trang web và dán đường linh vào ô nhập đường link google map.',
            footer: 'chúc bạn thành công',
            img: !isMobile ? HUONG_DAN_4 : HUONG_DAN_MOBILE_4,
            mobile: false,
            next: false,
            prev: true
        }
    ];

    const onCloseModal = () => {
        setName('');
        setAddress('');
        setAddressMap('');
        setYearBuilt('');
        setRestorationYear('');
        setRank('');
        setNote('');
        setUser(null);
        setIdDiary(null);
        setDataFiles([]);
        setDataLinks([]);
        setDataImages([]);
        setUtilities('');
        setDataDelete([]);
        setIsPublic(false)
        setValidateLink(initialValidate);
        setValidateName(initialValidate);
        setValidateUtilities(initialValidate);
        setValidateAddress(initialValidate);
        setValidateYear(initialValidate);
        setDataList([]);
        onClose();
    };

    const onSave = async () => {
        const formData = new FormData();
        for (let i = 0; i < dataFiles.length; i++) {
            formData.append('list_image', dataFiles[i], dataFiles[i].name);
        }
        const list_history = [];
        if (dataList.length > 0) {
            dataList.forEach((item) => {
                const obj = {
                    time: item.time,
                    content: item.content
                };
                list_history.push(obj);
            });
        }
        const dataObj = {
            name: name,
            address: address,
            address_map: addressMap,
            inauguration_year: yearBuilt,
            restoration_year: restorationYear,
            monument_ranking: rank,
            idPost: idDiary?._id,
            manager_ancestral_house: user?._id,
            dataLinks: dataLinks,
            note: note,
            utilities: utilities,
            list_history: list_history,
            isPublic: isPublic
        };

        formData.append('dataSend', JSON.stringify(dataObj));
        const rst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseCreate, formData);
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, 'Thêm thông tin nhà thờ thành công', () => {
                onCloseModal();
                onReload();
            });
        }
    };

    const onUpdate = async () => {
        const dataObj = {
            _id: data?._id,
            name: name,
            address: address,
            address_map: addressMap,
            monument_ranking: rank,
            idPost: idDiary?._id,
            manager_ancestral_house: user?._id,
            note: note,
            utilities: utilities,
            isPublic: isPublic
        };
        const rst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseUpdate, dataObj);
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, 'Cập nhật thông tin nhà thờ thành công', () => {
                onCloseModal();
                onReload();
            });
        }
    };

    const actionSave = () => {
        if (name.trim() === '' || name.trim() === null) {
            setValidateName({
                invalid: true,
                errors: 'Tên nhà thờ không được để trống'
            });
            return;
        }
        if (name.length > 250) {
            setValidateName({
                invalid: true,
                errors: 'Tên nhà thờ không được quá 250 ký tự'
            });
            return;
        }
        if (address.length > 300) {
            setValidateAddress({
                invalid: true,
                errors: 'Địa chỉ nhà thờ họ không được quá 300 ký tự'
            });
        }
        if (addressMap && !isUrlValid(addressMap)) {
            setValidateLink({
                invalid: true,
                errors: 'Đường dẫn địa chỉ google map không đúng định dạng vui lòng kiểm tra lại'
            });
            return;
        }
        if (yearBuilt && restorationYear) {
            if (parseInt(yearBuilt) >= parseInt(restorationYear)) {
                setValidateYear({
                    invalid: true,
                    errors: 'Năm xây dựng đang lớn hơn năm trùng tu'
                });
                return;
            }
        }
        if (utilities.length > 500) {
            setValidateUtilities({
                invalid: true,
                errors: 'Công năng nhà thờ họ không được quá 500 ký tự'
            });
            return;
        }
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn thêm mới thông tin nhà thờ dòng họ không`,
            () => {},
            () => onSave()
        );
    };

    const onChangeInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'name':
                setValidateName(initialValidate);
                setName(value);
                break;
            case 'address':
                setValidateAddress(initialValidate);
                setAddress(value);
                break;
            case 'addressMap':
                setValidateLink(initialValidate);
                setAddressMap(value);
                break;
            case 'yearBuilt':
                const re = /^[0-9\b]+$/;
                if (value === '' || re.test(value)) {
                    setValidateYear(initialValidate);
                    setYearBuilt(value);
                }
                break;
            case 'restorationYear':
                const res = /^[0-9\b]+$/;
                if (value === '' || res.test(value)) {
                    setValidateYear(initialValidate);
                    setRestorationYear(value);
                }

                break;
            case 'note':
                setNote(value);
                break;
            case 'utilities':
                setValidateUtilities(initialValidate);
                setUtilities(value);
                break;
            case 'rank':
                setValidateRank(initialValidate);
                setRank(value);
                break;
            default:
                break;
        }
    };

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const newDataImages = [...dataImages];
            const dataFile = [...dataFiles];
            const files = Array.from(event.target.files).slice(0, 20);
            files.forEach((file) => {
                file.eff = true;
                if (file.type.match(imageMimeType)) {
                    newDataImages.push(file);
                    dataFile.push(file);
                }
            });
            setDataImages(newDataImages);
            setDataFiles(dataFile);
        }
    };

    const onDeleteImage = () => {
        if (dataDelete.length === 0) {
            DialogUtil.showQuestions(false, 'Chưa chọn ảnh để xóa');
            return;
        }
        DialogUtil.showConfirm(
            'Thông báo',
            'Bạn có muốn xóa ảnh đã chọn không ?',
            () => {},
            () => onActionDelete()
        );
    };

    const onActionDelete = async () => {
        const obj = {
            _id: data?._id,
            arrayImage: dataDelete
        };
        const dataRst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseDeleteImage, obj);
        if (dataRst.status === 201) {
            setDataDelete([]);
            DialogUtil.showQuestions(true, 'Xóa ảnh thành công', () => getInfo());
        } else {
            DialogUtil.showQuestions(false, 'Xóa ảnh thất bại');
        }
    };

    const onChooseItem = (id) => {
        const arrData = [];
        arrData.push(id);
        const arr = dataImages;
        arr.forEach((element) => {
            if (element._id === id) {
                element.isSelected = !element.isSelected;
            }
        });
        const index = dataDelete.findIndex((f) => f === id);
        if (index === -1) {
            dataDelete.push(id);
        } else {
            dataDelete.splice(index, 1);
        }
        setDataDelete(dataDelete);
        const array = [];
        arr.forEach((file) => {
            array.push(file);
        });
        setDataImages(array);
    };

    const getInfo = async () => {
        const obj = {
            _id: data?._id
        };
        const rst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseDetail, obj);
        if (rst.status === 201) {
            if (rst?.data.length > 0) {
                setName(rst?.data[0]?.name);
                setAddress(rst?.data[0]?.address);
                setAddressMap(rst?.data[0]?.address_map);
                setYearBuilt(rst?.data[0]?.inauguration_year);
                setRestorationYear(rst?.data[0]?.restoration_year);
                setUtilities(rst?.data[0]?.utilities);
                setNote(rst?.data[0]?.note);
                setRank(rst?.data[0]?.monument_ranking);
                setIsPublic(rst?.data[0]?.isPublic ?? false);
                if (rst?.data[0]?.infor_post?.length > 0) {
                    setIdDiary(rst?.data[0]?.infor_post[0]);
                }
                if (rst?.data[0]?.infor_user?.length > 0) {
                    setUser({
                        _id: rst?.data[0]?.infor_user[0]?._id,
                        name: rst?.data[0]?.infor_user[0]?.full_name
                    });
                }
                const listData = rst?.data[0]?.list_image ?? [];
                if (listData.length > 0) {
                    listData.forEach((element) => {
                        element.isSelected = false;
                    });
                    setDataImages(listData);
                } else {
                    setDataImages([]);
                }
            }
        }
    };

    useEffect(() => {
        if (open && data?._id) {
            getInfo();
        }
    }, [open, data]);

    const onChooseImage = (item) => {
        item.eff = true;
        const arrImage = [...dataImages];
        const arrLink = [...dataLinks];
        arrLink.push(item);
        arrImage.push(item);
        setDataImages(arrImage);
        setDataLinks(arrLink);
    };

    const openModalDiary = () => {
        setOpenDiary(true);
    };

    const onChooseDiary = (data) => {
        setIdDiary(data);
    };

    const handleCloseModalDiary = () => {
        setOpenDiary(false);
    };

    const openModalUser = () => {
        setOpenModalMember(true);
    };

    const handleCloseModalMember = () => {
        setOpenModalMember(false);
    };

    const onSelectedMember = (data) => {
        setUser({
            _id: data?.id,
            name: data?.name
        });
    };

    const onReviewImage = (item) => {
        setImageSelect(item);
    };

    const onCloseImg = () => {
        setImageSelect(null);
    };

    const actionUpdate = () => {
        if (name.trim() === '' || name.trim() === null) {
            setValidateName({
                invalid: true,
                errors: 'Tên nhà thờ không được để trống'
            });
            return;
        }
        if (name.length > 250) {
            setValidateName({
                invalid: true,
                errors: 'Tên nhà thờ không được quá 250 ký tự'
            });
            return;
        }
        if (address.length > 300) {
            setValidateAddress({
                invalid: true,
                errors: 'Địa chỉ nhà thờ họ không được quá 300 ký tự'
            });
            return;
        }
        if (addressMap && !isUrlValid(addressMap)) {
            setValidateLink({
                invalid: true,
                errors: 'Đường dẫn địa chỉ google map không đúng định dạng vui lòng kiểm tra lại'
            });
            return;
        }
        if (yearBuilt && restorationYear) {
            if (parseInt(yearBuilt) >= parseInt(restorationYear)) {
                setValidateYear({
                    invalid: true,
                    errors: 'Năm xây dựng đang lớn hơn năm trùng tu'
                });
                return;
            }
        }
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn cập nhật thông tin dòng họ ${name}`,
            () => {},
            () => onUpdate()
        );
    };

    const userReview = () => {
        if (!user?._id) {
            return;
        }
        navigate(detailView, { state: { id: user?._id, screen: 'ListMember' } });
        dispatch({ type: MENU_OPEN, id: 'list_member' });
    };

    const onCloseModalDiary = () => {
        setShowDiary(false);
    };

    const onReviewDiary = async () => {
        const url = router.SUB_URL.diary_work + idDiary?._id;
        const dataRst = await fetchClient.getRequest(url);
        if (dataRst.status === 200) {
            if (dataRst?.data?.content.length !== 0) {
                let okValue = [];
                // eslint-disable-next-line array-callback-return
                dataRst?.data?.content.map((nodeBack) => {
                    const item = JSON.parse(nodeBack);
                    delete item.id;
                    okValue.push(item);
                });
                setDiary(dataRst.data);
                setDataContents(okValue);
                setShowDiary(true);
            } else {
                setDiary(dataRst.data);
                setDataContents(initialValueContent);
                setShowDiary(true);
            }
        }
    };

    const onRemoveFile = (item) => {
        if (item?._id) {
            const arrImage = [...dataImages];
            const arrLink = [...dataLinks];
            const index = arrImage.findIndex((f) => f._id === item._id);
            arrImage.splice(index, 1);
            setDataImages(arrImage);
            const index2 = arrLink.findIndex((f) => f._id === item._id);
            arrLink.splice(index2, 1);
            setDataLinks(arrLink);
        } else {
            const dataArr = dataImages;
            const index = dataArr.findIndex((f) => f.name === item.name);
            dataArr.splice(index, 1);
            const arr = [];
            dataImages.forEach((file) => {
                arr.push(file);
            });
            setDataImages(arr);
            const fileDatas = [...dataFiles];
            const index2 = fileDatas.findIndex((f) => f.name === item.name);
            fileDatas.splice(index2, 1);
            setDataFiles(fileDatas);
        }
    };

    const handleChange = (index, event) => {
        const values = [...dataList];
        if (event.target.value.length <= 1001) {
            values[index].content = event.target.value;
            setDataList(values);
        }
        // setCheck(true);
    };

    const handleChangeTime = (index, event) => {
        const values = [...dataList];
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            values[index].time = event.target.value;
            setDataList(values);
        }
    };

    const handleRemoveInput = (index) => {
        // if (dataNotification[index].content.trim() !== '') {
        //     DialogUtil.showConfirm(
        //         'Thông báo',
        //         `Bạn chắc chắn muốn xóa thông báo ${dataNotification[index].content} không?`,
        //         () => {},
        //         () => {
        //             onRemoveInput(index);
        //         }
        //     );
        // } else {
        //     onRemoveInput(index);
        // }
        const newInputs = [...dataList];
        newInputs.splice(index, 1);
        setDataList(newInputs);
        // setCheck(true);
    };

    const generateRandomString = () => {
        const length = 4;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    };

    const handleAddInput = (e) => {
        e.stopPropagation();
        const newId = generateRandomString();
        setDataList([...dataList, { _id: newId, time: '', content: '' }]);

        if (divRef.current) {
            const timer = setTimeout(() => {
                divRef?.current?.scrollTo({
                    top: divRef.current.scrollHeight
                });
            }, 100);
        }
    };

    const openLinkGoogle = () => {
        if (addressMap) {
            window.open(addressMap, '_blank', 'noreferrer');
        }
    };

    const handleChangePublic = () => {
        setIsPublic(!isPublic);
    };

    return (
        <>
            <Modal open={open} onClose={onCloseModal}>
                {mode === 'thong-tin' ? (
                    <Box sx={style} fullWidth>
                        <Grid container spacing={0}>
                            <Grid item xs={9} md={9}>
                                {type === true ? (
                                    <Typography id="modal-modal-title" variant="h3" component="h2">
                                        Xem thông tin nhà thờ: {data?.name}
                                    </Typography>
                                ) : (
                                    <Typography id="modal-modal-title" variant="h3" component="h2">
                                        {data?._id ? `Chỉnh sửa thông tin nhà thờ: ${data?.name}` : 'Thêm nhà thờ dòng họ'}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                md={3}
                                direction="row"
                                sx={{
                                    display: {
                                        md: 'flex',
                                        xs: 'flex'
                                    },
                                    flexDirection: { md: 'row', xs: 'column-reverse' },
                                    justifyContent: 'flex-end'
                                }}
                            >
                                {type === false ? (
                                    <Button
                                        disableElevation
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            width: data?._id ? '120px' : '100px',
                                            display: {
                                                md: 'flex',
                                                xs: 'none'
                                            }
                                        }}
                                        onClick={data?._id ? () => actionUpdate() : () => actionSave()}
                                        startIcon={<SaveIcon />}
                                    >
                                        {data?._id ? 'Cập nhật' : 'Lưu'}
                                    </Button>
                                ) : null}

                                <Button
                                    disableElevation
                                    sx={{ ml: 1 }}
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        onCloseModal();
                                    }}
                                    startIcon={<Close />}
                                >
                                    Đóng
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />

                        <Grid sx={{ paddingBottom: '35px' }} container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{
                                    display: { md: 'block', xs: mobileMode === 'info' ? 'block' : 'none' },
                                    height: { md: 'auto', xs: '70dvh' },
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    '&::-webkit-scrollbar': {
                                        width: { md: '0.4em', xs: '3px' },
                                        height: { md: 'auto', xs: '3px' },
                                        marginLeft: '4px'
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: customization?.themeDongHo?.heading,
                                        outline: 'slategrey'
                                    },
                                    paddingRight: '4px'
                                }}
                            >
                                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 }}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        label="Tên nhà thờ họ"
                                        sx={{
                                            pointerEvents: type === true ? 'none' : '',
                                            width: { xs: '70%', md: '80%', xl: '85%' }
                                        }}
                                        variant="outlined"
                                        value={name}
                                        onChange={onChangeInput}
                                        required
                                        placeholder="Nhập tên nhà thờ họ..."
                                        error={validateName.invalid}
                                        helperText={validateName.errors}
                                    />
                                    <Button
                                        sx={{
                                            pointerEvents: type === true ? 'none' : '',
                                        }}
                                        onClick={handleChangePublic}
                                        startIcon={isPublic === true ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                                    >
                                        Công khai
                                    </Button>
                                </Stack>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: 2
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            pointerEvents: type === true ? 'none' : '',
                                        }}
                                        error={validateUtilities.invalid}
                                        helperText={validateUtilities.errors}
                                        name="utilities"
                                        label="Giới thiệu chung/ Mô tả kiến trúc"
                                        fullWidth
                                        variant="outlined"
                                        value={utilities}
                                        onChange={onChangeInput}
                                        placeholder="Giới thiệu chung/ Mô tả kiến trúc"
                                        maxRows={3}
                                        minRows={3}
                                        multiline
                                    />
                                    
                                </Stack>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: 2
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        name="address"
                                        label="Địa chỉ nhà thờ"
                                        error={validateAddress.invalid}
                                        helperText={validateAddress.errors}
                                        sx={{
                                            pointerEvents: type === true ? 'none' : ''
                                        }}
                                        variant="outlined"
                                        value={address}
                                        onChange={onChangeInput}
                                        placeholder="Nhập địa chỉ nhà thờ"
                                    />
                                </Stack>
                                <Grid container spacing={1}>
                                    <Grid item md={10} xs={12}>
                                        <TextField
                                            name="addressMap"
                                            error={validateLink.invalid}
                                            label="Địa chỉ googlemap"
                                            fullWidth
                                            sx={{ pointerEvents: type === true ? 'none' : '' }}
                                            variant="outlined"
                                            value={addressMap}
                                            onChange={onChangeInput}
                                            helperText={validateLink.errors}
                                            placeholder="Nhập địa chỉ google map..."
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        {type ? (
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                startIcon={<RemoveRedEyeIcon />}
                                                sx={{ height: '46px' }}
                                                fullWidth
                                                onClick={() => {
                                                    openLinkGoogle();
                                                }}
                                            >
                                                Xem vị trí
                                            </Button>
                                        ) : (
                                            <Button
                                                sx={{ height: '46px' }}
                                                fullWidth
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                startIcon={<HelpIcon />}
                                                onClick={() => {
                                                    setMode('huong-dan');
                                                }}
                                            >
                                                Hướng dẫn
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>

                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 2
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        sx={{
                                            // disabled: 'disabled',
                                            marginBottom: '0px !important',
                                            pointerEvents: 'none',
                                            width: '75%'
                                        }}
                                        type="text"
                                        margin="normal"
                                        label={'Người quản lý'}
                                        value={user?.name ? user?.name : ''}
                                        name="path"
                                        placeholder="Người quản lý"
                                    />
                                    {/* <Button
                                        variant="contained"
                                        sx={{
                                            height: '46px',
                                            marginTop: '10px',
                                            marginLeft: '6px',
                                            // width: '95%',
                                            whiteSpace: 'nowrap',
                                            pointerEvents: 'none',
                                            display: { md: 'flex', xs: 'flex' }
                                        }}
                                        color="primary"
                                        onClick={type === true ? () => userReview() : () => openModalUser()}
                                        startIcon={<AttachFileIcon />}
                                    >
                                        {type === true ? 'Xem thông tin thành viên' : 'Chọn từ danh sách thành viên'}
                                    </Button> */}
                                    <Button
                                        variant="contained"
                                        sx={{
                                            height: '46px',
                                            marginTop: '10px',
                                            marginLeft: '6px',
                                            // width: '95%',
                                            whiteSpace: 'nowrap',
                                            display: { md: 'flex', xs: 'flex' }
                                        }}
                                        color="primary"
                                        onClick={type === true ? () => userReview() : () => openModalUser()}
                                        startIcon={<AttachFileIcon />}
                                    >
                                        {type === true
                                            ? 'Xem thông tin thành viên'
                                            : `${isMobile ? 'Danh sách' : 'Chọn từ danh sách thành viên'}`}
                                    </Button>
                                </Stack>
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 2
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            disabled: 'disabled',
                                            marginBottom: '0px !important',
                                            pointerEvents: 'none',
                                            width: '80%'
                                        }}
                                        type="text"
                                        margin="normal"
                                        label={'Bài viết giới thiệu về nhà thờ họ'}
                                        value={idDiary?.title ? idDiary?.title : ''}
                                        name="path"
                                        placeholder="Bài viết giới thiệu về nhà thờ họ"
                                    />
                                    <Button
                                        variant="contained"
                                        sx={{
                                            height: '46px',
                                            marginTop: '10px',
                                            marginLeft: '6px',
                                            // width: '95%',
                                            whiteSpace: 'nowrap',
                                            display: { md: 'flex', xs: 'flex' }
                                        }}
                                        color="primary"
                                        onClick={type === true ? () => onReviewDiary() : () => openModalDiary()}
                                        startIcon={<AttachFileIcon />}
                                    >
                                        {type === true ? 'Xem nội dung bài viết' : `${isMobile ? 'Danh sách' : 'Chọn từ danh sách phả ký'}`}
                                    </Button>
                                </Stack>

                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: 2
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            pointerEvents: type === true ? 'none' : ''
                                        }}
                                        error={validateRank.invalid}
                                        helperText={validateRank.errors}
                                        name="rank"
                                        label="Xếp hạng/ Danh hiệu"
                                        fullWidth
                                        variant="outlined"
                                        value={rank}
                                        onChange={onChangeInput}
                                        placeholder="Xếp hạng/ Danh hiệu"
                                        maxRows={3}
                                        minRows={3}
                                        multiline
                                    />
                                </Stack>

                                {/* <Box
                                sx={{
                                    borderRadius: '1px',
                                    borderColor: 'secondary',
                                    border: 1,
                                }}
                            >
                                <Typography id="modal-modal-title">Lịch sử xây dựng và trùng tu</Typography>

                                {dataList?.length > 0 ? (
                                    <Box
                                        sx={{
                                            justifyContent: 'flex-end',
                                            display: 'flex',
                                            flexDirection: { md: 'row', xs: 'column-reverse' }
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                marginRight: { md: '5px', xs: '0px' },
                                                marginBottom: { md: '10px', xs: '10px' },
                                                display: { md: 'flex', xs: 'flex' }
                                            }}
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<AddIcon />}
                                            onClick={(e) => {
                                                handleAddInput(e);
                                            }}
                                        >
                                            Thêm lịch sử
                                        </Button>
                                    </Box>
                                ) : null}
                                <Box
                                    id="container"
                                    ref={divRef}
                                    sx={{
                                        maxHeight: { md: '500px', xs: 'calc(95dvh - 180px)' },
                                        overflowX: 'hidden',
                                        overflowY: 'auto',
                                        paddingRight: '4px',
                                        '&::-webkit-scrollbar': {
                                            width: '0.4em'
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: customization?.themeDongHo?.heading,
                                            outline: 'slategrey'
                                        }
                                    }}
                                >
                                    {dataList?.length > 0 ? (
                                        dataList?.map((item, index) => (
                                            <Grid
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedBox(item?._id);
                                                }}
                                                key={index}
                                                spacing={1}
                                                container
                                                sx={{
                                                    borderRadius: '10px',
                                                    border: {
                                                        xs: `${selectedBox === item?._id ? '2px' : '1px'} solid ${
                                                            selectedBox === item?._id ? customization?.themeDongHo?.heading : '#454544'
                                                        }`
                                                    },
                                                    marginLeft: '0px',
                                                    width: '100%',
                                                    paddingBottom: { md: '10px', xs: '8px' },
                                                    marginBottom: { md: '15px', xs: '15px' },
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingRight: '4px',
                                                    marginTop: '0px'
                                                }}
                                            >
                                                <Grid item md={3} xs={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id={index}
                                                            fullWidth
                                                            label="Thời gian"
                                                            name={`time`}
                                                            value={item?.time ?? ''}
                                                            placeholder="Nhập thời gian"
                                                            onChange={(e) => handleChangeTime(index, e)}
                                                            // sx={{ marginRight: '5px' }}
                                                            // error={shouldValidate && (item.content.trim() === '' || item.content.length > 1000)}
                                                            // helperText={
                                                            //     shouldValidate &&
                                                            //     (item.content.trim() === ''
                                                            //         ? 'Vui lòng nhập nội dung thông báo'
                                                            //         : item.content.length > 1000
                                                            //         ? 'Nội dung không vượt quá 1000 kí tự'
                                                            //         : '')
                                                            // }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md="9" xs={12}>
                                                    <TextField
                                                        id={index}
                                                        fullWidth
                                                        label="Nội dung"
                                                        name={`content`}
                                                        value={item?.content ?? ''}
                                                        placeholder="Nhập nội dung thông báo"
                                                        onChange={(e) => handleChange(index, e)}
                                                        // sx={{ marginRight: '5px' }}
                                                        // error={shouldValidate && (item.content.trim() === '' || item.content.length > 1000)}
                                                        // helperText={
                                                        //     shouldValidate &&
                                                        //     (item.content.trim() === ''
                                                        //         ? 'Vui lòng nhập nội dung thông báo'
                                                        //         : item.content.length > 1000
                                                        //         ? 'Nội dung không vượt quá 1000 kí tự'
                                                        //         : '')
                                                        // }
                                                    />
                                                </Grid>
                                                <Grid item md="12">
                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                                                        <Button
                                                            disabled={selectedBox != item?._id}
                                                            size="small"
                                                            variant="contained"
                                                            onClick={(e) => handleRemoveInput(index, e)}
                                                            startIcon={<DeleteIcon />}
                                                        >
                                                            Xóa lịch sử
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                onClick={(e) => handleAddInput(e)}
                                                size="small"
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                sx={{ whiteSpace: 'nowrap', marginTop: '10px' }}
                                                startIcon={<AddIcon />}
                                            >
                                                Thêm
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Box> */}

                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 2
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            marginTop: '4px',
                                            pointerEvents: type === true ? 'none' : ''
                                        }}
                                        label="Ghi chú"
                                        fullWidth
                                        name="note"
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        value={note}
                                        onChange={onChangeInput}
                                        placeholder="Nhập ghi chú về nhà thờ họ"
                                    />
                                </Stack>
                                {data?._id ? (
                                    <Grid spacing={1} container>
                                        <Grid item md={6} xs={12}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    height: '46px',
                                                    marginTop: '10px',
                                                    marginLeft: '6px',
                                                    // width: '95%',
                                                    whiteSpace: 'nowrap',
                                                    display: { md: 'flex', xs: 'flex' }
                                                }}
                                                color="primary"
                                                startIcon={<TimelineIcon />}
                                                onClick={() => setOpenHistory(true)}
                                            >
                                                Lịch sử xây dựng và sửa chữa
                                            </Button>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{
                                                    height: '46px',
                                                    marginTop: '10px',
                                                    marginLeft: '6px',
                                                    // width: '95%',
                                                    whiteSpace: 'nowrap',
                                                    display: { md: 'flex', xs: 'flex' }
                                                }}
                                                onClick={() => setOpenImage(true)}
                                                color="primary"
                                                startIcon={<PhotoAlbumIcon />}
                                            >
                                                Album ảnh nhà thờ họ
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Grid>
                            {/* <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: {
                                    md: 'block',
                                    xs: mobileMode === 'map' ? 'block' : 'none'
                                },
                                height: { md: 'auto', xs: '90dvh' },
                                paddingTop: '10px  !important'
                            }}
                        >
                            <Box sx={{ width: 'full', marginY: '16px' }} />
                            <Typography variant="h4" sx={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}>
                                Ảnh nhà thờ họ
                            </Typography>
                            <div
                                style={{
                                    border: '0.5px solid black',
                                    borderRadius: '3px',
                                    padding: '5px'
                                }}
                            >
                                {type === false ? (
                                    <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Stack>
                                            <Button
                                                disableElevation
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddCircleRoundedIcon />}
                                                onClick={() => onOpenModal()}
                                                sx={{
                                                    marginRight: '10px',
                                                    whiteSpace: 'nowrap',
                                                    width: '120px',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Thêm Ảnh
                                            </Button>
                                            <input
                                                ref={imageRef}
                                                type="file"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={(e) => onImageChange(e)}
                                                multiple
                                            />
                                        </Stack>
                                        <Button
                                            disabled={!dataDelete.length > 0}
                                            disableElevation
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={onDeleteImage}
                                            startIcon={<DeleteIcon />}
                                            sx={{ marginRight: '10px', whiteSpace: 'nowrap', width: '120px' }}
                                        >
                                            Xóa Ảnh
                                        </Button>
                                    </Stack>
                                ) : null}

                                <Box
                                    sx={{
                                        overflowY: 'auto',
                                        height: { md: '340px', xs: '220px' }
                                    }}
                                    className={StyleModal.divImage}
                                >
                                    {dataImages.length > 0 ? (
                                        <ImageList sx={{ width: '100%' }} cols={3}>
                                            {dataImages.map((item, index) => {
                                                return (
                                                    <ImageListItem sx={{ cursor: 'pointer' }} key={index.toString()}>
                                                        <img
                                                            src={
                                                                !item.urlImage
                                                                    ? URL.createObjectURL(item)
                                                                    : `${config.serverImage + item.urlImage}`
                                                            }
                                                            onClick={() => onReviewImage(item)}
                                                            alt="title"
                                                            loading="lazy"
                                                            className={classes.styleImg}
                                                            style={{ objectFit: 'contain' }}
                                                            // onClick={() => {
                                                            //     setSelectImg(config.serverImage + item.urlImage);
                                                            // }}
                                                        />
                                                        {item?.eff && (
                                                            <ImageListItemBar
                                                                sx={{
                                                                    background:
                                                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
                                                                }}
                                                                position="top"
                                                                actionIcon={
                                                                    <IconButton
                                                                        sx={{ color: 'red' }}
                                                                        aria-label={`star ${index}`}
                                                                        onClick={() => onRemoveFile(item)}
                                                                    >
                                                                        <HighlightOffOutlinedIcon />
                                                                    </IconButton>
                                                                }
                                                                actionPosition="right"
                                                            />
                                                        )}

                                                        {item?._id && !item?.eff && !type && (
                                                            <ImageListItemBar
                                                                actionIcon={
                                                                    <>
                                                                        <IconButton
                                                                            sx={{ color: 'white' }}
                                                                            onClick={() => onChooseItem(item._id)}
                                                                        >
                                                                            {item.isSelected ? (
                                                                                <CheckCircleOutlinedIcon />
                                                                            ) : (
                                                                                <RadioButtonUncheckedOutlinedIcon />
                                                                            )}
                                                                        </IconButton>
                                                                    </>
                                                                }
                                                            />
                                                        )}
                                                    </ImageListItem>
                                                );
                                            })}
                                        </ImageList>
                                    ) : null}
                                </Box>
                            </div>
                        </Grid> */}
                        </Grid>
                        <Box
                            sx={{
                                display: { md: 'none', xs: 'block', marginTop: '16px' }
                            }}
                        >
                            <Button
                                disableElevation
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={data?._id ? () => actionUpdate() : () => actionSave()}
                                startIcon={<SaveIcon />}
                            >
                                {data?._id ? 'Lưu' : 'Cập nhật'}
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={userManualStyle} fullWidth>
                        {userManualList.map((userManualItem) => (
                            <Grid container sx={{ width: '100%', display: userManualIndex === userManualItem.index ? 'block' : 'none' }}>
                                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '8px' }}>
                                    <Typography variant="h4">Cách lấy lấy địa chỉ trên Google Map</Typography>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                            setUserManualIndex(1);
                                            setMode('thong-tin');
                                        }}
                                        startIcon={<CloseIcon />}
                                    >
                                        Đóng
                                    </Button>
                                </Grid>
                                <Grid xs={12} sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        src={userManualItem.img}
                                        style={{
                                            width: isMobile ? 'auto' : '100%',
                                            height: isMobile ? '400px' : 'auto'
                                        }}
                                        alt="huong-dan-1"
                                    />
                                </Grid>
                                <Grid xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '12px' }}>
                                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                                        {userManualItem.content}{' '}
                                        {userManualItem.link && (
                                            <a href={userManualItem.link} target="_blank" rel="noreferrer">
                                                {userManualItem.link}
                                            </a>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        disabled={!userManualItem.prev}
                                        onClick={() => {
                                            let newIdex = userManualIndex;
                                            newIdex -= 1;
                                            setUserManualIndex(newIdex);
                                        }}
                                        startIcon={<ArrowBackIosNewIcon />}
                                    >
                                        Trở lại
                                    </Button>
                                    <Button
                                        variant="contained"
                                        disabled={!userManualItem.next}
                                        sx={{ marginLeft: '8px' }}
                                        onClick={() => {
                                            let newIdex = userManualIndex;
                                            newIdex += 1;
                                            setUserManualIndex(newIdex);
                                        }}
                                        endIcon={<ArrowForwardIosIcon />}
                                    >
                                        Tiếp theo
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                )}
            </Modal>

            <ModalDiary show={openDiary} handleClose={handleCloseModalDiary} onChooseDiary={onChooseDiary} />
            <ModalListMember
                show={openModalMember}
                handleClose={handleCloseModalMember}
                onSelected={onSelectedMember}
                member={''}
                type={false}
            />
            {imageSelect ? (
                <Modal open={open} onClose={onCloseImg}>
                    <Box sx={boxImgStyle} fullWidth>
                        <Button
                            disableElevation
                            sx={{ mb: 1 }}
                            size="small"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                onCloseImg();
                            }}
                            startIcon={<Close />}
                        >
                            Đóng
                        </Button>
                        <img
                            alt="title"
                            loading="lazy"
                            src={!imageSelect.urlImage ? URL.createObjectURL(imageSelect) : `${config.serverImage + imageSelect.urlImage}`}
                            style={styleModal}
                        />
                    </Box>
                </Modal>
            ) : null}
            <ModalDetailDiary
                open={showDiary}
                onCloseModal={onCloseModalDiary}
                diary={diary}
                getEditorValue={() => {}}
                blogEditorRef={blogEditorRef}
                dataContents={dataContents}
            />
            <ModalHistory data={data} open={openHistory} onClose={() => setOpenHistory(false)} type={type} />
            <ModalAlbumImage open={openImage} data={data} onClose={() => setOpenImage(false)} type={type} />
        </>
    );
};

export default ModalAncestralHouse;
