import {
    Box,
    CardMedia,
    Grid,
    IconButton,
    Paper,
    Stack,
    Tooltip,
    Typography,
    TablePagination,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Modal,
    useMediaQuery,
    CircularProgress,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Table,
    TableContainer
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import MainCard from 'ui-component/cards/MainCard';
import { useSelector } from 'react-redux';
import NotFound from 'ui-component/404/404';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import BackgroundIMG from '../../../../../assets/images/icons/bggiapha.jpg';
import ImageDefault from '../../../../../assets/images/users/background.jpg';
import config from '../../../../../config';
import { useEffect, useState } from 'react';
import { ConvertName, convertTime, shortenName } from 'utils/helper';
import { styled, useTheme } from '@mui/material/styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '50%', xs: '95vw' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: { md: 2, xs: 1 }
};

const styleBox = {
    maxHeight: { xs: '75vh', sm: '87vh' },
    overflowY: 'auto'
};

const columns = [
    { id: 'stt', label: 'STT', minWidth: 50, align: 'center', rowAlign: 'center', xsDisplay: 'none', width: '50px' },
    {
        id: 'content',
        label: 'Tên bài viết',
        minWidth: { md: '200px', xs: '150px' },
        align: 'center',
        rowAlign: 'left',
        sx: { position: 'sticky', left: '0px', zIndex: '99', borderRight: { md: 'none', xs: '1px solid white' } }
    },
    { id: 'name_author', label: 'Mô tả', minWidth: { md: 200, xs: 150 }, align: 'center', rowAlign: 'left' },
    { id: 'birth', label: 'Tên tác giả', minWidth: { md: 110, xs: 110 }, align: 'center', rowAlign: 'left' },
    { id: 'create_by', label: 'Thời gian tạo', minWidth: { md: 250, xs: 150 }, align: 'center', rowAlign: 'left' }
];

const defaultDate = '--/--/--';

const ModalDocument = ({ show, handleClose, onChooseDocument, documents }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const [listDocument, setListDocument] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [total, setTotalRows] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [idDocument, setIdDocument] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // document.getElementById('list')?.scrollTo(0, 0);
        setPageSize(event.target.value);
        setPage(0);
    };

    const getListDocument = async () => {
        setLoading(true);
        const objData = {
            searchName: '',
            currentPage: page,
            rows: pageSize
        };
        // console.log('objData', objData);
        const rs = await fetchClient.postRequest(router.SUB_URL.documentFamily_list, objData);
        if (rs?.status === 201) {
            if (rs !== null) {
                setListDocument(rs?.data?.original);
                setTotalRows(rs?.data?.total);
                document?.getElementById('list')?.scrollTo(0, 0);
            } else {
                console.log('Empty list');
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const onClose = () => {
        setListDocument([]);
        setIdDocument('');
        setPage(0);
        setPageSize(20);
        handleClose();
    };

    const onSelectedRow = (id) => {
        setIdDocument(id);
    };

    const onSave = () => {
        onChooseDocument(idDocument);
        onClose();
    };

    useEffect(() => {
        if (show) {
            getListDocument();
        }
    }, [show, page, pageSize]);

    return (
        <Modal
            open={show}
            // onClose={() => handleCloseModal()}
        >
            <Box sx={style}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginY: '15px',
                        padding: { md: '0px', xs: '10px' }
                    }}
                >
                    <Typography id="modal-modal-title" variant="h3" component="h2" sx={{ maxWidth: '60%' }}>
                        Danh sách tài liệu
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' }, justifyContent: 'flex-end' }}>
                        <Button
                            disabled={idDocument === '' || idDocument?._id === documents?._id || idDocument?._id === documents?.id_document}
                            onClick={() => onSave()}
                            color="primary"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            sx={{ marginRight: '5px', borderBlockColor: 'black', marginTop: { md: '0px', xs: '5px' } }}
                        >
                            Chọn
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            startIcon={<CloseIcon />}
                            className="mr-2"
                            style={{ marginRight: 5, borderBlockColor: 'black' }}
                            onClick={() => onClose()}
                        >
                            Đóng
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        ...styleBox,
                        '&::-webkit-scrollbar': {
                            width: '0.4em'
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: customization?.themeDongHo?.heading,
                            outline: 'slategrey'
                        }
                    }}
                >
                    {loading ? (
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 'calc(100dvh - 300px)',
                                width: { xs: '100%', md: 'auto' }
                            }}
                            colSpan={12}
                        >
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <Grid item xs={12}>
                            <MainCard
                                contentSX={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingTop: '19px',
                                    paddingBottom: '18px !important'
                                }}
                                sx={{ marginBottom: '10px' }}
                            >
                                <Paper
                                    sx={{
                                        width: '100%',
                                        overflow: 'hidden',
                                        borderRadius: 0,
                                        position: 'relative',
                                        background: 'none'
                                    }}
                                >
                                    {listDocument?.length > 0 ? (
                                        <>
                                            {/* <Stack> */}
                                            <Grid
                                                container
                                                spacing={1}
                                                // ref={listRef}
                                                sx={{
                                                    backgroundColor: 'none',
                                                    paddingTop: '5px',
                                                    overflow: 'auto',
                                                    marginTop: { xs: '0px', md: '0px' },
                                                    maxHeight: {
                                                        // xs:
                                                        //     mobileFilterList.filter((item) => item.condition === true).length != 0
                                                        //         ? 'calc(100dvh - 280px)'
                                                        //         : 'calc(100dvh - 210px)',
                                                        sm: 'calc(100dvh - 270px)',
                                                        md: 'calc(100dvh - 250px)'
                                                    },
                                                    width: '100%',
                                                    marginLeft: 0,
                                                    '&::-webkit-scrollbar': {
                                                        width: '0.4em'
                                                    },
                                                    '&::-webkit-scrollbar-track': {
                                                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: customization?.themeDongHo?.heading,
                                                        outline: 'slategrey'
                                                    }
                                                }}
                                            >
                                                <>
                                                    {isMobile ? (
                                                        <Paper id="list" sx={{ width: '100%', overflow: 'hidden', borderRadius: 0 }}>
                                                            <TableContainer
                                                                sx={{
                                                                    '&::-webkit-scrollbar': {
                                                                        width: { md: '0.4em', xs: '3px' },
                                                                        height: { md: '5px', xs: '3px' }
                                                                    },
                                                                    '&::-webkit-scrollbar-track': {
                                                                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                                                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                                                    },
                                                                    '&::-webkit-scrollbar-thumb': {
                                                                        backgroundColor: customization?.themeDongHo?.heading,
                                                                        outline: 'slategrey'
                                                                    },
                                                                    maxHeight: isMobile ? 'calc(100vh - 240px)' : 'calc(100vh - 290px)'
                                                                }}
                                                            >
                                                                <Table
                                                                    sx={{
                                                                        borderRadius: 0,
                                                                        ...(isMobile && listDocument.length === 0 && { display: 'none' })
                                                                    }}
                                                                >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            {columns.map(
                                                                                (column) =>
                                                                                    ((isMobile && column.xsDisplay !== 'none') ||
                                                                                        !isMobile) && (
                                                                                        <TableCell
                                                                                            variant="head"
                                                                                            key={column.id}
                                                                                            align={column.align}
                                                                                            sx={{
                                                                                                minWidth: column.minWidth,
                                                                                                backgroundColor: 'rgb(144, 202, 249)',
                                                                                                color: 'black',
                                                                                                width: column?.width,
                                                                                                ...column.sx
                                                                                            }}
                                                                                        >
                                                                                            {column.label}
                                                                                        </TableCell>
                                                                                    )
                                                                            )}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    {listDocument?.length > 0 ? (
                                                                        <>
                                                                            {/* <Stack> */}
                                                                            <TableBody>
                                                                                {listDocument.map((item, index) => (
                                                                                    <TableRow
                                                                                        key={item._id}
                                                                                        sx={{
                                                                                            // backgroundColor: idChoose === row.ref_member ? '#b3c7e8' : '#f0f0f0',
                                                                                            cursor: 'pointer',
                                                                                            '&&.Mui-selected': {
                                                                                                backgroundColor: '#b3c7e8'
                                                                                            },
                                                                                            '&.MuiTableRow-hover:hover': {
                                                                                                backgroundColor:
                                                                                                    !isMobile && 'rgba(0, 0, 255, 0.3)'
                                                                                            },
                                                                                            backgroundColor:
                                                                                                idDocument?._id === item._id
                                                                                                    ? '#b3c7e8'
                                                                                                    : 'unset'
                                                                                        }}
                                                                                        onClick={() => onSelectedRow(item)}
                                                                                    >
                                                                                        <TableCell
                                                                                            sx={{
                                                                                                wordBreak: 'break-all',
                                                                                                verticalAlign: 'top',
                                                                                                height: 'auto !important',
                                                                                                position: { md: 'relative', xs: 'sticky' },
                                                                                                left: '0px',
                                                                                                zIndex: '9',
                                                                                                background: idDocument?._id === item._id ? '#b3c7e8' : 'white'
                                                                                            }}
                                                                                            align="left"
                                                                                        >
                                                                                            <Typography variant="h5">
                                                                                                {item?.name_document}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            style={{
                                                                                                height: 'auto !important',
                                                                                                wordBreak: 'break-all',
                                                                                                verticalAlign: 'top'
                                                                                            }}
                                                                                            align="left"
                                                                                        >
                                                                                            <Typography variant="h5">
                                                                                                {item.content
                                                                                                    ? ConvertName(item.content, 90)
                                                                                                    : ''}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            style={{
                                                                                                height: 'auto !important',
                                                                                                wordBreak: 'break-all',
                                                                                                verticalAlign: 'top'
                                                                                            }}
                                                                                            align="left"
                                                                                        >
                                                                                            <Typography variant="h5">
                                                                                                {item?.create_by.full_name
                                                                                                    ? isMobile
                                                                                                        ? shortenName(
                                                                                                              item.create_by.full_name,
                                                                                                              8,
                                                                                                              14,
                                                                                                              50
                                                                                                          )
                                                                                                        : shortenName(
                                                                                                              item.create_by.full_name,
                                                                                                              25,
                                                                                                              14,
                                                                                                              250
                                                                                                          )
                                                                                                    : 'Ẩn danh'}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            style={{
                                                                                                height: 'auto !important',
                                                                                                wordBreak: 'break-all',
                                                                                                verticalAlign: 'top'
                                                                                            }}
                                                                                            align="left"
                                                                                        >
                                                                                            <Typography variant="h5">
                                                                                                {' '}
                                                                                                {item?.update_at
                                                                                                    ? convertTime(item?.update_at)
                                                                                                    : defaultDate}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </>
                                                                    ) : (
                                                                        <TableRow>
                                                                            <TableCell
                                                                                sx={{ display: 'table-cell', textAlign: 'center' }}
                                                                                colSpan={12}
                                                                            >
                                                                                <NotFound />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </Table>
                                                            </TableContainer>
                                                        </Paper>
                                                    ) : (
                                                        <>
                                                            <Grid
                                                                container
                                                                sx={{
                                                                    margin: '0px 0px 15px 0px',
                                                                    borderBottom: '1px solid #aaa',
                                                                    width: '100%',
                                                                    position: 'sticky',
                                                                    // top: isMobile ? '-7px' : '-5px',
                                                                    top: '-5px',
                                                                    backgroundImage: `url(${BackgroundIMG})`,
                                                                    zIndex: !loading ? 1 : 0,
                                                                    paddingBottom: '5px'
                                                                }}
                                                                // spacing={isMobile ? 1 : 2}
                                                                spacing={2}
                                                            >
                                                                <Grid item xs={7.5} md={4.5}>
                                                                    <Typography variant="h4">Tên bài viết</Typography>
                                                                </Grid>
                                                                <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={3}>
                                                                    <Typography textAlign="center" variant="h4">
                                                                        Mô tả
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={2}>
                                                                    <Typography variant="h4">Tên tác giả</Typography>
                                                                </Grid>
                                                                <Grid item md={1.5}>
                                                                    <Typography
                                                                        sx={{
                                                                            marginRight: '20px',
                                                                            textAlign: { xs: 'right', md: 'center' }
                                                                        }}
                                                                        variant="h4"
                                                                    >
                                                                        Thời gian tạo
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item md={1}></Grid>
                                                            </Grid>
                                                            {listDocument.map((item) => (
                                                                <Grid
                                                                    spacing={2}
                                                                    sx={{
                                                                        padding: '5px',
                                                                        borderBottom: '1px solid #aaa',
                                                                        alignItems: 'center',
                                                                        cursor: 'pointer',
                                                                        backgroundColor: idDocument._id === item._id ? '#b3c7e8' : 'unset'
                                                                    }}
                                                                    onClick={() => onSelectedRow(item)}
                                                                    container
                                                                >
                                                                    <Grid
                                                                        // onClick={() => onSelectedRow(item?._id, true)}
                                                                        sx={{ display: 'flex', alignItems: 'center' }}
                                                                        item
                                                                        xs={7.5}
                                                                        md={4.5}
                                                                    >
                                                                        <Typography variant="h5">{item?.name_document}</Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        // onClick={() => onSelectedRow(item?._id, true)}
                                                                        item
                                                                        sx={{ display: { xs: 'none', md: 'block' } }}
                                                                        md={3}
                                                                    >
                                                                        <Typography variant="h5">
                                                                            {item.content ? ConvertName(item.content, 90) : ''}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        sx={{ display: { xs: 'none', md: 'block' } }}
                                                                        // onClick={() => onSelectedRow(item?._id, true)}
                                                                        item
                                                                        md={2}
                                                                    >
                                                                        <Typography variant="h5">
                                                                            {item?.create_by.full_name
                                                                                ? isMobile
                                                                                    ? shortenName(item.create_by.full_name, 8, 14, 50)
                                                                                    : shortenName(item.create_by.full_name, 25, 14, 250)
                                                                                : 'Ẩn danh'}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        //onClick={() => onSelectedRow(item?._id, true)}
                                                                        item
                                                                        sx={{ textAlign: 'center' }}
                                                                        md={1.5}
                                                                        xs={2.5}
                                                                    >
                                                                        <Typography variant="h5">
                                                                            {' '}
                                                                            {item?.update_at ? convertTime(item?.update_at) : defaultDate}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            </Grid>
                                            {/* </Stack> */}
                                        </>
                                    ) : (
                                        <Stack
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: 'calc(100dvh - 300px)',
                                                width: { xs: '100%', md: 'auto' }
                                            }}
                                            colSpan={12}
                                        >
                                            <NotFound />
                                        </Stack>
                                    )}
                                    <TablePagination
                                        sx={{
                                            ...(isMobile && listDocument?.length === 0
                                                ? { display: 'none' }
                                                : {
                                                      overflow: 'hidden',
                                                      '.MuiTablePagination-toolbar': {
                                                          paddingLeft: { xs: '0px', sm: '16px' }
                                                      },
                                                      '.MuiInputBase-root': {
                                                          marginRight: { xs: '5px', sm: '32px' },
                                                          marginLeft: { xs: '3px', sm: '8px' }
                                                      },
                                                      '.MuiTablePagination-actions': {
                                                          marginLeft: { xs: '5px', sm: '20px' }
                                                      },
                                                      '.MuiButtonBase-root': {
                                                          padding: { xs: '3px ', sm: '8px' }
                                                      }
                                                  })
                                        }}
                                        labelRowsPerPage="Phân trang"
                                        rowsPerPageOptions={[10, 20, 50]}
                                        component="div"
                                        count={total}
                                        rowsPerPage={pageSize}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        showFirstButton
                                        showLastButton
                                    />
                                    {/* {loading && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '100%',
                                        height: '100%',
                                        p: 4,
                                        border: 'none',
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )} */}
                                </Paper>
                                {/* <Loading show={showLoad} /> */}
                            </MainCard>
                        </Grid>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalDocument;
