import { Box, CardMedia, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useNavigate } from 'react-router';
import ConfigRouter from 'routes/config_router';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import LogoDefault from '../../../assets/images/logo.webp';
import BgTopFooter from '../../../assets/images/users/top-bg-footer.jpg';
import config from 'config';
import { useEffect, useState } from 'react';

const colorText = 'rgba(255, 255, 255, 0.75)';

const styleTitle = {
    color: colorText
};
const styleIcon = { marginRight: '3px', color: colorText };
const MemberSection = [
    { id: 'login', title: 'Đăng nhập', url: ConfigRouter.login },
    { id: 'forgot_password', title: 'Quên mật khẩu', url: ConfigRouter.forgotPassword }
];
const getIcon = (type) => {
    switch (type) {
        case 'facebook':
            return <FacebookIcon sx={styleIcon} />;
        case 'phone':
            return <PhoneIcon sx={styleIcon} />;
        case 'mail':
            return <EmailIcon sx={styleIcon} />;

        default:
            return <LanguageIcon sx={styleIcon} />;
    }
};
const FooterNewFeed = ({ nameFamily, logo, data, hometown, addressChurch, dataSetting }) => {
    const [dataContact, setDataContact] = useState([]);
    const [version, setVersion] = useState(null);
    const navigate = useNavigate();
    const handleClick = (url) => {
        if (url) {
            navigate(url);
        }
    };

    const user = localStorage.getItem('DATA_USER') || '';
    const onClickSocial = (item) => {
        switch (item?.type) {
            case 'facebook':
            case 'web':
                window.open(item?.url ?? '/', '_blank');
                break;
            case 'zalo':
                window.location = 'tel:' + item?.url;
                break;
            case 'phone':
                window.location = 'tel:' + item?.url;
                break;
            case 'mail':
                window.location = 'mailto:' + item?.url;
                break;

            default:
                window.open(item?.url ?? '/', '_blank');
                break;
        }
    };

    useEffect(() => {
        if (data) {
            setDataContact(data?.filter((item) => item?.type !== 'youtube'));
        }
    }, [data]);

    useEffect(() => {
        const version = localStorage.getItem('VERSION') ? localStorage.getItem('VERSION') : null;
        setVersion(version)
    }, []);

    // console.log('dataSetting', dataSetting)
    return (
        <Box
            component="footer"
            sx={{
                marginTop: `${dataSetting?.banner?.length > 0 && dataSetting?.comment?.length > 0 ? 'auto' : '0px'}`,
                backgroundColor: '#630707',
                paddingBottom: '10px',
                width: '100% !important'
            }}
        >
            <Box
                sx={{
                    backgroundImage: `url(${BgTopFooter})`,
                    height: '9px',
                    backgroundRepeat: 'repeat-x'
                }}
            ></Box>
            <Grid container sx={{ marginTop: '0px', width: { xs: '95%', xl: '70%' }, margin: 'auto' }} spacing={2}>
                <Grid item xs={12} md={5} sx={{ marginBottom: '20px' }}>
                    <Typography textAlign={'left'} sx={styleTitle} variant="h4" component={'h4'}>
                        {`Trang thông tin ${nameFamily}`}
                    </Typography>
                    <Box sx={{ height: '60%', paddingLeft: '10px' }}>
                        <CardMedia
                            component={'img'}
                            sx={{ height: '100px', width: '130px', maxHeight: '100%', objectFit: 'contain' }}
                            image={logo ? config.severImageBase64 + logo : LogoDefault}
                            title={nameFamily ?? ''}
                        />
                    </Box>
                    {hometown && (
                        <Box display={'flex'} sx={{ marginTop: '10px' }}>
                            <Typography
                                textAlign={'left'}
                                sx={{
                                    minWidth: '17%',
                                    ...styleTitle,

                                    marginBottom: '10px',
                                    fontWeight: 400
                                }}
                                variant="h5"
                                component={'h5'}
                            >
                                <span style={{ fontWeight: 'bold' }}>Nguyên quán </span>
                                {`: ${hometown}`}
                            </Typography>
                        </Box>
                    )}
                    {addressChurch && (
                        <Box display={'flex'}>
                            <Typography
                                textAlign={'left'}
                                sx={{
                                    minWidth: '17%',
                                    ...styleTitle,
                                    marginBottom: '10px',
                                    fontWeight: 400
                                }}
                                variant="h5"
                                component={'h5'}
                            >
                                <span style={{ fontWeight: 'bold' }}>Nhà thờ họ</span> {`: ${addressChurch}`}
                            </Typography>
                        </Box>
                    )}
                </Grid>
                {dataContact && dataContact.length > 0 && dataSetting.is_show ? (
                    <Grid item xs={12} sx={{ paddingLeft: { md: '56px !important', xs: '0px' } }} md={5}>
                        <Typography variant="h4" sx={styleTitle} component={'h4'}>
                            Thông tin liên hệ
                        </Typography>

                        <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                            {dataContact.map((item, index) => (
                                <li style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                    {getIcon(item?.type)}
                                    <Typography
                                        onClick={() => {
                                            onClickSocial(item);
                                        }}
                                        sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
                                        variant="h6"
                                        color={colorText}
                                        component={'h6'}
                                    >
                                        {item.title}
                                        {item.title === '' ? '' : ` : `}
                                        {/* {item?.type === 'phone' || item?.type === 'mail' ? ` : ${item?.url ?? ''}` : ''} */}
                                        {item.url}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </Grid>
                ) : null}
                <Grid item xs={12} md={2}>
                    <Typography variant="h4" sx={styleTitle} component={'h4'}>
                        Thành viên
                    </Typography>
                    {user === '' ? (
                        <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                            {MemberSection.map((item, index) => (
                                <li style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                    <ArrowRightIcon sx={{ marginRight: '3px', color: colorText }} />
                                    <Typography
                                        onClick={() => {
                                            handleClick(item.url);
                                        }}
                                        sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
                                        variant="h6"
                                        color={colorText}
                                        component={'h6'}
                                    >
                                        {item.title}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                            <li style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                <ArrowRightIcon sx={{ marginRight: '3px', color: colorText }} />
                                <Typography
                                    onClick={() => {
                                        handleClick(ConfigRouter.login);
                                    }}
                                    sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
                                    variant="h6"
                                    color={colorText}
                                    component={'h6'}
                                >
                                    Menu Chính
                                </Typography>
                            </li>
                        </ul>
                    )}
                </Grid>
            </Grid>
            <Typography textAlign={'center'} sx={{ marginTop: '10px' }} variant="h6" color={'rgb(128,128,128)'} component="h6">
                {version ? `Gia Phả Số: ${version} | © ${new Date().getFullYear()} bản quyền thuộc về ${nameFamily}` :
                `© ${new Date().getFullYear()} bản quyền thuộc về ${nameFamily}`}
            </Typography>
        </Box>
    );
};

export default FooterNewFeed;
