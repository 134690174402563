import { useNavigate } from 'react-router-dom';

// material-ui
import { Divider, Grid, Stack, Button, CardMedia, Typography } from '@mui/material';

// project imports
// import AuthWrapper1 from '../AuthWrapper1';
// import AuthCardWrapper from '../AuthCardWrapper';
// import AuthLogin from '../auth-forms/AuthLogin';
// import Logo from '../../../../assets/images/logo.webp';
import AuthLogin from '../../../authentication/auth-forms/AuthLogin'
import ConfigRouter from 'routes/config_router';
import config from 'config';
import { useEffect, useState } from 'react';
import router from 'routes/router';
// import QRCodeLogin from './QRCodeLogin';
import AuthWrapper1 from 'views/pages/authentication/AuthWrapper1';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import Logo from '../../../../../assets/images/logo.webp';
import fetchClient from 'views/utilities/RestfulAPI';
import QRCodeLogin from 'views/pages/authentication/authentication3/QRCodeLogin';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const LoginNewFeed = () => {
    const [linkLogo, setLinkLogo] = useState('');
    const navigate = useNavigate();
    const onForgot = () => {
        navigate(ConfigRouter.forgotPassword);
    };

    const user = localStorage.getItem('DATA_USER') || '';

    const navigateToNewfeed = () => {
        navigate(ConfigRouter.newFeed);
    };
    const getLogoDomain = async () => {
        const obj = { domain: window.location.origin };
        const res = await fetchClient.postRequest(router.CONFIG_URL.getLogoByDomain, obj);
        if (res?.status === 200) {
            const linkFromDb = res?.data?.data?.config_logo;
            if (linkFromDb) {
                setLinkLogo(linkFromDb);
                localStorage.setItem('m_family_logo', linkFromDb);
                return;
            }
        }
        setLinkLogo(localStorage.getItem('m_family_logo') ?? '');
    };
    useEffect(() => {
        getLogoDomain();
    }, []);
    // const currentURL = window.location.origin;
    return (
        <Grid
        container
        direction="column"
        justifyContent="flex-end"
        sx={{ p: 0, pt: '34px' }}
    >
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item sx={{m: 0,p: 0 ,display: 'flex' }}>
                    <AuthCardWrapper>
                        <Grid
                            container
                            // spacing={2}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ width: { md: '60%', xs: '100%', mt: '10px' } }}
                        >
                            <Grid item sx={{ mb: '4px' }}>
                                {linkLogo ? (
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            objectFit: 'initial',
                                            marginLeft: '5px',
                                            // width: 'auto',
                                            maxWidth: '300px',
                                            maxHeight: { md: '100px', xs: '80px' }
                                        }}
                                        image={config.severImageBase64 + linkLogo}
                                    />
                                ) : (
                                    <CardMedia component="img" height="150" width="180" image={Logo} />
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <AuthLogin typeLogin="newfeed" />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                           
                                <Grid item xs={12} sx={{m: 0, p: 0 }}>
                               
                                    <Stack direction="row" alignItems="center" justifyContent="center">
                                    {user === '' && (
                                        <>
                                        <Button
                                            onClick={() => {
                                                window.open('https://dangky.giaphaso.com/');
                                            }}
                                            variant="text"
                                        >
                                            Đăng ký
                                        </Button>                               
                                        <Button onClick={onForgot} variant="text">
                                            Quên mật khẩu?
                                        </Button>
                                        </>
                                        )}
                                    </Stack>
                                 
                                </Grid>
                
                            <Grid item xs={12} sx={{ marginTop: '25px', marginBottom: `${user !== '' ? '37px' : '0px'}` }}>
                                <Stack direction="row" alignItems="left" justifyContent="flex-start" spacing={1}>

                                    <Typography variant="span" gutterBottom sx={{ marginY: 2 }}>
                                        (*) Đăng nhập để thay đổi cài đặt hiển thị trang chủ 
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            sx={{
                                display: { md: 'flex', xs: 'none' },
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingLeft: 2,
                                paddingY: 10,
                                marginLeft: 3,
                                borderLeft: '1px solid #a19797'
                            }}
                            width="40%"
                        >
                            <QRCodeLogin />
                        </Grid>
                      
                    </AuthCardWrapper>
                   
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    );
};
export default LoginNewFeed;
