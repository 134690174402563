import { useEffect, useState, useRef } from 'react';
import {
    Paper,
    Button,
    Stack,
    Grid,
    TextField,
    TablePagination,
    Typography,
    Tooltip,
    IconButton,
    Box,
    Menu,
    MenuItem,
    ListItemIcon,
    ToggleButtonGroup,
    ToggleButton,
    useMediaQuery,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Chip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotFound from 'ui-component/404/404';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import ModalInfoWork from '../authorList/modal/ModalInfoWork';
import DialogUtil from 'ui-component/show_confirm_action';
import ModalPreview from './modal/ModalPreview';
import BookIcon from '@mui/icons-material/Book';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { getRoleButton } from 'utils/roleUility';
import { useTheme } from '@mui/material/styles';
import { shortenName } from 'utils/helper';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ModalFileWork from './modal/ModalFileWork';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { useNavigate } from 'react-router';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: '#0daeff'
            }
        }
    }
}));

const TYPE = {
    create: 'THEM_TP',
    update: 'CAP_NHAP_TP',
    view: 'XEM_TP',
    delete: 'XOA_TP'
};

const WORK = 'TAC_PHAM';
const AUTHOR = 'TAC_GIA';
const columns = [
    { id: 'stt', label: 'STT', minWidth: 70, align: 'center', rowAlign: 'center', xsDisplay: 'none', width: '70px' },
    {
        id: 'name_work',
        label: 'Tên tác phẩm',
        minWidth: { md: 150, xs: 150 },
        align: 'center',
        rowAlign: 'left',
        sx: { position: 'sticky', left: '0px', zIndex: '99', borderRight: { md: 'none', xs: '1px solid white' } }
    },
    { id: 'content', label: 'tác phẩm', minWidth: { md: 200, xs: 200 }, align: 'center', rowAlign: 'left' },
    { id: 'isPublic', label: 'Thể loại', minWidth: { md: 100, xs: 100 }, align: 'center', rowAlign: 'left' },
    { id: 'create_by', label: 'Thời gian phát hành', minWidth: { md: 205, xs: 120 }, align: 'center', rowAlign: 'left' },
    { id: 'update_by', label: 'Tóm tắt/mô tả', minWidth: { md: 150, xs: 200 }, align: 'center', rowAlign: 'left' },
    { id: 'award', label: 'Thành tựu', minWidth: { md: 150, xs: 200 }, align: 'center', rowAlign: 'left' },
    { id: 'status', label: 'Trạng thái', width: 120, align: 'center', rowAlign: 'left' }
    // { id: 'action', label: 'Thao tác', width: 120, align: 'center', rowAlign: 'left' }
];

const WorkList = ({ typeView, changeTab }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const timer = useRef(null);
    const customization = useSelector((state) => state.customization);
    const [anchorEl, setAnchorEl] = useState(null);
    const openOption = Boolean(anchorEl);
    const [listWork, setListWork] = useState([]);
    const [openModalPreview, setOpenModalPreview] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [workSelect, setWorkSelect] = useState(null);
    const [authorName, setAuthorName] = useState('');
    const [isLiterature, setIsLiterature] = useState(false);
    const [isPoem, setIsPoem] = useState(false);
    const [viewType, setViewType] = useState(typeView);
    const [typeModal, setTypeModal] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalAuthor, setTotalAuthor] = useState(0);
    const [totalWork, setTotalWork] = useState(0);
    const dataGenry = JSON.parse(localStorage.getItem('m_genre'));
    const [roles, setRole] = useState();
    const [idWork, setIdWork] = useState('');
    const [typeMode, setTypeMode] = useState('create');
    const [openFileWork, setOpenFileWork] = useState(false);
    const navigate = useNavigate();
    const [anchorElMenu, setAnchorElMenu] = useState(null);

    const openSelect = Boolean(anchorEl);
    const openDropdown = Boolean(anchorElMenu);

    const handleOpenDropDown = (event) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleCloseDropDown = () => {
        setAnchorElMenu(null);
    };
    // const RIGHT_CLICK_OPTIONS = [
    //     {
    //         name: 'Xem tác phẩm',
    //         alias: 'XEM_TP',
    //         icon: <VisibilityIcon fontSize="small" />,
    //         key: 'XEM_TP',
    //         show: roles?.read
    //     },
    //     {
    //         name: 'Chỉnh sửa thông tin',
    //         alias: 'CAP_NHAP_TP',
    //         icon: <EditIcon fontSize="small" />,
    //         key: 'CAP_NHAP_TP',
    //         show: roles?.update
    //     },
    //     {
    //         name: 'Xóa tác phẩm',
    //         alias: 'XOA_TP',
    //         icon: <DeleteIcon fontSize="small" />,
    //         key: 'XOA_TP',
    //         show: roles?.delete
    //     }
    // ];

    const DATA_OPTION = [
        {
            name: 'Thông tin tác phẩm',
            alias: 'TT_TAC_GIA',
            icon: <BookIcon fontSize="small" key="QUAN_HE_HT" />,
            show: true
        },
        {
            name: 'Nội dung tác phẩm',
            alias: 'TT_THANH_VIEN',
            icon: <ContentPasteIcon fontSize="small" />,
            key: 'DA_TP',
            show: true
        }
        // {
        //     name: 'Tệp lưu trữ nội dung',
        //     alias: 'DS_FILE',
        //     icon: <FileOpenIcon fontSize="small" />,
        //     key: 'DS_FILE',
        //     show: true
        // }
    ];

    // const RIGHT_CLICK_OPTIONS = [
    //     {
    //         name: 'Xem tác phẩm',
    //         alias: 'XEM_TP',
    //         icon: <VisibilityIcon fontSize="small" />,
    //         key: 'XEM_TP',
    //         show: roles?.read
    //     },
    //     {
    //         name: 'Chỉnh sửa thông tin',
    //         alias: 'CAP_NHAP_TP',
    //         icon: <EditIcon fontSize="small" />,
    //         key: 'CAP_NHAP_TP',
    //         show: roles?.update
    //     },
    //     {
    //         name: 'Xóa tác phẩm',
    //         alias: 'XOA_TP',
    //         icon: <DeleteIcon fontSize="small" />,
    //         key: 'XOA_TP',
    //         show: roles?.delete
    //     }
    // ];

    const handleChangeStatus = (e) => {
        switch (e.target.name) {
            case 'van xuoi':
                setIsLiterature(e.target.checked);
                break;
            case 'tho':
                setIsPoem(e.target.checked);
                break;
            default:
                break;
        }
    };

    const handleClose = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        setWorkSelect(null);
    };

    const onCloseModalFileWork = () => {
        setOpenFileWork(false);
    };

    const onOpenModalFileWork = () => {
        setOpenFileWork(true);
    };

    const handleClick = (item) => {
        setWorkSelect(item);
        setIdWork(item?._id);
    };

    const setSearch = (value) => {
        setAuthorName(value);
        if (timer) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            setPage(0);
            setRowsPerPage(10);
            getData(0, 10, value);
        }, 1000);
    };

    const handleChangeTypeView = (event, nextView) => {
        // console.log('aaa', nextView);

        changeTab(nextView);
        setViewType(nextView);
    };

    const handleCloseModalWork = () => {
        setTypeMode('create');
        setOpen(false);
    };

    const onClickAddWork = () => {
        setTypeModal(TYPE.create);
        setWorkSelect(null);
        setTypeMode('create');
        setOpen(true);
    };

    const onDeleteWork = async () => {
        const pr = {
            id: workSelect?._id
        };
        const urlApi = router.SUB_URL.delete_work;
        const rst = await fetchClient.postRequest(urlApi, pr);
        // console.log('rst', rst')
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, 'Xóa tác phẩm thành công', () => {
                getData();
            });
        } else {
            DialogUtil.showQuestions(false, 'Xóa tác phẩm thất bại');
        }
    };

    const handleDeleteWork = () => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn xoá tác phẩm ${workSelect?.name_work} không ?`,
            () => {},
            () => onDeleteWork()
        );
    };

    const onPublicWork = async (row) => {
        const pr = {
            id: row?._id,
            is_public: (row?.is_public !== null ? !row?.is_public : true)
        };
        const formData = new FormData();
        formData.append('dataWork', JSON.stringify(pr));
        const urlApi = router.SUB_URL.update_work;
        const rst = await fetchClient.postRequest(urlApi, formData);
        // console.log('rst', rst')
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, (((row?.is_public !== null ? !row?.is_public : true)?'Công khai': 'Hủy công khai')+' thông tin tác phẩm thành công'), () => {
                getData(page, rowsPerPage);
            });
        } else {
            DialogUtil.showQuestions(false, ((row?.is_public !== null ? !row?.is_public : true)?'Công khai': 'Hủy công khai')+'thông tin tác phẩm thất bại');
        }
    };


    const handlePublicWork = (row) => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn ${row?.is_public ? 'hủy công khai': 'công khai'} thông tin tác phẩm ${row?.name_work} không ?`,
            () => {},
            () => onPublicWork(row)
        );
    };


    const getData = async (page, rowsPerPage, authorName) => {
        setLoading(true);
        let urlApi = router.SUB_URL.list_works;
        const body = {
            search: authorName,
            currentPage: page,
            rowsPerpage: rowsPerPage
        };
        const rst = await fetchClient.postRequest(urlApi, body);
        if (rst.status === 201) {
            setLoading(false);
            setListWork(rst?.data?.original ?? []);
            setIdWork('');
            setWorkSelect(null);
        } else {
            setLoading(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getData(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        getData(0, +event.target.value);
    };

    const onReload = () => {
        getData(page, rowsPerPage);
        getSatic();
    };

    const findName = (value) => {
        const rst = dataGenry
            ?.filter((r) => {
                return r?.config_value.genre_id === value;
            })
            .map((m) => {
                return m?.config_value.genre_name;
            });
        return rst;
    };

    useEffect(() => {
        if (viewType === 'TAC_PHAM') {
            getData(page, rowsPerPage, authorName);
        }
    }, [viewType, page, rowsPerPage]);

    // console.log('listWork', listWork);
    const getSatic = async () => {
        let urlApi = router.SUB_URL.static_creator;
        const rst = await fetchClient.postRequest(urlApi);
        if (rst.status === 201) {
            setTotalAuthor(rst?.data?.totalAuthor);
            setTotalWork(rst?.data?.totalWork);
        } else {
            setLoading(false);
            DialogUtil.showQuestions(false, 'Lấy danh sách tác phẩm thất bại.');
        }
    };

    useEffect(() => {
        getSatic();
        async function getRoleButtonScreen() {
            const rs = await getRoleButton();
            setRole(rs);
        }
        getRoleButtonScreen();
    }, []);

    const onReviewWork = () => {
        setOpenModalPreview(true);
    };

    const onClickDrop = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onEditWork = () => {
        setTypeMode('create');
        setTypeModal(TYPE.update);
        setOpen(true);
    };

    const handleCloseDrop = (event) => {
        event.preventDefault();
        setAnchorEl(null);
    };

    const onClickMenuOption = (option) => {
        switch (option) {
            case 'TT_TAC_GIA':
                setOpen(true);
                setTypeMode('view');
                break;
            case 'TT_THANH_VIEN':
                setOpenModalPreview(true);
                break;
            case 'DS_FILE':
                onOpenModalFileWork();
                break;
            default:
                break;
        }
    };

    if (!loading) {
        return (
            <>
                <Stack>
                    <Grid container spacing={1}>
                        <Grid item md={11} xs={12}>
                            <div
                                style={{
                                    display: isMobile ? 'inline' : 'flex',
                                    marginBottom: '10px'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        marginY: { md: 0, xs: '20px' },
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <TextField
                                        sx={{ width: { md: '50%', xs: '100%' } }}
                                        id="search-bar"
                                        className="text"
                                        value={authorName}
                                        onChange={(e) => setSearch(e.target.value)}
                                        placeholder="Tìm kiếm theo tên tác phẩm ..."
                                        fullWidth
                                    />
                                     {isMobile && (
                                    <Box sx={{ display: { md: 'none', xs: 'flex', justifyContent: 'flex-end' } }}>
                                        <Button
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            sx={{ borderBlockColor: 'black', width: '100%' }}
                                            onClick={handleOpenDropDown}
                                        >
                                            <MoreHorizIcon />
                                        </Button>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button'
                                            }}
                                            anchorEl={anchorElMenu}
                                            open={openDropdown}
                                            onClose={handleCloseDropDown}
                                        >
                                            {roles?.create && (
                                                <MenuItem
                                                    onClick={() => {
                                                        handleCloseDropDown();
                                                        onClickAddWork();
                                                    }}
                                                >
                                                    <AddIcon sx={{ marginRight: 1 }} />
                                                    Thêm tác phẩm
                                                </MenuItem>
                                            )}
                                            {roles?.read && (
                                                <MenuItem
                                                    disabled={workSelect ? false : true}
                                                    onClick={() => {
                                                        handleCloseDropDown();
                                                        setOpen(true);
                                                        setTypeMode('view');
                                                    }}
                                                >
                                                    <BookIcon sx={{ marginRight: 1 }} />
                                                    Xem thông tin tác phẩm
                                                </MenuItem>
                                            )}
                                            {roles?.read && (
                                                <MenuItem
                                                    disabled={workSelect ? false : true}
                                                    onClick={() => {
                                                        handleCloseDropDown();
                                                        setOpenModalPreview(true);
                                                    }}
                                                >
                                                    <ContentPasteIcon sx={{ marginRight: 1 }} />
                                                    Xem nội dung tác phẩm
                                                </MenuItem>
                                            )}
                                            {roles?.update && (
                                                <MenuItem
                                                    disabled={workSelect ? false : true}
                                                    onClick={() => {
                                                        handleCloseDropDown();
                                                        onEditWork();
                                                    }}
                                                >
                                                    <EditIcon sx={{ marginRight: 1 }} />
                                                    Chỉnh sửa tác phẩm
                                                </MenuItem>
                                            )}
                                            {roles?.delete && (
                                                <MenuItem
                                                    disabled={workSelect ? false : true}
                                                    onClick={() => {
                                                        handleCloseDropDown();
                                                        handleDeleteWork();
                                                    }}
                                                >
                                                    <DeleteIcon sx={{ marginRight: 1 }} />
                                                    Xóa tác phẩm
                                                </MenuItem>
                                            )}
                                            <MenuItem
                                                onClick={() => {
                                                    handleCloseDropDown();
                                                    changeTab(AUTHOR);
                                                }}
                                            >
                                                <BookIcon sx={{ marginRight: 1 }} />
                                                Hiển thị danh sách tác phẩm
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                     )}
                                </Box>
                                <Box sx={{ display: { md: 'flex', xs: 'none' }, margin: isMobile ? '10px' : '0', marginLeft: 0 }}>
                                    {roles?.create && (
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => onClickAddWork()}
                                            sx={{
                                                marginRight: '10px',
                                                whiteSpace: 'nowrap',
                                                minWidth: '180px'
                                            }}
                                            startIcon={<AddIcon />}
                                        >
                                            Thêm mới tác phẩm
                                        </Button>
                                    )}
                                    {roles?.read && (
                                        <>
                                            <Button
                                                id="demo-customized-button"
                                                aria-controls={openSelect ? 'demo-customized-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openSelect ? 'true' : undefined}
                                                variant="contained"
                                                disableElevation
                                                disabled={workSelect ? false : true}
                                                onClick={onClickDrop}
                                                sx={{ marginRight: '10px', whiteSpace: 'nowrap', minWidth: '150px' }}
                                                startIcon={<VisibilityIcon />}
                                            >
                                                Xem chi tiết
                                            </Button>
                                            <StyledMenu
                                                id="demo-customized-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'demo-customized-button'
                                                }}
                                                anchorEl={anchorEl}
                                                open={openSelect}
                                                onClose={handleCloseDrop}
                                                onClick={handleCloseDrop}
                                            >
                                                {DATA_OPTION.map((option, index) => {
                                                    if (option.show === true)
                                                        return (
                                                            <MenuItem
                                                                key={index.toString()}
                                                                onClick={() => onClickMenuOption(option.alias)}
                                                            >
                                                                <ListItemIcon>{option.icon}</ListItemIcon>
                                                                {option.name}
                                                            </MenuItem>
                                                        );
                                                    return null;
                                                })}
                                            </StyledMenu>
                                        </>
                                    )}
                                    {roles?.update && (
                                        <Button
                                            disableElevation
                                            disabled={workSelect ? false : true}
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => onEditWork()}
                                            sx={{ marginRight: '10px', whiteSpace: 'nowrap', minWidth: '100px' }}
                                            startIcon={<EditIcon />}
                                        >
                                            Chỉnh sửa
                                        </Button>
                                    )}
                                    {roles?.delete && (
                                        <Button
                                            disableElevation
                                            disabled={workSelect ? false : true}
                                            fullWidth
                                            size="small"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleDeleteWork()}
                                            sx={{ whiteSpace: 'nowrap', minWidth: '100px' }}
                                            startIcon={<DeleteIcon />}
                                        >
                                            Xóa
                                        </Button>
                                    )}
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={1} sx={{ display: isMobile ? 'none' : 'flex', flexDirection: 'column' }}>
                            <ToggleButtonGroup
                                sx={{ display: 'flex', justifyContent: 'center' }}
                                value={viewType}
                                onChange={handleChangeTypeView}
                                exclusive
                            >
                                <Tooltip title="Hiển thị danh sách tác phẩm" placement="top">
                                    <span>
                                        <ToggleButton
                                            size="medium"
                                            selected={viewType === AUTHOR}
                                            onClick={() => changeTab(AUTHOR)}
                                            value={AUTHOR}
                                            aria-label={AUTHOR}
                                        >
                                            <PeopleAltIcon />
                                        </ToggleButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Đang hiển thị danh sách tác phẩm" placement="top">
                                    <span>
                                        <ToggleButton
                                            disabled={viewType === WORK}
                                            size="medium"
                                            onClick={() => changeTab(WORK)}
                                            selected={viewType === WORK}
                                            value={WORK}
                                            aria-label={WORK}
                                        >
                                            <BookIcon />
                                        </ToggleButton>
                                    </span>
                                </Tooltip>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: '10px', width: '100% !important', marginLeft: '0px' }}>
                        <Paper id="list" sx={{ width: '100%', overflow: 'hidden', borderRadius: 0 }}>
                            <TableContainer
                                sx={{
                                    '&::-webkit-scrollbar': {
                                        width: { md: '0.4em', xs: '3px' },
                                        height: { md: 'auto', xs: '3px' }
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: customization?.themeDongHo?.heading,
                                        outline: 'slategrey'
                                    },
                                    maxHeight: isMobile ? 'calc(100vh - 270px)' : 'calc(100vh - 290px)'
                                }}
                            >
                                <Table
                                    sx={{
                                        borderRadius: 0,
                                        ...(isMobile && listWork.length === 0 && { display: 'none' })
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(
                                                (column) =>
                                                    ((isMobile && column.xsDisplay !== 'none') || !isMobile) && (
                                                        <TableCell
                                                            variant="head"
                                                            key={column.id}
                                                            align={column.align}
                                                            sx={{
                                                                minWidth: column.minWidth,
                                                                backgroundColor: 'rgb(144, 202, 249)',
                                                                color: 'black',
                                                                width: column?.width,
                                                                ...column.sx
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    )
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    {listWork?.length > 0 ? (
                                        <>
                                            <TableBody>
                                                <>
                                                    {listWork.map((row, index) => (
                                                        <TableRow
                                                            key={row._id}
                                                            sx={{
                                                                // backgroundColor: idChoose === row.ref_member ? '#b3c7e8' : '#f0f0f0',
                                                                cursor: 'pointer',
                                                                '&&.Mui-selected': {
                                                                    backgroundColor: '#b3c7e8'
                                                                },
                                                                '&.MuiTableRow-hover:hover': {
                                                                    backgroundColor: !isMobile && 'rgba(0, 0, 255, 0.3)'
                                                                }
                                                            }}
                                                            selected={idWork === row._id}
                                                            onClick={() => handleClick(row)}
                                                        >
                                                            {!isMobile && (
                                                                <TableCell
                                                                    style={{ height: 'auto !important', verticalAlign: 'middle' }}
                                                                    align="center"
                                                                >
                                                                    {index + 1 + page * (rowsPerPage - 0)}
                                                                </TableCell>
                                                            )}
                                                            <TableCell
                                                                sx={{
                                                                    wordBreak: 'break-all',
                                                                    verticalAlign: 'top',
                                                                    height: 'auto !important',
                                                                    position: { md: 'relative', xs: 'sticky' },
                                                                    left: '0px',
                                                                    zIndex: '9',
                                                                    ...(isMobile && {
                                                                        backgroundColor: idWork === row._id ? '#b3c7e8' : 'white'
                                                                    })
                                                                }}
                                                                align="left"
                                                            >
                                                                {row?.name_work ? row.name_work : 'Ẩn danh'}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    height: 'auto !important',
                                                                    wordBreak: 'break-all',
                                                                    verticalAlign: 'top'
                                                                }}
                                                                align="left"
                                                            >
                                                                <Typography variant="h5">
                                                                    {row?.list_authors.length > 0
                                                                        ? row?.list_authors?.map((author) => (
                                                                              <Typography variant="h5">{author?.name_author}</Typography>
                                                                          ))
                                                                        : 'Chưa rõ'}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    height: 'auto !important',
                                                                    wordBreak: 'break-all',
                                                                    verticalAlign: 'top'
                                                                }}
                                                                align="left"
                                                            >
                                                                <Typography variant="h5">
                                                                    {row?.type.flatMap((m) => findName(m)).join(', ')}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell
                                                                item
                                                                sx={{ textAlign: 'left' }}
                                                                md={1.5}
                                                                // xs={2.5}
                                                            >
                                                                <Typography variant="h5"> {row?.publishing_year}</Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                item
                                                                sx={{ textAlign: 'left' }}
                                                                md={3}
                                                                // xs={2.5}
                                                            >
                                                                <Typography variant="h5"> {row?.content}</Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                item
                                                                sx={{ textAlign: 'left' }}
                                                                md={2}
                                                                // xs={2.5}
                                                            >
                                                                <Typography variant="h5"> {row?.award}</Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    placement="top-start"
                                                                    title={(row?.is_public?'Hủy công khai': 'Công khai') + ' tác phẩm '+ row?.name_work}
                                                                >
                                                                    <Chip
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            setIdWork(row._id);
                                                                            handlePublicWork(row);
                                                                        }}
                                                                        sx={{
                                                                            position: 'relative',
                                                                            zIndex: '20',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        color={row?.is_public? "info": "default"}
                                                                        label={row?.is_public? "Đã công khai": "Chưa công khai"}
                                                                    />
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            </TableBody>
                                        </>
                                    ) : (
                                        <TableRow>
                                            <TableCell sx={{ display: 'table-cell', textAlign: 'center' }} colSpan={12}>
                                                <NotFound />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{
                                    ...(listWork?.length === 0
                                        ? { display: 'none' }
                                        : {
                                              overflow: 'hidden',
                                              '.MuiTablePagination-toolbar': {
                                                  paddingLeft: { xs: '0px', sm: '16px' }
                                              },
                                              '.MuiInputBase-root': {
                                                  marginRight: { xs: '5px', sm: '32px' },
                                                  marginLeft: { xs: '3px', sm: '8px' }
                                              },
                                              '.MuiTablePagination-actions': {
                                                  marginLeft: { xs: '5px', sm: '20px' }
                                              },
                                              '.MuiButtonBase-root': {
                                                  padding: { xs: '3px ', sm: '8px' }
                                              }
                                          })
                                }}
                                labelRowsPerPage="Phân trang"
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={totalWork}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                showFirstButton
                                showLastButton
                            />
                        </Paper>
                    </Grid>
                </Stack>
                <ModalInfoWork
                    open={open}
                    handleClose={handleCloseModalWork}
                    typeModal={typeModal}
                    dataWork={workSelect}
                    onReload={onReload}
                    typeMode={typeMode}
                />
                <ModalPreview isOpen={openModalPreview} onClose={() => setOpenModalPreview(false)} dataWork={workSelect} />
                <ModalFileWork
                    show={openFileWork}
                    dataFile={workSelect?.file_upload.length > 0 ? workSelect?.file_upload : []}
                    handleClose={onCloseModalFileWork}
                    idWork={workSelect?._id}
                    nameWork={workSelect?.name_work}
                    type={'view'}
                    roles={roles}
                />
            </>
        );
    } else {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    p: 4,
                    border: 'none'
                }}
            >
                <CircularProgress />
            </Box>
        );
    }
};

export default WorkList;
