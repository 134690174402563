import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ConfigRouter from './config_router';
import PrivateRoute from './PrivateRoute';

const DetailUser = Loadable(lazy(() => import('views/pages/user/detail_user')));
const DetailFamily = Loadable(lazy(() => import('views/pages/family/detail_family')));
const MConfiguration = Loadable(lazy(() => import('views/pages/m_configuration/m_configuration')));
const DetailMember = Loadable(lazy(() => import('views/pages/member/Detail_Member')));
const ListMember = Loadable(lazy(() => import('views/pages/member/List_Member')));
const BuildGenealogy = Loadable(lazy(() => import('views/pages/build_genealogy/BuildGenealogy')));
const TreeViewer = Loadable(lazy(() => import('views/pages/tree/viewer')));
const EventManager = Loadable(lazy(() => import('views/pages/event/EventManager')));
const DiaryFamily = Loadable(lazy(() => import('views/pages/diary/DiaryFamily')));
const DiaryFamilyPublic = Loadable(lazy(() => import('views/pages/diary/diaryPublic/DiaryFamilyPublic')));
const LisAccount = Loadable(lazy(() => import('views/pages/list_account/List_Account')));
const ListService = Loadable(lazy(() => import('views/pages/list_service/List_Service')));
const AuthorList = Loadable(lazy(() => import('views/pages/creator/authorList/AuthorList')));
const AncestralHouse = Loadable(lazy(() => import('views/pages/ancestral_house/AncestralHouse'))); 
// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: (
        // <PrivateRoute>
        <MainLayout />
    ),
    // </PrivateRoute>,
    children: [
        {
            path: ConfigRouter.genealogy,
            element: (
                <PrivateRoute>
                    <BuildGenealogy />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.treeviewer,
            element: (
                <PrivateRoute>
                    <TreeViewer />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.userInfo,
            element: (
                <PrivateRoute>
                    <DetailUser />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.diaryFamily,
            element: (
                <PrivateRoute>
                    <DiaryFamily />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.detailFamily,
            element: (
                <PrivateRoute>
                    <DetailFamily />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.detaiMember,
            element: (
                <PrivateRoute>
                    <DetailMember />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.listMember,
            element: (
                <PrivateRoute>
                    <ListMember />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.configMaster,
            element: (
                <PrivateRoute>
                    <MConfiguration />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.listAccount,
            element: (
                <PrivateRoute>
                    <LisAccount />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.listService,
            element: (
                <PrivateRoute>
                    <ListService />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.event,
            element: (
                <PrivateRoute>
                    <EventManager />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.listCreator,
            element: (
                <PrivateRoute>
                    <listCreator />
                </PrivateRoute>
            )
        },
        {
            path: ConfigRouter.ancestralHouseList,
            element: (
                <PrivateRoute>
                    <AncestralHouse />
                </PrivateRoute>
            )
        }
    ]
};

export default MainRoutes;
