import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    TextField,
    Button,
    Box,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Menu,
    MenuItem
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getRoleButton } from 'utils/roleUility';
import axios from 'axios';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import config from 'config';
import DialogUtil from 'ui-component/show_confirm_action';
import useMediaQuery from '@mui/material/useMediaQuery';
import MainCard from 'ui-component/cards/MainCard';
import LoadingTable from 'ui-component/LoadingTable';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ModalAncestralHouse from './modal/ModalAncestralHouse';
import NotFound from 'ui-component/404/404';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const columns = [
    { id: 'stt', label: 'STT', align: 'center', rowAlign: 'center', width: '50px', xsDisplay: 'none' },
    {
        id: 'name',
        label: 'Tên nhà thờ',
        width: { md: 150, xs: 150 },
        align: 'center',
        rowAlign: 'left',
        sx: { position: 'sticky', left: '0px', zIndex: '99', borderRight: { md: 'none', xs: '1px solid white' } }
    },
    { id: 'address', label: 'Địa chỉ', width: { xs: '100px', xl: '150px' }, align: 'center', rowAlign: 'left' },
    { id: 'user', label: 'Người quản lý', width: '120px', align: 'center', rowAlign: 'left' },
    { id: 'rank', label: 'Xếp hạng/Danh hiệu', width: '90px', align: 'center', rowAlign: 'left' },
    // { id: 'action', label: 'Thao tác', width: 120, align: 'center', rowAlign: 'left' }
];

const AncestralHouse = () => {
    const timer = useRef(null);
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [listData, setListData] = useState([]);
    const [itemSelect, setItemSelect] = useState(null);
    const [total, setTotal] = useState(0);
    const [typeView, setTypeView] = useState(false);
    const [role, setRole] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const openDropdown = Boolean(anchorEl);

    const handleCloseDropDown = () => {
        setAnchorEl(null);
    };

    const handleChangeSearch = (e) => {
        setSearchName(e.target.value);
        if (timer) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            getData(e.target.value, 0, 10);
            setPage(0);
            setRowsPerPage(10);
            
        }, 500);
    };

    const onClose = () => {
        setTypeView(false);
        setOpen(false);
    };

    const onAdd = () => {
        setItemSelect(null);
        setTypeView(false);
        setOpen(true);
    };

    const getData = async (searchName, page, rowsPerPage) => {
        const objData = {
            searchName: searchName,
            currentPage: page,
            rowsPerpage: rowsPerPage
        };
        const dataRst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseList, objData);
        if (dataRst.status === 201) {
            setItemSelect(null);
            setTotal(dataRst?.data?.total);
            setListData(dataRst?.data?.original);
        }
    };

    const onReload = () => {
        setItemSelect(null);
        getData('', 0, 10);
    };

    useEffect(() => {
        getData(searchName, page, rowsPerPage);
    }, []);

    useEffect(() => {
        async function getRoleButtonScreen() {
            setLoading(true);
            const rs = await getRoleButton();
            setRole(rs);
            setLoading(false);
        }
        getRoleButtonScreen();
    }, []);

    const handleClickRowTable = (row) => {
        setItemSelect(row);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getData(searchName, newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        getData(searchName, 0 , +event.target.value);
    };

    const onDelete = async () => {
        const obj = {
            _id: itemSelect?._id
        };
        const rst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseDelete, obj);
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, 'Xóa thông tin nhà thờ thành công', () => onReload());
        } else {
            DialogUtil.showQuestions(false, 'Xóa thông tin thất bại');
        }
    };

    const onClickDelete = async () => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn xóa thông tin nhà thờ dòng họ này không`,
            () => {},
            () => onDelete()
        );
    };

    const onClickDetail = () => {
        setTypeView(false);
        setOpen(true);
    };

    const onReview = () => {
        setTypeView(true);
        setOpen(true);
    };

    const download = (url, param = undefined) => {
        const fullRoute = config.api + url;
        axios({
            url: fullRoute,
            method: 'post',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
            data: param
        }).then((response, error) => {
            if (error) {
                setLoading(false);
            } else {
                setLoading(false);
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = type;
                link.click();
            }
        });
    };

    const onExecl = async () => {
        setLoading(true);
        const pr = {};
        await download(router.SUB_URL.AncestralHouseExcel, pr);
    };

    const exportExcelDocument = async () => {
        DialogUtil.showConfirm(
            'Xác nhận',
            `Bạn có muốn tiếp tục tải file excel danh sách nhà thờ không ?`,
            () => {},
            () => onExecl()
        );
    };

    const handleOpenDropDown = (event) => {
        setAnchorEl(event.currentTarget);
    };

    if (loading) {
        return <LoadingTable></LoadingTable>;
    } else {
        return (
            <>
                {role?.read ? (
                    <MainCard
                        sx={{ height: { md: 'calc(100dvh - 122px)', xs: 'calc(100dvh - 100px)' } }}
                        contentSX={{ padding: { xs: '10px !important', sm: '24px !important' }, height: { xs: '100%', sm: 'auto' } }}
                    >
                        <div style={{ display: isMobile ? 'inline' : 'flex', marginBottom: '10px' }}>
                            <Box sx={{ width: '100%', display: 'flex', marginY: { md: 0, xs: '20px' } }}>
                                <TextField
                                    id="search-bar"
                                    className="text"
                                    variant="outlined"
                                    onChange={handleChangeSearch}
                                    placeholder="Nhập tên nhà thờ..."
                                    size="small"
                                    sx={{ width: { md: '40%', xs: '80%' } }}
                                />
                                <Box
                                    sx={{
                                        display: { md: 'none', xs: 'flex' },
                                        margin: isMobile ? '10px' : '0',
                                        marginLeft: 0
                                    }}
                                >
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        sx={{ 
                                            borderBlockColor: 'black', 
                                            width: '10%', 
                                            marginLeft: '3px',
                                            marginBottom: '2px'
                                        }}
                                        onClick={handleOpenDropDown}
                                    >
                                        <MoreHorizIcon />
                                    </Button>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button'
                                        }}
                                        anchorEl={anchorEl}
                                        open={openDropdown}
                                        onClose={handleCloseDropDown}
                                    >
                                        {role?.create && (
                                            <MenuItem
                                                onClick={() => onAdd()}
                                                sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px' }}
                                            >
                                                <AddIcon sx={{ marginRight: 1 }} />
                                                Thêm nhà thờ
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            disabled={!itemSelect}
                                            onClick={() => onClickDetail()}
                                            sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px' }}
                                        >
                                            <EditIcon sx={{ marginRight: 1 }} />
                                            {role?.update ? 'Sửa' : 'Xem chi tiết'}
                                        </MenuItem>
                                        {role?.delete && (
                                            <MenuItem
                                                disabled={!itemSelect}
                                                onClick={() => onClickDelete()}
                                                sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px' }}
                                            >
                                                <DeleteIcon sx={{ marginRight: 1 }} />
                                                Xóa
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            disabled={!itemSelect}
                                            onClick={() => onReview()}
                                            sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px' }}
                                        >
                                            <RemoveRedEyeIcon sx={{ marginRight: 1 }} />
                                            Xem chi tiết
                                        </MenuItem>
                                        {role?.export && (
                                            <MenuItem
                                                disabled={listData.length > 0 ? false : true}
                                                onClick={() => exportExcelDocument()}
                                                sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px' }}
                                            >
                                                <FileOpenIcon sx={{ marginRight: 1 }} />
                                                Xuất excel
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </Box>
                            </Box>
                            <Box sx={{ display: { md: 'flex', xs: 'none' }, margin: isMobile ? '10px' : '0', marginLeft: 0 }}>
                                {role?.create && (
                                    <Button
                                        disableElevation
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onAdd()}
                                        sx={{ marginRight: '10px', whiteSpace: 'nowrap', minWidth: '120px' }}
                                        startIcon={<AddIcon />}
                                    >
                                        Thêm nhà thờ
                                    </Button>
                                )}

                                {role?.update && (
                                    <Button
                                        disabled={!itemSelect}
                                        disableElevation
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onClickDetail()}
                                        sx={{ marginRight: '10px', whiteSpace: 'nowrap', minWidth: '100px' }}
                                        startIcon={<EditIcon />}
                                    >
                                        Chỉnh sửa
                                    </Button>
                                )}

                                {role?.delete && (
                                    <Button
                                        disabled={!itemSelect}
                                        disableElevation
                                        fullWidth
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => onClickDelete()}
                                        sx={{ marginRight: '10px', whiteSpace: 'nowrap', minWidth: '100px' }}
                                        startIcon={<DeleteIcon />}
                                    >
                                        Xóa
                                    </Button>
                                )}
                                <Button
                                    disabled={!itemSelect}
                                    disableElevation
                                    fullWidth
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => onReview()}
                                    sx={{ marginRight: '10px', whiteSpace: 'nowrap', minWidth: '120px' }}
                                    startIcon={<RemoveRedEyeIcon />}
                                >
                                    Xem chi tiết
                                </Button>
                                {role?.export && (
                                    <Button
                                        disableElevation
                                        fullWidth
                                        disabled={listData.length > 0 ? false : true}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={exportExcelDocument}
                                        sx={{ whiteSpace: 'nowrap', maxWidth: '100px' }}
                                        startIcon={<FileOpenIcon />}
                                    >
                                        Xuất excel
                                    </Button>
                                )}
                            </Box>
                        </div>
                        <>
                            <Paper
                                sx={{
                                    overflow: 'auto',
                                    // maxHeight: { md: 'calc(100vh - 330px)', xs: '100%' },
                                    width: '100%',
                                    marginLeft: 0,
                                    borderRadius: 0,
                                    '&::-webkit-scrollbar': {
                                        width: '0.4em'
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: customization?.themeDongHo?.heading,
                                        outline: 'slategrey'
                                    }
                                }}
                            >
                                <TableContainer
                                    sx={{
                                        // overflow: 'hidden',
                                        maxHeight: { xs: 'calc(100vh - 300px)', sm: 'calc(100vh - 280px)', md: 'calc(100vh - 280px)' },
                                        width: '100%',
                                        marginLeft: 0,
                                        position: 'relative',
                                        '&::-webkit-scrollbar': {
                                            width: { md: '0.4em', xs: '3px' },
                                            height: { md: 'auto', xs: '3px' }
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: customization?.themeDongHo?.heading,
                                            outline: 'slategrey'
                                        }
                                    }}
                                >
                                    <Table stickyHeader aria-label="sticky table" className="w-100">
                                        <TableHead sx={{ borderRadius: 0, ...(isMobile && listData.length === 0 && { display: 'none' }) }}>
                                            <TableRow>
                                                {columns.map(
                                                    (column) =>
                                                        ((isMobile && column.xsDisplay !== 'none') || !isMobile) && (
                                                            <TableCell
                                                                variant="head"
                                                                key={column.id}
                                                                align={column.align}
                                                                sx={{
                                                                    backgroundColor: 'rgb(144, 202, 249)',
                                                                    color: 'black',
                                                                    width: column?.width,
                                                                    ...column.sx
                                                                }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        )
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody sx={{ width: '100%' }}>
                                            {listData.length > 0 ? (
                                                listData.map((row, index) => (
                                                    <TableRow
                                                        hover
                                                        key={row._id}
                                                        selected={itemSelect?._id === row._id}
                                                        sx={{
                                                            // backgroundColor: idChoose === row.ref_member ? '#b3c7e8' : '#f0f0f0',
                                                            cursor: 'pointer',
                                                            '&&.Mui-selected': {
                                                                backgroundColor: '#b3c7e8'
                                                            },
                                                            '&.MuiTableRow-hover:hover': {
                                                                backgroundColor: !isMobile && 'rgba(0, 0, 255, 0.3)'
                                                            }
                                                        }}
                                                        onClick={() => handleClickRowTable(row)}
                                                    >
                                                        {!isMobile && (
                                                            <TableCell style={{ height: 'auto !important', width: '5%' }} align="center">
                                                                {index + 1 + page * (rowsPerPage - 0)}
                                                            </TableCell>
                                                        )}
                                                        <TableCell
                                                            sx={{
                                                                height: 'auto !important',
                                                                position: { md: 'relative', xs: 'sticky' },
                                                                left: '0px',
                                                                zIndex: '9',
                                                                ...(isMobile && {
                                                                    backgroundColor: itemSelect?._id === row._id ? '#b3c7e8' : 'white'
                                                                }),
                                                                width: '25%'
                                                            }}
                                                            align="center"
                                                        >
                                                            {row?.name}
                                                        </TableCell>

                                                        <TableCell style={{ height: 'auto !important', width: '30%' }} align="left">
                                                            {row?.address}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important', width: '18%' }} align="center">
                                                            {row?.infor_user.length > 0 ? row?.infor_user[0]?.full_name : ''}
                                                        </TableCell>
                                                        <TableCell style={{ height: 'auto !important', width: '12%' }} align="center">
                                                            {row?.monument_ranking}
                                                        </TableCell>

                                                        {/* <TableCell 
                                                        style={{ 
                                                            height: 'auto !important', 
                                                            width: '50%',
                                                            wordBreak: 'break-all'
                                                        }} 
                                                        align="center"
                                                    >
                                                        <Link
                                                            component="button"
                                                            variant="body2"
                                                            onClick={() => {
                                                                window.open(row?.address_map, "_blank")
                                                            }}
                                                        >
                                                            {row?.address_map}
                                                        </Link>
                                                    </TableCell> */}
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell sx={{ display: 'table-cell', textAlign: 'center' }} colSpan={12}>
                                                        <NotFound />
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    labelRowsPerPage="Phân trang "
                                    rowsPerPageOptions={[10, 20, 30]}
                                    component="div"
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    showFirstButton
                                    showLastButton
                                    sx={{
                                        ...(isMobile && listData.length === 0
                                            ? { display: 'none' }
                                            : {
                                                  '.MuiInputBase-root': {
                                                      marginRight: { xs: '10px', sm: '32px' }
                                                  },
                                                  '.MuiToolbar-root': {
                                                      padding: { xs: '0px', sm: 'auto' }
                                                  },
                                                  '.MuiTablePagination-actions': {
                                                      marginLeft: { xs: '5px', sm: '20px' }
                                                  },
                                                  overflowX: 'hidden',
                                                  '.MuiButtonBase-root': {
                                                      padding: { xs: '3px ', sm: '8px' }
                                                  }
                                              })
                                    }}
                                />
                            </Paper>
                        </>
                    </MainCard>
                ) : (
                    <MainCard
                        sx={{
                            height: { md: 'calc(100dvh - 122px)', xs: 'calc(100dvh - 102px)' }
                        }}
                        contentSX={{
                            height: '100%',
                            padding: { md: '24px', xs: '4px' }
                        }}
                    >
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <NotFound text={'Chỉ quản trị viên truy cập chức năng này'} />
                        </div>
                    </MainCard>
                )}

                <ModalAncestralHouse 
                    open={open} 
                    onClose={onClose} 
                    data={itemSelect} 
                    onReload={onReload} 
                    type={typeView} 
                />
            </>
        );
    }
};

export default AncestralHouse;
