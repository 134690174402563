import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Drawer,
    Fab,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Slider,
    Tooltip,
    Typography
} from '@mui/material';
import { IconSettings } from '@tabler/icons';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { SET_BORDER_RADIUS, SET_FONT_FAMILY, SET_THEME_DONGHO } from 'store/action/actions';
import { gridSpacing } from 'store/constant';

import redTheme from './themeCustom/redTheme';
import blueTheme from './themeCustom/blueTheme';

// concat 'px'
function valueText(value) {
    return `${value}px`;
}

// ==============================|| LIVE CUSTOMIZATION ||============================== //

const Customization = ({ openRightDrawer, onOpenRightDrawer }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);

    // drawer on/off
    // const [open, setOpen] = useState(false);
    // const handleToggle = () => {
    //     setOpen(!open);
    // };

    // useEffect(() => {
    //     setOpen(openRightDrawer);
    //     onOpenRightDrawer(open)
    // }, [openRightDrawer]);

    // state - border radius
    const [borderRadius, setBorderRadius] = useState(customization.borderRadius);
    const handleBorderRadius = (event, newValue) => {
        setBorderRadius(newValue);
    };

    useEffect(() => {
        dispatch({ type: SET_BORDER_RADIUS, borderRadius });
        localStorage.setItem('theme', JSON.stringify({ ...customization, borderRadius }));
    }, [dispatch, borderRadius]);

    let initialFont;
    switch (customization.fontFamily) {
        case `'Inter', sans-serif`:
            initialFont = 'Inter';
            break;
        case `'Poppins', sans-serif`:
            initialFont = 'Poppins';
            break;
        case `'Roboto', sans-serif`:
        default:
            initialFont = 'Roboto';
            break;
    }

    // state - font family
    const [fontFamily, setFontFamily] = useState(initialFont);
    useEffect(() => {
        let newFont;
        switch (fontFamily) {
            case 'Inter':
                newFont = `'Inter', sans-serif`;
                break;
            case 'Poppins':
                newFont = `'Poppins', sans-serif`;
                break;
            case 'Roboto':
            default:
                newFont = `'Roboto', sans-serif`;
                break;
        }
        dispatch({ type: SET_FONT_FAMILY, fontFamily: newFont });
        localStorage.setItem('theme', JSON.stringify({ ...customization, fontFamily: newFont }));
    }, [dispatch, fontFamily]);

    // state - theme family
    let initialThemeDongHo;

    switch (customization.themeDongHo.backgroundColor) {
        case '#9b0000':
            initialThemeDongHo = 'RED';
            break;
        case '#003168':
            initialThemeDongHo = 'BLUE';
            break;
        // default:
        //     initialThemeDongHo = 'RED';
        //     break;
    }

    const [themeDongHo, setThemeDongHo] = useState(initialThemeDongHo);
    console.log();
    useEffect(() => {
        let newThemeDongHo;
        switch (themeDongHo) {
            case 'RED':
                newThemeDongHo = redTheme;
                break;
            case 'BLUE':
                newThemeDongHo = blueTheme;
                break;
            default:
                newThemeDongHo = redTheme;
                break;
        }

        dispatch({ type: SET_THEME_DONGHO, themeDongHo: newThemeDongHo });
        localStorage.setItem('theme', JSON.stringify({ ...customization, themeDongHo: newThemeDongHo }));
    }, [dispatch, themeDongHo]);

    return (
        <>
            {/* toggle button */}
            {/* <Tooltip title="Live Customize">
                <Fab
                    component="div"
                    onClick={onOpenRightDrawer}
                    size="medium"
                    variant="circular"
                    color="secondary"
                    sx={{
                        borderRadius: 0,
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '4px',
                        top: '25%',
                        position: 'fixed',
                        right: 10,
                        zIndex: theme.zIndex.speedDial
                    }}
                >
                    <AnimateButton type="rotate">
                        <IconButton color="inherit" size="large" disableRipple>
                            <IconSettings />
                        </IconButton>
                    </AnimateButton>
                </Fab>
            </Tooltip> */}

            <Drawer
                anchor="right"
                onClose={onOpenRightDrawer}
                open={openRightDrawer}
                PaperProps={{
                    sx: {
                        width: 280
                    }
                }}
            >
                <PerfectScrollbar component="div">
                    <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                        <Grid item xs={12}>
                            {/* font family */}
                            <SubCard title="Loại phông chữ">
                                <FormControl>
                                    <RadioGroup
                                        aria-label="font-family"
                                        value={fontFamily}
                                        onChange={(e) => setFontFamily(e.target.value)}
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="Roboto"
                                            control={<Radio />}
                                            label="Roboto"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Poppins"
                                            control={<Radio />}
                                            label="Poppins"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="Inter"
                                            control={<Radio />}
                                            label="Inter"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: theme.palette.grey[900] }
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            {/* border radius */}
                            <SubCard title="Kích cỡ viền">
                                <Grid item xs={12} container spacing={2} alignItems="center" sx={{ mt: 2.5 }}>
                                    <Grid item>
                                        <Typography variant="h6" color={themeDongHo}>
                                            0px
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Slider
                                            size="small"
                                            value={borderRadius}
                                            onChange={handleBorderRadius}
                                            getAriaValueText={valueText}
                                            valueLabelDisplay="on"
                                            aria-labelledby="discrete-slider-small-steps"
                                            marks
                                            step={2}
                                            min={0}
                                            max={24}
                                            // color="secondary"
                                            sx={{
                                                color: { themeDongHo },
                                                '& .MuiSlider-valueLabel': {
                                                    color: 'secondary'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6" color={themeDongHo}>
                                            24px
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            {/* border radius */}
                            <SubCard title="Giao diện">
                                <FormControl>
                                    <RadioGroup
                                        aria-label="font-family"
                                        value={themeDongHo}
                                        onChange={(e) => setThemeDongHo(e.target.value)}
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel
                                            value="RED"
                                            control={<Radio />}
                                            label="Đỏ"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'red' }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="BLUE"
                                            control={<Radio />}
                                            label="Xanh Dương"
                                            sx={{
                                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                                '& .MuiFormControlLabel-label': { color: 'blue' }
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </SubCard>
                        </Grid>
                    </Grid>
                </PerfectScrollbar>
            </Drawer>
        </>
    );
};

export default Customization;
