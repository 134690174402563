export const GENEALOGY = 'genealogy';
export const TREE = 'tree';
export const INFO_FAMILY = 'info_family';
export const STATISTICAL = 'statistical';
export const HISTORY_FAMILY = 'history_family';
export const GRAVE = 'grave';
export const DIARY = 'diary';
export const ALBUM = 'album';
export const DOCUMENT = 'document';
export const LIST_MEMBER = 'list_member';
export const EVENT = 'event';
export const MASTER = 'master';
export const ACCOUNT = 'account';
export const LISTPATRIACRCH = 'listPatriarch';
export const HOMEPAGE = 'homepage';
export const DETAILMEMBER = 'detail_member';
export const LISTACCOUNT = 'list_account';
export const SERVICE = 'service';
export const SETTING_HOMEPAGE = 'setting_homepage';
export const CREATOR = 'creator';
export const SYSTEM_LOG = 'system_log';
export const ANCESTRALHOUSE = 'ancestral_house';
export const TOPIC_POST = 'topic_family';

export const ROOT = 'ROOT';
export const EDITOR = 'EDITOR';
export const VIEWER = 'VIEWER';

export const CREATE = 'create';
export const READ = 'read';
export const UPDATE = 'update';
export const DELETE = 'delete';
export const EXPORT = 'export';

export const MENUALIAS = 'Menu-Alias';
