import {
    TablePagination,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    useMediaQuery,
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Button,
    TextField,
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import listProduct from './dataProduct.json';
import EastIcon from '@mui/icons-material/East';

const OtherProduct = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    // const handleClick = (link) =>{

    // }
    return (
        <>
            <MainCard
                sx={{
                    height: { sm: 'calc(100dvh - 122px)', xs: 'calc(100dvh - 100px)' },
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    overflow: 'auto',
                    overflowX: 'hidden',
                    '&::-webkit-scrollbar': {
                        width: { md: '0.4em', xs: '3px' },
                        height: { md: 'auto', xs: '3px' }
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: customization?.themeDongHo?.heading,
                        outline: 'slategrey'
                    }
                }}
                contentSX={{
                    padding: { xs: '10px !important', sm: '24px !important' },
                    height: { xs: '100%', sm: 'auto' },
                    overflowX: 'hidden'
                }}
            >
                <Box sx={{ height: '10%', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h1">Các sản phẩm khác</Typography>
                </Box>
                
                <Grid container spacing={4} sx={{ marginTop: '10px' }}>
                    {listProduct.map((x) => (
                        <>
                            <Grid item>
                                <Card
                                    sx={{
                                        maxWidth: isMobile ? 'auto' : 345,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box sx={{}}>
                                        <CardMedia sx={{ height: 200, width: 200, m: 'auto', my: '20px' }} image={x.image} title={x.name} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h4" component="div">
                                                {x.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {x.des}
                                            </Typography>
                                        </CardContent>
                                    </Box>

                                    <CardActions>
                                        <Button
                                            endIcon={<EastIcon />}
                                            sx={{ mx: 'auto' }}
                                            variant="contained"
                                            href={x.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Tìm hiểu thêm{' '}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </MainCard>
        </>
    );
};

export default OtherProduct;
