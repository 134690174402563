import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Button, Modal, IconButton, Typography, Divider, Grid } from '@mui/material';
import { Box, Stack } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { Close } from '@mui/icons-material';
import ModalFileWork from './ModalFileWork';
import ModalDetailDiary from './ModalDetailDiary';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import ModalDocumentFamily from 'ui-component/family/ModalDocument';
import config from 'config';
import axios from 'axios';
import DialogUtil from 'ui-component/show_confirm_action';
import ModalPreviewDocument from 'ui-component/family/ModalPreviewDocument';
import DownloadIcon from '@mui/icons-material/Download';
import NotFound from 'ui-component/404/404';
import { ConvertName } from 'utils/helper';
import { getRoleButton } from 'utils/roleUility';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    m: '10px 0px'
};

const initialValueContent = [
    {
        type: 'p',
        children: [
            {
                text: ''
            }
        ]
    }
];

const role = {
    create: false,
    edit: false,
    view: false,
    update: false,
    delete: false,
    export: true
};

const LENGTHNAME = 20;

const ModalPreview = ({ isOpen, onClose, dataWork, isFromHomePage }) => {
    const [show, setShow] = useState(false);
    const [openDocument, setOpenDocument] = useState(false);
    const [openDiary, setOpenDiary] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [dataContents, setDataContents] = useState(initialValueContent);
    const [dataEditor, setDataEditor] = useState([]);
    const [diary, setDiary] = useState({});
    const [dataDocument, setDataDocument] = useState({});
    const [response, setResponse] = useState({});
    const blogEditorRef = useRef();
    const [showModal, setShowModal] = useState(false);
    // const [roles, setRole] = useState({});

    const onCloseModalFile = () => {
        setShow(false);
    };
    const onCloseModalDiary = () => {
        setOpenDiary(false);
    };

    const onCloseModalDocumentFamily = () => {
        setOpenDocument(false);
        setDataDocument({});
        setResponse({});
    };

    const onCloseModalDocumentPreview = () => {
        setOpenPreview(false);
    };

    const getEditorValue = async (value) => {
        setDataEditor(value);
    };

    const getDetailDiary = async () => {
        const url = router.SUB_URL.diary_work + dataWork?.diary?.id_diary;
        const dataRst = await fetchClient.getRequest(url);
        if (dataRst.status === 200) {
            if (dataRst?.data?.content.length !== 0) {
                let okValue = [];
                // eslint-disable-next-line array-callback-return
                dataRst?.data?.content.map((nodeBack) => {
                    const item = JSON.parse(nodeBack);
                    delete item.id;
                    okValue.push(item);
                });
                setDiary(dataRst.data);
                setDataContents(okValue);
                setOpenDiary(true);
            } else {
                setDiary(dataRst.data);
                setDataContents(initialValueContent);
                setOpenDiary(true);
            }
        }
    };

    const getDetailDocument = async () => {
        const obj = {
            _id: dataWork?.document?.id_document
        };
        const url = router.SUB_URL.documentFamily_detail;
        const dataRst = await fetchClient.postRequest(url, obj);
        if (dataRst.status === 201) {
            setDataDocument(dataRst?.data);
        }
        setOpenDocument(true);
    };

    const checkTypeFile = (nameFile) => {
        const parts = nameFile.split('.');
        const fileExtension = parts[parts.length - 1];
        return fileExtension;
    };

    const reviewLink = (data) => {
        window.open(data?.link_document, '_blank');
    };

    const openPreviewFileData = (data) => {
        const typeFile = checkTypeFile(data?.url_document);
        if (['jpe', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'png', 'ico', 'pdf', 'txt', 'svg', 'html'].includes(`${typeFile}`.toLowerCase())) {
            setResponse(data._id);
            setOpenPreview(true);
        } else {
            setShowModal(true);
        }
    };

    const reviewFile = (data) => {
        openPreviewFileData(data);
    };

    const download = (url, param = undefined) => {
        const fullRoute = config.api + url;
        axios({
            url: fullRoute,
            method: 'post',
            responseType: 'blob',
            withCredentials: true,
            data: param
        })
            .then((response, error) => {
                console.log('nnnnnnn');
                if (response.status === 201) {
                    const headerLine = response.headers['content-disposition'];
                    if (headerLine) {
                        const filename = headerLine.split('filename=')[1].split(';')[0];
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                    }
                } else {
                    DialogUtil.showQuestions(false, 'Tải tài liệu thất bại');
                }
            })
            .catch((error) => {
                DialogUtil.showQuestions(false, 'Tải tài liệu thất bại!');
            });
    };

    const onDownloadClick = async (data) => {
        const obj = {
            _id: data._id
        };
        // console.log('obj', obj);
        await download(router.SUB_URL.documentFamily_download, obj);
    };

    const onDownload = (data) => {
        onDownloadClick(data);
    };

    const onSelectPreview = (e) => {
        const name = e.target.name;
        switch (name) {
            case 'file':
                setShow(true);
                break;
            case 'link':
                window.open(dataWork?.link, '_blank');
                break;
            case 'diary':
                getDetailDiary();
                break;
            case 'document':
                getDetailDocument();
                break;
            default:
                break;
        }
    };

    // useEffect(() => {
    //     async function getRoleButtonScreen() {
    //         const rs = await getRoleButton();
    //         setRole(rs);
    //     }
    //     getRoleButtonScreen();
    // }, []);


    return (
        <>
            <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} fullWidth>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        {`Nội dung tác phẩm ${ConvertName(dataWork?.name_work, 40)}`}
                    </Typography>
                    <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                    <Box position="absolute" top={0} right={0}>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Stack direction="column" spacing={2} sx={{ fontSize: '20px', padding: '10px 10px' }}>
                        {dataWork?.file_upload?.length > 0 && (
                            <Button variant="contained" color="primary" name="file" onClick={onSelectPreview} fullWidth>
                                Từ file
                            </Button>
                        )}
                        {dataWork?.link && (
                            <Button variant="contained" color="primary" name="link" onClick={onSelectPreview} fullWidth>
                                Từ link
                            </Button>
                        )}
                        {dataWork?.diary?.id_diary && (
                            <Button variant="contained" color="primary" name="diary" onClick={onSelectPreview} fullWidth>
                                Từ phả ký
                            </Button>
                        )}
                        {dataWork?.document?.id_document && (
                            <Button variant="contained" color="primary" name="document" onClick={onSelectPreview} fullWidth>
                                Từ tài liệu
                            </Button>
                        )}

                        {!dataWork?.file_upload?.length > 0 &&
                            !dataWork?.link &&
                            !dataWork?.diary?.id_diary &&
                            !dataWork?.document?.id_document && (
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <NotFound text={'Tác phẩm chưa có dữ liệu để xem trước.'} />
                                </div>
                            )}
                    </Stack>
                </Box>
            </Modal>
            <ModalFileWork
                show={show}
                dataFile={dataWork?.file_upload?.length > 0 ? dataWork?.file_upload : []}
                handleClose={onCloseModalFile}
                idWork={dataWork?._id}
                nameWork={dataWork?.name_work}
                type={'preview'}
                roles={{...role, is_client: isFromHomePage === true}}
            />
            <ModalDetailDiary
                open={openDiary}
                onCloseModal={onCloseModalDiary}
                diary={diary}
                getEditorValue={getEditorValue}
                blogEditorRef={blogEditorRef}
                dataContents={dataContents}
            />
            <ModalDocumentFamily
                roles={{...role, is_client: isFromHomePage === true}}
                open={openDocument}
                closeModal={onCloseModalDocumentFamily}
                data={dataDocument}
                onReload={() => {}}
                typeMode="review"
                reviewLink={reviewLink}
                reviewFile={reviewFile}
                onDownload={onDownload}
            />
            <ModalPreviewDocument
                open={openPreview}
                closeModal={onCloseModalDocumentPreview}
                response={response}
                type=""
                title={dataWork?.document?.title}
            />
            <Modal open={showModal} onClose={() => setShowModal(false)} hideBackdrop>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { md: '40%', xs: '95vw' },
                        bgcolor: 'background.paper',
                        outline: 'none',
                        boxShadow: 24,
                        p: 2
                    }}
                >
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        Thông báo
                    </Typography>
                    <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                    <Box position="absolute" top={0} right={0}>
                        <IconButton onClick={() => setShow(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Grid spacing={3}>
                        <Typography>Định dạng tệp tin không hỗ trợ xem trước, bạn có thể tải để xem tệp tin </Typography>
                    </Grid>
                    <Grid spacing={3}>
                        <Box sx={{ mt: 2 }} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                            <Button
                                disableElevation
                                size="medium"
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={<DownloadIcon />}
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => onDownloadClick(dataDocument)}
                            >
                                Tải xuống
                            </Button>

                            <Button
                                disableElevation
                                size="medium"
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={<Close />}
                                style={{ marginRight: 5, borderBlockColor: 'black' }}
                                onClick={() => setShowModal(false)}
                            >
                                Đóng
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};

export default ModalPreview;
