import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import success from '../assets/images/icons/success.webp';
import wrong from '../assets/images/icons/wrong.webp';
import { Card, CardHeader, CardContent, Button, Avatar, Typography, CardActions } from '@mui/material';

function showQuestions(check = true, message = '', action = () => {}) {
    confirmAlert({
        closeOnClickOutside: false,
        customUI: ({ onClose }) => (
            <Card
                sx={{
                    p: 2,
                    width: 300,
                    borderRadius: 1,
                    boxShadow: 'none',
                    borderTop: check ? `4px solid green` : `4px solid red`,
                    borderLeft: `1px solid #787773`,
                    borderBottom: `1px solid #787773`,
                    borderRight: `1px solid #787773`
                }}
            >
                <div style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                    <Avatar src={check ? success : wrong} sx={{ width: 56, height: 56 }} />
                </div>

                <CardContent>
                    <div
                        style={{ alignItems: 'center', flex: 1, justifyContent: 'center', textAlign: 'center' }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    ></div>
                </CardContent>
                <CardActions style={{ alignItems: 'center', flex: 1, justifyContent: 'center' }}>
                    <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await onClose();
                            action();
                        }}
                        autoFocus
                    >
                        Đóng
                    </Button>
                </CardActions>
            </Card>
        )
    });
}

function showConfirm(
    title = 'Thông báo',
    message = '',
    secondAction = () => {},
    thirdAction = () => {},
    titleOne = 'Không',
    titleTwo = 'Có'
) {
    confirmAlert({
        closeOnClickOutside: false,
        customUI: ({ onClose }) => (
            <Card
                sx={{
                    p: 2,
                    maxWidth: 500,
                    borderRadius: 1,
                    boxShadow: 'none',
                    borderTop: `2px solid blue`,
                    borderLeft: `1px solid gray`,
                    borderBottom: `1px solid gray`,
                    borderRight: `1px solid gray`
                }}
            >
                <CardHeader title={title} />
                <CardContent>
                    <Typography variant="body2">{message}</Typography>
                </CardContent>
                <CardActions className="mt-1" style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await onClose();
                            thirdAction();
                        }}
                    >
                        {titleTwo}
                    </Button>
                    <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await onClose();
                            secondAction();
                        }}
                    >
                        {titleOne}
                    </Button>
                </CardActions>
            </Card>
        )
    });
}

function Confirm({
    title = 'Thông báo',
    message = '',
    titleOk = 'Có',
    titleCacel = 'Không',
    okAction = () => {},
    cancelAction = () => {}
}) {
    confirmAlert({
        closeOnClickOutside: false,
        customUI: ({ onClose }) => (
            <Card
                sx={{
                    p: 2,
                    maxWidth: 500,
                    borderRadius: 1,
                    boxShadow: 'none',
                    borderTop: `2px solid blue`,
                    borderLeft: `1px solid gray`,
                    borderBottom: `1px solid gray`,
                    borderRight: `1px solid gray`
                }}
            >
                <CardHeader title={title} />
                <CardContent>
                    <Typography variant="body2">{message}</Typography>
                </CardContent>
                <CardActions className="mt-1" style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await onClose();
                            okAction();
                        }}
                    >
                        {titleOk}
                    </Button>
                    <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await onClose();
                            cancelAction();
                        }}
                        autoFocus
                    >
                        {titleCacel}
                    </Button>
                </CardActions>
            </Card>
        )
    });
}

function showQuestionsTwoAction(
    role = 'ROOT',
    check = true,
    message = '',
    firstTitle = '',
    secondTitle = 'Đóng',
    firstAction = () => {},
    secondAction = () => {}
) {
    confirmAlert({
        closeOnClickOutside: false,
        customUI: ({ onClose }) => (
            <Card
                sx={{
                    p: 2,
                    width: 350,
                    borderRadius: 1,
                    boxShadow: 'none',
                    borderTop: check ? `4px solid green` : `4px solid red`,
                    borderLeft: `1px solid #787773`,
                    borderBottom: `1px solid #787773`,
                    borderRight: `1px solid #787773`
                }}
            >
                <div style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                    <Avatar src={check ? success : wrong} sx={{ width: 56, height: 56 }} />
                </div>

                <CardContent>
                    <div style={{ alignItems: 'center', flex: 1, justifyContent: 'center', textAlign: 'center' }}>
                        <div dangerouslySetInnerHTML={{ __html: message }}></div>
                    </div>
                </CardContent>
                <CardActions className="mt-1" style={{ flex: 1, justifyContent: role === 'ROOT' ? 'flex-end' : 'center' }}>
                    {role === 'ROOT' && (
                        <Button
                            size="small"
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                await onClose();
                                firstAction();
                            }}
                        >
                            {firstTitle}
                        </Button>
                    )}
                    <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            await onClose();
                            secondAction();
                        }}
                        autoFocus
                    >
                        {secondTitle}
                    </Button>
                </CardActions>
            </Card>
        )
    });
}

const DialogUtil = {
    showQuestions,
    showConfirm,
    Confirm,
    showQuestionsTwoAction
};

export default DialogUtil;
