import { useState, useEffect, useRef } from 'react';
import { Modal, Box, Typography, Divider, IconButton, Stack, useMediaQuery, Grid } from '@mui/material';
import axios from 'axios';

import { Close } from '@mui/icons-material';
import config from '../../config';
import router from '../../routes/router';
import { ShortCutName } from 'utils/helper';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '40%', xs: '95vw' },
    bgcolor: 'background.paper',
    height: { md: '350px', xs: 'auto' },
    boxShadow: 24,
    p: 2
};

const ModalPreviewDocument = ({ open, closeModal, response, type, title }) => {
    const [nameFile, setNameFIle] = useState('');
    const [checkData, setCheckData] = useState(false);
    const matchDownMd = useMediaQuery('(max-width:1440px)');

    const preview = (param = undefined) => {
        let fullRoute = config.api + router.SUB_URL.documentFamily_download;
        if (type === 'newfeed') {
            fullRoute = config.api + router.SUB_URL.downloadDocumentDomain;
            param.domain = window.location.origin;
        }
        axios({
            url: fullRoute,
            method: 'post',
            responseType: 'blob',
            withCredentials: true,
            data: param
        }).then((response, error) => {
            if (response.status === 201) {
                console.log('success');
                const { data, headers } = response;
                const objUrl = URL.createObjectURL(data);
                const headerLine = headers['content-disposition'];
                const fileName = headerLine.split('filename=')[1].split(';')[0];
                const mimeType = fileName.split('.').at(-1);
                console.log('mimeType ', mimeType);
                if (['jpe', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'png', 'ico'].includes(mimeType.toLowerCase())) {
                    setCheckData(true);
                    const imgTag = document.createElement('img');
                    imgTag.onload = function () {
                        const w = imgTag.width;
                        // console.log('w ', w);
                        const h = imgTag.height;
                        // console.log('height ', h);
                        if (h > 640) {
                            imgTag.setAttribute('style', 'object-fit:contain');
                            imgTag.setAttribute('height', `${matchDownMd ? '600px' : '650px'}`);
                        }
                    };

                    setNameFIle(fileName);
                    imgTag.setAttribute('src', objUrl);
                    document.getElementById('result').append(imgTag);
                } else if (['pdf', 'txt', 'svg', 'html', 'pptx'].includes(mimeType)) {
                    setCheckData(true);
                    const iFrameTag = document.createElement('iframe');
                    iFrameTag.setAttribute('type', headers['content-type']);
                    iFrameTag.setAttribute('src', `${objUrl}`);
                    iFrameTag.setAttribute('width', '100%');
                    iFrameTag.setAttribute('height', `${matchDownMd ? '600' : '680px'}`);

                    setNameFIle(fileName);
                    document.getElementById('result').append(iFrameTag);
                } else {
                    setCheckData(false);
                    setNameFIle(fileName);
                    document.getElementById('result_not').append('Định dạng file không hỗ trợ xem trước');
                    document.getElementById('result_not').style.fontSize = '25px';
                }
            } else {
                setCheckData(false);
                setNameFIle('Lỗi hiển thị');
                document.getElementById('result_not').append('Hiển thị xem trước file thất bại');
            }
        });
    };

    const previewDocument = async () => {
        try {
            const obj = {
                _id: response
            };
            // console.log('obj ', obj);
            await preview(obj);
        } catch (error) {
            console.log('error ', error);
        }
    };

    useEffect(() => {
        if (open === true) {
            previewDocument();
        }
    }, [open]);

    const onClose = () => {
        closeModal();
    };

    return (
        <>
            <Modal open={open} onClose={() => onClose()} hideBackdrop>
                {checkData ? (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { md: '50%', xs: '95vw' },
                            bgcolor: 'background.paper',
                            outline: 'none',
                            boxShadow: 24,
                            height: { md: `${matchDownMd ? '90%' : '80%'}`, xs: 'auto' },
                            p: 2
                        }}
                        fullWidth
                    >
                        <Typography
                            id="modal-modal-title"
                            variant="h3"
                            component="h2"
                            sx={{ maxWidth: { md: 'auto', xs: '80vw' }, wordBreak: 'break-word', marginRight: '15px' }}
                        >
                            {type === 'newfeed' ? ShortCutName(title, 80) ?? 'Tài liệu' : ShortCutName(nameFile, 80)}
                        </Typography>
                        <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                        <Box position="absolute" top={0} right={0}>
                            <IconButton onClick={() => onClose()}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Grid spacing={3}>
                            <Stack id="result" />
                        </Grid>
                    </Box>
                ) : (
                    <Box sx={styles}>
                        <Typography
                            id="modal-modal-title"
                            variant="h3"
                            component="h2"
                            sx={{ maxWidth: { md: 'auto', xs: '80vw' }, wordBreak: 'break-word' }}
                        >
                            {ShortCutName(nameFile, 80)}
                        </Typography>
                        <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                        <Box position="absolute" top={0} right={0}>
                            <IconButton onClick={() => onClose()}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Grid spacing={3} sx={{ height: '90%' }}>
                            <Stack
                                id="result_not"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            />
                        </Grid>
                    </Box>
                )}
            </Modal>
        </>
    );
};
export default ModalPreviewDocument;
