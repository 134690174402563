const PagesNewfeed = {
    home: 'trang-chu',
    introduction: 'gioi-thieu',
    contact: 'lien-he',
    document: 'tu-lieu-dong-ho',
    diary: 'pha-ky',
    album: 'album',
    statistical: 'thong-ke',
    ancestral_house: 'nha-tho',
    creator: 'tac-gia-tac-pham'

};
export default PagesNewfeed;
