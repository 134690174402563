// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconUser, IconLock, IconSmartHome } from '@tabler/icons';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import ConstructionIcon from '@mui/icons-material/Construction';
import ConfigRouter from 'routes/config_router';
import EventIcon from '@mui/icons-material/Event';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PortraitIcon from '@mui/icons-material/Portrait';

import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import UpdateIcon from '@mui/icons-material/Update';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

import BookIcon from '@mui/icons-material/Book';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';

import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import DnsIcon from '@mui/icons-material/Dns';
import ListIcon from '@mui/icons-material/List';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import TempleBuddhistIcon from '@mui/icons-material/TempleBuddhist';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconUser,
    IconLock,
    IconSmartHome,
    FamilyRestroomIcon,
    PersonIcon,
    LockIcon,
    PeopleIcon,
    ConstructionIcon,
    EventIcon,
    ReceiptLongIcon,
    PortraitIcon,
    DashboardIcon,
    AccessibilityIcon,
    AutoGraphIcon,
    UpdateIcon,
    MilitaryTechIcon,
    BookIcon,
    PhotoLibraryIcon,
    SnippetFolderIcon,
    SettingsSuggestIcon,
    DnsIcon,
    ListIcon,
    LocalLibraryIcon,
    TempleBuddhistIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const family = {
    id: 'family',
    title: 'Thông tin dòng họ',
    type: 'group',
    children: [
        {
            id: ConfigRouter.detailFamily,
            title: 'Thông tin dòng họ',
            type: 'collapse',
            // url: ConfigRouter.detailFamily,
            icon: icons.FamilyRestroomIcon,
            // breadcrumbs: false,
            spMobile: true,
            children: [
                {
                    id: ConfigRouter.detailFamily,
                    title: 'Thông tin chung',
                    type: 'item',
                    icon: icons.DashboardIcon,
                    url: ConfigRouter.detailFamily,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.listPatriarch,
                    title: 'Danh sách tộc trưởng',
                    type: 'item',
                    icon: icons.AccessibilityIcon,
                    url: ConfigRouter.listPatriarch,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.statisticalFamily,
                    title: 'Thống kê dòng họ',
                    type: 'item',
                    icon: icons.AutoGraphIcon,
                    url: ConfigRouter.statisticalFamily,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.historyFamily,
                    title: 'Lịch sử dòng họ',
                    type: 'item',
                    icon: icons.UpdateIcon,
                    url: ConfigRouter.historyFamily,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.graveFamily,
                    title: 'Thông tin mộ phần',
                    type: 'item',
                    icon: icons.MilitaryTechIcon,
                    url: ConfigRouter.graveFamily,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.ancestralHouseList,
                    title: 'Danh sách nhà thờ',
                    type: 'item',
                    icon: icons.TempleBuddhistIcon,
                    url: ConfigRouter.ancestralHouseList,
                    breadcrumbs: false,
                    spMobile: true
                },
            ]
        },
        {
            id: ConfigRouter.diaryFamily,
            title: 'Phả ký dòng họ',
            type: 'collapse',
            // url: ConfigRouter.diaryFamily,
            icon: icons.ReceiptLongIcon,
            // breadcrumbs: false,
            spMobile: true,
            children: [
                {
                    id: ConfigRouter.diaryFamily,
                    title: 'Bài viết phả ký',
                    type: 'item',
                    icon: icons.BookIcon,
                    url: ConfigRouter.diaryFamily,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.albumFamily,
                    title: 'Album dòng họ',
                    type: 'item',
                    icon: icons.PhotoLibraryIcon,
                    url: ConfigRouter.albumFamily,
                    breadcrumbs: false,
                    spMobile: true
                },
                {
                    id: ConfigRouter.documentFamily,
                    title: 'Tư liệu dòng họ',
                    type: 'item',
                    icon: icons.SnippetFolderIcon,
                    url: ConfigRouter.documentFamily,
                    breadcrumbs: false,
                    spMobile: true
                }
            ]
        },
        {
            id: ConfigRouter.listMember,
            title: 'Danh sách thành viên',
            type: 'item',
            url: ConfigRouter.listMember,
            icon: icons.PeopleIcon,
            breadcrumbs: false,
            spMobile: true
        },
        {
            id: ConfigRouter.event,
            title: 'Sự kiện dòng họ',
            type: 'item',
            url: ConfigRouter.event,
            icon: icons.EventIcon,
            breadcrumbs: false,
            spMobile: true
        },
        {
            id: ConfigRouter.listCreator,
            title: 'Tác giả / Tác phẩm',
            type: 'item',
            icon: icons.LocalLibraryIcon,
            url: ConfigRouter.listCreator,
            breadcrumbs: false,
            spMobile: true
        }
    ]
};

export default family;
