import {
    Box,
    CardMedia,
    Grid,
    IconButton,
    Paper,
    Stack,
    Tooltip,
    Typography,
    TablePagination,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
    CircularProgress,
    useMediaQuery,
    TableRow,
    TableCell,
    TableHead,
    Table,
    TableContainer,
    TableBody
} from '@mui/material';
import { useSelector } from 'react-redux';
import NotFound from 'ui-component/404/404';
import MainCard from 'ui-component/cards/MainCard';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConvertName, shortenName } from 'utils/helper';
import ImageDefault from '../../../../assets/images/users/background.jpg';
import BackgroundIMG from '../../../../assets/images/icons/bggiapha.jpg';
import { useEffect, useState } from 'react';
import ModalAddWork from '../workList/modal/ModalAddWork';
import { styled, useTheme } from '@mui/material/styles';
import ModalAddWorkByAuthor from '../authorList/modal/ModalAddWorkByAuthor';
import router from 'routes/router';
import fetchClient from 'views/utilities/RestfulAPI';
import DialogUtil from 'ui-component/show_confirm_action';
import ModalInfoWork from '../authorList/modal/ModalInfoWork';
import { getAliasScreen, getRoleButton } from 'utils/roleUility';
import { is } from 'date-fns/locale';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: '#0daeff'
            }
        }
    }
}));

const TYPE = {
    create: 'THEM_TP',
    update: 'CAP_NHAP_TP',
    view: 'XEM_THONG_TIN_TP',
    delete: 'XOA_TP'
};

const columns = [
    { id: 'stt', label: 'STT', minWidth: 50, align: 'center', rowAlign: 'center', xsDisplay: 'none', width: '50px' },
    {
        id: 'content',
        label: 'Tên tác phẩm',
        minWidth: { md: '200px', xs: '150px' },
        align: 'center',
        rowAlign: 'left',
        sx: { position: 'sticky', left: '0px', zIndex: '99', borderRight: { md: 'none', xs: '1px solid white' } }
    },
    { id: 'name_author', label: 'Vai trò', minWidth: { md: 200, xs: 150 }, align: 'center', rowAlign: 'left' },
    { id: 'birth', label: 'Thời điểm tham gia', minWidth: { md: 110, xs: 110 }, align: 'center', rowAlign: 'left' },
    { id: 'isPublic', label: 'Thể loại', minWidth: { md: 250, xs: 0 }, align: 'center', rowAlign: 'left', xsDisplay: 'none' },
    { id: 'create_by', label: 'Năm sáng tác', minWidth: { md: 250, xs: 150 }, align: 'center', rowAlign: 'left' },
    { id: 'update_by', label: 'Chủ đề', minWidth: { md: 250, xs: 120 }, align: 'center', rowAlign: 'left' }
    // { id: 'action', label: 'Thao tác', width: 120, align: 'center', rowAlign: 'left' }
];

const ListWorkCommon = ({ dataAuthor, dataInfoAuthor, onReload, type, roles }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);

    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [workSelect, setWorkSelect] = useState(null);
    const [idWork, setIdWork] = useState(null);
    // const [roles, setRole] = useState();
    const [typeModel, setTypeModel] = useState(null);
    const [open, setOpen] = useState(false);
    const openOption = Boolean(anchorEl);
    const [loading, setLoading] = useState(false);
    const [listWorkByAuthor, setListWorkByAuthor] = useState(dataInfoAuthor || []);

    const [dataGenre, setDataGenre] = useState(localStorage.getItem('m_genre') ? JSON.parse(localStorage.getItem('m_genre')) : null);

    const dataAthourRole = localStorage.getItem('m_athour_role') ? JSON.parse(localStorage.getItem('m_athour_role')) : [];

    const RIGHT_CLICK_OPTIONS = [
        {
            name: 'Chỉnh sửa tác phẩm',
            alias: TYPE.update,
            icon: <EditIcon fontSize="small" />,
            key: 'create',
            show: roles?.update
        },
        {
            name: 'Xóa tác phẩm',
            alias: TYPE.delete,
            icon: <DeleteIcon fontSize="small" />,
            key: 'delete',
            show: roles?.delete
        }
    ];

    const findName = (value) => {
        const rst = dataGenre
            ?.filter((r) => {
                return r?.config_value.genre_id === value;
            })
            .map((m) => {
                return m?.config_value.genre_name;
            });
        return rst;
    };

    const findNameRole = (value) => {
        const rst = dataAthourRole
            .filter((r) => {
                return r?.athour_id === value;
            })
            .map((m) => {
                return m?.athour_name;
            });

        return rst;
    };

    const handleCloseModalWork = () => {
        setOpen(false);
    };

    const handleClick = (event, item) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setWorkSelect(item);
        setIdWork(item?._id);
    };

    const handleDeleteWorkByAuthor = () => {
        DialogUtil.showConfirm(
            'Thông báo',
            `Bạn có muốn xóa tác phẩm ${workSelect?.name_work} không?`,
            () => {},
            () => {
                onDeleteWorkByAuthor();
            }
        );
    };

    const onDeleteWorkByAuthor = async () => {
        const body = {
            idWork: workSelect?._id,
            idAuthor: dataInfoAuthor?._id
        };
        let urlApi = router.SUB_URL.remove_work;
        const rst = await fetchClient.postRequest(urlApi, body);
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, 'Xóa tác phẩm thành công', () => {
                onReload();
            });
        } else {
            DialogUtil.showQuestions(false, 'Xóa tác phẩm thất bại');
        }
    };

    const handleClose = (event) => {
        event.preventDefault();
        setAnchorEl(null);
        setWorkSelect(null);
    };

    const onOpenModalWork = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setWorkSelect(null);
        setOpenModal(false);
    };

    const onClickMenuOption = (alias) => {
        setAnchorEl(null);
        switch (alias) {
            case 'CAP_NHAP_TP':
                setTypeModel(TYPE.update);
                setOpenModal(true);
                break;
            case 'XOA_TP':
                handleDeleteWorkByAuthor();
                break;
            default:
                break;
        }
    };

    // const findRole = (data) => {
    //     let nameRole = '';
    //     console.log('data', data);
    //     if (data?.role.length > 0) {
    //         let rst = '';
    //         data?.role?.map((item) => {
    //             if (item.id_author === dataInfoAuthor?._id) {
    //                 rst = item.role;
    //             }
    //         });
    //         if (rst) {
    //             dataAthourRole.map((element) => {
    //                 if (element.athour_id === rst) {
    //                     nameRole = element.athour_name;
    //                 }
    //             });
    //         }
    //     }
    //     return nameRole;
    // };

    // const findRole  = (data) => {
    //     if(data?.role?.length > 0){

    //     }
    // }

    const findTimeWrite = (data) => {
        let timeWrite = '';
        if (data.length > 0) {
            data.map((item) => {
                if (item.id_author === dataInfoAuthor?._id) {
                    timeWrite = item.time_write;
                }
            });
        }
        return timeWrite;
    };

    const getListWorkByAuthor = async () => {
        setLoading(true);
        let urlApi = router.SUB_URL.info_author;
        const obj = {
            id: dataAuthor
        };
        const dataRst = await fetchClient.postRequest(urlApi, obj);
        if (dataRst.status === 201) {
            setListWorkByAuthor(dataRst?.data[0]);
            setLoading(false);
        } else {
            setLoading(false);
            DialogUtil.showQuestions(false, 'Lấy dữ liệu tác phẩm thất bại');
        }
    };

    const onReloadListWork = () => {
        getListWorkByAuthor();
    };

    useEffect(() => {
        getListWorkByAuthor();
    }, []);

    // useEffect(() => {
    //     async function getRoleButtonScreen() {
    //         const rs = await getRoleButton();
    //         setRole(rs);
    //     }
    //     getRoleButtonScreen();
    // }, []);

    // console.log('listWorkByAuthor', listWorkByAuthor);
    return (
        <>
            <Grid item xs={12}>
                <FormGroup>
                    <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {roles?.create && (
                            <Box>
                                {type === 'REVIEW' ? null : (
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        style={{ marginRight: 5, marginBottom: 5, borderBlockColor: 'black' }}
                                        onClick={() => onOpenModalWork()}
                                    >
                                        Thêm mới
                                    </Button>
                                )}
                            </Box>
                        )}
                    </Grid>
                </FormGroup>
                <MainCard
                    contentSX={{ display: 'flex', flexDirection: 'column', paddingTop: '19px', paddingBottom: '18px !important' }}
                    sx={{ marginBottom: '10px' }}
                >
                    {loading ? (
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 'calc(100dvh - 300px)',
                                width: { xs: '100%', md: 'auto' }
                            }}
                            colSpan={12}
                        >
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <Paper
                            sx={{
                                width: '100%',
                                overflow: 'hidden',
                                borderRadius: 0,
                                position: 'relative',
                                background: 'none'
                            }}
                        >
                            {listWorkByAuthor?.list_work?.length > 0 ? (
                                <>
                                    {/* <Stack> */}
                                    <Grid
                                        container
                                        spacing={1}
                                        // ref={listRef}
                                        sx={{
                                            backgroundColor: 'none',
                                            paddingTop: '5px',
                                            overflowX: 'auto',
                                            marginTop: { xs: '0px', md: '0px' },
                                            maxHeight: {
                                                sm: 'calc(100dvh - 600px)',
                                                md: 'calc(100dvh - 600px)'
                                            },
                                            // width: '100%',
                                            marginLeft: 0,

                                            '&::-webkit-scrollbar': {
                                                width: '0.4em'
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: customization?.themeDongHo?.heading,
                                                outline: 'slategrey'
                                            }
                                        }}
                                    >
                                        {isMobile ? (
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{ marginTop: '10px', width: '100% !important', marginLeft: '0px' }}
                                            >
                                                <Paper id="list" sx={{ width: '100%', overflow: 'hidden', borderRadius: 0 }}>
                                                    <TableContainer
                                                        sx={{
                                                            '&::-webkit-scrollbar': {
                                                                width: { md: '0.4em', xs: '3px' },
                                                                height: { md: '5px', xs: '3px' }
                                                            },
                                                            '&::-webkit-scrollbar-track': {
                                                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                                            },
                                                            '&::-webkit-scrollbar-thumb': {
                                                                backgroundColor: customization?.themeDongHo?.heading,
                                                                outline: 'slategrey'
                                                            },
                                                            maxHeight: isMobile ? 'calc(100vh - 240px)' : 'calc(100vh - 290px)'
                                                        }}
                                                    >
                                                        <Table
                                                            sx={{
                                                                borderRadius: 0
                                                            }}
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    {columns.map(
                                                                        (column) =>
                                                                            ((isMobile && column.xsDisplay !== 'none') || !isMobile) && (
                                                                                <TableCell
                                                                                    variant="head"
                                                                                    key={column.id}
                                                                                    align={column.align}
                                                                                    sx={{
                                                                                        minWidth: column.minWidth,
                                                                                        backgroundColor: 'rgb(144, 202, 249)',
                                                                                        color: 'black',
                                                                                        width: column?.width,
                                                                                        ...column.sx
                                                                                    }}
                                                                                >
                                                                                    {column.label}
                                                                                </TableCell>
                                                                            )
                                                                    )}
                                                                </TableRow>
                                                            </TableHead>
                                                            {listWorkByAuthor?.list_work?.length > 0 ? (
                                                                <>
                                                                    {/* <Stack> */}
                                                                    <TableBody>
                                                                        {listWorkByAuthor?.list_work.map((item) => (
                                                                            <TableRow
                                                                                key={item.id_work}
                                                                                sx={{
                                                                                    // backgroundColor: idChoose === row.ref_member ? '#b3c7e8' : '#f0f0f0',
                                                                                    cursor: 'pointer',
                                                                                    '&&.Mui-selected': {
                                                                                        backgroundColor: '#b3c7e8'
                                                                                    },
                                                                                    '&.MuiTableRow-hover:hover': {
                                                                                        backgroundColor: !isMobile && 'rgba(0, 0, 255, 0.3)'
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <TableCell
                                                                                    sx={{
                                                                                        wordBreak: 'break-all',
                                                                                        verticalAlign: 'top',
                                                                                        height: 'auto !important',
                                                                                        position: { md: 'relative', xs: 'sticky' },
                                                                                        left: '0px',
                                                                                        zIndex: '9',
                                                                                        backgroundColor: 'white'
                                                                                    }}
                                                                                    align="left"
                                                                                >
                                                                                    {item?.name_work}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    style={{
                                                                                        height: 'auto !important',
                                                                                        wordBreak: 'break-all',
                                                                                        verticalAlign: 'top'
                                                                                    }}
                                                                                    align="left"
                                                                                >
                                                                                    {item?.authors?.filter(
                                                                                        (author) => author.id_author === dataAuthor
                                                                                    ).length > 0 &&
                                                                                        item?.authors
                                                                                            ?.filter(
                                                                                                (author) => author.id_author === dataAuthor
                                                                                            )
                                                                                            .map((author) =>
                                                                                                Array.isArray(author.role) ? (
                                                                                                    author?.role?.map((role) => (
                                                                                                        <Typography
                                                                                                            variant="span"
                                                                                                            sx={{ display: 'block' }}
                                                                                                        >
                                                                                                            {findNameRole(role ?? [])}
                                                                                                        </Typography>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <Typography variant="span">
                                                                                                        {findNameRole(author?.role)}
                                                                                                    </Typography>
                                                                                                )
                                                                                            )}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    style={{
                                                                                        height: 'auto !important',
                                                                                        wordBreak: 'break-all',
                                                                                        verticalAlign: 'top'
                                                                                    }}
                                                                                    align="left"
                                                                                >
                                                                                    {/* {dateTimeAuthor(row.birth_day_solar, row.dead_day_solar)} */}
                                                                                    <Typography variant="h5">
                                                                                        {findTimeWrite(item?.authors ?? [])}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                {/* <TableCell
                                                                                    style={{
                                                                                        height: 'auto !important',
                                                                                        wordBreak: 'break-all',
                                                                                        verticalAlign: 'top'
                                                                                    }}
                                                                                    align="left"
                                                                                >
                                                                                    <Typography variant="h5">
                                                                                        {item?.type.flatMap((m) => findName(m)).join(', ')}
                                                                                    </Typography>
                                                                                </TableCell> */}

                                                                                <TableCell align="left">
                                                                                    <Typography variant="h5">
                                                                                        {' '}
                                                                                        {item?.publishing_year}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    style={{
                                                                                        height: 'auto !important',
                                                                                        verticalAlign: 'top'
                                                                                    }}
                                                                                    align="left"
                                                                                >
                                                                                    <Typography variant="h5"> {item?.topic}</Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Tooltip title="Tùy chọn">
                                                                                        <IconButton
                                                                                            id="demo-customized-button"
                                                                                            aria-controls={
                                                                                                openOption
                                                                                                    ? 'demo-customized-menu'
                                                                                                    : undefined
                                                                                            }
                                                                                            aria-haspopup="true"
                                                                                            aria-expanded={openOption ? 'true' : undefined}
                                                                                            variant="contained"
                                                                                            disableElevation
                                                                                            onClick={(event) => handleClick(event, item)}
                                                                                        >
                                                                                            <MoreVertIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </>
                                                            ) : (
                                                                <TableRow>
                                                                    <TableCell
                                                                        sx={{ display: 'table-cell', textAlign: 'center' }}
                                                                        colSpan={12}
                                                                    >
                                                                        <NotFound />
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </Grid>
                                        ) : (
                                            <>
                                                {' '}
                                                <Grid
                                                    container
                                                    sx={{
                                                        // width: 'max-content',
                                                        margin: '0px 0px 15px 0px',
                                                        borderBottom: '1px solid #aaa',

                                                        position: 'sticky',
                                                        top: '-5px',
                                                        backgroundImage: `url(${BackgroundIMG})`,
                                                        zIndex: 0,
                                                        paddingBottom: '5px'
                                                    }}
                                                    // spacing={2}
                                                >
                                                    <Grid item sx={{ display: { md: 'block' } }} md={2} xs={'auto'}>
                                                        <Typography textAlign="center" variant="h4">
                                                            Tên tác phẩm
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={'auto'}>
                                                        <Typography textAlign="center" variant="h4">
                                                            Vai trò
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={'auto'}>
                                                        <Typography textAlign="center" variant="h4">
                                                            Thời điểm tham gia
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={'auto'}>
                                                        <Typography sx={{ textAlign: { xs: 'right', md: 'center' } }} variant="h4">
                                                            Thể loại
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={2} xs={'auto'}>
                                                        <Typography sx={{ textAlign: { xs: 'right', md: 'center' } }} variant="h4">
                                                            Năm sáng tác
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item md={1} xs={'auto'}>
                                                        <Typography sx={{ textAlign: { xs: 'right', md: 'center' } }} variant="h4">
                                                            Chủ đề
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={1} xs={'auto'}></Grid>
                                                </Grid>
                                                {listWorkByAuthor?.list_work.map((item) => (
                                                    <Grid
                                                        sx={{
                                                            padding: '5px',
                                                            borderBottom: '1px solid #aaa',
                                                            alignItems: 'right',
                                                            cursor: 'pointer'
                                                        }}
                                                        container
                                                    >
                                                        <Grid
                                                            item
                                                            sx={{ display: { xs: 'block', md: 'block' }, textAlign: 'left' }}
                                                            md={2}
                                                            xs="auto"
                                                        >
                                                            <Typography variant="h5" sx={{ wordBreak: 'break-word' }}>
                                                                {item?.name_work}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{
                                                                display: { xs: 'block', md: 'block' },
                                                                textAlign: 'left'
                                                            }}
                                                            md={2}
                                                            xs="auto"
                                                        >
                                                            {/* {item?.authors.length > 0
                                                            ? item?.authors?.map((author) => (
                                                                  <Typography variant="h5">{findRole(author ?? [])}</Typography>
                                                              ))
                                                            : 'Chưa rõ'} */}
                                                            {/* {item?.authors?.length > 0 &&
                                                                item?.authors?.map((author) =>
                                                                    Array.isArray(author.role) ? (
                                                                        author.role?.map((role) => (
                                                                            <Typography variant="h5">{findNameRole(role ?? [])}</Typography>
                                                                        ))
                                                                    ) : (
                                                                        <Typography variant="h5">{findNameRole(author?.role)}</Typography>
                                                                    )
                                                                )} */}
                                                            {item?.authors?.filter((author) => author.id_author === dataAuthor).length >
                                                                0 &&
                                                                item?.authors
                                                                    ?.filter((author) => author.id_author === dataAuthor)
                                                                    .map((author) =>
                                                                        Array.isArray(author.role) ? (
                                                                            author?.role?.map((role) => (
                                                                                <Typography variant="span" sx={{ display: 'block' }}>
                                                                                    {findNameRole(role ?? [])}
                                                                                </Typography>
                                                                            ))
                                                                        ) : (
                                                                            <Typography variant="span">
                                                                                {findNameRole(author?.role)}
                                                                            </Typography>
                                                                        )
                                                                    )}
                                                            {/* <Typography variant="h5">{findRole(item?.authors ?? [])}</Typography> */}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{ display: { xs: 'block', md: 'block' }, textAlign: 'left' }}
                                                            md={2}
                                                            xs="auto"
                                                        >
                                                            <Typography variant="h5">{findTimeWrite(item?.authors ?? [])}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{ textAlign: 'left' }}
                                                            md={2}
                                                            xs="auto"
                                                            // xs={2.5}
                                                        >
                                                            <Typography variant="h5">
                                                                {item?.type.flatMap((m) => findName(m)).join(', ')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{ textAlign: 'center' }}
                                                            md={2}
                                                            xs="auto"
                                                            // xs={2.5}
                                                        >
                                                            <Typography variant="h5"> {item?.publishing_year}</Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sx={{ textAlign: 'left' }}
                                                            md={1}
                                                            xs="auto"
                                                            // xs={2.5}
                                                        >
                                                            <Typography variant="h5"> {item?.topic}</Typography>
                                                        </Grid>
                                                        <Grid item sx={{ textAlign: 'right' }} xs={'auto'} md={1}>
                                                            <Tooltip title="Tùy chọn">
                                                                <IconButton
                                                                    id="demo-customized-button"
                                                                    aria-controls={openOption ? 'demo-customized-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={openOption ? 'true' : undefined}
                                                                    variant="contained"
                                                                    disableElevation
                                                                    onClick={(event) => handleClick(event, item)}
                                                                >
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                    </Grid>
                                </>
                            ) : (
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 'calc(100dvh - 300px)',
                                        width: { xs: '100%', md: 'auto' }
                                    }}
                                    colSpan={12}
                                >
                                    <NotFound />
                                </Stack>
                            )}

                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'demo-customized-button'
                                }}
                                anchorEl={anchorEl}
                                open={openOption}
                                onClose={handleClose}
                                // onClick={handleClose}
                            >
                                {RIGHT_CLICK_OPTIONS.map((option, index) => {
                                    if (option.show === true)
                                        return (
                                            <MenuItem key={index.toString()} onClick={() => onClickMenuOption(option.alias)}>
                                                <ListItemIcon>{option.icon}</ListItemIcon>
                                                {option.name}
                                            </MenuItem>
                                        );
                                    return null;
                                })}
                            </StyledMenu>
                        </Paper>
                    )}
                </MainCard>
            </Grid>
            <ModalAddWorkByAuthor
                open={openModal}
                handleClose={handleCloseModal}
                typeModal={null}
                dataAuthor={dataInfoAuthor}
                onReload={onReloadListWork}
                idAuthor={dataInfoAuthor?._id ?? ''}
                dataInfoWork={workSelect}
            />
            <ModalInfoWork
                open={open}
                handleClose={handleCloseModalWork}
                typeModal={typeModel}
                dataWork={workSelect}
                onReload={onReloadListWork}
            />
        </>
    );
};

export default ListWorkCommon;
