/* eslint-disable import/no-anonymous-default-export */
export default {
    SUB_URL: {
        loginAPI: 'auth/login',
        logOutAPI: 'auth/logout',
        infoUser: 'auth/info_user',
        updateUserAPI: 'auth/update_user',
        avatarUserApi: 'auth/avatar_profile/',
        changePassWordAPI: 'auth/change_password',
        uploadAvatarAPi: 'auth/uploads',
        showImageApi: 'auth/showImage',
        getPackage: 'auth/getPackageByFamily',
        infoFamilyAPI: 'family/info_family',
        updateFamilyAPI: 'family/update_family',
        forgotPasswordRequest: 'auth/forgotPassword',
        forgotPasswordUpdate: 'auth/forgotPasswordUpdate',
        getPostPublic: 'auth/diary',
        nationalAPI: 'common/m_nationality',
        religionAPI: 'common/m_religion',
        createMemberAPI: 'member/create_member',
        updateMemberAPI: 'member/update_member',
        getConfigAPI: 'configuration/get_config',
        getHusWifeAPI: 'member/info_relationship/',
        exportExcelHusWifeAPI: 'member/export_excel_member/',
        member_getInfoMember: 'member/info_member',
        member_getData: 'member/getData',
        member_downloadWord: 'member/download_word',
        member_downloadExcel: 'member/download_excel',
        member_deleteOne: 'member/deleteOne',
        member_cancelRelation: 'member/cancel_relationship',
        member_changeRelation: 'member/change_relationship',
        member_sortMember: 'member/sort_member',
        member_sortChild: 'member/sort_children',
        memeber_addChild: 'member/add_children',
        memeber_listChild: 'member/list_child/',
        member_updateRoot: 'member/update_root',
        member_updateHead: 'member/update_head',
        member_updateLevel: 'member/update_level',
        member_getMember: 'member/get_member',
        member_getMainMember: 'member/getMainMember',
        member_getListNotRelation: 'member/list_not_relation',
        member_addFromList: 'member/add_fromList',
        member_listPatriarchal: 'member/list_patriarch',
        member_caneclChild: 'member/canecl_child',
        member_getListChildByParents: 'member/getChildByParents',
        member_addChildList: 'member/add_child_list',
        member_statisticMember: 'member/statistic_member',
        member_statisticJob: 'member/stacticJob',
        member_statisticEdu: 'member/stacticEdu',
        member_statisticTitle: 'member/stacticTitle',
        member_liststatisticJob: 'member/list_member_job_statistic',
        member_liststatisticEdu: 'member/list_member_edu_statistic',
        member_liststatisticTitle: 'member/list_member_title_statistic',
        member_downloadExcelSpecial: 'member/download_special_excel',
        member_downloadExcelAdvanced: 'member/download_advanced_excel',
        member_statisticBirthDay: 'member/listMemberBirthday',
        member_excelListmember: 'member/excel_listmember',
        member_listadopted: 'member/list_add_adopted',
        member_addAdopted: 'member/add_child_adopted',
        member: 'member/list_notpatriarch',
        member_listGrave: 'member/list_grave',
        member_relation: 'member/relation_member',
        member_execlRelation: 'member/excel_relation_member/',
        member_deleteCareer: 'member/delete_career',
        member_deleteEducation: 'member/delete_education',
        member_deleteEvent: 'member/delete_event',
        member_getBirthday: 'member/static_birthday',
        member_getAnniversary: 'member/static_anniversary',
        member_updateHeadNow: 'member/update_head_now',
        member_exportChild: 'member/export_child',
        member_exportSibling: 'member/export_sibling',
        member_getAllMember: 'member/all_member',
        member_getListAddChild: 'member/list_add_child',
        member_ancestor: 'member/find_ancestor',
        member_current: 'member/current_member',
        member_listAuthorMember: 'member/list_member_author',
        historyFamily_create: 'history_family/create',
        historyFamily_get_history_family: 'history_family/get_allhistory',
        historyFamily_delete: 'history_family/delete',
        historyFamily_update: 'history_family/update',
        historyFamily_changesort: 'history_family/changesort',
        postFamily_get_list_post: 'post_family/getListPost',
        postFamily_change_featured: 'post_family/changeFeaturedPost',
        postFamily_change_public: 'post_family/changePublicPost',
        postFamily_change_favorite: 'post_family/changeFavoritePost',
        postFamily_change_completed: 'post_family/changeCompletedPost',
        postFamily_count: 'post_family/getCountPost',
        postFamily_delete: 'post_family/delete',
        postFamily_upload: 'post_family/uploadFileDiary',
        getListRelatedPost: 'post_family/getListRelatedPost',
        albumFamily_create: 'album_family/create_album',
        albumFamily_list: 'album_family/list_album',
        albumFamily_detail: 'album_family/detail_album',
        albumFamily_updateAlbum: 'album_family/update_album',
        albumFamily_addImage: 'album_family/add_images',
        albumFamily_ListImage: 'album_family/listImage_album',
        albumFamily_updateImage: 'album_family/update_image',
        albumFamily_deletImage: 'album_family/delete_image',
        albumFamily_deletAlbum: 'album_family/delete_album',
        albumFamily_SetAvarta: 'album_family/set_avarta',
        albumFamily_DownloadImage: 'album_family/download_image',
        albumFamily_changePublicImages: 'album_family/changePublic_images',
        documentFamily_list: 'document/list_document',
        documentFamily_create: 'document/create_document',
        documentFamily_update: 'document/update_document',
        documentFamily_delete: 'document/delete_document',
        documentFamily_download: 'document/download_document',
        documentFamily_preview: 'document/preview_document',
        documentFamily_change_status: 'document/change_status_document',
        documentFamily_detail: 'document/detail_document',
        graveMember_createGrave: 'grave/create_grave',
        graveMember_infoGrave: 'grave/info_grave',
        graveMember_deleteImage: 'grave/delete_image',
        graveMember_updateGrave: 'grave/update_grave',
        graveMember_deleteGrave: 'grave/delete_grave',
        event_getData: 'event/list_event',
        event_downloadExcel: 'event/excel_event',
        event_create: 'event/ceate_event',
        event_update: 'event/update_event',
        event_delete: 'event/delete_event',
        event_getDataMonth: 'event/static_month',
        event_getDataYear: 'event/list_event_year',
        event_getDetail: 'event/get_event',
        eventYear_getEvent: 'event_year/get_event',
        total_disk: 'auth/user_diskspace',
        diary_create: 'post_family/create',
        list_allPost: 'post_family/getListPostAll',
        diary_detail: 'post_family/detail_post/',
        diary_update: 'post_family/update_diary',
        diary_updateContent: 'post_family/update_content',
        add_account: 'auth/add_acount',
        delete_account: 'auth/delete_acount',
        detail_account: 'auth/detail_account',
        update_account: 'auth/update_account',
        change_account: 'auth/change_type_account',
        reset_account: 'auth/reset_password_account',
        getPermission: 'role_permission/getPermisionByUser',
        getPermisionByScreen: 'role_permission/getPermisionByScreen',
        infoServices: 'auth/infor_service',
        staticAccount: 'auth/static_account',
        listPackage: 'auth/list_package',
        publickManage: 'auth/update_package',
        listBillPackage: 'auth/public_listOder',
        listPackageManage: 'auth/public_package',
        packageFamily: 'auth/getPackageByFamily',
        getPackageByDomain: 'auth/getPackageByDomain',
        downloadDocumentDomain: 'auth/download_document',
        getAllSettingHomepage: 'setting_homepage/getAll_setting',
        getLayoutSettingHomepage: 'setting_homepage/get_layout',
        updateLayoutSettingHomepage: 'setting_homepage/update_layout',
        updateSettingHomepage: 'setting_homepage/update_setting',
        insertBanner: 'setting_homepage/insert_banner',
        deleteBanner: 'setting_homepage/delete_banner',
        changeStatusBanner: 'setting_homepage/change_status_banner',
        changeStatusSetting: 'setting_homepage/change_status_setting',
        getNumHomepapge: 'homepage/get_num_data_homepapge',
        getAlbumHomePage: 'homepage/get_albums',
        getListPostHomePage: 'homepage/get_list_post',
        getDocumentHomePage: 'homepage/get_documents',
        getSettingStatus: 'setting_homepage/getSettingStatus',
        getSettingHomePage: 'homepage/get_setting_homepage',
        getInforFamilyHomePage: 'homepage/get_infoFamily_domain',
        insertCommentHomePage: 'homepage/insert_comment',
        staticMemberHomePage: 'homepage/static_member',
        getDataChartHomePage: 'homepage/get_data_chart',
        getBirthDeadMemberHomePage: 'homepage/get_birth_dead_member',
        getFontFamily: 'homepage/get_font',
        getAncestralHouse: 'homepage/get_ancestral_house',
        detailAncestralHouse: 'homepage/detail_ancestral_house',
        detailDiarylHouse: 'homepage/detail_diary',
        getCreatorWork: 'homepage/get_list_creator_work',
        getCreatorAuthor: 'homepage/get_list_creator_author',
        statics_member: 'member/statitic_members',
        member_word: 'member/word_member',
        get_version: 'auth/get_version',
        member_branch: 'member/family_branch',
        number_memner_branch: 'member/members_branch',
        number_member_level: 'member/member_level',
        check_sortkey: 'member/check_sortkey',
        advance_search: 'member/advance_search',
        advance_search_statistics: 'member/advance_search_statistics',
        word_level: 'member/word_level',
        remove_notify: 'setting_homepage/remove_notify',
        add_notify: 'setting_homepage/add_notify',
        move_notify: 'setting_homepage/move_notify',
        move_comment: 'setting_homepage/move_comment',
        create_author: 'creator/create_author',
        create_work: 'creator/create_work',
        list_authors: 'creator/list_authors',
        list_works: 'creator/list_works',
        info_author: 'creator/info_author',
        info_work: 'creator/info_work',
        delete_author: 'creator/delete_author',
        public_author: 'creator/public_author',
        public_work: 'creator/public_work',
        update_author: 'creator/update_author',
        update_work: 'creator/update_work',
        delete_work: 'creator/delete_work',
        remove_work: 'creator/remove_work',
        preview_work: 'creator/preview_work',
        download_work: 'creator/download_work',
        delete_file: 'creator/delete_file',
        diary_work: 'post_family/diary/',
        static_creator: 'creator/static_creator',
        excel_creator: 'creator/excel_creator',
        list_diary: 'diary/list_diary',
        excel_system: 'diary/excel_system_log',
        time_diary: 'diary/statistics_time',
        AncestralHouseCreate: 'ancestral_house/create',
        AncestralHouseUpdate: 'ancestral_house/update',
        AncestralHouseDelete: 'ancestral_house/delete',
        AncestralHouseList: 'ancestral_house/list',
        AncestralHouseDetail: 'ancestral_house/detail',
        AncestralHouseDeleteImage: 'ancestral_house/delete_image',
        AncestralHouseExcel: 'ancestral_house/excel',
        AncestralHouseHistory: 'ancestral_house/create_history',
        AncestralHouseUpload: 'ancestral_house/uploadImage',
        create_topic: 'topic_family/create_topic',
        update_topic: 'topic_family/update_topic',
        delete_topic: 'topic_family/delete_topic',
        list_topic: 'topic_family/get_list_topic',
        all_topic: 'topic_family/get_all_topic',
        check_topic_exist: 'topic_family/check_name_exist'
    },
    CONFIG_URL: {
        getAllConfigKey: 'configuration/configuration-key',
        getAllDataByConfigKey: 'configuration/getdata-bykey',
        getDataByConfigTitle: 'configuration/getdata-byTitle',
        getDataByConfigLogo: 'configuration/getdata-byLogo',
        getDataConfigByKey: 'configuration/getchild-data-bykey',
        insertDataConfig: 'configuration/insert-data',
        updateDataConfig: 'configuration/update-data',
        deleteDataConfig: 'configuration/delete-data',
        national: 'configuration/getDataNational',
        religion: 'configuration/getDataReligion',
        ethnic: 'configuration/getDataEthnic',
        updateDataTitle: 'configuration/update-dataValue',
        uploadLogo: 'configuration/upload-logo',
        deleteLogo: 'configuration/delete_logo',
        getLogoByDomain: 'auth/get_logo_domain',
        getDataConfigByFont: 'configuration/getdata-byFont'
    }
};
