// project imports
import config from 'config';

// action - state management
import * as actionTypes from '../action/actions';

export const initialState = (localStorage.getItem('theme') &&
    localStorage.getItem('theme') !== '' &&
    JSON.parse(localStorage.getItem('theme'))) || {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    themeDongHo: config.themeDongHo,
    opened: false,
    isTexting: false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.SET_THEME_DONGHO:
            return {
                ...state,
                themeDongHo: action.themeDongHo
            };
        case actionTypes.SET_IS_TEXTING:
            return {
                ...state,
                isTexting: action.isTexting
            };
        default:
            return state;
    }
};

export default customizationReducer;
