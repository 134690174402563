/* eslint-disable react-hooks/rules-of-hooks */
import { Navigate, useNavigate, Routes, Route } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import ForgotPasswordRoutes from './ForgotPasswordRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import SplashRoutes from './SplashRoutes';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useEffect, useState, lazy, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DATA_USER, DATA_FAMILY } from 'store/action/actions';
import fetchClient from '../views/utilities/RestfulAPI';
import router from './router';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// project imports
import Loadable from 'ui-component/Loadable';

import { AnonymousRoute } from './AnonymousRoute';
import MainLayout from 'layout/MainLayout';
import ConfigRouter from './config_router';
import PrivateRoute from './PrivateRoute';
import MinimalLayout from 'layout/MinimalLayout';
import NewfeedLayout from 'layout/NewfeedLayout';
import NewVersionModal from 'ui-component/new_version_modal';
import Creator from 'views/pages/creator/Creator';
import SystemLog from 'views/pages/system_log/SystemLog';
import OtherProduct from 'views/pages/other_product/OtherProduct';
import AncestralHouse from 'views/pages/ancestral_house/AncestralHouse';
// import TableFamily from 'ui-component/family/TableFamily';
// import MainCard from 'ui-component/cards/MainCard';
// import HistoryFamily from 'ui-component/family/HistoryFamily';
// import GraveFamily from 'ui-component/family/GraveFamily';
// import StatisticalFamily from 'ui-component/family/StatisticalFamily';
// import DocumentFamily from 'ui-component/family/DocumentFamily';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const Splash = Loadable(lazy(() => import('views/pages/authentication/Splash')));

// MainRoutes
const HomePage = Loadable(lazy(() => import('views/pages/homepage/HomePage')));
const DetailUser = Loadable(lazy(() => import('views/pages/user/detail_user')));
const DetailFamily = Loadable(lazy(() => import('views/pages/family/detail_family')));
const MConfiguration = Loadable(lazy(() => import('views/pages/m_configuration/m_configuration')));
const DetailMember = Loadable(lazy(() => import('views/pages/member/Detail_Member')));
const ListMember = Loadable(lazy(() => import('views/pages/member/List_Member')));
const BuildGenealogy = Loadable(lazy(() => import('views/pages/build_genealogy/BuildGenealogy')));
const TreeViewer = Loadable(lazy(() => import('views/pages/tree/viewer')));
const EventManager = Loadable(lazy(() => import('views/pages/event/EventManager')));
const DiaryFamily = Loadable(lazy(() => import('views/pages/diary/DiaryFamily')));
const AlbumFamily = Loadable(lazy(() => import('ui-component/family/AlbumFamily')));
const DiaryFamilyPublic = Loadable(lazy(() => import('views/pages/diary/diaryPublic/DiaryFamilyPublic')));
const LisAccount = Loadable(lazy(() => import('views/pages/list_account/List_Account')));
const ListService = Loadable(lazy(() => import('views/pages/list_service/List_Service')));

const DocumentFamily = Loadable(lazy(() => import('ui-component/family/DocumentFamily')));
const StatisticalFamily = Loadable(lazy(() => import('ui-component/family/StatisticalFamily')));
const GraveFamily = Loadable(lazy(() => import('ui-component/family/GraveFamily')));
const HistoryFamily = Loadable(lazy(() => import('ui-component/family/HistoryFamily')));
const MainCard = Loadable(lazy(() => import('ui-component/cards/MainCard')));
const TableFamily = Loadable(lazy(() => import('ui-component/family/TableFamily')));
const SettingNewfeed = Loadable(lazy(() => import('views/pages/setting_newfeed/SettingNewfeed')));

const NewFeedMap = Loadable(lazy(() => import('views/pages/newfeed/NewFeedMap')));
const AuthorList = Loadable(lazy(() => import('views/pages/creator/authorList/AuthorList')));
const NewFeed = Loadable(lazy(() => import('views/pages/newfeed/NewFeed')));
const NewFeedV2 = Loadable(lazy(() => import('views/pages/newfeed/NewFeedV2')));

// ForgotPasswordRoutes
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/AuthForgotPassword')));
const AuthForgotPasswordFrom = Loadable(lazy(() => import('views/pages/authentication/authentication3/AuthForgotPasswordFrom')));

const styles = {
    snackbarStyleViaContentProps: {
        backgroundColor: 'orange'
    }
};

// ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [diaryPublicUrl, setDiaryPublicUrl] = useState(false);
    // const [title, setTitle] = useState(localStorage.getItem('m_family_title'));
    const currentPath = window.location.pathname;
    const [newVersion, setNewVersion] = useState();
    const customization = useSelector((state) => state.customization);
    const titleDefault = `Gia Phả Số`;
    const helmetContext = {};
    const title = localStorage.getItem('m_family_title')?.trim() || titleDefault;
    const onCheckToken = async () => {
        if (window.location.pathname === ConfigRouter.treeviewerShare) {
            return;
        }
        const dataUser = await localStorage.getItem('DATA_USER');
        if (dataUser) {
            getDataUser();
            // getDataUser();
            const dataFamily = await fetchClient.sendRequest(router.SUB_URL.infoFamilyAPI, 'post');
            dispatch({ type: DATA_FAMILY, dataFamily: dataFamily?.data });
        }
    };
    const [openNewVersionModal, setNewVersionModal] = useState(false);
    const [dataUser, setDataUser] = useState({});
    const getDataUser = async () => {
        const data = await fetchClient.postRequest(router.SUB_URL.infoUser);
        if (data?.data && data.status === 201) {
            setDataUser(data.data);
            localStorage.setItem(dataUser, JSON.stringify(data.data));
            dispatch({ type: DATA_USER, dataUser: data.data });
        }
    };
    const checkVersion = async () => {
        const version = localStorage.getItem('VERSION') ? localStorage.getItem('VERSION') : null;
        const newVersionData = await fetchClient.getRequest(router.SUB_URL.get_version);
        if (newVersionData?.data) {
            const versionNew = newVersionData?.data?.data

            if (versionNew) {
                setNewVersion(versionNew);

                if (version != null) {
                    if (version != null && version?.toString() !== versionNew.toString()) {
                        setNewVersion(versionNew.toString());
                        setNewVersionModal(true);
                    }
                } else {
                    localStorage.setItem('VERSION', versionNew.toString());
                }
            }
        }
    };
    useEffect(() => {
        checkVersion();
    }, [currentPath]);

    useEffect(() => {
        onCheckToken();
        const regex = /\/diary\/[0-9a-z]+\/[a-z0-9](_?[a-z0-9])*/gm;
        const isdiaryPublicUrl = regex.test(window.location.pathname);
        setDiaryPublicUrl(isdiaryPublicUrl);
    }, []);

    const handleClose = () => {
        console.log('1');
    };

    const onCloseModal = () => {
        localStorage.setItem('VERSION', newVersion);
        window.location.reload(true);
        setNewVersionModal(false);
    };

    const action = (
        <Fragment>
            <Button type="submit" variant="contained" color="primary" size="small" onClick={onCloseModal}>
                Cập nhật
            </Button>
        </Fragment>
    );

    // if (openNewVersionModal) {
    //     return (
    //         <>

    //         </>
    //       );
    //     // return
    //     //     {
    //     //         <Snackbar
    //     //     open={open}
    //     //     autoHideDuration={6000}
    //     //     onClose={handleClose}
    //     //     message="Note archived"
    //     //     action={action}
    //     // />
    //     //     }
    //     // return <NewVersionModal openModal={openNewVersionModal} setOpenModal={setNewVersionModal} newVersion={newVersion} />;
    // }
    return (
        <>
            <HelmetProvider context={helmetContext}>
                {!diaryPublicUrl && <Helmet data-rh="true" title={title} meta={[{ name: 'title', content: title }]} />}
                <Routes>
                    {/* <Route path="/logout" element={<AuthLogin3 />} /> */}
                    {/* <Route path="/" element={<Splash />} /> */}

                    <Route element={<NewfeedLayout />}>
                        {/* <Route path={ConfigRouter.newFeed} element={<NewFeed />} /> */}
                        <Route path={ConfigRouter.newFeed} element={<NewFeedMap />} />
                    </Route>

                    <Route
                        path="/login"
                        element={
                            <AnonymousRoute>
                                <AuthLogin3 />
                            </AnonymousRoute>
                        }
                    />

                    <Route
                        path={ConfigRouter.forgotPassword}
                        element={
                            <AnonymousRoute>
                                <AuthForgotPassword />
                            </AnonymousRoute>
                        }
                    />

                    <Route
                        path="form/forgot_password"
                        element={
                            <AnonymousRoute>
                                <AuthForgotPasswordFrom />
                            </AnonymousRoute>
                        }
                    />

                    <Route path="*" element={<Navigate to="/" />} />

                    <Route path={ConfigRouter.treeviewerShare} element={<TreeViewer onlyView />} />

                    <Route
                        path={ConfigRouter.homePage}
                        element={
                            <PrivateRoute>
                                <HomePage />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        element={
                            <PrivateRoute>
                                <MainLayout />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            path={ConfigRouter.genealogy}
                            element={
                                <PrivateRoute>
                                    <BuildGenealogy />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.treeviewer}
                            element={
                                <PrivateRoute>
                                    <TreeViewer />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.userInfo}
                            element={
                                <PrivateRoute>
                                    <DetailUser />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.detailFamily}
                            element={
                                <PrivateRoute>
                                    <DetailFamily />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.detaiMember}
                            element={
                                <PrivateRoute>
                                    <DetailMember />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.listMember}
                            element={
                                <PrivateRoute>
                                    <ListMember />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.configMaster}
                            element={
                                <PrivateRoute>
                                    <MConfiguration />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.listAccount}
                            element={
                                <PrivateRoute>
                                    <LisAccount />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.listService}
                            element={
                                <PrivateRoute>
                                    <ListService />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.event}
                            element={
                                <PrivateRoute>
                                    <EventManager />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.diaryFamily}
                            element={
                                <PrivateRoute>
                                    <DiaryFamily />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.listCreator}
                            element={
                                <PrivateRoute>
                                    <Creator />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.ancestralHouseList}
                            element={
                                <PrivateRoute>
                                    <AncestralHouse />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.listSymstemLog}
                            element={
                                <PrivateRoute>
                                    <SystemLog />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.listOtherProduct}
                            element={
                                <PrivateRoute>
                                    <OtherProduct />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.listPatriarch}
                            element={
                                <PrivateRoute>
                                    <MainCard
                                        sx={{ height: 'calc(100dvh - 122px)' }}
                                        contentSX={{
                                            padding: { xs: '10px !important', sm: '24px !important' },
                                            height: { xs: '100%', sm: 'auto' }
                                        }}
                                    >
                                        <TableFamily />
                                    </MainCard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.statisticalFamily}
                            element={
                                <PrivateRoute>
                                    <MainCard
                                        sx={{ height: { xs: 'calc(100dvh - 92px)', md: 'calc(100dvh - 122px)' } }}
                                        contentSX={{
                                            padding: { xs: '10px !important', sm: '24px !important' },
                                            height: { xs: '100%', sm: 'auto' }
                                        }}
                                    >
                                        <StatisticalFamily />
                                    </MainCard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.historyFamily}
                            element={
                                <PrivateRoute>
                                    <HistoryFamily />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.graveFamily}
                            element={
                                <PrivateRoute>
                                    <MainCard
                                        sx={{ height: 'calc(100dvh - 122px)' }}
                                        contentSX={{
                                            padding: { xs: '10px !important', sm: '24px !important' },
                                            height: { xs: '100%', sm: 'auto' }
                                        }}
                                    >
                                        <GraveFamily />
                                    </MainCard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.albumFamily}
                            element={
                                <PrivateRoute>
                                    <MainCard
                                        sx={{ height: { sm: 'calc(100dvh - 122px)', xs: 'calc(100dvh - 100px)' } }}
                                        contentSX={{
                                            padding: { xs: '10px !important', sm: '24px !important' },
                                            height: { xs: '100%', sm: 'auto' }
                                        }}
                                    >
                                        <AlbumFamily />
                                    </MainCard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.documentFamily}
                            element={
                                <PrivateRoute>
                                    <MainCard
                                        sx={{ height: { sm: 'calc(100dvh - 122px)', xs: 'calc(100dvh - 100px)' } }}
                                        contentSX={{
                                            padding: { xs: '10px !important', sm: '24px !important' },
                                            height: { xs: '100%', sm: 'auto' }
                                        }}
                                    >
                                        <DocumentFamily />
                                    </MainCard>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path={ConfigRouter.settingNewfeed}
                            element={
                                <PrivateRoute>
                                    <SettingNewfeed />
                                </PrivateRoute>
                            }
                        />
                    </Route>
                    <Route path="/diary/:idFamily/:slug" element={<DiaryFamilyPublic />} />
                </Routes>
                <Snackbar
                    ContentProps={{
                        sx: {
                            background: customization?.themeDongHo?.heading
                        }
                    }}
                    open={openNewVersionModal}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="Đã có phiên bản mới"
                    action={action}
                />
            </HelmetProvider>
        </>
    );
}
