/* eslint-disable import/prefer-default-export */
import { Navigate } from 'react-router-dom';
import ConfigRouter from 'routes/config_router';

export const AnonymousRoute = ({ children }) => {
    const user = localStorage.getItem('DATA_USER');

    if (user) {
        return <Navigate to={ConfigRouter.homePage} />;
    }

    return children;
};
