import { Box, CircularProgress } from '@mui/material';
import ImageHeader from '../assets/images/icons/bggiapha.jpg';
function LoadingTable() {
    return (
        <>
           
            <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        height: '100%',
                        p: 4,
                        border: 'none',
                        // backgroundColor: 'white'
                        backgroundImage: `url(${ImageHeader})`
                    }}
                >
                    <CircularProgress />
            </Box>
        </>
    );
}

export default LoadingTable;
