import { Box, Button, Grid, IconButton, TextField, Typography, Modal, Stack, Divider } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import SaveIcon from '@mui/icons-material/Save';
import DialogUtil from 'ui-component/show_confirm_action';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import NotFound from 'ui-component/404/404';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { xs: '500%', sm: '50%' },
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 3,
    minWidth: { xs: '80%', sm: '45%' },
    height: { md: 'auto', xs: '85vh' }
    // overflowY: 'auto',
};

const ModalHistory = ({ data, open, onClose, type }) => {
    const divRef = useRef(null);
    const [inputs, setInputs] = useState([]);
    const customization = useSelector((state) => state.customization);
    const [formErrors, setFormErrors] = useState([]);
    const [timeErrors, setTimeErrors] = useState([]);

    const handleClose = () => {
        setInputs([]);
        setFormErrors([]);
        setTimeErrors([]);
        onClose();
    };

    const generateRandomString = () => {
        const length = 4;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    };

    const getDetail = async () => {
        const obj = {
            _id: data?._id
        };
        const rst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseDetail, obj);
        if (rst.status === 201) {
            const dataRst = rst?.data[0]?.list_history;
            if (dataRst?.length > 0) {
                dataRst.map((elemrnt) => {
                    elemrnt._id = generateRandomString();
                });
                dataRst.sort((a, b) => (parseInt(a.time) > parseInt(b.time) ? 1 : -1));
                setInputs(dataRst);
            }
        }
    };

    useEffect(() => {
        if (open === true) {
            getDetail();
        }
    }, [open]);

    const handleAddInput = (e, id) => {
        e.stopPropagation();
        const index = inputs.findIndex((obj) => obj._id === id);
        const arrayObjects = [...inputs];
        const newId = generateRandomString();
        const newObject = { _id: newId, time: '', content: '' };

        if (index !== -1) {
            arrayObjects.splice(index + 1, 0, newObject);
            setInputs(arrayObjects);
        }
    };

    const handleAddInputNew = (e) => {
        e.stopPropagation();
        const newId = generateRandomString();
        const arrayObjects = [...inputs];
        const newObject = { _id: newId, time: '', content: '' };
        arrayObjects.push(newObject);
        setInputs(arrayObjects);
    };

    const removeError = (indexs, fields, data) => {
        const filteredData = data.filter((item) => !(item.index === indexs && item.field === fields));
        setFormErrors(filteredData);
    };

    const removeErrorTime = (indexs, fields, data) => {
        const filteredData = data.filter((item) => !(item.index === indexs && item.field === fields));
        setTimeErrors(filteredData);
    };

    const handleChange = (index, field, event) => {
        const values = [...inputs];

        if (event.target.value.length <= 1001) {
            values[index].content = event.target.value;
            removeError(index, field, formErrors);
            setInputs(values);
        }
    };

    const handleChangeTime = (index, field, event) => {
        const values = [...inputs];
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            values[index].time = event.target.value;
            removeErrorTime(index, field, timeErrors);
            setInputs(values);
        }
    };

    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    const actionSave = async () => {
        const arr = [];
        inputs.forEach((element) => {
            const obj = {
                time: element.time,
                content: element.content
            };
            arr.push(obj);
        });
        const obj = {
            _id: data?._id,
            list_history: arr
        };
        const rst = await fetchClient.postRequest(router.SUB_URL.AncestralHouseHistory, obj);
        if (rst.status === 201) {
            DialogUtil.showQuestions(true, 'Thêm thông tin lịch sử nhà thờ thành công', () => {
                handleClose();
            });
        }
    };

    const validateForm = () => {
        const errors = [];
        const err = [];

        inputs.forEach((input, index) => {
            if (!input.content.trim()) {
                errors.push({ index, field: 'content', message: 'Nội dung không được để trống' });
            }
            if (!input.time.trim()) {
                err.push({ index, field: 'time', message: 'Thời gian không được để trống' });
            }
        });

        setFormErrors(errors);
        setTimeErrors(err);
        return errors.length === 0 && err.length === 0;
    };

    const onSave = async () => {
        const isValid = validateForm();
        if (isValid) {
            DialogUtil.showConfirm(
                'Thông báo',
                `Bạn có muốn thêm mới lịch sử xây dựng và sửa chữa nhà thờ không?`,
                () => {},
                () => actionSave()
            );
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                handleClose();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // alignItems: 'center',
                        marginY: '15px',
                        padding: { md: '0px', xs: '10px' }
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography id="modal-modal-title" variant="h3" component="h2">
                            Lịch sử xây dựng và sửa chữa nhà thờ: {data?.name ?? ''}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column-reverse' }, justifyContent: 'flex-end' }}>
                            {inputs?.length > 0 ? (
                                <Button
                                    sx={{
                                        marginRight: { md: '5px', xs: '0px' },
                                        marginTop: { md: '0px', xs: '5px' },
                                        display: { md: type ? 'none' : 'flex', xs: type ? 'none' : 'flex' }
                                    }}
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    onClick={() => onSave()}
                                >
                                    Lưu
                                </Button>
                            ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    {!type && (
                                        <Button
                                            onClick={(e) => handleAddInputNew(e)}
                                            size="small"
                                            disableElevation
                                            variant="contained"
                                            color="primary"
                                            sx={{ whiteSpace: 'nowrap', marginTop: '10px' }}
                                            startIcon={<AddIcon />}
                                        >
                                            Thêm
                                        </Button>
                                    )}
                                </Box>
                            )}
                            <Button size="small" variant="contained" onClick={() => handleClose()} startIcon={<Close />}>
                                Đóng
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }} />
                <Box
                    ref={divRef}
                    sx={{
                        maxHeight: { md: '500px', xs: 'calc(85dvh - 180px)' },
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        paddingRight: '4px',
                        '&::-webkit-scrollbar': {
                            width: '0.2em'
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: customization?.themeDongHo?.heading,
                            outline: 'slategrey'
                        }
                    }}
                >
                    {inputs?.length > 0 ? (
                        inputs?.map((item, index) => (
                            <Grid
                                key={index}
                                spacing={1}
                                container
                                sx={{
                                    marginLeft: '0px',
                                    width: '100%',
                                    paddingBottom: { md: '5px', xs: '5px' },
                                    marginBottom: { md: '5px', xs: '5px' },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingRight: '4px',
                                    marginTop: '0px'
                                }}
                            >
                                <Grid item md={type ? 2 : 2.5} xs={12}>
                                    <TextField
                                        id={index}
                                        sx={{
                                            pointerEvents: type === true ? 'none' : ''
                                        }}
                                        fullWidth
                                        label="Thời gian"
                                        name={`time`}
                                        value={item?.time ?? ''}
                                        placeholder="Nhập thời gian"
                                        onChange={(e) => handleChangeTime(index, 'time', e)}
                                        error={
                                            !!item.titleError ||
                                            !!timeErrors.find((error) => error.index === index && error.field === 'time')
                                        }
                                        helperText={
                                            item.titleError ||
                                            timeErrors.find((error) => error.index === index && error.field === 'time')?.message ||
                                            ''
                                        }
                                    />
                                </Grid>
                                <Grid item md={type ? 10 : 8} xs={12}>
                                    <TextField
                                        id={index}
                                        fullWidth
                                        sx={{
                                            pointerEvents: type === true ? 'none' : ''
                                        }}
                                        label="Nội dung"
                                        name={`content`}
                                        value={item?.content ?? ''}
                                        placeholder="Nhập nội dung"
                                        onChange={(e) => handleChange(index, 'content', e)}
                                        error={
                                            !!item.titleError ||
                                            !!formErrors.find((error) => error.index === index && error.field === 'content')
                                        }
                                        helperText={
                                            item.titleError ||
                                            formErrors.find((error) => error.index === index && error.field === 'content')?.message ||
                                            ''
                                        }
                                    />
                                </Grid>
                                {!type && (
                                    <Grid
                                        item
                                        md={1.5}
                                        xs="12"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <IconButton onClick={(e) => handleAddInput(e, item?._id)} aria-label="thêm mới" color="primary">
                                            <AddIcon />
                                        </IconButton>
                                        <IconButton onClick={(e) => handleRemoveInput(index, e)} aria-label="xóa" color="primary">
                                            <HorizontalRuleIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        ))
                    ) : (
                        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} colSpan={12}>
                            <NotFound />
                        </Stack>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalHistory;
