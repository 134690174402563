// theme constant
export const gridSpacing = 3;
export const drawerWidthOld = 260;
export const drawerWidth = 240;
export const appDrawerWidth = 320;
export const dataUser = 'DATA_USER';
export const DATA_FAMILY_TITLE = 'm_family_title';
export const DATA_FAMILY_LOGO = 'm_family_logo';
export const DATA_NATIONAL = 'm_nationality';
export const DATA_RELIGION = 'm_religion';
export const DATA_ETHNIC = 'm_ethnic';
export const DATA_GENRE = 'm_genre';
export const DATA_ATHOUR_ROLE = 'm_athour_role';
export const DATA_CAREER = 'm_career';
export const DATA_EDUCATION = 'm_education';
export const DATA_PRIZE = 'm_prize';
export const dataFamily = 'DATA_FAMILY';
export const DATA_FONT = 'm_family_font';
export const DATA_LANGUAGE = 'm_language';
