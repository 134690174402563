import { Box, Typography, CssBaseline, useMediaQuery } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './Header';
import HeaderV2 from './Header/indexV2';
import FooterNewFeed from './Footer';
import { useEffect, useState } from 'react';
import fetchClient from 'views/utilities/RestfulAPI';
import router from 'routes/router';
import BgLayout_1 from '../../assets/images/layout/bg_layout_1.png';
import BgLayout_2 from '../../assets/images/layout/bg_layout_2.png';
import BgNewfeed_2 from '../../views/pages/newfeed/assets/bg-newfeed-2_2.jpg';
import { ROOT } from 'constant/roleAlias';
import DialogUtil from 'ui-component/show_confirm_action';
import ConfigRouter from 'routes/config_router';
import ScrollTop from './ScrollTop';
import config from 'config';
// import Login from 'views/pages/authentication/authentication3/Login3';
import LoginNewFeed from 'views/pages/newfeed/component/login_newfeed/loginNewFeed';
import Loading from 'views/pages/authentication/Loading';
import canhHoa1 from 'views/pages/newfeed/assets/canh-hoa-1.png';
import canhHoa2 from 'views/pages/newfeed/assets/canh-hoa-2.png';
import Snowfall from 'react-snowfall';

const flower1 = document.createElement('img');
flower1.src = canhHoa1;
const flower2 = document.createElement('img');
flower2.src = canhHoa2;

// ==============================|| NEWFEED LAYOUT ||============================== //
const ARR = ['album', 'diary', 'document'];
const getTotalElementShow = (data) => {
    let count = 0;
    Object.keys(data).map((item, index) => {
        const keyValue = data[item];
        if (Array.isArray(keyValue)) {
            const isContant = keyValue.find((item) => item?.isShow);
            if (isContant) {
                count++;
            }
        } else if (typeof keyValue === 'object') {
            switch (item) {
                case 'data_exists':
                    Object.keys(keyValue).map((each) => {
                        if (ARR.includes(each) && keyValue[each]) {
                            count++;
                        }
                    });
                    break;

                default:
                    if (keyValue?.isShow) {
                        count++;
                    }
                    break;
            }
        }
    });
    return count;
};

const NewfeedLayout = () => {
    const smallScreen = useMediaQuery('(max-width: 600px)');
    const laptopScreen = useMediaQuery('(max-width: 1360px)');
    const mediumScreen = useMediaQuery('(max-width: 900px)');
    const navigate = useNavigate();
    const [dataSetting, setDataSetting] = useState({});
    const [dataNotification, setDataNotification] = useState([]);
    const [dataContact, setDataContact] = useState([]);
    const [logo, setLogo] = useState('');
    const [nameFamily, setNameFamily] = useState('');
    const [homeTown, setHomeTown] = useState('');
    const [addressChurch, setAddressChurch] = useState('');
    const [layoutKey, setLayoutKey] = useState('LAYOUT001');
    const [bgHomePage, setBgHomePage] = useState(BgLayout_1);
    const [showAnimation, setShowAnimation] = useState(false);
    const [bgSize, setBgSize] = useState('cover');

    // const [isPackageFree, setIsPackageFree] = useState(false);
    if (window.location.host === 'dungthu.giaphaso.com') {
        navigate(ConfigRouter.login);
    }

    // const user = localStorage.getItem('DATA_USER') ?? '';
    const getDataFamily = async () => {
        const rst = await fetchClient.getRequest(router.SUB_URL.getInforFamilyHomePage);
        if (rst?.status === 200) {
            setLogo(rst.data?.logo?.config_logo);
            setNameFamily(rst.data?.infoFamily?.family_name);
            setHomeTown(rst?.data?.infoFamily?.family_hometown);
            setAddressChurch(rst?.data?.infoFamily?.family_address_church);
        } else {
            console.log('rst', rst);
        }
    };

    const getInfoPacketDomain = async () => {
        const obj = { domain: window.location.origin };
        const rst = await fetchClient.postRequest(router.SUB_URL.getPackageByDomain, obj);
        if (rst?.status === 200) {
            const packageFamily = rst.data?.data[0]?.is_trial ?? '';
            if (packageFamily === true) {
                return navigate(ConfigRouter.login);
            } else {
                return true;
            }
        } else {
            // DialogUtil.showConfirm
            console.log('Lỗi');
        }
        
    };

    const staticMember = async () => {
        const data = await fetchClient.getRequest(router.SUB_URL.staticMemberHomePage);
        // console.log('datastatic', data);
    };
    const getData = async () => {
        const rst = await fetchClient.postRequest(router.SUB_URL.getSettingHomePage);
        if (rst?.status === 201) {
            const dataNotifi = rst?.data?.notification ?? [];
            if (dataNotifi.length > 0) {
                dataNotifi.sort(function (a, b) {
                    var c = new Date(a?.time_notification);
                    var d = new Date(b?.time_notification);
                    return d - c;
                });
            }
            setDataNotification(dataNotifi);
            setDataSetting(rst?.data);
            setDataContact(rst?.data?.social ?? []);
            // console.log('aaa3', rst?.data?.is_show)
            // console.log('bbbb3', localStorage.getItem('DATA_USER'))
            if (rst?.data?.is_show === false && localStorage.getItem('DATA_USER') !== '' && localStorage.getItem('DATA_USER') !== null) {
                return navigate(ConfigRouter.homePage);
            }
        } else {
            console.log('rst', rst);
        }
    };
    const [loading, setLoading] = useState(false);
    const getAllData = async () => {
        setLoading(true);
        await getInfoPacketDomain();
        await getData();
        await staticMember();
        await getDataFamily();
        setLoading(false);
    };
    useEffect(() => {
        if (window.location.host !== 'dungthu.giaphaso.com') {
            getAllData();
        }
    }, []);

    const HEADER_MAP = [
        {
            key: 'LAYOUT001',
            component: (
                <Header
                    dataNotification={dataNotification}
                    dataContact={dataContact}
                    logo={logo}
                    nameFamily={nameFamily}
                    dataSetting={dataSetting}
                />
            ),
            backgroundImage: BgLayout_1,
            bgSize: 'cover',
            showAnimation: false
        },
        {
            key: 'LAYOUT002',
            component: (
                <HeaderV2
                    dataNotification={dataNotification}
                    dataContact={dataContact}
                    logo={logo}
                    nameFamily={nameFamily}
                    dataSetting={dataSetting}
                />
            ),
            backgroundImage: BgNewfeed_2,
            bgSize: '100px',
            showAnimation: true
        }
    ];

    useEffect(() => {
        if (dataSetting) {
            const layoutKey = dataSetting?.layout && dataSetting?.layout != [] ? dataSetting?.layout : 'LAYOUT001';

            setLayoutKey(layoutKey);
            console.log(HEADER_MAP.filter((layout) => layout.key === layoutKey)[0]?.backgroundImage);
            setBgHomePage(HEADER_MAP.filter((layout) => layout.key === layoutKey)[0]?.backgroundImage);
            setBgSize(HEADER_MAP.filter((layout) => layout.key === layoutKey)[0]?.bgSize);
            setShowAnimation(HEADER_MAP.filter((layout) => layout.key === layoutKey)[0]?.showAnimation);
            // setBgHomePage(HEADER_MAP.filter(layout => layout.key == layoutKey)?.backgroundImage)
        }
    }, [dataSetting]);

    if (loading) {
        return <Loading />;
    } else {
        return (
            // <Box sx={{ height: { md: 'auto', xs: 'calc(100dvh)' }, backgroundColor: '#FFFAE8' }}>
            <Box
                sx={{
                    minHeight: '100vh',
                    height: 'auto',
                    color: 'white'
                }}
            >
                <CssBaseline />

                {showAnimation && (
                    <Snowfall
                        images={[flower1, flower2]}
                        radius={[3.0, 15.0]}
                        speed={[1.0, 2.0]}
                        snowflakeCount={20}
                        style={{
                            zIndex: '99999',
                            position: 'fixed',
                            width: '100vw',
                            height: '100vh'
                        }}
                    />
                )}

                {HEADER_MAP.map((layout) => {
                    if (layout.key === layoutKey) {
                        return layout.component;
                    }
                })}

                <Box
                    sx={{
                        width: '100%',
                        paddingX: smallScreen ? '2.5%' : laptopScreen ? '5%' : '10%',
                        color: 'white',
                        margin: 'auto',
                        marginTop: '-20px',
                        paddingBottom: '20px',
                        backgroundImage: `url(${bgHomePage})`,
                        backgroundSize: bgSize
                        // backgroundAttachment: 'fixed'
                    }}
                >
                    <Outlet logo={logo} context={[dataSetting]} />
                </Box>
                <ScrollTop />
                <FooterNewFeed
                    logo={logo}
                    nameFamily={nameFamily}
                    data={dataContact}
                    hometown={homeTown}
                    addressChurch={addressChurch}
                    dataSetting={dataSetting}
                />
            </Box>
        );
    }
};

export default NewfeedLayout;
