import * as actionTypes from '../action/actions';

export const initialState = {
    dataNational: [],
    dataReligion: []
};

const dataCommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DATA_NATIONAL:
            return {
                ...state,
                dataNational: action.dataNational
            };
        case actionTypes.DATA_RELIGION:
            return {
                ...state,
                dataReligion: action.dataReligion
            };
        default:
            return state;
    }
};
export default dataCommonReducer;
